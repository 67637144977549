import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'
import { StyledHeader, StyledTitle } from '@/shared/components/Panel/styled-components'

const BonusCard = ({ title }) => {
  const [css, theme] = useStyletron()
  const cardStyle = css({
    backgroundColor: 'white',
    color: theme.colors.accent700,
    padding: '1rem',
    margin: '1rem',
    borderRadius: '1rem',
    boxShadow: theme.lighting.shadow400,
  })
  return (
    <div className={cardStyle}>
      <StyledHeader>
        <StyledTitle>{title}</StyledTitle>
      </StyledHeader>
    </div>
  )
}
BonusCard.propTypes = {
  title: PropTypes.string,
}

export default BonusCard
