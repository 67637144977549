import PropTypes from 'prop-types'
import { Notification } from 'baseui/notification'

const SubscribersToolsErrorNotification = ({ error }) => {
  if (!error || error === '') {
    return
  }

  return (
    <Notification
      overrides={{
        Body: {
          style: ({ $theme }) => ({
            outline: `${$theme.colors.warning200} solid`,
            backgroundColor: $theme.colors.warning200,
            width: '100%',
            margin: '.25rem 0rem .75rem 0rem',
          }),
        },
      }}
    >
      {error}
    </Notification>
  )
}

SubscribersToolsErrorNotification.propTypes = {
  error: PropTypes.string,
}

export default SubscribersToolsErrorNotification
