import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { StyledTable, SortableHeadCell, StyledBodyCell } from 'baseui/table-grid'
import { withStyle, useStyletron } from 'baseui'
import { SIZE } from 'baseui/button'
import IconCarbonCheckmarkOutline from '@/shared/icons/IconCarbonCheckmarkOutline'
import IconCarbonPauseOutline from '@/shared/icons/IconCarbonPauseOutline'
import IconCarbonWarningAlt from '@/shared/icons/IconCarbonWarningAlt'
import { useTranslation } from 'react-i18next'
import useOfferManagement from '../../OrganizationShow/Common/useOfferManagement'
import useDashboardTableStyles from '../../../DashboardPage/DashboardTable/useDashboardTableStyles'

const TelecomOffersTable = ({
  items,
  providerUuid,
  duplicateRoute,
  deleteRoute,
  providerDispatch,
  loadProviderData,
}) => {
  const { t } = useTranslation()
  const [, theme] = useStyletron()
  const navigate = useNavigate()
  const { deleteOffer, duplicateOffer, handleSorting, sort, getSortedData } = useOfferManagement({
    t,
    duplicateRoute,
    deleteRoute,
    providerDispatch,
    loadProviderData,
    providerUuid,
  })
  const { tableRow, headingDisplay, headCellOverrides } = useDashboardTableStyles()

  const Table = withStyle(StyledTable, {
    alignContent: 'start',
  })

  return (
    <Table role='grid' $gridTemplateColumns='40% 6% 7% 7% 10% 30%'>
      <div role='row' className={headingDisplay}>
        <SortableHeadCell
          title={t('organizationManagement:name')}
          direction={sort.sortingKey === 'name' ? sort.direction : ''}
          onSort={() => handleSorting('name')}
          overrides={headCellOverrides}
        />
        <SortableHeadCell
          title={t('organizationManagement:table.offer_type')}
          direction={sort.sortingKey === 'product_type' ? sort.direction : ''}
          onSort={() => handleSorting('product_type')}
          overrides={headCellOverrides}
        />
        <SortableHeadCell
          title={t('organizationManagement:table.complete')}
          direction={sort.sortingKey === 'complete' ? sort.direction : ''}
          onSort={() => handleSorting('complete')}
          overrides={headCellOverrides}
        />
        <SortableHeadCell
          title={t('organizationManagement:table.status')}
          direction={sort.sortingKey?.includes('status') ? sort.direction : ''}
          onSort={() => handleSorting('status')}
          overrides={headCellOverrides}
        />
        <SortableHeadCell
          title={t('organizationManagement:table.inactive_date')}
          direction={sort.sortingKey?.includes('inactiveDate') ? sort.direction : ''}
          onSort={() => handleSorting('inactiveDate')}
          overrides={headCellOverrides}
        />
        <SortableHeadCell title={t('common:actions')} overrides={headCellOverrides} />
      </div>
      {getSortedData(items).map((row, index) => {
        const striped = index % 2 === 0
        return (
          <div role='row' className={tableRow} key={row.id}>
            <StyledBodyCell $striped={striped}>{row.name}</StyledBodyCell>
            <StyledBodyCell $striped={striped}>
              {t('organizationManagement:offer.product_type_' + row.product_type)}
            </StyledBodyCell>
            <StyledBodyCell $striped={striped}>
              {row.is_complete ? (
                <IconCarbonCheckmarkOutline color='#197676' size={32} />
              ) : (
                <IconCarbonWarningAlt color='#FF0000' size={32} />
              )}
            </StyledBodyCell>
            <StyledBodyCell $striped={striped}>
              {row.active ? (
                <IconCarbonCheckmarkOutline color='#197676' size={32} />
              ) : (
                <IconCarbonPauseOutline color='#FF0000' size={32} />
              )}
            </StyledBodyCell>
            <StyledBodyCell $striped={striped}>{row.inactive_date ? row.inactive_date : '-'}</StyledBodyCell>
            <StyledBodyCell $striped={striped}>
              <>
                <SDButton
                  key={`modify-${row.id}`}
                  onClick={() =>
                    navigate('/telecom/organisations/provider/' + providerUuid + '/offers/' + row.id + '/edit')
                  }
                  size={SIZE.compact}
                  style={{ marginRight: '1rem' }}
                >
                  {t('common:edit')}
                </SDButton>
                <SDButton
                  key={`duplicate-${row.id}`}
                  onClick={() => duplicateOffer(row.id)}
                  size={SIZE.compact}
                  style={{ marginRight: '1rem', backgroundColor: theme.colors.accent500 }}
                >
                  {t('common:duplicate')}
                </SDButton>
                <SDButton
                  key={`delete-${row.id}`}
                  onClick={() => deleteOffer(row.id)}
                  size={SIZE.compact}
                  style={{ backgroundColor: theme.colors.negative500 }}
                >
                  {t('common:delete')}
                </SDButton>
              </>
            </StyledBodyCell>
          </div>
        )
      })}
    </Table>
  )
}

TelecomOffersTable.propTypes = {
  items: PropTypes.array,
  providerUuid: PropTypes.string,
  deleteRoute: PropTypes.string,
  duplicateRoute: PropTypes.string,
  providerDispatch: PropTypes.func,
  loadProviderData: PropTypes.func,
}

export default TelecomOffersTable
