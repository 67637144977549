import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  formErrors: {},
  selectedAssistance: {
    id: 0,
    name: '',
  },
}

const updateAssistanceFormErrorsHandler = (state, action) => {
  state.formErrors = action.payload
}

const updateSelectedAssistanceHandler = (state, action) => {
  state.selectedAssistance = action.payload
}

export const AssistancesSlice = createSlice({
  name: 'assistances',
  initialState,
  reducers: {
    updateAssistancesFormErrors: updateAssistanceFormErrorsHandler,
    updateSelectedAssistance: updateSelectedAssistanceHandler,
    clearAssistances: () => initialState,
  },
})

export const { updateAssistancesFormErrors, updateSelectedAssistance, clearAssistances } = AssistancesSlice.actions

export default AssistancesSlice.reducer
