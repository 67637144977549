import PropTypes from 'prop-types'
import { StyledBodyCell } from 'baseui/table-grid'
import StatusBadge from './StatusBadge'
import { ACCESSIBILITY_TYPE, StatefulTooltip } from 'baseui/tooltip'
import { useStyletron } from 'baseui'
import getSubscriptionStatusColour from './getSubscriptionStatusColour'

const StatusCell = ({ status, errors, striped, selected, noStatusText }) => {
  const [css] = useStyletron()
  const listStyle = css({
    padding: '0',
    margin: '0',
    listStyle: 'none',
  })
  const cellStyle = css({
    padding: '.5rem',
    backgroundColor: selected ? '#c1d1e2' : 'none',
  })
  const colour = getSubscriptionStatusColour(status)
  const computedStatus = status.length > 0 ? status : noStatusText

  if (errors && errors.length > 0) {
    return (
      <StyledBodyCell className={cellStyle} $striped={striped && !selected}>
        <StatefulTooltip
          content={() => {
            const children = []
            errors.forEach((error, index) => {
              children.push(
                <li key={index}>
                  {error}
                  <br />
                </li>,
              )
            })

            return <ul className={listStyle}>{children}</ul>
          }}
          showArrow
          returnFocus
          autoFocus
          accessibilityType={ACCESSIBILITY_TYPE.tooltip}
        >
          <span>
            <StatusBadge status={computedStatus} colour={colour} />
          </span>
        </StatefulTooltip>
      </StyledBodyCell>
    )
  }

  return (
    <StyledBodyCell className={cellStyle} $striped={striped && !selected}>
      <StatusBadge status={computedStatus} colour={colour} />
    </StyledBodyCell>
  )
}

StatusCell.propTypes = {
  status: PropTypes.string,
  errors: PropTypes.array,
  striped: PropTypes.bool,
  selected: PropTypes.bool,
  noStatusText: PropTypes.string,
}

export default StatusCell
