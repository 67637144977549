import { useStyletron } from 'baseui'
import SubscriptionStatusStepItem from './SubscriptionStatusStepItem'
import getSubscriptionStatusStep from './getSubscriptionStatusStep'
import SubscriptionStatusError from './SubscriptionStatusError'
import { useTranslation } from 'react-i18next'
import useBusinessType from '@/shared/hooks/useBusinessType'
import { useSelector } from 'react-redux'
import { selectCommonFormConfigurationData } from '../../../../../containers/SubscriptionFormPage/SubscriptionFormConfigurationSelectors'

const SubscriptionStatusStep = () => {
  const { subscriptionStatus, statusErrors } = useSelector(selectCommonFormConfigurationData)
  const status = subscriptionStatus ?? null
  const { type } = useBusinessType({
    loaderRoutesEnergy: {},
    loaderRoutesInsurance: {},
    loaderRoutes: {},
    modulesStatuses: {},
    modulesStatusesEnergy: {},
    modulesStatusesInsurance: {},
  })
  const { t } = useTranslation()
  const [css] = useStyletron()
  const itemStyle = css({
    display: 'flex',
    flexDirection: 'row',
  })
  const statusError = statusErrors.length > 0 ? statusErrors : null
  const stepsConfig = getSubscriptionStatusStep(status ?? '')

  return (
    <>
      <div className={itemStyle}>
        <SubscriptionStatusStepItem
          stepConfig={stepsConfig.step1}
          name={t('subscriptionForm:header.steps_names.dossier')}
        />
        <SubscriptionStatusStepItem
          stepConfig={stepsConfig.step2}
          name={t('subscriptionForm:header.steps_names.complete')}
        />
        <SubscriptionStatusStepItem
          stepConfig={stepsConfig.step3}
          name={t('subscriptionForm:header.steps_names.transmission')}
        />
        <SubscriptionStatusStepItem
          stepConfig={stepsConfig.step4}
          name={t('subscriptionForm:header.steps_names.transferred')}
        />
        <SubscriptionStatusStepItem
          stepConfig={stepsConfig.step5}
          name={
            type === 'telecom' || type === 'assurance'
              ? t('subscriptionForm:header.steps_names.net')
              : t('subscriptionForm:header.steps_names.signed')
          }
        />
        {statusError && <SubscriptionStatusError error={statusError} />}
      </div>
    </>
  )
}

export default SubscriptionStatusStep
