import { createSlice } from '@reduxjs/toolkit'
import dashboardData from './dashboardData'

const initialState = {
  ...dashboardData,
}

const updateDashboardFiltersHandler = (state, action) => {
  state.filters = action.payload
}

const updateDashboardUrlHandler = (state, action) => {
  state.url = action.payload
}

const updateTableIsLoadingHandler = (state, action) => {
  state.tableLoading = action.payload
}

const updateTableDataHandler = (state, action) => {
  state.data = action.payload
}

const updateCurrentSubscriptionDetailsHandler = (state, action) => {
  state.currentSubscription = action.payload
}

const updateDashboardPageHandler = (state, action) => {
  state.page = action.payload
}

export const DashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    updateDashboardFilters: updateDashboardFiltersHandler,
    updateDashboardUrl: updateDashboardUrlHandler,
    updateTableIsLoading: updateTableIsLoadingHandler,
    updateTableData: updateTableDataHandler,
    updateCurrentSubscriptionDetails: updateCurrentSubscriptionDetailsHandler,
    updateDashboardPage: updateDashboardPageHandler,
    clearDashboard: () => initialState,
  },
})

export const {
  updateDashboardFilters,
  updateDashboardUrl,
  clearDashboard,
  updateTableIsLoading,
  updateTableData,
  updateCurrentSubscriptionDetails,
  updateDashboardPage,
} = DashboardSlice.actions

export default DashboardSlice.reducer
