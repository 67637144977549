import PropTypes from 'prop-types'
import SDCard from '@/shared/components/SDComponents/SDCard'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { ListItemLabel } from 'baseui/list'
import { useTranslation } from 'react-i18next'

const SalesAgentBonusStats = ({ bonuses }) => {
  const { t } = useTranslation()
  const infos = bonuses.infos ?? null
  const deciles = bonuses.deciles ?? null
  const transformation = bonuses.transformation ?? null
  const leads = bonuses.leads ?? null
  const contract_per_hour = bonuses.contract_per_hour ?? null
  const superwoman = bonuses.superwoman ?? null
  const quality = bonuses.quality ?? null
  const ekomi = bonuses.ekomi ?? null
  const generateList = (items, force = false) => {
    const entries = items ? Object.entries(items) : []
    const list = []
    if (entries.length === 0) {
      return ''
    }
    entries.forEach(([key, value]) => {
      if ((key !== 'total_primes' && key !== 'rank') || force === true) {
        list.push(
          <ListItemLabel
            description={value}
            overrides={{
              LabelContent: {
                style: ({ $theme }) => ({
                  paddingTop: '0.5rem',
                }),
              },
            }}
          >
            {t('home:bonus_label_' + key)}
          </ListItemLabel>,
        )
      }
    })

    return list
  }

  return (
    <FlexGrid flexGridColumnCount={3} flexGridColumnGap='scale800' flexGridRowGap='scale800'>
      <FlexGridItem>
        {infos && infos.total_primes && infos.rank && (
          <SDCard
            title={t('home:bonus_title_summary')}
            content={generateList({ total_primes: infos.total_primes, rank: infos.rank }, true)}
          />
        )}
        <SDCard title={t('home:bonus_title_info')} content={generateList(infos)} />
        <SDCard title={t('home:bonus_title_deciles')} content={generateList(deciles)} />
      </FlexGridItem>
      <FlexGridItem>
        <SDCard title={t('home:bonus_title_transfo')} content={generateList(transformation)} />
        <SDCard title={t('home:bonus_title_leads')} content={generateList(leads)} />
        <SDCard title={t('home:bonus_title_contracts')} content={generateList(contract_per_hour)} />
        <SDCard title={t('home:bonus_title_superwoman')} content={generateList(superwoman)} />
      </FlexGridItem>
      <FlexGridItem>
        <SDCard title={t('home:bonus_title_quality')} content={generateList(quality, true)} />
        <SDCard title={t('home:bonus_title_ekomi')} content={generateList(ekomi)} />
      </FlexGridItem>
    </FlexGrid>
  )
}

SalesAgentBonusStats.propTypes = {
  bonuses: PropTypes.object,
}

export default SalesAgentBonusStats
