import PropTypes from 'prop-types'
import { StyledBodyCell } from 'baseui/table-grid'
import { useStyletron } from 'baseui'
import OriginBadge from './OriginBadge'

const OriginCell = ({ origin, striped, selected }) => {
  const [css] = useStyletron()
  const cellStyle = css({
    padding: '.5rem',
    backgroundColor: selected ? '#c1d1e2' : 'none',
  })

  return (
    <StyledBodyCell className={cellStyle} $striped={striped && !selected}>
      <OriginBadge origin={origin} />
    </StyledBodyCell>
  )
}

OriginCell.propTypes = {
  origin: PropTypes.string,
  striped: PropTypes.bool,
  selected: PropTypes.bool,
}

export default OriginCell
