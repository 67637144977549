import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useStyletron } from 'styletron-react'
import { useDispatch, useSelector } from 'react-redux'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import { dashboardActions } from '../../../DashboardSagas'

const useAbandonModal = ({ handler, id }) => {
  const { t } = useTranslation()
  const [css] = useStyletron()
  const dispatch = useDispatch()
  const [reason, setReason] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setLoading] = useState(false)
  const { endpoints } = useSelector(selectPageConfigurationData)

  const handleResponse = success => {
    if (success) {
      handler()
      return
    }
    setLoading(false)
    setError(t('subscriptionTable:save_error'))
  }

  const sendReason = () => {
    setLoading(true)
    setError('')
    if (reason.length === 0) {
      setError(t('subscriptionTable:mandatory_field'))
      setLoading(false)
      return
    }
    dispatch({
      type: dashboardActions.RETRACT_SUBSCRIPTION,
      url: endpoints.abandonUrl,
      reason,
      id,
      responseCallback: handleResponse,
    })
  }

  return {
    t,
    css,
    reason,
    error,
    isLoading,
    sendReason,
    setReason,
  }
}

export default useAbandonModal
