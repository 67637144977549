import { createSelector } from 'reselect'

export const selectUsersManagement = state => state.usersManagement

export const selectUsersManagementUsers = createSelector(
  selectUsersManagement,
  usersManagement => usersManagement.users,
)

export const selectUsersManagementIsLoading = createSelector(
  selectUsersManagement,
  usersManagement => usersManagement.isLoadingUsers,
)

export const selectUsersManagementSelectedUser = createSelector(
  selectUsersManagement,
  usersManagement => usersManagement.selectedUser,
)

export const selectUsersManagementUsersError = createSelector(
  selectUsersManagement,
  usersManagement => usersManagement.usersError,
)
