import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconCarbonBuilding = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'Building'
  size = size ?? 16
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 32 32' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <path d='M28,2H16a2.002,2.002,0,0,0-2,2V14H4a2.002,2.002,0,0,0-2,2V30H30V4A2.0023,2.0023,0,0,0,28,2ZM9,28V21h4v7Zm19,0H15V20a1,1,0,0,0-1-1H8a1,1,0,0,0-1,1v8H4V16H16V4H28Z' />
      <rect x='18' y='8' width='2' height='2' />
      <rect x='24' y='8' width='2' height='2' />
      <rect x='18' y='14' width='2' height='2' />
      <rect x='24' y='14' width='2' height='2' />
      <rect x='18' y='19.9996' width='2' height='2' />
      <rect x='24' y='19.9996' width='2' height='2' />
      <rect id='_Transparent_Rectangle_' data-name='&lt;Transparent Rectangle&gt;' />
    </Icon>
  )
}

IconCarbonBuilding.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconCarbonBuilding
