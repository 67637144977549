import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconCarbonWater = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'Calendar'
  size = size ?? 16
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 32 32' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <path d='M16,24V22a3.2965,3.2965,0,0,0,3-3h2A5.2668,5.2668,0,0,1,16,24Z' />
      <path d='M16,28a9.0114,9.0114,0,0,1-9-9,9.9843,9.9843,0,0,1,1.4941-4.9554L15.1528,3.4367a1.04,1.04,0,0,1,1.6944,0l6.6289,10.5564A10.0633,10.0633,0,0,1,25,19,9.0114,9.0114,0,0,1,16,28ZM16,5.8483l-5.7817,9.2079A7.9771,7.9771,0,0,0,9,19a7,7,0,0,0,14,0,8.0615,8.0615,0,0,0-1.248-3.9953Z' />
    </Icon>
  )
}

IconCarbonWater.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconCarbonWater
