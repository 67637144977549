import { call, put, select } from 'redux-saga/effects'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import getAPIConfig from '@/shared/tools/getAPIConfig'
import { updatePropaneModuleConfiguration } from '../../../SubscriptionFormConfigurationSlice'

export const propaneModuleActions = {
  PROPANE_LOAD_ENERGY_SUPPLIERS: 'PROPANE_LOAD_ENERGY_SUPPLIERS',
  PROPANE_LOAD_QUOTE_REASONS: 'PROPANE_LOAD_QUOTE_REASONS',
  PROPANE_LOAD_VISIBILITY: 'PROPANE_LOAD_VISIBILITY'
}

export function * loadPropaneEnergySuppliers () {
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { load_energy_supplier_options_url: url } =
    yield select(selectPageConfigurationData)
  const fullUrl = `${url}?lang=${lang}&energy=propane`

  try {
    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })
    const response = yield call(fetch, fullUrl, config)
    const isValid = response.status === 200
    const responseData = yield call([response, response.json])
    if (!isValid) {
      console.warn('loadEnergySupplierError', responseData, response.status)
      return
    }
    yield put(updatePropaneModuleConfiguration({ energySuppliers: responseData }))
  } catch (error) {
    console.warn('loadEnergySupplierError', error)
  }
}

export function * loadPropaneQuoteReasons () {
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { load_quote_reason_options: url } = yield select(selectPageConfigurationData)
  const fullUrl = `${url}?lang=${lang}&energy=propane`

  try {
    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })
    const response = yield call(fetch, fullUrl, config)
    const isValid = response.status === 200
    const responseData = yield call([response, response.json])
    if (!isValid) {
      console.warn('loadQuoteReasonError', responseData, response.status)
      return
    }
    yield put(updatePropaneModuleConfiguration({ quoteReasonsList: responseData }))
  } catch (error) {
    console.warn('loadQuoteReasonError', error)
  }
}

export function * loadPropaneVisibility ({ workflow }) {
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { propane_visibility: url } = yield select(selectPageConfigurationData)
  const fullUrl = `${url}?lang=${lang}&workflowName=${workflow}`

  try {
    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })
    const response = yield call(fetch, fullUrl, config)
    const isValid = response.status === 200
    const responseData = yield call([response, response.json])
    if (!isValid) {
      console.warn('loadQuoteReasonError', responseData, response.status)
      return
    }
    yield put(updatePropaneModuleConfiguration({ propaneVisibility: responseData }))
  } catch (error) {
    console.warn('loadQuoteReasonError', error)
  }
}
