import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useHttp from '@/shared/hooks/useHttp'

const useVonageSwitchModule = (vonageStatusDb, vonageUpdateUrl) => {
  const { t } = useTranslation()
  const [vonageStatusLoading, setVonageStatusLoading] = useState(false)
  const [vonageStatus, setVonageStatus] = useState(vonageStatusDb ?? false)
  const { getConfig, sendRequest } = useHttp()
  const submitVonageStatus = () => {
    setVonageStatusLoading(true)
    sendRequest(vonageUpdateUrl, getConfig('POST'), response => {
      setVonageStatus(response.newStatus ?? false)
      setVonageStatusLoading(false)
      window.location.reload()
    })
  }

  return {
    vonageStatus,
    vonageStatusLoading,
    submitVonageStatus,
    vonageTitle: t('home:vonage_switch_title'),
  }
}

export default useVonageSwitchModule
