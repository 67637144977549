import { TYPE } from 'baseui/select'
import HOPSelect from '@/shared/components/HOPComponents/HOPSelect'

const UserSelector = ({ users, userIdSelected, setUserIdSelected }) => {
  const formatUsers = () => {
    const convertedList = {}
    for (const [key, user] of Object.entries(users)) {
      convertedList[user.id] = user.firstname + ' ' + user.name
    }
    return convertedList
  }

  return (
    <HOPSelect
      id='change-user-selector'
      type={TYPE.search}
      optionsList={formatUsers()}
      selectedValue={userIdSelected}
      onChangeHandler={params => {
        setUserIdSelected(params.value[0]?.id)
      }}
    />
  )
}

export default UserSelector
