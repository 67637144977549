import { styled } from 'baseui'

const StyledFormStatus = styled('div', ({ $theme, status }) => ({
  display: 'flex',
  paddingTop: '.5rem',
  paddingRight: '.5rem',
  paddingBottom: '.5rem',
  paddingLeft: '.5rem',
  marginBottom: '1rem',
  backgroundColor: $theme.colors[`${status}150`],
  color: $theme.colors[status],
  border: `.125rem solid ${$theme.colors[status]}`,
  ...$theme.typography.HeadingXSmall,
  fontSize: $theme.typography.ParagraphSmall.fontSize,
  lineHeight: $theme.typography.ParagraphSmall.lineHeight,
  borderTopLeftRadius: $theme.borders.radius200,
  borderTopRightRadius: $theme.borders.radius200,
  borderBottomRightRadius: $theme.borders.radius200,
  borderBottomLeftRadius: $theme.borders.radius200,
  boxShadow: $theme.lighting.shadow400,
  cursor: 'default',
  userSelect: 'none',
  '@media screen and (min-width: 768px)': {
    marginBottom: '0',
  },
}))

export default StyledFormStatus
