import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import { StyledBodyCell } from 'baseui/table-grid'
import { StyledLink } from 'baseui/link'
import { titleIconOverrides } from '@/shared/components/FormHeader/form-header-styled-components'
import IconCarbonLaunch from '@/shared/icons/IconCarbonLaunch'

const ZohoCell = ({ zohoId = null, striped }) => {
  const [css] = useStyletron()
  const iconProps = {
    size: 20,
    overrides: titleIconOverrides,
  }
  const columnStyle = css({
    display: 'flex',
    flexDirection: 'column',
  })
  const rowStyle = css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '.25rem',
  })
  const cellStyle = css({
    padding: '.5rem',
    backgroundColor: 'none',
  })
  const spanStyle = css({
    padding: '.5rem',
    backgroundColor: 'none',
  })
  return (
    <StyledBodyCell className={cellStyle} $striped={striped}>
      <div className={columnStyle}>
        <div className={rowStyle}>
          {zohoId ? (
            <div>
              <span className={spanStyle}>{zohoId}</span>
              <StyledLink href={'https://crm.zoho.com/crm/org52021474/tab/Potentials/' + zohoId} target='_blank'>
                <IconCarbonLaunch {...iconProps} />
              </StyledLink>
            </div>
          ) : (
            'N/A'
          )}
        </div>
      </div>
    </StyledBodyCell>
  )
}

ZohoCell.propTypes = {
  zohoId: PropTypes.string,
  striped: PropTypes.bool,
}

export default ZohoCell
