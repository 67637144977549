import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconCarbonPower = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'Power'
  size = size ?? 16
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 32 32' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <path d='M22.5,5.74l-1,1.73a11,11,0,1,1-11,0l-1-1.73a13,13,0,1,0,13,0Z' transform='translate(0)' />
      <rect x='15' y='2' width='2' height='14' />
    </Icon>
  )
}

IconCarbonPower.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconCarbonPower
