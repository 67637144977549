import { createSelector } from 'reselect'

export const selectedAssistances = state => state.assistances

export const selectSelectedAssistance = createSelector(
  selectedAssistances,
  assistances => assistances.selectedAssistance,
)

export const selectAssistanceFormErrors = createSelector(selectedAssistances, assistances => assistances.formErrors)
