import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { SortableHeadCell } from 'baseui/table-grid'

import SDCard from '@/shared/components/SDComponents/SDCard'
import OriginCell from '@/containers/DashboardPage/DashboardTable/bones/OriginCell'
import DatesCell from '@/containers/DashboardPage/DashboardTable/bones/DatesCell'
import OfferCell from '@/containers/DashboardPage/DashboardTable/bones/OfferCell'
import OwnerCell from '@/containers/DashboardPage/DashboardTable/bones/OwnerCell'
import AddressCell from '@/containers/DashboardPage/DashboardTable/bones/AddressCell'
import StatusAndActionsCell from '@/containers/DashboardPage/DashboardTable/bones/StatusAndActionsCell'
import DefaultCell from '@/containers/DashboardPage/DashboardTable/bones/DefaultCell'
import useCallInformationStyles from './useCallInformationStyles'
import SpinnerText from './SpinnerText'
import ProductTypeCell from '../DashboardPage/DashboardTable/bones/ProductTypeCell'

const LastSales = ({ hopSubscriptions, hopSubscriptionsLoading }) => {
  const { cardStyleLastSales, displayContents, headCellOverrides, Table, overrides } = useCallInformationStyles()
  const { t } = useTranslation()

  if (hopSubscriptionsLoading || !hopSubscriptions) {
    return <SDCard content={<SpinnerText text={t('callInformation:last_sales_module.loading')} />} />
  }
  if (hopSubscriptions.length <= 0) {
    return <SDCard content={t('callInformation:last_sales_module.no_content')} />
  }

  return (
    <div className={cardStyleLastSales}>
      <Table role='grid' $gridTemplateColumns='5% 15% 5% 20% 20% 20% 20%' overrides={overrides}>
        <div role='row' className={displayContents}>
          <SortableHeadCell title={''} overrides={headCellOverrides}  direction={null}/>
          <SortableHeadCell
            title={t('callInformation:last_sales_module.header_fields.status')}
            overrides={headCellOverrides}
            direction={null}
          />

          <SortableHeadCell
            title={t('callInformation:last_sales_module.header_fields.origin')}
            overrides={headCellOverrides}
            direction={null}
          />

          <SortableHeadCell
            title={t('callInformation:last_sales_module.header_fields.dates')}
            overrides={headCellOverrides}
            direction={null}
          />

          <SortableHeadCell
            title={t('callInformation:last_sales_module.header_fields.owner')}
            overrides={headCellOverrides}
            direction={null}
          />
          <SortableHeadCell
            title={t('callInformation:last_sales_module.header_fields.offer')}
            overrides={headCellOverrides}
            direction={null}
          />
          <SortableHeadCell
            title={t('callInformation:last_sales_module.header_fields.address')}
            overrides={headCellOverrides}
            direction={null}
          />
        </div>
        {hopSubscriptions.map((row, index) => {
          const striped = index % 2 === 0
          return (
            <div role='row' className={displayContents} key={row.id}>
              <ProductTypeCell subscriberProductType={row.subscriberProductType} striped={striped} />

              <StatusAndActionsCell
                status={row.translatedStatus}
                retrievedFrom={row.retrievedFrom}
                errors={row.statusErrors}
                striped={striped}
                subscriptionId={row.id ?? null}
                subscriberProductType={row.subscriberProductType}
              />

              <OriginCell origin={row.salesChannel} striped={striped} />
              {row.retrievedFrom === 'hop' && (
                <DatesCell dates={[row.dateCreatedAt, row.dateSignature, row.dateActivation]} striped={striped} />
              )}

              {row.retrievedFrom === 'zoho' && (
                <DefaultCell value={new Date(row.dateCreatedAt).toLocaleString()} striped={striped} />
              )}

              <OwnerCell name={row.owner} striped={striped} />

              <OfferCell
                provider={row.providerName}
                offer={row.offerName}
                retrievedFrom={row.retrievedFrom}
                dealName={row.dealName}
                zohoId={row.zohoId}
                prestation={row.prestationType}
                striped={striped}
              />

              <AddressCell
                firstLine={(row.address?.streetNumber ?? '') + ' ' + (row.address?.streetName ?? '')}
                secondLine={(row.address?.zipcode ?? '') + ' ' + (row.address?.city ?? '')}
                striped={striped}
              />
            </div>
          )
        })}
      </Table>
    </div>
  )
}

LastSales.propTypes = {
  hopSubscriptions: PropTypes.object,
  hopSubscriptionsLoading: PropTypes.bool,
}

export default LastSales
