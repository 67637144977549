import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconStatusError = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? ''
  size = size ?? 16
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 16 16' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.6667 -0.666992H16V-0.000325501H16.6667V-0.666992ZM0 7.99967C0 3.58167 3.582 -0.000325501 8 -0.000325501C12.418 -0.000325501 16 3.58167 16 7.99967C16 12.4177 12.418 15.9997 8 15.9997C3.582 15.9997 0 12.4177 0 7.99967ZM8.83333 4.66634C8.83333 4.20634 8.45933 3.83301 7.99933 3.83301C7.53933 3.83301 7.16667 4.20634 7.16667 4.66634C7.16667 5.12634 7.53933 5.49967 7.99933 5.49967C8.45933 5.49967 8.83333 5.12634 8.83333 4.66634ZM6.66667 11.333V11.9997H9.33333V11.3337C9.26971 11.3104 9.2052 11.2911 9.14264 11.2723C8.88859 11.1963 8.66667 11.1299 8.66667 10.8437V6.66701H6.66667V7.33368C6.71342 7.35097 6.76062 7.36693 6.80716 7.38267C7.0818 7.47554 7.33333 7.56059 7.33333 7.86501V10.843C7.33333 11.1279 7.11307 11.1942 6.85972 11.2705C6.79646 11.2895 6.73114 11.3092 6.66667 11.333Z'
        fill='#C20000'
      />
    </Icon>
  )
}

IconStatusError.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconStatusError
