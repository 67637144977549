import { styled } from 'baseui'

export const Card = styled('div', ({ $theme }) => ({
  background: $theme.colors.bg100,
  padding: '0.5rem',
  borderRadius: $theme.borders.radius400,
  boxShadow: $theme.lighting.shadow400,
  display: 'flex',
  'flex-direction': 'column',
}))
export const CardForm = styled('div', ({ $theme }) => ({
  marginTop: '0.5rem',
  background: $theme.colors.bg100,
  padding: '0.25rem',
  borderRadius: $theme.borders.radius400,
  display: 'flex',
  width: '100%',
}))

export const Title = styled('h2', ({ $theme }) => ({
  alignContent: 'left',
  color: $theme.colors.primary,
}))

export const Columns = styled('div', () => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '@media screen and (min-width: 1200px)': {
    flexDirection: 'row',
  },
}))

export const Column = styled('div', () => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  margin: '0 0 1rem 1rem',
  ':last-child': {
    flexDirection: 'column-reverse',
    justifyContent: 'space-between',
  },
  '@media screen and (min-width: 1200px)': {
    width: '100%',
  },
}))
