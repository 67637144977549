import { useState } from 'react'
import { BaseProvider, createTheme } from 'baseui'
import selectraPrimitives from '@/shared/theme/themePrimitives'
import selectraOverrides from '@/shared/theme/themeOverrides'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { KIND, SIZE } from 'baseui/button'
import SDPaginator from '@/shared/components/SDComponents/SDPaginator/SDPaginator'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import DevToolsSearch from './DevToolsSearch'
import PropTypes from 'prop-types'
import SubscribersList from './SubscribersList'
import useHttp from '@/shared/hooks/useHttp'
import ValidationStatusBadge from '@/shared/components/FormHeader/bones/ValidationStatusBadge'
import { useTranslation } from 'react-i18next'
import IconCarbonRotate360 from '@/shared/icons/IconCarbonRotate360'
import { Check } from 'baseui/icon'
import useBusinessType from '@/shared/hooks/useBusinessType'
import IconCustomZoho from '@/shared/icons/IconCustomZoho'
import SubscribersToolsErrorNotification from '@/containers/DevTools/SubscribersToolsErrorNotification'
import { useSelector } from 'react-redux'
import { selectAuthData } from '@/containers/Login/AuthSelectors'

const SubscribersTools = ({ urls }) => {
  const { t } = useTranslation()
  const { lang } = useSelector(selectAuthData)
  const [status, setStatus] = useState({})
  const [error, setError] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [paginator, setPaginator] = useState({ paginator: '' })
  const { sendRequest, isLoading, getConfig } = useHttp()
  const [subscriberAction, setSubscriberAction] = useState(0)
  const { getApplicationTypeFolder } = useBusinessType({ loaderRoutesEnergy: {}, loaderRoutes: {} })
  const folderType = getApplicationTypeFolder()
  const productType = folderType.toLowerCase()
  const flexGridItemProps = {
    display: 'flex',
    alignItems: 'center',
  }
  const [subscribers, setSubscribers] = useState([])
  const [page, setPage] = useState(1)
  const selectraTheme = createTheme(selectraPrimitives, selectraOverrides)
  const elementsPerPage = 20
  const requestStatus = () => {
    if (status && Object.keys(status).length !== 0) {
      return <ValidationStatusBadge text={status.text} status={status.colour} />
    }
  }
  const requestResponseHandler = response => {
    if (response.error === false) {
      setError(null)
      setStatus({ text: t('devTools:action_result_ok'), colour: 'positive' })
    } else {
      setError(response.error)
      setStatus({ text: t('devTools:action_result_ko'), colour: 'negative' })
    }
  }
  const requestSubscriptionsHandler = response => {
    setSubscribers(response.subscribers.data)
    setPaginator(response.subscribers)
  }
  const signToNetStatus = subscriber => {
    setError(null)
    setStatus({})
    setSubscriberAction(subscriber?.id)
    sendRequest(
      urls.subscriptions_sign_to_net_status_url +
        '/' +
        (subscriber?.id ?? 0) +
        '?lang=' +
        lang +
        '&businessType=' +
      productType,
      getConfig('PUT'),
      requestResponseHandler,
    )
  }
  const requestSearchHandler = (searchTerm, pageNumber = 1) => {
    setError(null)
    setStatus({})
    setSearchTerm(searchTerm)
    sendRequest(
      urls.subscriptions_search + '/' + productType + '/' + pageNumber + '/' + searchTerm + '?lang=' + lang,
      getConfig('GET'),
      requestSubscriptionsHandler,
    )
  }
  const resetExportState = subscriber => {
    setError(null)
    setStatus({})
    sendRequest(
      urls.subscriptions_reset_export_status_url + '/' + (subscriber?.id ?? 0) + '?lang=' + lang,
      getConfig('PUT'),
      requestResponseHandler,
    )
  }
  const reSyncToZoho = subscriber => {
    setError(null)
    setStatus({})
    setSubscriberAction(subscriber?.id)
    sendRequest(
      urls.subscriptions_sync_to_zoho_url + '/' + productType + '/' + (subscriber?.id ?? 0) + '/1' + '?lang=' + lang,
      getConfig('PUT'),
      requestResponseHandler,
    )
  }
  const getStatus = (subscriber, productType) => {
    if (productType === 'energy') {
      return subscriber?.product_energy?.subscriber_status ?? ''
    }
    if (productType === 'insurance') {
      return subscriber?.product_insurance?.subscriber_status ?? ''
    }
    return subscriber?.product_telecom?.subscriber_status ?? ''
  }
  const getExportState = (subscriber, productType) => {
    if (productType === 'energy') {
      return subscriber?.product_energy?.zoho_export_state ?? ''
    }
    if (productType === 'insurance') {
      return subscriber?.product_insurance?.zoho_export_state ?? ''
    }
    return subscriber?.product_telecom?.zoho_export_state ?? ''
  }
  const transformSubscribers = subscribersList => {
    if (!subscribersList) {
      return []
    }
    return subscribersList.map(subscriber => {
      const status = getStatus(subscriber, productType)
      const exportState = getExportState(subscriber, productType)
      return [
        subscriber.id,
        subscriber.email,
        subscriber.email_token,
        subscriber.firstname,
        subscriber.lastname,
        status,
        exportState,
        new Date(subscriber.created_at).toLocaleString(),
        <SDButton
          key={subscriber.id}
          block
          size={SIZE.mini}
          kind={KIND.secondary}
          startEnhancer={() => <IconCarbonRotate360 size={16} />}
          onClick={() => resetExportState(subscriber)}
        >
          {t('devTools:action_export_state')}
        </SDButton>,
        <SDButton
          key={subscriber.id}
          block
          size={SIZE.mini}
          kind={KIND.secondary}
          startEnhancer={() => <Check size={16} />}
          onClick={() => signToNetStatus(subscriber)}
          isLoading={isLoading && subscriberAction === subscriber.id}
        >
          {t('devTools:action_sign_to_net')}
        </SDButton>,
        <SDButton
          key={subscriber.id}
          block
          size={SIZE.mini}
          kind={KIND.secondary}
          onClick={() => reSyncToZoho(subscriber)}
          startEnhancer={() => <IconCustomZoho size={16} />}
          isLoading={isLoading && subscriberAction === subscriber.id}
        >
          {t('devTools:action_sync_zoho')}
        </SDButton>,
      ]
    })
  }
  const Paginator = () => {
    if (!paginator) {
      return ''
    }

    const lastPage = Math.ceil(paginator?.total / elementsPerPage)

    return (
      <SDPaginator
        numPages={lastPage}
        currentPage={page}
        onPageChange={({ nextPage }) => {
          requestSearchHandler(searchTerm, nextPage)
          setPage(Math.min(Math.max(nextPage, 1), lastPage))
        }}
      />
    )
  }

  return (
    <BaseProvider theme={selectraTheme}>
      <FlexGrid flexGridColumnCount={2} marginBottom='.5rem' flexGridColumnGap='scale800'>
        <FlexGridItem {...flexGridItemProps}>
          <DevToolsSearch
            requestSearchHandler={value => requestSearchHandler(value)}
            placeholder={'Rechercher dans ce tableau par le email token'}
          />
        </FlexGridItem>
        <FlexGridItem {...flexGridItemProps}>{requestStatus()}</FlexGridItem>
      </FlexGrid>
      <FlexGrid>
        <FlexGridItem {...flexGridItemProps}>
          <SubscribersToolsErrorNotification error={error} />
        </FlexGridItem>
      </FlexGrid>
      <SubscribersList items={transformSubscribers(subscribers)} />
      <Paginator />
    </BaseProvider>
  )
}

SubscribersTools.propTypes = {
  urls: PropTypes.object,
}

export default SubscribersTools
