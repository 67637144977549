import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  isLoadingUsers: false,
  users: {
    total: 0,
    lastPage: 0,
    nextPage: 0,
    currentPage: 0,
    data: [],
  },
  selectedUser: {
    id: 0,
    name: '',
    authorized_sites: '',
  },
  usersError: '',
}

const updateUsersManagementIsLoadingUsersHandler = (state, action) => {
  state.isLoadingUsers = action.payload
}

const updateUsersManagementUsersHandler = (state, action) => {
  state.users = action.payload
}

const updateUsersManagementSelectedUserHandler = (state, action) => {
  state.selectedUser = action.payload
}

const updateUsersManagementUsersErrorHandler = (state, action) => {
  state.usersError = action.payload
}

export const UsersManagementSlice = createSlice({
  name: 'usersManagement',
  initialState,
  reducers: {
    updateUsersManagementIsLoadingUsers: updateUsersManagementIsLoadingUsersHandler,
    updateUsersManagementUsers: updateUsersManagementUsersHandler,
    updateUsersManagementSelectedUser: updateUsersManagementSelectedUserHandler,
    updateUsersManagementUsersError: updateUsersManagementUsersErrorHandler,
    clearUsersManagement: () => initialState,
  },
})

export const {
  updateUsersManagementIsLoadingUsers,
  updateUsersManagementUsers,
  updateUsersManagementUsersError,
  updateUsersManagementSelectedUser,
  clearUsersManagement,
} = UsersManagementSlice.actions

export default UsersManagementSlice.reducer
