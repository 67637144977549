import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  formErrors: {},
  selectedMonConseillerPerso: {
    id: 0,
    name: '',
  },
}

const updateMonConseillerPersoFormErrorsHandler = (state, action) => {
  state.formErrors = action.payload
}

const updateSelectedMonConseillerPersoHandler = (state, action) => {
  state.selectedMonConseillerPerso = action.payload
}

export const MonConseillerPersoSlice = createSlice({
  name: 'monConseillerPersoProducts',
  initialState,
  reducers: {
    updateMonConseillerPersoFormErrors: updateMonConseillerPersoFormErrorsHandler,
    updateSelectedMonConseillerPerso: updateSelectedMonConseillerPersoHandler,
    clearMonConseillerPerso: () => initialState,
  },
})

export const { updateMonConseillerPersoFormErrors, updateSelectedMonConseillerPerso, clearMonConseillerPerso } =
  MonConseillerPersoSlice.actions

export default MonConseillerPersoSlice.reducer
