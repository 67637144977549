export default function getSubscriptionFromZohoStatusColour(status) {
  switch (status) {
    case 'To send to customer':
    case 'Sold to call back':
      return {
        light: '#ffed78',
        dark: '#8b7900',
      }
    case 'In progress':
      return {
        light: '#C6EE55',
        dark: '#5B6E27',
      }
    case 'Duplicate':
    case 'rejected':
    case 'Not sold to call back':
      return {
        light: '#ffd07a',
        dark: '#40281f',
      }
    case 'abandon':
      return {
        light: '#FEE9E9',
        dark: '#DC0909',
      }
    case 'net':
      return {
        light: '#F1FFFF',
        dark: '#0C8A1E',
      }
    case 'Sold to pass':
      return {
        light: '#00bbbb',
        dark: '#003434',
      }
    default:
      return {
        light: '#c1d1e2',
        dark: '#565e66',
      }
  }
}
