import { call, select, takeLatest } from 'redux-saga/effects'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import getAPIConfig from '@/shared/tools/getAPIConfig'

export const officesManagementActions = {
  UPDATE_OFFICE: 'UPDATE_OFFICE',
  CREATE_OFFICE: 'CREATE_OFFICE',
}

export function* updateOffice({ officeData, updateOfficeSuccessNotification, setFormErrors }) {
  try {
    const { authTokenSession } = yield select(selectAuthData)
    const { routes } = yield select(selectPageConfigurationData)

    const config = yield call(getAPIConfig, { method: 'put', authTokenSession, body: officeData })
    const response = yield call(fetch, routes.update, config)

    const { success, errors = {}, message } = yield call([response, response.json])

    if (success) {
      yield call(updateOfficeSuccessNotification, message)
    } else {
      yield call(setFormErrors, errors)
    }
  } catch (error) {
    yield call(setFormErrors, error)
  }
}

export function* createOffice({ officeData, updateOfficeSuccessNotification, setFormErrors }) {
  try {
    const { authTokenSession } = yield select(selectAuthData)
    const { routes } = yield select(selectPageConfigurationData)

    const config = yield call(getAPIConfig, { method: 'post', authTokenSession, body: officeData })
    const response = yield call(fetch, routes.store, config)

    const { success, errors = {}, message } = yield call([response, response.json])
    if (success) {
      yield call(updateOfficeSuccessNotification, message)
    } else {
      yield call(setFormErrors, errors)
    }
  } catch (error) {
    yield call(setFormErrors, error)
  }
}

export default function* officesManagementSagas() {
  yield takeLatest(officesManagementActions.UPDATE_OFFICE, updateOffice)
  yield takeLatest(officesManagementActions.CREATE_OFFICE, createOffice)
}
