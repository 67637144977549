import { createSelector } from 'reselect'

export const selectSubscriptionFormConfiguration = state => state.subscriptionFormConfiguration

export const selectSubscriptionFormConfigurationData = createSelector(selectSubscriptionFormConfiguration, data => data)

export const selectCommonFormConfigurationData = createSelector(
  selectSubscriptionFormConfiguration,
  data => data.common,
)

export const selectZohoModuleConfigurationData = createSelector(
  selectSubscriptionFormConfiguration,
  data => data.zohoModule,
)

export const selectCustomerModuleConfigurationData = createSelector(
  selectSubscriptionFormConfiguration,
  data => data.customerModule,
)

export const selectCharacteristicsModuleConfigurationData = createSelector(
  selectSubscriptionFormConfiguration,
  data => data.characteristicsModule,
)

export const selectBankingModuleConfigurationData = createSelector(
  selectSubscriptionFormConfiguration,
  data => data.bankingModule,
)

export const selectPropaneModuleConfigurationData = createSelector(
  selectSubscriptionFormConfiguration,
  data => data.propaneModule,
)

export const selectProviderConfiguration = createSelector(
  selectSubscriptionFormConfiguration,
  data => data.providerConfig,
)

export const selectOfferConfiguration = createSelector(selectSubscriptionFormConfiguration, data => data.offerConfig)

export const selectOfferModuleConfigurationData = createSelector(
  selectSubscriptionFormConfiguration,
  data => data.offerModule,
)

export const selectAdditionalSaleModuleConfigurationData = createSelector(
  selectSubscriptionFormConfiguration,
  data => data.additionalSales,
)

export const selectConsumptionModuleConfigurationData = createSelector(
  selectSubscriptionFormConfiguration,
  data => data.consumptionModule,
)

export const selectElectricityMeterModuleConfigurationData = createSelector(
  selectSubscriptionFormConfiguration,
  data => data.electricityMeterModule,
)

export const selectGasMeterModuleConfigurationData = createSelector(
  selectSubscriptionFormConfiguration,
  data => data.gasMeterModule,
)

export const selectPdlSearchConfigurationData = createSelector(
  selectSubscriptionFormConfiguration,
  data => data.pdlSearchData,
)

export const selectPceSearchConfigurationData = createSelector(
  selectSubscriptionFormConfiguration,
  data => data.pceSearchData,
)
