import { SIZE } from 'baseui/button'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { Table } from 'baseui/table-semantic'
import { Modal, ModalHeader, ModalBody } from 'baseui/modal'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import AddEditModal from './AddEditModal'
import Title from '@/shared/components/PageTitle'
import useOfficesManagement from './useOfficesManagement'

const OfficesManagement = () => {
  const { offices, pageConfigurationIsLoading, t, isOpen, close, setIsOpen, updateOffice, selectedOffice } =
    useOfficesManagement()

  const flexGridItemProps = {
    display: 'flex',
    alignItems: 'center',
  }

  const COLUMNS = [
    t('officesManagement:table.name'),
    t('officesManagement:table.mcp_email'),
    t('officesManagement:table.mcp_calendly'),
    '',
  ]

  const transformOffices = offices => {
    if (!offices) {
      return []
    }
    return offices.map(office => {
      return [
        office.name ?? '',
        office.mcp_agent?.email ?? '',
        office.mcp_agent?.calendly_url ?? '',
        <SDButton
          id={'office-' + office.uuid + '-edit-btn'}
          key={office.uuid}
          size={SIZE.compact}
          onClick={() => updateOffice(office)}
        >
          {t('common:edit')}
        </SDButton>,
      ]
    })
  }

  if (pageConfigurationIsLoading) {
    return <p>{t('common:fetching_data')}</p>
  }

  return (
    <>
      <Title title={t('officesManagement:title')} id='offices-title' />
      {offices?.length && (
        <h2>
          {offices.length} {t('officesManagement:offices_found')}
        </h2>
      )}
      <FlexGrid flexGridColumnCount={2} marginBottom='.5rem'>
        <FlexGridItem {...flexGridItemProps}>
          <SDButton key={0} block={false} size={SIZE.compact} onClick={() => updateOffice({ uuid: '' })}>
            {t('officesManagement:create_button')}
          </SDButton>
        </FlexGridItem>
      </FlexGrid>
      <Table columns={COLUMNS} data={transformOffices(offices)} />

      <Modal onClose={close} isOpen={isOpen}>
        <ModalHeader>
          {selectedOffice?.uuid === ''
            ? t('officesManagement:modal.header_create')
            : t('officesManagement:modal.header_update')}
        </ModalHeader>
        <ModalBody>
          <AddEditModal setIsOpen={setIsOpen} />
        </ModalBody>
      </Modal>
    </>
  )
}

export default OfficesManagement
