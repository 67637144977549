import PropTypes from 'prop-types'

// Project imports
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { useTranslation } from 'react-i18next'
import { ModalSection } from '@/shared/components/SDComponents/SDModal/styled-components'
import PanelInfoBlock from '@/shared/components/Panel/bones/PanelInfoBlock'
import { useStyletron } from 'baseui'

const SubmissionModelContent = ({ submitFormModal, formSubmitted, formCanBeSubmitted }) => {
  const { t } = useTranslation()
  const [css] = useStyletron()

  return (
    <ModalSection>
      <div className={css({ margin: '0 1rem 0' })}>
        <PanelInfoBlock type='warning'>{t('subscriptionForm:header.modal.recording_script')}</PanelInfoBlock>
      </div>
      <SDButton
        id='modal-button-save-and-send'
        block={true}
        onClick={submitFormModal}
        isLoading={formSubmitted}
        disabled={formSubmitted || formCanBeSubmitted === false}
      >
        {t('subscriptionForm:header.modal.submit')}
      </SDButton>
    </ModalSection>
  )
}

SubmissionModelContent.propTypes = {
  submitFormModal: PropTypes.func,
  formSubmitted: PropTypes.bool,
  formCanBeSubmitted: PropTypes.bool,
}

export default SubmissionModelContent
