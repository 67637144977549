const useDashboardFiltersParams = (filters, applicationType) => {
  const params = []
  const filterStatus = () => {
    if (filters.status) {
      switch (filters.status) {
        case 'treated':
          params.push('filterOnlyTreated=true')
          break
        case 'nonTreated':
          params.push('filterOnlyNotTreated=true')
          break
        case 'error':
          params.push('filterOnlyError=true')
          break
        case 'abandonedSelectra':
          params.push('filterOnlyAbandonedSelectra=true')
          break
        case 'abandonedProvider':
          params.push('filterOnlyAbandonedProvider=true')
          break
      }
    }
  }
  const filterOrigin = () => {
    if (filters.origin) {
      params.push('origin=' + filters.origin)
    }
  }
  const filterEnergy = () => {
    if (filters.energy) {
      params.push('energy=' + filters.energy)
    }
  }
  const filterProductType = () => {
    if (filters.productType) {
      params.push('productType=' + filters.productType)
    }
  }
  const filterProductTypeInsurance = () => {
    if (filters.productTypeInsurance) {
      params.push('productType=' + filters.productTypeInsurance)
    }
  }
  const filterSubscriptionId = () => {
    if (filters.subscriptionId) {
      params.push('subscriptionId=' + filters.subscriptionId)
    }
  }
  const filterZohoId = () => {
    if (filters.zohoId) {
      params.push('zohoId=' + filters.zohoId)
    }
  }
  const filterMeters = () => {
    if (filters.meterIdElectricity) {
      params.push('meterIdElectricity=' + filters.meterIdElectricity)
    }
    if (filters.meterIdGas) {
      params.push('meterIdGas=' + filters.meterIdGas)
    }
  }
  const filterCustomer = () => {
    if (filters.lastname) {
      params.push('lastname=' + filters.lastname)
    }
    if (filters.firstname) {
      params.push('firstname=' + filters.firstname)
    }
    if (filters.email) {
      params.push('email=' + filters.email)
    }
    if (filters.phone) {
      params.push('phone=' + encodeURIComponent(filters.phone))
    }
  }
  const filterUserId = () => {
    if (filters.userId) {
      params.push('userId=' + filters.userId)
    }
  }
  const filterProviderId = () => {
    if (filters.providerId) {
      params.push('providerId=' + filters.providerId)
    }
  }
  const filterCreation = () => {
    if (filters.creationFrom) {
      params.push('createdFromDate=' + filters.creationFrom)
    }

    if (filters.creationTo) {
      params.push('createdToDate=' + filters.creationTo)
    }

    if (filters.creation) {
      switch (filters.creation) {
        case 'today':
          params.push('limitByDays=1')
          break
        case 'lastWeek':
          params.push('limitByDays=7')
          break
        case 'lastMonth':
          params.push('limitByDays=30')
          break
        case 'currentMonth':
          params.push(`limitByDays=${new Date().getDate()}`)
          break
      }
    }
  }
  const filterPrestation = applicationType => {
    if (applicationType === 'energie' || applicationType === 'assurance') {
      return
    }
    if (filters.prestation) {
      switch (filters.prestation) {
        case 'cdf':
          params.push('situation=switching')
          break
        case 'mes':
          params.push('situation=moving')
          break
      }
    }
  }
  const filterPrestationEnergy = applicationType => {
    if (applicationType === 'telecom' || applicationType === 'assurance') {
      return
    }
    if (filters.prestationEnergy) {
      params.push('situation=' + filters.prestationEnergy)
    }
  }
  const filterPrestationInsurance = applicationType => {
    if (applicationType === 'telecom' || applicationType === 'energie') {
      return
    }
    if (filters.prestationInsurance) {
      params.push('situation=' + filters.prestationInsurance)
    }
  }
  const filterContractType = () => {
    if (filters.contractType) {
      switch (filters.contractType) {
        case 'part':
          params.push('contractType=part')
          break
        case 'pro':
          params.push('contractType=pro')
          break
      }
    }
  }
  const filterDetails = () => {
    if (filters.details) {
      if (filters.details.includes('owned')) {
        params.push('onlyMine=true')
      }
      if (filters.details.includes('withCallback')) {
        params.push('withCallback=true')
      }
    }
  }
  const filterCustom = () => {
    if (filters.custom) {
      params.push('custom=' + filters.custom)
      return true
    }
    return false
  }
  const filterPriority = () => {
    if (filters.priority) {
      if (filters.priority.includes('high')) {
        params.length = 0
        params.push('highPriority=true')
      }
      if (filters.priority.includes('low')) {
        params.length = 0
        params.push('lowPriority=true')
      }
    }
    return false
  }
  const create = () => {
    if (filterCustom() === true) {
      return params
    }
    filterStatus()
    filterOrigin()
    filterEnergy()
    filterProductType()
    filterProductTypeInsurance()
    filterSubscriptionId()
    filterZohoId()
    filterMeters()
    filterCustomer()
    filterUserId()
    filterProviderId()
    filterCreation()
    filterPrestation(applicationType)
    filterContractType()
    filterDetails()
    filterPriority()
    filterPrestationEnergy(applicationType)
    filterPrestationInsurance(applicationType)
    return params
  }

  return {
    create,
  }
}

export default useDashboardFiltersParams
