import { combineReducers } from 'redux'

import auth from '@/containers/Login/AuthSlice'
import pageConfiguration from '@/store/PageConfiguration/PageConfigurationSlice'
import usersManagement from '@/containers/UsersManagement/UsersManagementSlice'
import servicesStatus from '@/containers/ServicesStatus/ServicesStatusSlice'
import carbonOffsets from '@/containers/CarbonOffsetManagement/CarbonOffsetsSlice'
import officesManagement from '@/containers/OfficesManagement/OfficesManagementSlice'
import assistances from '@/containers/AssistancesManagement/AssistancesSlice'
import monConseillerPersoProducts from '@/containers/MonConseillerPersoProductsManagement/MonConseillerPersoSlice'
import dashboard from '@/containers/DashboardPage/DashboardSlice'
import subscriptionForm from '@/containers/SubscriptionFormPage/SubscriptionFormSlice'
import subscriptionFormConfiguration from '@/containers/SubscriptionFormPage/SubscriptionFormConfigurationSlice'

const appReducer = combineReducers({
  auth,
  pageConfiguration,
  usersManagement,
  servicesStatus,
  carbonOffsets,
  officesManagement,
  assistances,
  monConseillerPersoProducts,
  dashboard,
  subscriptionForm,
  subscriptionFormConfiguration,
})

export type RootState = ReturnType<typeof appReducer>

export const rootReducer = (state: ReturnType<typeof appReducer>, action: any) => appReducer(state, action)
