import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { SortableHeadCell } from 'baseui/table-grid'

import SDCard from '@/shared/components/SDComponents/SDCard'
import OwnerCell from '@/containers/DashboardPage/DashboardTable/bones/OwnerCell'
import useCallInformationStyles from './useCallInformationStyles'
import SpinnerText from './SpinnerText'

const LastCalls = ({ lastCalls, lastCallsloading }) => {
  const { cardStyleLastCalls, displayContents, headCellOverrides, Table, overrides } = useCallInformationStyles()
  const { t } = useTranslation()

  if (lastCallsloading || !lastCalls) {
    return <SDCard content={<SpinnerText text={t('callInformation:calls_module.loading')} />} />
  }
  if (lastCalls.length <= 0) {
    return <SDCard content={t('callInformation:calls_module.no_content')} />
  }

  return (
    <div className={cardStyleLastCalls}>
      <Table role='grid' $gridTemplateColumns='20% 20% 20% 20% 20%' overrides={overrides}>
        <div role='row' className={displayContents}>
          <SortableHeadCell
            title={t('callInformation:calls_module.header_fields.call_type')}
            overrides={headCellOverrides}
          />
          <SortableHeadCell
            title={t('callInformation:calls_module.header_fields.call_date')}
            overrides={headCellOverrides}
          />
          <SortableHeadCell
            title={t('callInformation:calls_module.header_fields.call_time')}
            overrides={headCellOverrides}
          />
          <SortableHeadCell
            title={t('callInformation:calls_module.header_fields.call_agent')}
            overrides={headCellOverrides}
          />
          <SortableHeadCell
            title={t('callInformation:calls_module.header_fields.call_guid')}
            overrides={headCellOverrides}
          />
        </div>
        {lastCalls.map((call, index) => {
          const striped = index % 2 === 0
          return (
            <div role='row' className={displayContents} key={call.id}>
              <OwnerCell name={call.type_call} striped={striped} />
              <OwnerCell name={call.created_at} striped={striped} />
              <OwnerCell name={call.duration} striped={striped} />
              <OwnerCell name={call.sales_agent_name} striped={striped} />
              <OwnerCell name={call.guid_id} striped={striped} />
            </div>
          )
        })}
      </Table>
    </div>
  )
}

LastCalls.propTypes = {
  lastCalls: PropTypes.array,
  lastCallsloading: PropTypes.bool,
}

export default LastCalls
