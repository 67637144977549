import { OrganizationParametersGeneration } from './OrganizationParametersGeneration'

const saveTelecomOrganization = async (organizationData, providerDispatch, url, headers, sendRequest) => {
  providerDispatch({
    type: 'saving',
    payload: { loading: true },
  })

  const options = OrganizationParametersGeneration(organizationData)
  headers.body = options
  await sendRequest(url, headers, (json, status) => {
    providerDispatch({
      type: 'saving',
      payload: { loading: false },
    })
    if (status === 200) {
      providerDispatch({ type: 'saving', payload: { saved: true } })
    }
    if (json.errors) {
      providerDispatch({ type: 'setErrors', payload: json.errors })
    }
  })
}

export default saveTelecomOrganization
