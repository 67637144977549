import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useBusinessType from '@/shared/hooks/useBusinessType'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { pageConfigurationActions } from '@/store/PageConfiguration/PageConfigurationSagas'
import {
  selectPageConfigurationData,
  selectPageConfigurationIsLoading,
} from '@/store/PageConfiguration/PageConfigurationSelectors'
import { useTranslation } from 'react-i18next'
import useModal from '@/shared/hooks/useModal'
import { selectSelectedCarbonOffset } from './CarbonOffsetsSelectors'
import { updateSelectedCarbonOffset } from './CarbonOffsetsSlice'

const useCarbonOffsets = () => {
  const dispatch = useDispatch()
  const { loaderRoutesEnergy, loaderRoutes, loaderRoutesInsurance } = useSelector(selectAuthData)
  const { getApplicationUrl } = useBusinessType({
    loaderRoutesEnergy,
    loaderRoutes,
    loaderRoutesInsurance,
    modulesStatuses: {},
    modulesStatusesEnergy: {},
    modulesStatusesInsurance: {},
  })
  const initUrl = getApplicationUrl('carbon-offset')
  const { carbonOffsets } = useSelector(selectPageConfigurationData)
  const isLoading = useSelector(selectPageConfigurationIsLoading)
  const selectedCarbonOffset = useSelector(selectSelectedCarbonOffset)

  const { t } = useTranslation()
  const { isOpen, close, setIsOpen } = useModal()

  const [page, setPage] = useState(1)
  const [carbonOffsetsSearch, setCarbonOffsetsSearch] = useState(carbonOffsets ?? [])

  const setCarbonOffsetHandler = carbonOffsetSearchList => {
    if (carbonOffsetSearchList !== null) {
      setCarbonOffsetsSearch(carbonOffsetSearchList)
    } else {
      setCarbonOffsetsSearch(carbonOffsets)
    }
  }

  const setSelectedCarbonOffset = carbonOffsetData => {
    const carbonOffset = {
      id: carbonOffsetData?.id ?? 0,
      name: carbonOffsetData?.id ? carbonOffsetData.name : '',
      price: carbonOffsetData?.id ? carbonOffsetData.price : '',
      description: carbonOffsetData?.id ? carbonOffsetData.description : '',
      zoho_id: carbonOffsetData?.id ? carbonOffsetData.zoho_id : '',
      energy_type: carbonOffsetData?.id ? carbonOffsetData.energy_type : '',
      zoho_prefix: carbonOffsetData?.id ? carbonOffsetData.potential_name : '',
      spb_code: carbonOffsetData?.id ? carbonOffsetData.spb_code : '',
    }
    dispatch(updateSelectedCarbonOffset(carbonOffset))
    setIsOpen(true)
  }

  useEffect(() => {
    dispatch({ type: pageConfigurationActions.INITIALIZE, initUrl })
  }, [])

  useEffect(() => {
    if (!carbonOffsets) return
    setCarbonOffsetsSearch(carbonOffsets)
  }, [carbonOffsets])

  return {
    pageConfigurationIsLoading: isLoading,
    t,
    isOpen,
    close,
    setIsOpen,
    page,
    setPage,
    carbonOffsets: carbonOffsetsSearch,
    elementsPerPage: 7,
    setSelectedCarbonOffset,
    selectedCarbonOffset,
    setCarbonOffsetHandler,
  }
}

export default useCarbonOffsets
