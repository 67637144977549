import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import { StyledBodyCell } from 'baseui/table-grid'

import creationLogo from '../license--draft.svg'
import signatureLogo from '../pen.svg'
import activationLogo from '../power.svg'

import { useTranslation } from 'react-i18next'

const DatesCell = ({ dates, striped, selected }) => {
  const { t } = useTranslation()
  const [css] = useStyletron()
  const columnStyle = css({
    display: 'flex',
    flexDirection: 'column',
  })
  const rowStyle = css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '.25rem',
  })
  const iconStyle = css({
    display: 'inline-block',
    height: '1rem',
    width: '1rem',
    marginRight: '.25rem',
  })
  const svgStyle = css({
    width: '100%',
  })
  const cellStyle = css({
    padding: '.5rem',
    backgroundColor: selected ? '#c1d1e2' : 'none',
  })
  const creationDate = new Date(dates[0])
  const signatureDate = new Date(dates[1])
  const activationDate = new Date(dates[2])
  const signature =
    signatureDate instanceof Date && !isNaN(signatureDate)
      ? `${signatureDate.toLocaleDateString('fr-FR')} | ${signatureDate.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })}`
      : dates[1]
  const activation =
    activationDate instanceof Date && !isNaN(activationDate)
      ? `${activationDate.toLocaleDateString('fr-FR')} | ${activationDate.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })}`
      : dates[2]

  return (
    <StyledBodyCell className={cellStyle} $striped={striped && !selected}>
      <div className={columnStyle}>
        <div className={rowStyle}>
          <span className={iconStyle}>
            <img className={svgStyle} src={creationLogo} alt={t('subscriptionTable:creation')} />
          </span>
          {`${creationDate.toLocaleDateString('fr-FR')} | ${creationDate.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })}`}
        </div>
        <div className={rowStyle}>
          <span className={iconStyle}>
            <img className={svgStyle} src={signatureLogo} alt={t('subscriptionTable:signature')} />
          </span>
          {signature}
        </div>
        <div className={rowStyle}>
          <span className={iconStyle}>
            <img className={svgStyle} src={activationLogo} alt={t('subscriptionTable:activation')} />
          </span>
          {activation}
        </div>
      </div>
    </StyledBodyCell>
  )
}

DatesCell.propTypes = {
  dates: PropTypes.array,
  striped: PropTypes.bool,
  selected: PropTypes.bool,
}

export default DatesCell
