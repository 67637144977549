import PropTypes from 'prop-types'
import SDModal from '@/shared/components/SDComponents/SDModal/SDModal'
import AdditionalSalesModalContent from './ModalContent/AdditionalSalesModalContent'
import EkomiModalContent from './ModalContent/EkomiModalContent'
import BusinessCardModalContent from './ModalContent/BusinessCardModalContent'
import SubmissionModelContent from './ModalContent/SubmissionModelContent'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectBillingSubscriptionData } from '../../../../containers/SubscriptionFormPage/SubscriptionFormSelectors'
import useFormStatus from '../../../../containers/SubscriptionFormPage/useFormStatus'

const ModalWindowSubmission = ({ submitForm, isOpen, setIsOpen, sendRequest, isLoading }) => {
  const { t } = useTranslation()
  const { formCanBeSubmitted, formSubmitted } = useFormStatus()
  const { businessCard } = useSelector(selectBillingSubscriptionData)

  const submitFormModal = async event => {
    event.preventDefault()
    await submitForm(event)
    setIsOpen(false)
  }

  const submissionModalContent = () => {
    return (
      <SubmissionModelContent
        submitFormModal={submitFormModal}
        formSubmitted={formSubmitted}
        formCanBeSubmitted={formCanBeSubmitted}
      />
    )
  }

  const sections = () => {
    return (
      <>
        <AdditionalSalesModalContent setIsOpen={setIsOpen} />
        <EkomiModalContent sendRequest={sendRequest} isLoading={isLoading} />
        <BusinessCardModalContent businessCard={businessCard} />
      </>
    )
  }

  return (
    <SDModal
      backgroundImage={{ image: 'scene' }}
      openHandler={setIsOpen}
      isOpen={isOpen}
      sections={sections()}
      submissionSection={submissionModalContent()}
      title={t('subscriptionForm:header.modal.title')}
    />
  )
}

ModalWindowSubmission.propTypes = {
  submitForm: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  sendRequest: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default ModalWindowSubmission
