export default function calculateDaysUntilDate(targetDate, t) {
  if (targetDate === null || targetDate === '') {
    return t('home:speed_formation.no_courses_yet')
  }

  const currentDate = new Date()
  targetDate = new Date(targetDate)

  // Truncate hours, minutes, seconds, and milliseconds to compare only dates
  currentDate.setHours(0, 0, 0, 0)
  targetDate.setHours(0, 0, 0, 0)

  const oneDayInMilliseconds = 24 * 60 * 60 * 1000
  const differenceInDays = Math.round((targetDate - currentDate) / oneDayInMilliseconds)

  if (differenceInDays <= 0) {
    return `⏳ ${t('home:speed_formation.course_done')} ⌛️`
  }

  return `⏳ ${differenceInDays} ${t('home:speed_formation.remaining_days')} ⌛️`
}
