import { FormControl } from 'baseui/form-control'
import { HOPInput } from '@/shared/components/HOPComponents/HOPInput'
import HOPSelect from '@/shared/components/HOPComponents/HOPSelect'
import { SIZE } from 'baseui/button'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { Select } from 'baseui/select'
import useAddEditModal from './useAddEditModal'

const AddEditModalNonSelectra = ({ setIsOpen }) => {
  const {
    t,
    close,
    formErrors,
    selectedUser,
    updateSelectedUser,
    sitesOptions,
    submitHandler,
    roleList,
    convertList,
    generateSitesSelectedValues,
  } = useAddEditModal({ setIsOpen })

  return (
    <form onSubmit={submitHandler}>
      <FormControl label={t('usersManagement:modal.name')} error={formErrors.name ? formErrors.name[0] : ''}>
        <HOPInput
          currentValue={selectedUser.name}
          handleValue={value => {
            updateSelectedUser({ name: value })
          }}
        />
      </FormControl>
      <FormControl
        label={t('usersManagement:modal.firstname')}
        error={formErrors.firstname ? formErrors.firstname[0] : ''}
      >
        <HOPInput
          currentValue={selectedUser.firstname}
          handleValue={value => {
            updateSelectedUser({ firstname: value })
          }}
        />
      </FormControl>
      <FormControl label={t('usersManagement:modal.email')} error={formErrors.email ? formErrors.email[0] : ''}>
        <HOPInput
          currentValue={selectedUser.email}
          handleValue={value => {
            updateSelectedUser({ email: value })
          }}
        />
      </FormControl>
      <FormControl label={t('usersManagement:modal.role_type')} error={formErrors.role ? formErrors.role[0] : ''}>
        <HOPSelect
          optionsList={convertList(roleList)}
          placeholder={selectedUser.role ? selectedUser.role : t('common:choose')}
          selectedValue={selectedUser.role ?? ''}
          onChangeHandler={params => updateSelectedUser({ role: params.value[0]?.id })}
        />
      </FormControl>
      {!selectedUser.id && (
        <FormControl
          label={t('usersManagement:modal.password')}
          error={formErrors.password ? formErrors.password[0] : ''}
        >
          <HOPInput handleValue={value => updateSelectedUser({ password: value })} />
        </FormControl>
      )}
      <FormControl label={t('usersManagement:modal.zoho_id')} error={formErrors.zoho_id ? formErrors.zoho_id[0] : ''}>
        <HOPInput
          currentValue={selectedUser.zoho_id ?? ''}
          handleValue={value => updateSelectedUser({ zoho_id: value })}
        />
      </FormControl>
      <FormControl label={t('usersManagement:modal.authorized_sites')}>
        <Select
          multi
          labelKey='label'
          valueKey='id'
          options={sitesOptions}
          value={generateSitesSelectedValues(selectedUser.authorized_sites)}
          onChange={({ value }) => updateSelectedUser({ authorized_sites: value })}
        />
      </FormControl>
      <SDButton
        disabled={false}
        isLoading={false}
        size={SIZE.compact}
        block={false}
        overrides={{
          BaseButton: {
            style: () => ({
              marginRight: '.5rem',
            }),
          },
        }}
        type='submit'
      >
        {selectedUser.id ? t('common:edit') : t('common:create')}
      </SDButton>
      <SDButton size={SIZE.compact} block={false} type='button' onClick={close}>
        {t('common:cancel')}
      </SDButton>
    </form>
  )
}

export default AddEditModalNonSelectra
