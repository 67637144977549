import { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Editor } from '@tinymce/tinymce-react'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import useHttp from '@/shared/hooks/useHttp'
import { useTranslation } from 'react-i18next'
import { DatePicker } from 'baseui/datepicker'
import { SIZE } from 'baseui/input'
import { selectAuthData } from '@/containers/Login/AuthSelectors'

const FormationForm = ({ data, setData, setIsEditing }) => {
  const { authTokenSession } = useSelector(selectAuthData)
  const { t } = useTranslation()
  const { sendRequest, getConfig, isLoading, error } = useHttp({ token: authTokenSession })
  const editorRef = useRef(null)
  const [date, setDate] = useState(data.speedFormation.date ? new Date(data.speedFormation.date) : null)

  const saveSpeedFormation = async () => {
    if (!editorRef.current) {
      return
    }

    const url = data.editSpeedFormationUrl ?? ''

    const headers = getConfig('POST')
    const options = new URLSearchParams()
    options.append('speed_training_text', editorRef.current.getContent())
    options.append('speed_training_end_date', date.toISOString().slice(0, 10))

    headers.body = options

    await sendRequest(url, headers, (result, status) => {
      if (status !== 200) {
        return
      }

      setData({
        ...data,
        speedFormation: {
          text: editorRef.current.getContent(),
          date,
        },
      })
      setIsEditing(false)
    })
  }

  return (
    <>
      <Editor
        id='speed-formation-text-input'
        apiKey={data.tinymceKey}
        initialValue={data.speedFormation.text}
        onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          height: 250,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'code',
            'wordcount',
          ],
          toolbar:
            'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
        }}
      />
      <DatePicker
        size={SIZE.compact}
        formatString='dd/MM/yyyy'
        minDate={new Date()}
        maxDate={null}
        value={date ? date : new Date()}
        onChange={({ date }) => {
          const dateFormatted = Array.isArray(date) ? new Date(date[0] + ' UTC') : new Date(date ? date + ' UTC' : date)
          setDate(dateFormatted)
        }}
      />
      <SDButton id='speed-formation-cancel-button' onClick={() => setIsEditing(false)}>
        {t('common:cancel')}
      </SDButton>
      <SDButton id='speed-formation-save-button' onClick={saveSpeedFormation} isLoading={isLoading}>
        {t('common:save')}
      </SDButton>
      {error}
    </>
  )
}

FormationForm.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
}

export default FormationForm
