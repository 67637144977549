import { SIZE } from 'baseui/spinner'
import SSBSpinner from './styled/SSBSpinner'
import StyledFormHeader from './styled/StyledFormHeader'
import FormValidation from './bones/FormValidation'
import FormSubmission from './bones/FormSubmission'
import SubscriptionSignHelper from './bones/SubscriptionSignHelper'
import SubscriptionStatusStep from './bones/SubscriptionStatus/SubscriptionStatusStep'
import useFormStatus from '../../../containers/SubscriptionFormPage/useFormStatus'

const FormHeader = () => {
  const { formIsLoaded } = useFormStatus()

  if (!formIsLoaded) {
    return (
      <StyledFormHeader>
        <SSBSpinner $size={SIZE.small} />
      </StyledFormHeader>
    )
  }
  return (
    <StyledFormHeader>
      <SubscriptionStatusStep />
      <FormValidation />
      <SubscriptionSignHelper />
      <FormSubmission />
    </StyledFormHeader>
  )
}

export default FormHeader
