const getAPIConfig = ({ method, authTokenSession, body }) => {
  const config = {
    method,
    mode: 'cors',
    headers: {
      Accept: 'Application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + authTokenSession
    }
  }

  if (!body) return config

  return { ...config, body: JSON.stringify(body) }
}

export default getAPIConfig
