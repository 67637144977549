import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { selectedDashboard } from '../DashboardSelectors'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import { useState } from 'react'
import { SORT_DIRECTION } from 'baseui/table-grid'
import { dashboardActions } from '../DashboardSagas'
import { compareDates, compareNumber, compareReverseString, compareString } from '@/shared/tools/compareFunctions'

const useDashboardTable = () => {
  const dispatch = useDispatch()
  const url = new URL(window.location.href)
  const { t } = useTranslation()
  const { tableLoading, data, currentSubscription } = useSelector(selectedDashboard)
  const { endpoints } = useSelector(selectPageConfigurationData)
  const [sort, setSort] = useState({
    sortingKey: url.searchParams.get('sortingKey') ?? null,
    direction: url.searchParams.get('direction') ?? null,
  })
  const setSorting = state => {
    Object.keys(state).forEach(key => {
      url.searchParams.set(key, state[key])
    })

    window.history.pushState({ path: url.href }, null, url.href)

    setSort(state)
  }
  const handleSorting = key => {
    switch (key) {
      case 'status':
        if (sort.direction === SORT_DIRECTION.ASC) {
          setSorting({
            sortingKey: 'status',
            direction: SORT_DIRECTION.DESC,
          })
        } else {
          setSorting({
            sortingKey: 'status',
            direction: SORT_DIRECTION.ASC,
          })
        }
        break
      case 'origin':
        if (sort.direction === SORT_DIRECTION.ASC) {
          setSorting({
            sortingKey: 'origin',
            direction: SORT_DIRECTION.DESC,
          })
        } else {
          setSorting({
            sortingKey: 'origin',
            direction: SORT_DIRECTION.ASC,
          })
        }
        break
      case 'dates':
        if (sort.sortingKey === 'datesCreation') {
          sort.direction === SORT_DIRECTION.ASC
            ? setSorting({
                sortingKey: 'datesCreation',
                direction: SORT_DIRECTION.DESC,
              })
            : setSorting({
                sortingKey: 'datesSignature',
                direction: SORT_DIRECTION.ASC,
              })
        } else if (sort.sortingKey === 'datesSignature') {
          sort.direction === SORT_DIRECTION.ASC
            ? setSorting({
                sortingKey: 'datesSignature',
                direction: SORT_DIRECTION.DESC,
              })
            : setSorting({
                sortingKey: 'datesActivation',
                direction: SORT_DIRECTION.ASC,
              })
        } else if (sort.sortingKey === 'datesActivation') {
          sort.direction === SORT_DIRECTION.ASC
            ? setSorting({
                sortingKey: 'datesActivation',
                direction: SORT_DIRECTION.DESC,
              })
            : setSorting({
                sortingKey: null,
                direction: null,
              })
        } else {
          setSorting({
            sortingKey: 'datesCreation',
            direction: SORT_DIRECTION.ASC,
          })
        }
        break
      case 'contact':
        if (sort.sortingKey === 'contactLastname') {
          sort.direction === SORT_DIRECTION.ASC
            ? setSorting({
                sortingKey: 'contactLastname',
                direction: SORT_DIRECTION.DESC,
              })
            : setSorting({
                sortingKey: 'contactFirstname',
                direction: SORT_DIRECTION.ASC,
              })
        } else if (sort.sortingKey === 'contactFirstname') {
          sort.direction === SORT_DIRECTION.ASC
            ? setSorting({
                sortingKey: 'contactFirstname',
                direction: SORT_DIRECTION.DESC,
              })
            : setSorting({
                sortingKey: 'contactNumberOfCalls',
                direction: SORT_DIRECTION.ASC,
              })
        } else if (sort.sortingKey === 'contactNumberOfCalls') {
          sort.direction === SORT_DIRECTION.ASC
            ? setSorting({
                sortingKey: 'contactNumberOfCalls',
                direction: SORT_DIRECTION.DESC,
              })
            : setSorting({
                sortingKey: null,
                direction: null,
              })
        } else {
          setSorting({
            sortingKey: 'contactLastname',
            direction: SORT_DIRECTION.ASC,
          })
        }
        break
      case 'offer':
        if (sort.sortingKey === 'offerSituation') {
          sort.direction === SORT_DIRECTION.ASC
            ? setSorting({
                sortingKey: 'offerSituation',
                direction: SORT_DIRECTION.DESC,
              })
            : setSorting({
                sortingKey: 'offerProvider',
                direction: SORT_DIRECTION.ASC,
              })
        } else if (sort.sortingKey === 'offerProvider') {
          sort.direction === SORT_DIRECTION.ASC
            ? setSorting({
                sortingKey: 'offerProvider',
                direction: SORT_DIRECTION.DESC,
              })
            : setSorting({
                sortingKey: 'offerOffer',
                direction: SORT_DIRECTION.ASC,
              })
        } else if (sort.sortingKey === 'offerOffer') {
          sort.direction === SORT_DIRECTION.ASC
            ? setSorting({
                sortingKey: 'offerOffer',
                direction: SORT_DIRECTION.DESC,
              })
            : setSorting({
                sortingKey: null,
                direction: null,
              })
        } else {
          setSorting({
            sortingKey: 'offerSituation',
            direction: SORT_DIRECTION.ASC,
          })
        }
        break
    }
  }

  const getSortedData = data => {
    const array = data.slice(0)

    switch (sort.sortingKey) {
      case 'status':
        return sort.direction === SORT_DIRECTION.ASC
          ? array.sort((a, b) => {
              return compareString(a.translatedStatus, b.translatedStatus)
            })
          : array.sort((a, b) => {
              return compareReverseString(a.translatedStatus, b.translatedStatus)
            })

      case 'origin':
        return sort.direction === SORT_DIRECTION.ASC
          ? array.sort((a, b) => {
              return compareString(a.subscriberData.details.salesChannel, b.subscriberData.details.salesChannel)
            })
          : array.sort((a, b) => {
              return compareReverseString(a.subscriberData.details.salesChannel, b.subscriberData.details.salesChannel)
            })

      case 'datesCreation':
        array.sort((a, b) => {
          return compareDates(a.dateCreatedAt, b.dateCreatedAt)
        })
        return sort.direction === SORT_DIRECTION.ASC ? array : array.reverse()

      case 'datesSignature':
        array.sort((a, b) => {
          return compareDates(a.dateSignature, b.dateSignature)
        })
        return sort.direction === SORT_DIRECTION.ASC ? array : array.reverse()

      case 'datesActivation':
        array.sort((a, b) => {
          return compareDates(a.dateActivation, b.dateActivation)
        })
        return sort.direction === SORT_DIRECTION.ASC ? array : array.reverse()

      case 'contactLastname':
        return sort.direction === SORT_DIRECTION.ASC
          ? array.sort((a, b) => {
              return compareString(a.lastname, b.lastname)
            })
          : array.sort((a, b) => {
              return compareReverseString(a.lastname, b.lastname)
            })

      case 'contactFirstname':
        return sort.direction === SORT_DIRECTION.ASC
          ? array.sort((a, b) => {
              return compareString(a.firstname, b.firstname)
            })
          : array.sort((a, b) => {
              return compareReverseString(a.firstname, b.firstname)
            })

      case 'contactNumberOfCalls':
        array.sort((a, b) => {
          return compareNumber(a.numberOfCalls, b.numberOfCalls)
        })
        return sort.direction === SORT_DIRECTION.ASC ? array : array.reverse()

      case 'offerSituation':
        return sort.direction === SORT_DIRECTION.ASC
          ? array.sort((a, b) => {
              return compareString(a.situation, b.situation)
            })
          : array.sort((a, b) => {
              return compareReverseString(a.situation, b.situation)
            })

      case 'offerProvider':
        return sort.direction === SORT_DIRECTION.ASC
          ? array.sort((a, b) => {
              return compareString(a.providerName, b.providerName)
            })
          : array.sort((a, b) => {
              return compareReverseString(a.providerName, b.providerName)
            })

      case 'offerOffer':
        return sort.direction === SORT_DIRECTION.ASC
          ? array.sort((a, b) => compareString(a.offer, b.offer))
          : array.sort((a, b) => compareReverseString(a.offer, b.offer))

      default:
        return data
    }
  }

  const retrieveSubscriptionDetails = subscriptionId => {
    const detailsPath = endpoints?.detailsUrl ?? ''
    const url = detailsPath.replace('*', subscriptionId)
    dispatch({ type: dashboardActions.LOAD_SUBSCRIPTION_DETAILS, url })
  }

  return {
    t,
    tableLoading,
    data: getSortedData(data?.data ?? []),
    handleSorting,
    sort,
    retrieveSubscriptionDetails,
    currentSubscriptionId: currentSubscription?.id ?? null,
  }
}

export default useDashboardTable
