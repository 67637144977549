import { FormControl } from 'baseui/form-control'
import SDInput from '@/shared/components/SDComponents/SDInput/SDInput'
import { SIZE } from 'baseui/input'
import { useState } from 'react'

const PaymentDateRange = ({ attributeId, attributeTechName, currentValue, updateOffer, t }) => {
  const currentValueArray = currentValue ? currentValue.split(';') : []
  const [rangeData, setRangeData] = useState(currentValueArray)
  const updateOfferRange = rangeData => {
    const start = rangeData[0] ?? ''
    const end = rangeData[1] ?? ''
    if (start && end) {
      updateOffer('attr_' + attributeId, start + ';' + end)
    }
  }

  return (
    <FormControl key={'attr_' + attributeId} label={t('organizationManagement:offer.attr_' + attributeTechName)}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'left',
        }}
      >
        <FormControl key={'attr_range_1_' + attributeId} label={t('organizationManagement:offer.between_start')}>
          <SDInput
            min='1'
            max='31'
            size={SIZE.compact}
            type='number'
            value={rangeData[0] ?? ''}
            onChange={e => {
              const newValues = [e.target.value, rangeData[1] ?? '']
              setRangeData(newValues)
            }}
            onBlur={e => {
              const newValues = [e.target.value, rangeData[1] ?? '']
              updateOfferRange(newValues)
            }}
          />
        </FormControl>
        <FormControl
          key={'attr_range_2_' + attributeId}
          label={t('organizationManagement:offer.between_end')}
          overrides={{
            Label: {
              style: () => ({
                marginLeft: '2rem',
              }),
            },
          }}
        >
          <SDInput
            type='number'
            min='1'
            max='31'
            size={SIZE.compact}
            value={rangeData[1] ?? ''}
            onChange={e => {
              const newValues = [rangeData[0] ?? '', e.target.value]
              setRangeData(newValues)
            }}
            onBlur={e => {
              const newValues = [rangeData[0] ?? '', e.target.value]
              updateOfferRange(newValues)
            }}
          />
        </FormControl>
      </div>
    </FormControl>
  )
}

export default PaymentDateRange
