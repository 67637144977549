import { FormControl } from 'baseui/form-control'
import { Editor } from '@tinymce/tinymce-react'

const GenericEditor = ({ tinymceKey, attributeId, attributeTechName, currentValue, updateOffer, t }) => {
  return (
    <FormControl key={'attr_' + attributeId} label={t('organizationManagement:offer.attr_' + attributeTechName)}>
      <Editor
        apiKey={tinymceKey}
        init={{
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'code',
            'wordcount',
          ],
          toolbar:
            'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
        }}
        initialValue={currentValue}
        onChange={e => updateOffer('attr_' + attributeId, e.target.getContent())}
      />
    </FormControl>
  )
}

export default GenericEditor
