import { useStyletron } from 'baseui'
import { useTranslation } from 'react-i18next'

const FormStatusError = ({ statusCode }) => {
  const { t } = useTranslation()
  const [css, theme] = useStyletron()
  const headingStyle = css({
    margin: '1rem 10px 10px 10px',
    color: theme.colors.primary,
    ...theme.typography.HeadingMedium,
  })

  return (
    <>
      {statusCode === 403 && <h1 className={headingStyle}>{t('subscriptionForm:access_error.unauthorized_access')}</h1>}
      {statusCode === 404 && <h1 className={headingStyle}>{t('subscriptionForm:access_error.not_found')}</h1>}
    </>
  )
}

export default FormStatusError
