import { Suspense, lazy, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './ModulesPanel.module.css'
import AdditionalSalesContainer from '../AdditionalSalesPanel/AdditionalSalesContainer'
import { useSnackbar } from 'baseui/snackbar'
import IconCarbonCheckmarkOutline from '@/shared/icons/IconCarbonCheckmarkOutline'
import { useStyletron } from 'baseui'
import CommonFallback from '@/shared/components/CommonFallback'
import { useTranslation } from 'react-i18next'
import SubscriptionActionsContainer from '../SubscriptionActionsPanel/SubscriptionActionsContainer'
import CommentsContainer from '@/containers/SubscriptionFormPage/CommentsPanel/CommentsContainer'
import useBusinessType from '@/shared/hooks/useBusinessType'
import { useDispatch, useSelector } from 'react-redux'
import { selectCommonFormConfigurationData } from '../SubscriptionFormConfigurationSelectors'
import { updateCommonConfiguration } from '../SubscriptionFormConfigurationSlice'

const ModulesPanel = ({ country, applicationType }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { realStatus, showSaveNotification, saveAction } = useSelector(selectCommonFormConfigurationData)
  const { getApplicationTypeFolder } = useBusinessType({
    loaderRoutesEnergy: {},
    loaderRoutes: {},
    loaderRoutesInsurance: {},
    modulesStatuses: {},
    modulesStatusesEnergy: {},
    modulesStatusesInsurance: {},
  })
  const [, theme] = useStyletron()
  const [CurrentLayout, setCurrentLayout] = useState(false)
  const getSuccessfulSaveText = () => {
    if (realStatus === 'R0500') {
      return t('subscriptionForm:snackbar.retracted')
    }
    if (saveAction === 'save' || saveAction === 'save_without_status') {
      return t('subscriptionForm:snackbar.save_successfully')
    }
    return t('subscriptionForm:snackbar.save_send_zoho_successfully')
  }
  const { enqueue } = useSnackbar()
  const importLocalizedLayout = async () => {
    try {
      const folder = getApplicationTypeFolder()
      const countryFolder = country.toUpperCase()
      const module = lazy(() => import(`./Layouts/${folder}/${countryFolder}/Layout.jsx`))
      setCurrentLayout(module)
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    if (!showSaveNotification) return
    enqueue({
      message: getSuccessfulSaveText(),
      // eslint-disable-next-line react/display-name
      startEnhancer: () => {
        return <IconCarbonCheckmarkOutline color={theme.colors.positive} />
      },
    })
    dispatch(updateCommonConfiguration({ showSaveNotification: false }))
  }, [showSaveNotification])

  useEffect(() => {
    importLocalizedLayout()
  }, [country, applicationType])

  if (!CurrentLayout) {
    return (
      <div className={styles.ModulesPanel}>
        <CommonFallback />
      </div>
    )
  }

  return (
    <div className={styles.ModulesPanel}>
      <Suspense fallback={<CommonFallback />}>
        <CurrentLayout />
      </Suspense>
      <AdditionalSalesContainer />
      <SubscriptionActionsContainer />
      <CommentsContainer />
    </div>
  )
}

ModulesPanel.propTypes = {
  country: PropTypes.string,
  applicationType: PropTypes.string,
}

export default ModulesPanel
