import { SIZE } from 'baseui/button'
import { Table } from 'baseui/table-semantic'

import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'

const IpAddressWhitelistsTable = ({ ipAddressWhitelists, t, openIpAddressesModal, organizations }) => {
  const COLUMNS = [
    t('ipAddressesManagement:table.name'),
    t('ipAddressesManagement:table.ip_addresses'),
    t('ipAddressesManagement:organization'),
    t('ipAddressesManagement:table.update')
  ]

  const transformIpAddresses = ipAddressWhitelists => {
    if (!ipAddressWhitelists) {
      return []
    }

    return ipAddressWhitelists.map(ipAddressWhitelist => {
      const organization = organizations.find(organization => organization.id === ipAddressWhitelist.organization_id)
      return [
        ipAddressWhitelist.name,
        ipAddressWhitelist.ip_addresses,
        organization?.label ?? '',
        <SDButton
          id={'ip-address-' + ipAddressWhitelist.id + '-edit-btn'}
          key={ipAddressWhitelist.id}
          size={SIZE.compact}
          onClick={() => openIpAddressesModal(ipAddressWhitelist)}
        >
          {t('common:edit')}
        </SDButton>
      ]
    })
  }

  return <Table columns={COLUMNS} data={transformIpAddresses(ipAddressWhitelists)} />
}

export default IpAddressWhitelistsTable
