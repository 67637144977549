const CAR_MINIMUM = {
  carElectricty: 1000,
  carGasMain: 1000,
  carGasSecondary: 200,
}

function getDefaultValues() {
  return { ...CAR_MINIMUM }
}

// eslint-disable-next-line one-var
export const defaultValues = getDefaultValues()
