import PropTypes from 'prop-types'
import { mergeOverrides } from 'baseui'
import { Button } from 'baseui/button'
import { SDButtonGroupButtonOverrides } from './styled-components'
import { forwardRef } from 'react'

// eslint-disable-next-line react/display-name
const SDButtonGroupButton = forwardRef((props, ref) => {
  const newOverrides = mergeOverrides(SDButtonGroupButtonOverrides(), props.overrides)

  return (
    <Button {...props} ref={ref} overrides={newOverrides}>
      {props.children}
    </Button>
  )
})

SDButtonGroupButton.propTypes = {
  overrides: PropTypes.object,
  children: PropTypes.string,
}

export default SDButtonGroupButton
