export default function getSelection(url) {
  switch (url) {
    case '/telecom':
    case '/energie':
      return url === location.pathname
    case '/telecom/subscription/list/overview':
    case '/telecom/subscription/list/websales':
    case '/energie/subscription/list/overview':
    case '/energie/subscription/list/websales':
      return (
        location.pathname.startsWith('/telecom/subscription/list') ||
        location.pathname.startsWith('/energie/subscription/list')
      )
    case '/telecom/subscription/add':
    case '/telecom/subscription/edit':
    case '/telecom/subscription/view':
    case '/energie/subscription/add':
    case '/energie/subscription/edit':
    case '/energie/subscription/view':
      return (
        location.pathname.startsWith('/telecom/subscription/add') ||
        location.pathname.startsWith('/telecom/subscription/edit') ||
        location.pathname.startsWith('/telecom/subscription/view') ||
        location.pathname.startsWith('/energie/subscription/add') ||
        location.pathname.startsWith('/energie/subscription/edit') ||
        location.pathname.startsWith('/energie/subscription/view')
      )
    case '/telecom/organisations/provider':
    case '/energie/organisations/provider':
      return (
        location.pathname.startsWith('/telecom/organisations') || location.pathname.startsWith('/energie/organisations')
      )
    case '/telecom/provider-options':
      return location.pathname.startsWith('/telecom/provider-options')
    case '/users-mngt':
    case '/ip-addresses-management':
    case '/contact-page':
    case '/telecom/call-reasons':
    case '/telecom/additional-sales/carbon-offsets':
    case '/telecom/additional-sales/assistances':
    case '/telecom/dev-tools/tools':
    case '/telecom/offers':
    case '/telecom/services-status':
    case '/energie/call-reasons':
    case '/energie/additional-sales/carbon-offsets':
    case '/energie/additional-sales/assistances':
    case '/energie/dev-tools/tools':
    case '/energie/offers':
    case '/energie/services-status':
    case '/logout':
      return location.pathname.startsWith(url)
  }
}
