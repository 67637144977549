import { SIZE } from 'baseui/button'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import AssistanceForm from './AssistanceForm/AssistanceForm'
import AssistancesList from './AssistancesList'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { Modal, ModalHeader, ModalBody } from 'baseui/modal'
import PageTitle from '@/shared/components/PageTitle'
import Paginator from '@/shared/components/Paginator'
import SearchInTable from '@/shared/components/SearchInTable'
import useAssistances from './useAssistances'

const Assistances = () => {
  const {
    t,
    assistances,
    isLoading,
    tinymceKey,
    elementsPerPage,
    isOpen,
    close,
    page,
    setPage,
    setAssistanceHandler,
    updateAssistanceSelected,
    selectedAssistance,
  } = useAssistances()

  const flexGridItemProps = {
    display: 'flex',
    alignItems: 'center',
  }

  if (isLoading) {
    return <p>{t('common:fetching_data')}</p>
  }

  return (
    <>
      <PageTitle title={t('assistancesConfiguration:title')} />
      <FlexGrid flexGridColumnCount={2} marginBottom={'.5rem'}>
        <FlexGridItem {...flexGridItemProps}>
          <SDButton key={0} block={false} size={SIZE.compact} onClick={() => updateAssistanceSelected(null)}>
            {t('assistancesConfiguration:create_button')}
          </SDButton>
        </FlexGridItem>
        <FlexGridItem {...flexGridItemProps}>
          <SearchInTable setElements={setAssistanceHandler} elements={assistances} />
        </FlexGridItem>
      </FlexGrid>
      <AssistancesList
        items={assistances}
        page={page}
        elementsPerPage={elementsPerPage}
        updateAssistance={updateAssistanceSelected}
      />
      <Paginator elementsPerPage={elementsPerPage} page={page} setPage={setPage} elements={assistances} />
      <Modal onClose={close} isOpen={isOpen}>
        <ModalHeader>
          {!selectedAssistance?.id || selectedAssistance.id === 0
            ? t('assistancesConfiguration:modal_header_create')
            : t('assistancesConfiguration:modal_header_update')}
        </ModalHeader>
        <ModalBody>
          <AssistanceForm tinymceKey={tinymceKey} onCancel={close} />
        </ModalBody>
      </Modal>
    </>
  )
}

export default Assistances
