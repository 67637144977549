import { SIZE } from 'baseui/button'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import MonConseillerPersoForm from './MonConseillerPersoForm/MonConseillerPersoForm'
import MonConseillerPersoList from './MonConseillerPersoList'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { Modal, ModalHeader, ModalBody } from 'baseui/modal'
import PageTitle from '@/shared/components/PageTitle'
import Paginator from '@/shared/components/Paginator'
import SearchInTable from '@/shared/components/SearchInTable'
import useMonConseillerPerso from './useMonConseillerPerso'

const MonConseillerPersoProducts = () => {
  const {
    t,
    monConseillerPersoProducts,
    isLoading,
    elementsPerPage,
    isOpen,
    close,
    page,
    setPage,
    setMonConseillerPersoHandler,
    updateMonConseillerPersoSelected,
    selectedMonConseillerPerso,
    type,
  } = useMonConseillerPerso()

  const flexGridItemProps = {
    display: 'flex',
    alignItems: 'center',
  }

  if (isLoading) {
    return <p>{t('common:fetching_data')}</p>
  }

  return (
    <>
      <PageTitle title={t('monConseillerPersoConfiguration:title') + type} />
      <FlexGrid flexGridColumnCount={2} marginBottom={'.5rem'}>
        <FlexGridItem {...flexGridItemProps}>
          <SDButton key={0} block={false} size={SIZE.compact} onClick={() => updateMonConseillerPersoSelected(null)}>
            {t('monConseillerPersoConfiguration:create_button')}
          </SDButton>
        </FlexGridItem>
        <FlexGridItem {...flexGridItemProps}>
          <SearchInTable setElements={setMonConseillerPersoHandler} elements={monConseillerPersoProducts} />
        </FlexGridItem>
      </FlexGrid>
      <MonConseillerPersoList
        items={monConseillerPersoProducts}
        page={page}
        elementsPerPage={elementsPerPage}
        updateMonConseillerPerso={updateMonConseillerPersoSelected}
      />
      <Paginator
        elementsPerPage={elementsPerPage}
        page={page}
        setPage={setPage}
        elements={monConseillerPersoProducts}
      />
      <Modal onClose={close} isOpen={isOpen}>
        <ModalHeader>
          {!selectedMonConseillerPerso?.id || selectedMonConseillerPerso.id === 0
            ? t('monConseillerPersoConfiguration:modal_header_create')
            : t('monConseillerPersoConfiguration:modal_header_update')}
        </ModalHeader>
        <ModalBody>
          <MonConseillerPersoForm onCancel={close} />
        </ModalBody>
      </Modal>
    </>
  )
}

export default MonConseillerPersoProducts
