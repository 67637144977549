import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconCarbonAxa = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'Axa'
  size = size ?? 16
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 32 32' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <path
        d='M18.9,15.9h-1.4L26.7,4H4v20.9c0,0,0,0,0,0c0,0,1.5-1.9,2.9-3.7c1.2-1.6,2.4-3.2,2.5-3.3c0.2-0.4,0.6-1.1,0.6-1.1h1.8
		c0,0,0.1,0.7,0.1,0.9c0,0.2,1.2,3.8,1.2,3.9l0.6-0.8l-1.1-3.3c0,0-0.2-0.6-0.3-0.7h2.2c0,0.2,0,0.4,0.1,0.6
		c0.1,0.3,0.5,1.9,0.5,1.9s1.5-1.8,1.6-2c0.1-0.2,0.2-0.4,0.2-0.5h1.8c0,0-0.3,0.2-0.9,1c-0.2,0.2-2.1,2.7-2.1,2.7s0.2,0.6,0.2,0.8
		c0,0.1,0,0.1,0,0.1c0,0,0,0,0.1-0.1c0.5-0.6,2.7-3.5,2.8-3.8c0.1-0.2,0.3-0.4,0.4-0.7h1.8c0,0,0,0.5,0.1,0.7l1.1,3.8
		c0.4,1.2,1.3,4.2,1.6,4.5h-2.3c0-0.3,0-0.5-0.1-0.8c-0.1-0.3-0.9-3-0.9-3h-3.6l-0.6,0.8c0,0,0.7,2.1,0.7,2.2
		c0.1,0.2,0.4,0.7,0.4,0.7h-2.2c0,0-0.1-0.3-0.1-0.5c0-0.1-0.2-0.7-0.2-0.7s-0.5,0.5-0.6,0.8c-0.1,0.2-0.2,0.4-0.2,0.4h-1.7
		c0,0-0.1-0.3-0.1-0.5c0-0.1-0.2-0.8-0.2-0.8s-0.5,0.6-0.6,0.8c-0.1,0.2-0.2,0.4-0.2,0.4H9.9c0,0,0.5-0.5,0.6-0.6
		c0.3-0.3,1.3-1.7,1.3-1.7l-0.4-1.4H7.9c0,0-2,2.7-2.1,2.8c-0.1,0.1-0.7,0.9-0.7,1H4V28h24V4h0L18.9,15.9z'
      />
      <path
        d='M10.5,18.4C10.5,18.4,10.5,18.3,10.5,18.4c-0.3,0.5-1.8,2.4-1.8,2.4h2.4c0,0-0.5-1.4-0.5-1.8
		C10.5,18.8,10.5,18.6,10.5,18.4z'
      />
      <path d='M14.3,21.9l-0.6,0.8l0.4,1.3l0.7-0.9C14.7,23,14.3,21.9,14.3,21.9z' />
      <path
        d='M19.7,18.4C19.7,18.4,19.7,18.3,19.7,18.4c-0.3,0.5-1.8,2.4-1.8,2.4h2.4c0,0-0.5-1.4-0.5-1.8
		C19.7,18.8,19.7,18.6,19.7,18.4z'
      />
    </Icon>
  )
}

IconCarbonAxa.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconCarbonAxa
