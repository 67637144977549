import { StyledRow, StyledCell } from 'baseui/table'
import { SIZE } from 'baseui/button'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'

export default function ObjectiveListItem(props) {
  const { objective } = props
  const { t } = useTranslation()

  return (
    <StyledRow>
      <StyledCell>{objective.description}</StyledCell>
      <StyledCell>{objective.start}</StyledCell>
      <StyledCell>{objective.end}</StyledCell>
      <StyledCell>
        <NavLink to={`/telecom/objectives/${objective.id}`}>
          <SDButton
            size={SIZE.compact}
            overrides={{
              BaseButton: {
                style: () => ({
                  marginRight: '.5rem',
                }),
              },
            }}
          >
            {t('objectives:edit_button')}
          </SDButton>
        </NavLink>
        <SDButton
          size={SIZE.compact}
          onClick={() => {
            if (window.confirm((<p>{t('alert')}</p>).props.children)) {
              props.onDeleteClick?.(objective)
            }
          }}
        >
          {t('objectives:delete_button')}
        </SDButton>
      </StyledCell>
    </StyledRow>
  )
}

ObjectiveListItem.propTypes = {
  objective: PropTypes.array,
  onDeleteClick: PropTypes.func,
}
