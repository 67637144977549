import PropTypes from 'prop-types'
import { Notification, KIND } from 'baseui/notification'
import IconCarbonMisuseOutline from '@/shared/icons/IconCarbonMisuseOutline'
import IconCarbonWarningAlt from '@/shared/icons/IconCarbonWarningAlt'
import IconCarbonCheckmarkOutline from '@/shared/icons/IconCarbonCheckmarkOutline'
import IconCarbonInformation from '@/shared/icons/IconCarbonInformation'
import { InfoBlockIconOverrides } from '@/shared/components/Panel/styled-components'
import { useStyletron } from 'baseui'

const SDNotification = ({ children, type, icon }) => {
  const [, theme] = useStyletron()
  const iconProps = {
    size: 24,
    color:
      type === 'negative' || type === 'warning' || type === 'positive'
        ? theme.colors[type]
        : type === 'additionalSale'
          ? theme.colors.warning
          : theme.colors.primary,
    overrides: InfoBlockIconOverrides
  }
  const kindResolver = type => {
    switch (type) {
      case 'info':
        return KIND.info
      case 'positive':
        return KIND.positive
      case 'warning':
        return KIND.warning
      case 'negative':
        return KIND.negative
      default:
        return KIND.info
    }
  }
  const getIcon = () => {
    switch (type) {
      case 'negative':
        return <IconCarbonMisuseOutline {...iconProps} />
      case 'warning':
        return <IconCarbonWarningAlt {...iconProps} />
      case 'positive':
        return <IconCarbonCheckmarkOutline {...iconProps} />
      default:
        return <IconCarbonInformation {...iconProps} />
    }
  }
  const outputIcon = (() => {
    return icon === true ? getIcon() : ''
  })()

  return (
    <Notification
      overrides={{
        Body: { style: { width: 'auto', borderRadius: '10px' } }
      }}
      kind={kindResolver(type)}
    >
      {outputIcon}
      {children}
    </Notification>
  )
}

SDNotification.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  type: PropTypes.oneOf(['', 'info', 'negative', 'positive', 'warning']),
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.func, PropTypes.object])
}

export default SDNotification
