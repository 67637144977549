import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  offices: [],
  selectedOffice: {
    uuid: '',
    name: '',
    mcp_agent_id: '',
  },
  officesError: '',
}

const updateOfficesManagementOfficesHandler = (state, action) => {
  state.offices = action.payload
}

const updateOfficesManagementSelectedOfficeHandler = (state, action) => {
  state.selectedOffice = action.payload
}

const updateOfficessManagementOfficesErrorHandler = (state, action) => {
  state.officesError = action.payload
}

export const OfficesManagementSlice = createSlice({
  name: 'officesManagement',
  initialState,
  reducers: {
    updateOfficesManagementOffices: updateOfficesManagementOfficesHandler,
    updateOfficesManagementSelectedOffice: updateOfficesManagementSelectedOfficeHandler,
    updateOfficesManagementOfficessError: updateOfficessManagementOfficesErrorHandler,
    clearOfficesManagement: () => initialState,
  },
})

export const {
  updateOfficesManagementOffices,
  updateOfficesManagementOfficessError,
  updateOfficesManagementSelectedOffice,
  clearOfficesManagement,
} = OfficesManagementSlice.actions

export default OfficesManagementSlice.reducer
