import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconCarbonLaunch = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'Tool'
  size = size ?? 16
  color = color ?? theme.colors.primary

  return (
    <Icon data-baseweb='icon' ref={ref} viewBox='0 0 32 32' size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <path d='M26,28H6a2.0027,2.0027,0,0,1-2-2V6A2.0027,2.0027,0,0,1,6,4H16V6H6V26H26V16h2V26A2.0027,2.0027,0,0,1,26,28Z' />
      <polygon points='20 2 20 4 26.586 4 18 12.586 19.414 14 28 5.414 28 12 30 12 30 2 20 2' />
    </Icon>
  )
}

IconCarbonLaunch.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconCarbonLaunch
