import PropTypes from 'prop-types'
import { SIZE } from 'baseui/button'
import { FormControl } from 'baseui/form-control'
import { HeadingSmall } from 'baseui/typography'
import { Modal, ROLE } from 'baseui/modal'
import AbandonModal from '../Modules/AbandonModal/AbandonModal'
import CallLogModal from '../Modules/CallLogModal/CallLogModal'
import CommentsModal from '../Modules/CommentsModal/CommentsModal'
import IconCarbonTool from '@/shared/icons/IconCarbonTool'
import SDButtonGroup from '@/shared/components/SDComponents/SDButtonGroup/SDButtonGroup'
import SDButtonGroupButton from '@/shared/components/SDComponents/SDButtonGroup/SDButtonGroupButton'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import useToolsUtilities from './useToolsUtilities'

const ToolsUtilities = ({ subscription, updateTable }) => {
  const {
    t,
    setModalType,
    modalType,
    isOpen,
    setIsOpen,
    buttonGroupStyle,
    buttonStyle,
    applicationType,
    setIsOpenHandler,
  } = useToolsUtilities(updateTable)

  return (
    <>
      <HeadingSmall>
        <IconCarbonTool size={24} />
        {t('subscriptionTable:tools_title')}
      </HeadingSmall>

      <FormControl label={t('subscriptionTable:status')}>
        <SDButtonGroup className={buttonGroupStyle} size={SIZE.mini}>
          <SDButtonGroupButton
            className={buttonStyle}
            disabled={!subscription.actionsLinks.abandon}
            onClick={e => {
              e.preventDefault()
              setModalType('abandon')
              setIsOpen(true)
            }}
          >
            {t('subscriptionTable:abandon')}
          </SDButtonGroupButton>
        </SDButtonGroup>
      </FormControl>

      <FormControl label={t('subscriptionTable:actions')}>
        <SDButton
          onClick={e => {
            e.preventDefault()
            window.open('/' + applicationType + '/subscription/edit/' + subscription.id, '_blank')
          }}
          block={true}
        >
          {t('subscriptionTable:edit')}
        </SDButton>
      </FormControl>

      <FormControl label={t('subscriptionTable:comments')}>
        <SDButton
          size={SIZE.mini}
          block={true}
          onClick={e => {
            e.preventDefault()
            setModalType('comments')
            setIsOpen(true)
          }}
        >
          {t('subscriptionTable:view_add_comments')}
        </SDButton>
      </FormControl>

      <FormControl label={t('subscriptionTable:history')}>
        <SDButtonGroup className={buttonGroupStyle} size={SIZE.mini}>
          <SDButtonGroupButton
            disabled={!subscription.actionsLinks.editCallsNum}
            className={buttonStyle}
            onClick={e => {
              e.preventDefault()
              setModalType('callLog')
              setIsOpen(true)
            }}
          >
            {t('subscriptionTable:call_log')}
          </SDButtonGroupButton>
        </SDButtonGroup>
      </FormControl>

      <Modal onClose={() => setIsOpen(false)} isOpen={isOpen} animate autoFocus size={SIZE.full} role={ROLE.dialog}>
        {modalType === 'abandon' && <AbandonModal handler={setIsOpenHandler} id={subscription.id} />}
        {modalType === 'comments' && (
          <CommentsModal comments={subscription.comments} id={subscription.id} handler={setIsOpenHandler} />
        )}
        {modalType === 'callLog' && (
          <CallLogModal calls={subscription.calls} id={subscription.id} handler={setIsOpenHandler} />
        )}
      </Modal>
    </>
  )
}

ToolsUtilities.propTypes = {
  subscription: PropTypes.object,
  endpoints: PropTypes.object,
  updateTable: PropTypes.func,
}

export default ToolsUtilities
