import Financial from '../Sections/Financial'
import Identity from '../Sections/Identity'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import Integration from '../Sections/Integration'
import Contract from '../Sections/Contract'
import ElectricityGasRelated from '../Sections/ElectricityGasRelated'

const GeneralTab = ({ t, providerState, updateOffer, errors, blockStyle, sectionStyle }) => {
  return (
    <>
      <FlexGrid flexGridColumnCount={3} flexGridColumnGap='scale800' flexGridRowGap='scale800'>
        <FlexGridItem>
          <div className={blockStyle}>
            <h2 className={sectionStyle}>{t('organizationManagement:general_info.title')}</h2>
            <Identity t={t} updateOffer={updateOffer} providerState={providerState} errors={errors} />
          </div>
          <div className={blockStyle}>
            <h2 className={sectionStyle}>{t('organizationManagement:services_integration.title')}</h2>
            <Integration t={t} updateOffer={updateOffer} providerState={providerState} errors={errors} />
          </div>
          {providerState.selectedOffer.energy_type !== 'propane' && (
            <div className={blockStyle}>
              <h2 className={sectionStyle}>{t('organizationManagement:energy_related.title')}</h2>
              <ElectricityGasRelated t={t} updateOffer={updateOffer} providerState={providerState} errors={errors} />
            </div>
          )}
        </FlexGridItem>
        <FlexGridItem>
          <div className={blockStyle}>
            <h2 className={sectionStyle}>{t('organizationManagement:contract_section.title')}</h2>
            <Contract t={t} updateOffer={updateOffer} providerState={providerState} errors={errors} />
          </div>
        </FlexGridItem>
        <FlexGridItem>
          <div className={blockStyle}>
            <h2 className={sectionStyle}>{t('organizationManagement:finance_section.title')}</h2>
            <Financial t={t} updateOffer={updateOffer} providerState={providerState} errors={errors} />
          </div>
        </FlexGridItem>
      </FlexGrid>
    </>
  )
}

export default GeneralTab
