import { useEffect, useState } from 'react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { fetchAgentGoals } from '@/containers/Objectives/goalRequest'
import AgentGoalItem from './AgentGoalItem'

const AgentGoals = () => {
  const [goals, setGoals] = useState([])

  useEffect(() => {
    ;(async () => {
      const goals = await fetchAgentGoals()

      setGoals(goals)
    })()
  }, [])

  if (!goals || goals.length <= 0) {
    return ''
  }

  return (
    <FlexGrid flexGridColumnCount={3} flexGridColumnGap='scale800' flexGridRowGap='scale800'>
      {goals.map(goal => (
        <FlexGridItem key={goal.id}>
          <AgentGoalItem goal={goal} />
        </FlexGridItem>
      ))}
    </FlexGrid>
  )
}

export default AgentGoals
