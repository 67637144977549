import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useBusinessType from '@/shared/hooks/useBusinessType'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { useTranslation } from 'react-i18next'
import { pageConfigurationActions } from '@/store/PageConfiguration/PageConfigurationSagas'
import {
  selectPageConfigurationData,
  selectPageConfigurationIsLoading
} from '@/store/PageConfiguration/PageConfigurationSelectors'
import useDashboardFiltersParams from './DashboardFilters/useDashboardFiltersParams'
import { selectedDashboard } from './DashboardSelectors'
import { updateDashboardUrl, updateDashboardFilters, updateDashboardPage } from './DashboardSlice'
import { dashboardActions } from './DashboardSagas'

const useDashboard = ({ type }) => {
  const dispatch = useDispatch()
  const { loaderRoutesEnergy, loaderRoutes, loaderRoutesInsurance } = useSelector(selectAuthData)
  const { getApplicationUrl, type: applicationType } = useBusinessType({
    loaderRoutesEnergy,
    loaderRoutes,
    loaderRoutesInsurance,
    modulesStatuses: {},
    modulesStatusesEnergy: {},
    modulesStatusesInsurance: {}
  })
  const initUrl =
    type === 'overview'
      ? getApplicationUrl('subscriptions-list-telesales') ?? ''
      : getApplicationUrl('subscriptions-list-websales') ?? ''
  const { t } = useTranslation()
  const isLoading = useSelector(selectPageConfigurationIsLoading)
  const { tableUrl, prefilters, users, providers, endpoint } = useSelector(selectPageConfigurationData)
  const { page, filters, url } = useSelector(selectedDashboard)

  const manageURL = pageToUse => {
    if (!tableUrl) {
      return ''
    }

    const dashboardFiltersParams = useDashboardFiltersParams(filters, applicationType)
    const params = dashboardFiltersParams.create()
    const baseUrl = tableUrl ?? ''

    if (pageToUse) {
      params.push(`cursor=${pageToUse}`)
    }

    if (baseUrl.includes('?')) {
      return baseUrl + '&' + params.join('&')
    }

    return baseUrl + '?' + params.join('&')
  }

  const getSubscriptions = () => {
    dispatch({ type: dashboardActions.LOAD_SUBSCRIPTIONS, url })
  }

  const dispatchFilters = filters => {
    dispatch(updateDashboardFilters(filters))
  }

  const updateUrlHandler = pageToUse => {
    const newUrl = manageURL(pageToUse)
    if (!filters || !pageToUse || !newUrl) return
    dispatch(updateDashboardUrl(newUrl))
  }

  const initDashboardPage = () => {
    dispatch(updateDashboardPage(1))
    dispatch(updateDashboardUrl(''))
  }

  useEffect(() => {
    dispatch({ type: pageConfigurationActions.INITIALIZE, initUrl })
    window.addEventListener('beforeunload', initDashboardPage)
    return () => {
      window.removeEventListener('beforeunload', initDashboardPage)
    }
  }, [])

  useEffect(() => {
    updateUrlHandler(page)
  }, [page, tableUrl])

  useEffect(() => {
    updateUrlHandler(1)
  }, [filters])

  useEffect(() => {
    if (!prefilters) return
    dispatchFilters(JSON.parse(prefilters))
  }, [prefilters])

  useEffect(() => {
    if (url) {
      getSubscriptions()
    }
  }, [url])

  return {
    t,
    isLoading,
    getSubscriptions,
    users,
    providers,
    endpoint,
    filters,
    usersList: users,
    providersList: providers,
    applicationType
  }
}

export default useDashboard
