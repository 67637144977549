import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconCarbonEventsAlt = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'Tag'
  size = size ?? 16
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 32 32' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <path
        d='M18,31h2V29a1.0006,1.0006,0,0,1,1-1h6a1.0006,1.0006,0,0,1,1,1v2h2V29a3.0033,3.0033,0,0,0-3-3H21a3.0033,3.0033,0,0,0-3,3Z'
        transform='translate(0 0)'
      />
      <path
        d='M24,25a4,4,0,1,1,4-4A4.0039,4.0039,0,0,1,24,25Zm0-6a2,2,0,1,0,2,2A2.0027,2.0027,0,0,0,24,19Z'
        transform='translate(0 0)'
      />
      <path
        d='M2,31H4V29a1.0009,1.0009,0,0,1,1-1h6a1.0009,1.0009,0,0,1,1,1v2h2V29a3.0033,3.0033,0,0,0-3-3H5a3.0033,3.0033,0,0,0-3,3Z'
        transform='translate(0 0)'
      />
      <path
        d='M8,25a4,4,0,1,1,4-4A4.0042,4.0042,0,0,1,8,25Zm0-6a2,2,0,1,0,2,2A2.0023,2.0023,0,0,0,8,19Z'
        transform='translate(0 0)'
      />
      <path
        d='M18,16h2V14a1.0009,1.0009,0,0,1,1-1h6a1.0009,1.0009,0,0,1,1,1v2h2V14a3.0033,3.0033,0,0,0-3-3H21a3.0033,3.0033,0,0,0-3,3Z'
        transform='translate(0 0)'
      />
      <path
        d='M24,10a4,4,0,1,1,4-4A4.0042,4.0042,0,0,1,24,10Zm0-6a2,2,0,1,0,2,2A2.0023,2.0023,0,0,0,24,4Z'
        transform='translate(0 0)'
      />
      <path
        d='M2,16H4V14a1.0013,1.0013,0,0,1,1-1h6a1.0013,1.0013,0,0,1,1,1v2h2V14a3.0033,3.0033,0,0,0-3-3H5a3.0033,3.0033,0,0,0-3,3Z'
        transform='translate(0 0)'
      />
      <path
        d='M8,10a4,4,0,1,1,4-4A4.0045,4.0045,0,0,1,8,10ZM8,4a2,2,0,1,0,2,2A2.002,2.002,0,0,0,8,4Z'
        transform='translate(0 0)'
      />
    </Icon>
  )
}

IconCarbonEventsAlt.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconCarbonEventsAlt
