import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import useHttp from '@/shared/hooks/useHttp'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import useBusinessType from '@/shared/hooks/useBusinessType'

const useContactPage = () => {
  const { t } = useTranslation()
  const [zoho, setZoho] = useState('')
  const { sendRequest, getConfig } = useHttp()
  const [data, setData] = useState(null)
  const [subscriptionsResults, setSubscriptionsResults] = useState(null)
  const [createCacheError, setCreateCacheError] = useState(null)
  const [searchError, setSearchError] = useState(null)
  const [loading,setLoading] = useState(null)
  const { loaderRoutes, lang } =
    useSelector(selectAuthData)
  const { getApplicationUrl } = useBusinessType({
    loaderRoutes,
  })
  const initUrl = getApplicationUrl('contact-page')

  const urlCreateCache = () => {
    return data.urls.contact_page_create_call_cache_url
  }

  const requestSearchContactResponse = (response, statusCode) => {
    setLoading(false)
    if(statusCode === 200){
      setSubscriptionsResults(response)
      return
    }

    if(response.message){
      setSearchError(response.message)
      return
    }
    setSearchError(t('contactPage:error_search'))
  }

  const requestCreateContactCacheResponse = response => {
    if (response && response.zohoId) {
          window.open('/call-information/' + response.zohoId, '_blank');
      } else {
        setCreateCacheError(t('contactPage:subscriptions_table_results.fail_contact_create_cache'));
      }
  }

  const requestSearchContactHandler = () => {
    setLoading(true)
    setSearchError(null)
    sendRequest(data.urls.contact_page_search_url + '/' + zoho, getConfig('GET'), requestSearchContactResponse)
  }

  const requestCreateContactCacheHandler = (urlCreateCache, subscriptionId) => {
    sendRequest(urlCreateCache + '/' + subscriptionId, getConfig('GET'), requestCreateContactCacheResponse)
  }

  const initialize = () => {
    const countryUrl = initUrl ? initUrl + '?lang=' + lang : ''
    sendRequest(countryUrl, getConfig('GET'), json => setData(json))
  }

  return {
    t,
    zoho,
    setZoho,
    requestSearchContactHandler,
    requestCreateContactCacheHandler,
    initialize,
    data,
    subscriptionsResults,
    urlCreateCache,
    createCacheError,
    loading,
    searchError
  }
}

export default useContactPage
