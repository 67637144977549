export const EnergyOfferParametersGeneration = (offerData, selectedProviderId) => {
  const options = new URLSearchParams()
  options.append('lang', 'fr')
  const parametersToExclude = ['created_at', 'updated_at', 'organisation', 'is_complete']
  for (const [name, value] of Object.entries(offerData)) {
    if (value !== undefined && value !== null && value.toString().length > 0 && !parametersToExclude.includes(name)) {
      options.append(name, value)
    }
  }

  if (!offerData.organisation_id) {
    options.append('organisation_id', selectedProviderId)
  }

  if (!offerData.id) {
    options.append('id', 0)
  }

  if (!offerData.debordement_call_status) {
    options.append('debordement_call_status', '0')
    options.append('debordement_call_zoho_id', '')
  }

  return options
}
