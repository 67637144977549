import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'
import { useTranslation } from 'react-i18next'
import { Notification } from 'baseui/notification'
import Table from './Table'
import { StyledTitleWrapped } from '../styled-components'

const ModalLogs = ({ data }) => {
  const [css] = useStyletron()
  const { t } = useTranslation()

  if (data.length === 0) {
    return (
      <Notification
        overrides={{
          Body: {
            style: {
              width: 'auto',
              color: 'rgb(20, 96, 170)',
              backgroundColor: 'rgb(233, 242, 251)',
            },
          },
        }}
      >
        {() => t('subscriptionForm:subscription_actions_module.historic_logs.no_export_logs_result')}
      </Notification>
    )
  }

  return (
    <div
      className={css({
        height: 'auto',
      })}
    >
      {Object.entries(data).map(([index, historic]) => {
        return (
          // eslint-disable-next-line react/jsx-key
          <>
            <StyledTitleWrapped>{historic.author + ' - ' + historic.created_at_readable}</StyledTitleWrapped>
            <Table data={historic.changes} />
          </>
        )
      })}
    </div>
  )
}

ModalLogs.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default ModalLogs
