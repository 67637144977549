import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'

export default function StatusBadge({ status, colour }) {
  const [css] = useStyletron()
  const linkStyle = css({
    display: 'flex',
    width: '100%',
    padding: '.125rem .25rem',
    border: `2px solid ${colour.light}`,
    background: colour.light,
    color: colour.dark,
    borderRadius: '.25rem',
  })
  const contentStyle = css({
    margin: '0 auto',
    fontSize: '.75rem',
    textAlign: 'center',
  })

  return (
    <div className={linkStyle}>
      <div className={contentStyle}>{status}</div>
    </div>
  )
}

StatusBadge.propTypes = {
  status: PropTypes.string,
  colour: PropTypes.object,
}
