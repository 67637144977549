import { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import CommonFallback from '@/shared/components/CommonFallback'
import { useTranslation } from 'react-i18next'
import ProviderManagementContext from '../ProviderManagementContext'
import useHttp from '@/shared/hooks/useHttp'
import useBusinessType from '@/shared/hooks/useBusinessType'
import EnergyForm from './Energy/EnergyForm'
import TelecomForm from './Telecom/TelecomForm'
import saveTelecomOrganization from './Telecom/saveTelecomOrganization'
import saveEnergyOrganization from './Energy/saveEnergyOrganization'
import saveInsuranceOrganization from './Insurance/saveInsuranceOrganization'
import InsuranceForm from './Insurance/InsuranceForm'
import { useSelector } from 'react-redux'
import { selectAuthData } from '@/containers/Login/AuthSelectors'

const Edit = () => {
  const { lang } = useSelector(selectAuthData)
  const { type } = useBusinessType({
    loaderRoutesEnergy: {},
    loaderRoutes: {},
    loaderRoutesInsurance: {},
    modulesStatuses: {},
    modulesStatusesEnergy: {},
    modulesStatusesInsurance: {},
  })
  const providerContext = useContext(ProviderManagementContext)
  const providerState = providerContext.providerState
  const providerDispatch = providerContext.providerDispatch
  const { sendRequest, getConfig } = useHttp()
  const { t } = useTranslation()
  const { id } = useParams()
  const loadProviderData = async providerId => {
    await sendRequest(
      providerState.routes.providerInformation.replace('*', providerId) + '?lang=' + lang,
      getConfig('GET'),
      json => providerDispatch({ type: 'setProvider', payload: json }),
    )
  }
  const saveProvider = () => {
    if (type === 'telecom') {
      saveTelecomOrganization(
        providerState.selectedProvider,
        providerDispatch,
        providerState.routes.update.replace('*', id) + '?lang=' + lang,
        getConfig('PUT'),
        sendRequest,
      )
    }
    if (type === 'energie') {
      saveEnergyOrganization(
        providerState.selectedProvider,
        providerDispatch,
        providerState.routes.update.replace('*', id) + '?lang=' + lang,
        getConfig('PUT'),
        sendRequest,
      )
    }
    if (type === 'assurance') {
      saveInsuranceOrganization(
        providerState.selectedProvider,
        providerDispatch,
        providerState.routes.update.replace('*', id) + '?lang=' + lang,
        getConfig('PUT'),
        sendRequest,
      )
    }
  }

  useEffect(() => {
    loadProviderData(id)
  }, [id])

  if (!providerState.selectedProvider.uuid) {
    return <CommonFallback />
  }

  return (
    <>
      {type === 'energie' && (
        <EnergyForm
          providerDispatch={providerDispatch}
          providerState={providerState}
          saveProvider={saveProvider}
          title={t('organizationManagement:edit') + ' : ' + providerState.selectedProvider.name}
        />
      )}
      {type === 'telecom' && (
        <TelecomForm
          providerDispatch={providerDispatch}
          providerState={providerState}
          saveProvider={saveProvider}
          title={t('organizationManagement:edit') + ' : ' + providerState.selectedProvider.name}
          lang={lang}
        />
      )}
      {type === 'assurance' && (
        <InsuranceForm
          providerDispatch={providerDispatch}
          providerState={providerState}
          saveProvider={saveProvider}
          title={t('organizationManagement:edit') + ' : ' + providerState.selectedProvider.name}
        />
      )}
    </>
  )
}

Edit.propTypes = {
  token: PropTypes.string,
}

export default Edit
