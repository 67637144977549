import { createSelector } from 'reselect'

export const selectOfficesManagement = state => state.officesManagement

export const selectOfficesManagementOffices = createSelector(
  selectOfficesManagement,
  officesManagement => officesManagement.offices,
)

export const selectOfficesManagementSelectedOffice = createSelector(
  selectOfficesManagement,
  officesManagement => officesManagement.selectedOffice,
)
