import { styled } from 'baseui'
import backgroundWave from './bones/modal-wave.svg'

export const ModalTitle = styled('h2', ({ $theme }) => ({
  margin: '0 0 1rem 0',
  color: $theme.colors.primary,
  ...$theme.typography.HeadingMedium,

  '@media screen and (min-width: 768px)': {
    ...$theme.typography.HeadingLarge,
  },
}))

export const ModalContent = styled('div', () => ({
  display: 'flex',
  flexDirection: 'column',
  width: '450px',
}))

export const ModalSections = styled('div', () => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const ModalSubmission = styled('div', () => ({
  marginTop: '1rem',
}))

export const ModalSection = styled('div', () => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 50%',
  ':not(:last-child)': {
    marginRight: '1rem',
  },
}))

export const ModalText = styled('p', () => ({
  marginTop: '1rem',
}))

export const SDModalOverrides = () => ({
  Dialog: {
    style: ({ $theme }) => ({
      width: '75vw',
      borderTopLeftRadius: $theme.borders.radius400,
      borderTopRightRadius: $theme.borders.radius400,
      borderBottomRightRadius: $theme.borders.radius400,
      borderBottomLeftRadius: $theme.borders.radius400,

      '@media screen and (min-width: 768px)': {
        width: '640px',
        height: 'auto',
        minHeight: '380px',
        backgroundColor: $theme.colors.white,
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${backgroundWave})`,
        backgroundPosition: 'right bottom, center calc(100% + 50px)',
        backgroundSize: 'auto',
      },
    }),
  },
})
