export const SDPaginatorOverrides = () => ({
  Root: {
    style: () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'auto',
      marginTop: '.5rem',
      paddingTop: '.5rem',
      paddingRight: '.5rem',
      paddingBottom: '.5rem',
      paddingLeft: '.5rem',
      backgroundColor: 'rgba( 255, 255, 255, 0.5 )',
    }),
  },
})
