import { useState } from 'react'
import { Select, TYPE } from 'baseui/select'
import { FormControl } from 'baseui/form-control'
import debounce from '@/shared/tools/debounce'
import { useTranslation } from 'react-i18next'

const ProductComboBox = props => {
  const [value, setValue] = useState([])
  const { t } = useTranslation()
  const [options, setOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getOptionLabel = ({ option }) => {
    if (option.provider_name?.length > 0) {
      return `${option.provider_name} ${option.name}`
    } else {
      return `${option.name}`
    }
  }

  /**
   * Fetch options asyncronously
   * @type {debounced}
   */
  const handleInputChange = debounce(async value => {
    if (!value) {
      return
    }
    setIsLoading(true)
    try {
      const result = await fetch(`/telecom/api/goals/products?&search=${value}`)
      const data = await result.json()
      setOptions(data)
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, 400)

  return (
    <FormControl label={t('objectives:products')}>
      <Select
        options={options}
        valueKey='id'
        getOptionLabel={getOptionLabel}
        getValueLabel={getOptionLabel}
        placeholder={t('objectives:products')}
        maxDropdownHeight='300px'
        type={TYPE.search}
        onChange={({ value }) => {
          setValue(value)
          props.onChange?.(value)
        }}
        closeOnSelect={false}
        isLoading={isLoading}
        onInputChange={event => {
          const target = event.target
          handleInputChange(target.value)
        }}
        value={props.value}
        filterOptions={option => option}
      />
    </FormControl>
  )
}

export default ProductComboBox
