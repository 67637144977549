import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'

import phoneLogo from '../phone.svg'

const PhoneBadge = ({ callNo, phone, zohoLink }) => {
  const [css] = useStyletron()
  const linkStyle = css({
    display: 'flex',
    width: '100%',
    border: '2px solid limegreen',
    borderRadius: '.25rem',
    backgroundColor: 'white',
  })
  const badgeStyle = css({
    display: 'flex',
    width: '1.5rem',
    padding: '.125rem .25rem .125rem .125rem',
    backgroundColor: 'limegreen',
  })
  const imgStyle = css({
    width: '1.125rem',
    height: '1.125rem',
  })
  const countStyle = css({
    display: 'block',
    width: '.5rem',
    height: '.5rem',
    marginTop: '-.3125rem',
    marginLeft: '-.5rem',
    fontSize: '10px',
  })
  const contentStyle = css({
    padding: '.125rem .25rem',
    margin: '0 auto',
    textAlign: 'center',
  })

  if (!phone) {
    return ''
  }

  return (
    <a className={linkStyle} href={zohoLink} target='_blank' rel='noreferrer'>
      <div className={badgeStyle}>
        <img className={imgStyle} src={phoneLogo} alt={'Contact'} />
        <span className={countStyle}>{callNo}</span>
      </div>
      <div className={contentStyle}>
        <span>{phone}</span>
      </div>
    </a>
  )
}

PhoneBadge.propTypes = {
  callNo: PropTypes.number,
  phone: PropTypes.string,
  zohoLink: PropTypes.string,
}

export default PhoneBadge
