import { useEffect, useReducer, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Input } from 'baseui/input'
import { FormControl } from 'baseui/form-control'
import { Button } from 'baseui/button'
import UserComboBox from './UserComboBox'
import ProductComboBox from './ProductComboBox'
import GoalCombinations from './GoalCombinations'
import ObjectiveDate from './ObjectiveDate'
import ConfirmationModule from './ConfirmationModule'
import { useTranslation } from 'react-i18next'
import { useStyletron } from 'baseui'
import ArrowRight from 'baseui/icon/arrow-right'

const SET_DESCRIPTION = 'SET_DESCRIPTION',
  SET_START = 'SET_START',
  SET_END = 'SET_END',
  SET_GOALS = 'SET_GOALS',
  defaultState = {
    objective: {
      description: '',
      start: '',
      end: '',
    },
    goals: [],
  },
  reducer = (state, action) => {
    switch (action.type) {
      case SET_DESCRIPTION:
        return {
          ...state,
          objective: {
            ...state.objective,
            description: action.payload,
          },
        }
      case SET_START:
        return {
          ...state,
          objective: {
            ...state.objective,
            start: action.payload,
          },
        }
      case SET_END:
        return {
          ...state,
          objective: {
            ...state.objective,
            end: action.payload,
          },
        }
      case SET_GOALS:
        return {
          ...state,
          goals: [...action.payload],
        }
      default:
        throw new Error()
    }
  },
  ObjectiveForm = props => {
    const { data } = props,
      { t, i18n } = useTranslation(),
      [css, theme] = useStyletron(),
      [canBeResent, setCanBeResent] = useState(false),
      objective = data?.objective
        ? {
            ...defaultState.objective,
            ...data.objective,
          }
        : defaultState.objective,
      initialState = {
        ...defaultState,
        goals: [...defaultState.goals, ...(data?.goals ?? [])],
        objective,
      },
      [state, dispatch] = useReducer(reducer, initialState),
      [users, setUsers] = useState([]),
      [products, setProducts] = useState([]),
      generateGoalCombinations = () => {
        setCanBeResent(true)
        const combinations = users.map((user, i) => {
            const userCombinations = products.map((product, j) => {
              return {
                id: `${i}_${j}`,
                data: {
                  userName: user.name,
                  userFirstName: user.firstName,
                  userId: user.id,
                  productId: product.id,
                  productType: product.type,
                  productName: product.name,
                  providerName: product.provider_name,
                  objective: 1,
                },
              }
            })
            return userCombinations
          }),
          actionpayload = []
        combinations.map(combination => {
          const newGoal = Object.assign({}, combination[0])
          actionpayload.push(newGoal)
        })
        dispatch({
          type: SET_GOALS,
          payload: [...state.goals, ...actionpayload],
        })
        setUsers([])
        setProducts([])
      },
      submitHandler = async () => {
        props.onSubmit?.({
          objective: {
            ...state.objective,
          },
          goals: [...state.goals.map(goal => goal.data)],
        })
        setCanBeResent(false)
      }

    useEffect(() => {
      setCanBeResent(true)
    }, [state.goals])

    return (
      <>
        <form>
          <ConfirmationModule showSaveNotification={props.showSaveNotification} />
          <div className='global-container'>
            <div className={'row'}>
              <div className={'col-12'}>
                <FormControl label={i18n.t('objectives:description')}>
                  <Input
                    value={state.objective.description}
                    onChange={e =>
                      dispatch({
                        type: SET_DESCRIPTION,
                        payload: e.target.value,
                      })
                    }
                    placeholder={i18n.t('objectives:description')}
                    clearOnEscape
                  />
                </FormControl>
              </div>
            </div>

            <div
              className={css({
                [theme.mediaQuery.medium]: {
                  display: 'flex',
                  alignItems: 'center',
                },
              })}
            >
              <ObjectiveDate
                label={t('objectives:start_date')}
                value={state.objective.start}
                onChange={date => {
                  dispatch({
                    type: SET_START,
                    payload: date,
                  })
                }}
              />
              <ArrowRight size={24} />
              <ObjectiveDate
                label={t('objectives:end_date')}
                value={state.objective.end}
                onChange={date => {
                  dispatch({
                    type: SET_END,
                    payload: date,
                  })
                }}
              />
            </div>

            <div className={'row'}>
              <div className={'col-12 col-md-6'}>
                <UserComboBox onChange={setUsers} value={users} />
              </div>

              <div className={'col-12 col-md-6'}>
                <ProductComboBox onChange={setProducts} value={products} />
              </div>
            </div>

            <div className={'row'}>
              <div className={'col-12'}>
                <Button type='button' onClick={generateGoalCombinations}>
                  {i18n.t('objectives:goal_combination')}
                </Button>
                <GoalCombinations
                  value={state.goals}
                  onChange={goals => {
                    dispatch({
                      type: SET_GOALS,
                      payload: goals,
                    })
                  }}
                />
              </div>
            </div>

            <div className={'row'}>
              <div className={'col-auto mx-auto'}>
                <NavLink to='/telecom/objectives'>
                  <Button>{i18n.t('objectives:go_back_button')}</Button>
                </NavLink>
              </div>
              <div className={'col-auto mx-auto'}>
                <Button
                  type={'button'}
                  disabled={users.length !== 0 || products.length !== 0 || !canBeResent}
                  onClick={submitHandler}
                >
                  {i18n.t('objectives:save_button')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </>
    )
  }

export default ObjectiveForm
