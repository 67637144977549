import FieldsSwitch from '../Fields/FieldsSwitch'

const ContentTab = ({ t, providerState, updateOffer, fields, blockStyle }) => {
  return (
    <div className={blockStyle}>
      <FieldsSwitch fields={fields} updateOffer={updateOffer} t={t} providerState={providerState} group='content' />
    </div>
  )
}

export default ContentTab
