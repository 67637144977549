import React, { useState, useEffect } from 'react'
import { useStyletron } from 'baseui'
import { SIZE } from 'baseui/input'
import { FormControl } from 'baseui/form-control'
import { useTranslation } from 'react-i18next'
import { Table } from 'baseui/table-semantic'
import useHttp from '@/shared/hooks/useHttp'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import SDNotification from '@/shared/components/SDComponents/SDNotification'
import { useNavigate } from 'react-router-dom'
import SDInput from '@/shared/components/SDComponents/SDInput/SDInput'

const SearchContactFromZoho = ({
  zohoSearchUrl,
  zohoSearchResponseUrl,
  createSubscription,
  applicationType,
  subscriptionId
}) => {
  const { t } = useTranslation()
  const [css, theme] = useStyletron()
  const [phone, setPhone] = useState(null)
  const [phoneError, setPhoneError] = useState(null)
  const [resultError, setResultError] = useState(null)
  const [searchId, setSearchId] = useState(null)
  const [loadingResponse, setLoadingResponse] = useState(false)
  const [result, setResult] = useState([])
  const { sendRequest, isLoading, getConfig } = useHttp({})
  const navigate = useNavigate()
  const cardStyle = css({
    padding: '1rem',
    marginBottom: '2rem',
    backgroundColor: 'white',
    borderRadius: '1rem',
    boxShadow: '0 2px 6px rgba(20,96,170,.3)'
  })
  const headingStyle = css({
    margin: '1rem 10px 10px 10px',
    color: theme.colors.primary,
    ...theme.typography.HeadingMedium
  })
  const parent = css({
    display: 'flex'
  })
  const COLUMNS = [
    t('subscriptionForm:contact_search.table.name'),
    t('subscriptionForm:contact_search.table.firstname'),
    t('subscriptionForm:contact_search.table.phone'),
    ''
  ]
  const triggerSearchClient = () => {
    if (!phone) {
      return
    }
    setResult([])
    setResultError(null)
    const headers = getConfig('GET')
    headers.headers['Content-Type'] = 'application/json'
    sendRequest(
      zohoSearchUrl + '?phone=' + encodeURIComponent(phone),
      headers,
      (response, status) => {
        if (status !== 200 && status !== 201) {
          setPhoneError(response)
          setSearchId(null)
          return
        }
        if (!response.id) {
          setPhoneError(t('subscriptionForm:contact_search.request_saved'))
          return
        }
        setPhoneError(null)
        setSearchId(response.id)
      }
    )
  }

  const handleRetrieveResponse = (response, status) => {
    if (status === 202) {
      return { response: null, error: false, isFinished: false }
    }
    if (status === 500) {
      return { response, error: true, isFinished: false }
    }

    return { response, error: false, isFinished: true }
  }

  const retrieveSearchDataResponse = async () => {
    const headers = getConfig('GET')
    headers.headers['Content-Type'] = 'application/json'
    return await sendRequest(
      zohoSearchResponseUrl.replace('%id%', searchId),
      headers,
      handleRetrieveResponse
    )
  }

  const mapResult = result => {
    return result.map(element => {
      return [
        element.name,
        element.firstname,
        element.phone,
        <SDButton
          key={element.zoho_id}
          onClick={() => createSubscription(generateParameters(element))}
        >{t('subscriptionForm:contact_search.choose')}
        </SDButton>
      ]
    })
  }

  const generateParameters = element => {
    let params = '&bypassCallInformation=1&id=' + (element.zoho_id ?? '') + '&'
    params += 'phone=' + (element.phone ?? '') + '&'
    params += 'firstname=' + (element.firstname ?? '') + '&'
    params += 'lastname=' + (element.name ?? '')
    return params
  }

  useEffect(() => {
    if (searchId === null) {
      return
    }
    let count = 0
    setLoadingResponse(true)
    const interval = setInterval(async () => {
      const searchResponse = await retrieveSearchDataResponse()
      console.log(searchResponse, count)
      count++
      if (
        count === 3 ||
        searchResponse.response ||
        searchResponse.error ||
        searchResponse.isFinished
      ) {
        clearInterval(interval)
        setLoadingResponse(false)
        setSearchId(null)
        if (searchResponse.error) {
          setResultError(searchResponse.response.error)
        }
        if (searchResponse.response?.data) {
          if (searchResponse.response?.data.length === 1) {
            createSubscription(
              generateParameters(searchResponse.response?.data[0])
            )
          } else {
            setResult(searchResponse.response.data)
          }
        }
      }
    }, 3000)
  }, [searchId])

  useEffect(
    () => {
      if (!subscriptionId) {
        return
      }
      navigate(`/${applicationType}/subscription/edit/${subscriptionId}`)
    },
    [subscriptionId]
  )

  return (
    <>
      <div className={parent}>
        <div className={cardStyle}>
          <h1 className={headingStyle}>
            {t('subscriptionForm:contact_search.panel_name')}
          </h1>
          <FormControl
            error={phoneError?.errors?.phone ?? phoneError?.error ?? phoneError?.message ?? ''}
            label={
              <span>
                {t('subscriptionForm:contact_search.phone_label')}
              </span>
            }
          >
            <SDInput
              size={SIZE.compact}
              placeholder={t('subscriptionForm:contact_search.phone_label')}
              autoComplete='off'
              onBlur={event => setPhone(event.target.value)}
            />
          </FormControl>
          <SDButton
            block
            onClick={triggerSearchClient}
            isLoading={isLoading || loadingResponse}
            disabled={isLoading || loadingResponse}
          >
            {t('subscriptionForm:contact_search.button')}
          </SDButton>
          {searchId && loadingResponse &&
            <SDNotification type='positive'>
              {t('subscriptionForm:contact_search.request_saved')}
            </SDNotification>}
        </div>
      </div>
      {resultError &&
        <SDNotification type='warning'>
          {resultError}
        </SDNotification>}
      {result && resultError === null && result.length > 0 &&
        <div className={parent}>
          <div className={cardStyle}>
            <Table columns={COLUMNS} data={mapResult(result)} />
          </div>
        </div>}
    </>
  )
}

export default SearchContactFromZoho
