import { Route, Routes } from 'react-router-dom'
import CreateForm from './SubscriptionForm/CreateForm'
import EditForm from './SubscriptionForm/EditForm'

const TelesalesForm = () => {
  return (
    <Routes>
      <Route exact path='add' element={<CreateForm />} />
      <Route path='edit/:id' element={<EditForm />} />
    </Routes>
  )
}

export default TelesalesForm
