import PropTypes from 'prop-types'
import { StyledBodyCell } from 'baseui/table-grid'
import { useStyletron } from 'baseui'
import getIcon from '../../../../hoc/SidebarMenu/helpers/getIcon'

const ProductTypeCell = ({ subscriberProductType, striped, selected }) => {
  const [css, theme] = useStyletron()
  const cellStyle = css({
    padding: '.5rem',
    backgroundColor: selected ? '#c1d1e2' : 'none',
  })
  const iconProps = {
    size: 24,
    color: theme.colors.primary,
    title: '',
  }

  if (!subscriberProductType) {
    return <StyledBodyCell className={cellStyle} $striped={striped && !selected} />
  }

  return (
    <StyledBodyCell className={cellStyle} $striped={striped && !selected}>
      {getIcon(subscriberProductType + '-app')(iconProps)}
    </StyledBodyCell>
  )
}

ProductTypeCell.propTypes = {
  striped: PropTypes.bool,
  selected: PropTypes.bool,
  icon: PropTypes.func,
}

export default ProductTypeCell
