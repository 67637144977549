import { FormControl } from 'baseui/form-control'
import { useEffect, useState } from 'react'
import SDSelect from '@/shared/components/SDComponents/SDSelect/SDSelect'
import useHttp from '@/shared/hooks/useHttp'

const ComparatorOffer = ({ currentValue, updateOffer, t, providerState }) => {
  const [comparatorOffers, setComparatorOffers] = useState([])
  const [isLoadingOffers, setIsLoadingOffers] = useState(false)
  const [selectedValue, setSelectedValue] = useState([])
  const { sendRequest, getConfig } = useHttp()
  const loadOffers = () => {
    let url = providerState.routes.offers.filteredComparatorOffers
    url += '?contract_type=' + encodeURIComponent(providerState.selectedOffer.contract_type)
    url += '&energy_type=' + encodeURIComponent(providerState.selectedOffer.energy_type)

    sendRequest(url, getConfig('GET'), (response, statusCode) => {
      if (statusCode === 200) {
        setComparatorOffers(response)
      }
      setIsLoadingOffers(false)
    })
  }

  useEffect(() => {
    if (comparatorOffers.length <= 0) {
      setSelectedValue([])
      return
    }
    for (const [, offer] of Object.entries(comparatorOffers)) {
      const data = {
        label: offer.label,
        id: offer.id,
      }
      if (parseInt(offer.id) === parseInt(currentValue)) {
        setSelectedValue([data])
      }
    }
  }, [comparatorOffers])

  useEffect(() => {
    if (providerState.selectedOffer.energy_type && providerState.selectedOffer.contract_type) {
      loadOffers()
    }
  }, [providerState.selectedOffer.energy_type, providerState.selectedOffer.contract_type])

  return (
    <FormControl key={'comparator_offer'} label={t('organizationManagement:offer.comparator_offer')}>
      <SDSelect
        isLoading={isLoadingOffers}
        value={selectedValue}
        options={comparatorOffers}
        onChange={params => {
          setSelectedValue(params.value)
          updateOffer('comparator_id', params.value[0]?.id ?? '')
        }}
      />
    </FormControl>
  )
}

export default ComparatorOffer
