export const mapTelecomSubscriptionData = viewParameters => {
  const currentContract = viewParameters?.current_subscriber ?? {}

  return {
    characteristics: {
      productType: currentContract.product_telecom?.product_type ?? '',
      prestationType: currentContract.product_telecom?.prestation_type ?? '',
    },
    contract: {
      providerId: currentContract.product_telecom?.organisation_id ?? '',
      offerId: currentContract.product_telecom?.offer_id ?? '',
      providerExternalId: currentContract.product_telecom?.provider_external_id ?? '',
      providerOptions: viewParameters.provider_options ?? {},
    },
  }
}
