const useToken = () => {
  const get = name => window.localStorage.getItem(name)
  const set = (name, tokenValue) => window.localStorage.setItem(name, tokenValue)
  const destroy = () => window.localStorage.removeItem('hop-telecom-auth')

  return {
    get,
    set,
    destroy,
  }
}

export default useToken
