import PropTypes from 'prop-types'

import { mergeOverrides } from 'baseui'
import { Button, SIZE } from 'baseui/button'

import { SDButtonOverrides } from './styled-components'
import { forwardRef } from 'react'

// eslint-disable-next-line react/display-name
const SDButton = forwardRef((props, ref) => {
  const newProps = {}
  const newOverrides = mergeOverrides(SDButtonOverrides(props.block), props.overrides)

  Object.keys(props).forEach(prop => {
    if (prop !== 'block') {
      newProps[prop] = props[prop]
    }
  })

  return <Button {...newProps} ref={ref} size={props.size ?? SIZE.compact} overrides={newOverrides} />
})

SDButton.propTypes = {
  block: PropTypes.bool,
  size: PropTypes.string,
  overrides: PropTypes.object,
}

export default SDButton
