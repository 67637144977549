import common from './common.json'
import objectives from './objectives.json'
import home from './home.json'
import menu from './menu.json'
import devTools from './devTools.json'
import carbonOffsetConfiguration from './carbonOffsetConfiguration.json'
import callReasonsConfiguration from './callReasonsConfiguration.json'
import assistancesConfiguration from './assistancesConfiguration.json'
import monConseillerPersoConfiguration from './monConseillerPersoConfiguration.json'
import callInformation from './callInformation.json'
import additionalSales from './additionalSales.json'
import subscriptionTable from './subscriptionTable.json'
import subscriptionForm from './subscriptionForm.json'
import subscriptionTools from './subscriptionTools.json'
import servicesManagement from './admin/servicesManagement.json'
import organizationManagement from './admin/organizationManagement.json'
import providerOptionsManagement from './admin/providerOptionsManagement.json'
import officesManagement from './admin/officesManagement.json'
import usersManagement from './admin/usersManagement.json'
import ipAddressesManagement from './admin/ipAddressesManagement.json'
import contactPage from './contactPage.json'
import auth from './auth.json'
import dashboard from './dashboard.json'

export default {
  home,
  objectives,
  common,
  menu,
  devTools,
  carbonOffsetConfiguration,
  callReasonsConfiguration,
  callInformation,
  additionalSales,
  subscriptionTable,
  subscriptionForm,
  subscriptionTools,
  servicesManagement,
  organizationManagement,
  providerOptionsManagement,
  usersManagement,
  ipAddressesManagement,
  auth,
  dashboard,
  assistancesConfiguration,
  monConseillerPersoConfiguration,
  officesManagement,
  contactPage,
}
