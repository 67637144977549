import { call, put, select, takeLatest } from 'redux-saga/effects'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import getAPIConfig from '@/shared/tools/getAPIConfig'
import { selectServicesStatusList } from './ServicesStatusSelectors'
import { updateServicesStatusList } from './ServicesStatusSlice'

export const servicesStatusActions = {
  UPDATE_SERVICE_STATUS: 'UPDATE_SERVICE_STATUS',
}

export function* updateServiceStatusSaga({ service, status }) {
  try {
    const { authTokenSession, lang } = yield select(selectAuthData)
    const { editRoute } = yield select(selectPageConfigurationData)
    const services = yield select(selectServicesStatusList)
    const config = yield call(getAPIConfig, { method: 'post', authTokenSession, body: { service, status, lang } })
    const response = yield call(fetch, editRoute, config)

    const { newStatus } = yield call([response, response.json])
    if (newStatus === true || newStatus === false) {
      const newServicesStatus = { ...services, [service]: newStatus }
      yield put(updateServicesStatusList(newServicesStatus))
    }
  } catch (error) {
    console.error(error)
  }
}

export default function* servicesStatusSagas() {
  yield takeLatest(servicesStatusActions.UPDATE_SERVICE_STATUS, updateServiceStatusSaga)
}
