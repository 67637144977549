import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: {
    initialized: false,
    authTokenSession: null,
    modulesStatuses: null,
    modulesStatusesEnergy: null,
    modulesStatusesInsurance: null,
    loaderRoutes: {
      home: '',
    },
    loaderRoutesEnergy: {
      home: '',
    },
    loaderRoutesInsurance: {
      home: '',
    },
    isSelectraAdmin: false,
    isAdmin: false,
    isMcpAgent: false,
    userId: null,
    sessionExpiration: null,
    authorizedSites: {},
  },
  error: '',
}

const updateAuthIsLoadingHandler = (state, action) => {
  state.isLoading = action.payload
}

const updateAuthDataHandler = (state, action) => {
  state.data = action.payload
}

const updateAuthErrorHandler = (state, action) => {
  state.error = action.payload
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateAuthIsLoading: updateAuthIsLoadingHandler,
    updateAuthData: updateAuthDataHandler,
    updateAuthError: updateAuthErrorHandler,
    clearAuth: () => initialState,
  },
})

export const { updateAuthIsLoading, updateAuthData, updateAuthError, clearAuth } = authSlice.actions

export default authSlice.reducer
