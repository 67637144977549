import { Modal, ModalHeader, ModalBody } from 'baseui/modal'
import { SIZE } from 'baseui/button'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'

import CarbonOffsetsForm from './CarbonOffsetsForm/CarbonOffsetsForm'
import CarbonOffsetsList from './CarbonOffsetList'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import Paginator from '@/shared/components/Paginator'
import PageTitle from '@/shared/components/PageTitle'
import SearchInTable from '@/shared/components/SearchInTable'
import useCarbonOffsets from './useCarbonOffsets'

const CarbonOffsets = () => {
  const {
    t,
    carbonOffsets,
    pageConfigurationIsLoading,
    isOpen,
    close,
    setIsOpen,
    page,
    setPage,
    setCarbonOffsetHandler,
    elementsPerPage,
    setSelectedCarbonOffset,
    selectedCarbonOffset,
  } = useCarbonOffsets()

  const flexGridItemProps = {
    display: 'flex',
    alignItems: 'center',
  }

  const transformCarbonOffset = carbonOffsetList => {
    if (!carbonOffsetList) return []
    let elements = carbonOffsetList.map(carbonOffset => {
      return [
        carbonOffset.id,
        carbonOffset.name,
        carbonOffset.price,
        carbonOffset.description,
        carbonOffset.energy_type,
        carbonOffset.zoho_id,
        <SDButton
          key={carbonOffset.id}
          block={true}
          size={SIZE.compact}
          onClick={() => setSelectedCarbonOffset(carbonOffset)}
        >
          {t('common:edit')}
        </SDButton>,
      ]
    })

    elements = elements.slice((page - 1) * elementsPerPage, page * elementsPerPage)
    return elements
  }

  if (pageConfigurationIsLoading) {
    return <p>{t('common:fetching_data')}</p>
  }

  return (
    <>
      <PageTitle title={t('carbonOffsetConfiguration:title')} />
      <FlexGrid flexGridColumnCount={2} marginBottom={'.5rem'}>
        <FlexGridItem {...flexGridItemProps}>
          <SDButton key={0} block={false} size={SIZE.compact} onClick={() => setSelectedCarbonOffset(null)}>
            {t('carbonOffsetConfiguration:create_product')}
          </SDButton>
        </FlexGridItem>
        <FlexGridItem {...flexGridItemProps}>
          <SearchInTable
            placeholder={t('carbonOffsetConfiguration:search_product')}
            setElements={setCarbonOffsetHandler}
            elements={carbonOffsets}
          />
        </FlexGridItem>
      </FlexGrid>
      <CarbonOffsetsList items={transformCarbonOffset(carbonOffsets)} />
      <Paginator elementsPerPage={elementsPerPage} page={page} setPage={setPage} elements={carbonOffsets ?? []} />
      <Modal onClose={close} isOpen={isOpen}>
        <ModalHeader>
          {selectedCarbonOffset?.id === 0
            ? t('carbonOffsetConfiguration:modal_header_create')
            : t('carbonOffsetConfiguration:modal_header_update')}
        </ModalHeader>
        <ModalBody>
          <CarbonOffsetsForm onCancel={close} setIsOpen={setIsOpen} />
        </ModalBody>
      </Modal>
    </>
  )
}

export default CarbonOffsets
