import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useBusinessType from '@/shared/hooks/useBusinessType'
import { pageConfigurationActions } from '@/store/PageConfiguration/PageConfigurationSagas'
import {
  selectPageConfigurationData,
  selectPageConfigurationIsInitialized,
  selectPageConfigurationIsLoading,
} from '@/store/PageConfiguration/PageConfigurationSelectors'
import { mapCommonSubscriptionData } from './Common/mapCommonSubscriptionData'
import { mapTelecomSubscriptionData } from './Telecom/mapTelecomSubscriptionData'
import { mapEnergySubscriptionData } from './Energy/mapEnergySubscriptionData'
import { initializeContractData } from '../SubscriptionFormSlice'
import EnergyFieldsValidation from './Energy/EnergyFieldsValidation'
import TelecomFieldsValidation from './Telecom/TelecomFieldsValidation'
import {
  selectBillingSubscriptionData,
  selectCustomerSubscriptionData,
  selectElectricityMeterSubscriptionData,
  selectEnergyCharacteristicsSubscriptionData,
  selectEnergyConsumptionData,
  selectEnergyContractSubscriptionData,
  selectGasMeterSubscriptionData,
  selectInsuranceCharacteristicsSubscriptionData,
  selectInsuranceContractSubscriptionData,
  selectInvoiceAddressSubscriptionData,
  selectMainAddressSubscriptionData,
  selectPropaneSubscriptionData,
  selectTelecomCharacteristicsSubscriptionData,
  selectTelecomContractSubscriptionData,
} from '../SubscriptionFormSelectors'
import {
  selectCharacteristicsModuleConfigurationData,
  selectCommonFormConfigurationData,
  selectElectricityMeterModuleConfigurationData,
  selectOfferConfiguration,
  selectOfferModuleConfigurationData,
  selectPropaneModuleConfigurationData,
  selectProviderConfiguration,
} from '../SubscriptionFormConfigurationSelectors'
import { initialFormConfigurationStateUpdate, updateCommonConfiguration } from '../SubscriptionFormConfigurationSlice'
import useFormStatus from '../useFormStatus'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import useHttp from '@/shared/hooks/useHttp'
import subscriptionStatus from '@/containers/SubscriptionFormPage/subscriptionStatus'
import mapAdditionalSalesData from './Common/mapAdditionalSalesData'
import InsuranceFieldsValidation from './Insurance/InsuranceFieldsValidation'
import { mapInsuranceSubscriptionData } from './Insurance/mapInsuranceSubscriptionData'

const useSubscriptionForm = ({ applicationType, initUrl, id, saveRoute }) => {
  const allowedCountries = ['fr', 'en', 'be']
  const [validations, setValidations] = useState(false)
  const [mandatoryFields, setMandatoryFields] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { authTokenSession: token, lang } = useSelector(selectAuthData)
  const { getConfig } = useHttp()
  const { realStatus } = useSelector(selectCommonFormConfigurationData)
  const providerConfig = useSelector(selectProviderConfiguration)
  const { formIsLoaded } = useFormStatus()
  const offerConfig = useSelector(selectOfferConfiguration)
  const elements = useSelector(selectPageConfigurationData)
  const isInitialized = useSelector(selectPageConfigurationIsInitialized)
  const pageConfigurationIsLoading = useSelector(selectPageConfigurationIsLoading)
  const customerData = useSelector(selectCustomerSubscriptionData)
  const billingData = useSelector(selectBillingSubscriptionData)
  const electricityMeterData = useSelector(selectElectricityMeterSubscriptionData)
  const gasMeterData = useSelector(selectGasMeterSubscriptionData)
  const mainAddressData = useSelector(selectMainAddressSubscriptionData)
  const invoicingAddressData = useSelector(selectInvoiceAddressSubscriptionData)
  const telecomContractData = useSelector(selectTelecomContractSubscriptionData)
  const energyContractData = useSelector(selectEnergyContractSubscriptionData)
  const insuranceContractData = useSelector(selectInsuranceContractSubscriptionData)
  const insuranceCharacteristicsData = useSelector(selectInsuranceCharacteristicsSubscriptionData)
  const telecomCharacteristicsData = useSelector(selectTelecomCharacteristicsSubscriptionData)
  const energyCharacteristicsData = useSelector(selectEnergyCharacteristicsSubscriptionData)
  const energyConsumptionData = useSelector(selectEnergyConsumptionData)
  const propaneData = useSelector(selectPropaneSubscriptionData)
  const { cityEligibility } = useSelector(selectCharacteristicsModuleConfigurationData)
  const { pdlEligibilityData } = useSelector(selectElectricityMeterModuleConfigurationData)
  const { providers, energyOrigin } = useSelector(selectOfferModuleConfigurationData)
  const { propaneVisibility, quoteReasonsList, energySuppliers } = useSelector(selectPropaneModuleConfigurationData)
  const { getApplicationTypeFolder } = useBusinessType({
    loaderRoutesEnergy: {},
    loaderRoutesInsurance: {},
    loaderRoutes: {},
    modulesStatuses: {},
    modulesStatusesEnergy: {},
    modulesStatusesInsurance: {},
  })
  const importValidationModules = async country => {
    const businessTypeFolder = getApplicationTypeFolder()
    const defaultValidations = await import(`./${businessTypeFolder}/fieldsValidation/default/validation.js`)
    let rules = defaultValidations.default
    try {
      const countryValidation = await import(`./${businessTypeFolder}/fieldsValidation/${country}/validation.js`)
      rules = countryValidation?.default
        ? {
            ...defaultValidations.default,
            ...countryValidation.default,
          }
        : defaultValidations
      setValidations(rules)
    } catch {
      setValidations(rules)
    }
  }

  const statusUpdate = result => {
    let productSold = null
    if (applicationType === 'telecom') productSold = result.product_telecom
    if (applicationType === 'energie') productSold = result.product_energy
    if (applicationType === 'assurance') productSold = result.product_insurance

    if (productSold && productSold.subscriber_status) {
      dispatch(
        updateCommonConfiguration({
          lastSentDate: result.lastSentDate ?? '',
          realStatus: productSold.subscriber_status ?? '',
          isEditable: result.isEditable ?? true,
          canSendSubscription: result.canSendSubscription ?? false,
          subscriptionStatus: result.displayLightStatus ?? '',
          statusErrors: result.statusErrors ?? [],
        }),
      )
    }
  }

  const importMandatoryModules = async country => {
    const businessTypeFolder = getApplicationTypeFolder()
    const defaultMandatory = await import(`./${businessTypeFolder}/fieldsMandatoriness/default/mandatory.js`)
    let fields = defaultMandatory.default
    try {
      const countryMandatoriness = await import(`./${businessTypeFolder}/fieldsMandatoriness/${country}/mandatory.js`)
      fields = countryMandatoriness?.default
        ? {
            ...defaultMandatory.default,
            ...countryMandatoriness.default,
          }
        : defaultMandatory
      setMandatoryFields(fields)
    } catch {
      setMandatoryFields(fields)
    }
  }

  const getValidationByType = (applicationType, field) => {
    if (applicationType === 'telecom') {
      return TelecomFieldsValidation(
        field,
        validations,
        mandatoryFields,
        offerConfig,
        customerData,
        billingData,
        mainAddressData,
        telecomContractData,
        telecomCharacteristicsData,
      )
    }
    if (applicationType === 'assurance') {
      return InsuranceFieldsValidation(
        field,
        validations,
        mandatoryFields,
        offerConfig,
        customerData,
        billingData,
        mainAddressData,
        insuranceContractData,
        insuranceCharacteristicsData,
      )
    }
    if (applicationType === 'energie') {
      return EnergyFieldsValidation(
        field,
        validations,
        mandatoryFields,
        offerConfig,
        providerConfig,
        customerData,
        billingData,
        electricityMeterData,
        gasMeterData,
        mainAddressData,
        invoicingAddressData,
        energyContractData,
        energyCharacteristicsData,
        cityEligibility,
        pdlEligibilityData,
        providers,
        energyOrigin,
        energyConsumptionData,
        propaneData,
        propaneVisibility,
        quoteReasonsList,
        energySuppliers,
      )
    }
  }

  const validateMissingFields = () => {
    const missingData = {}
    const mandatoryFieldsState = {}
    let isValid = true

    Object.entries(mandatoryFields).map(([field]) => {
      const { isValid: isValidField, isMandatory } = getValidationByType(applicationType, field)
      if (isMandatory === true && isValidField === false) {
        isValid = false
        missingData[field] = [t('subscriptionForm:validation_errors.' + field)]
      }
      mandatoryFieldsState[field] = isMandatory
    })

    return { isValid, missingData, mandatoryFieldsState }
  }

  useEffect(() => {
    dispatch({ type: pageConfigurationActions.INITIALIZE, initUrl: initUrl.replace('*', id) + '?isSpa=1' })
  }, [])

  useEffect(() => {
    if (!isInitialized || pageConfigurationIsLoading || !elements) return
    const contractData = {
      common: mapCommonSubscriptionData(elements),
      telecom: mapTelecomSubscriptionData(elements),
      energy: mapEnergySubscriptionData(elements),
      insurance: mapInsuranceSubscriptionData(elements),
      additionalSales: mapAdditionalSalesData(elements),
    }
    dispatch(initializeContractData(contractData))

    if (allowedCountries.includes(elements.country)) {
      importValidationModules(elements.country)
      importMandatoryModules(elements.country)
    }
  }, [elements])

  useEffect(() => {
    if (!elements || !isInitialized || pageConfigurationIsLoading) return
    dispatch(
      initialFormConfigurationStateUpdate({
        saveUrl: saveRoute,
        token,
        id,
        isEditable: elements.is_editable,
        lastSentDate: elements.lastSentDate,
        realStatus: elements.realStatus,
        canSendSubscription: elements.canSendSubscription,
        subscriptionStatus: elements.displayLightStatus ?? '',
        isRetracted: elements.is_retracted,
        statusErrors: elements.status_errors ?? [],
        createdByUserId: elements.created_by_user_id?.toString() ?? '',
      }),
    )
  }, [elements, isInitialized, pageConfigurationIsLoading])

  useEffect(() => {
    let interval = null
    if (realStatus !== '' && realStatus !== '0' && realStatus !== 'A0100' && realStatus !== 'S0100') {
      interval = setInterval(() => {
        subscriptionStatus(elements.subscriber_data_url + '?lang=' + lang, statusUpdate, getConfig('GET'))
      }, 15000)
    }
    if (interval) {
      return () => clearInterval(interval)
    }
  }, [realStatus])

  useEffect(() => {
    if (!formIsLoaded || !validations) {
      return
    }

    const { isValid, missingData, mandatoryFieldsState } = validateMissingFields()
    dispatch(
      updateCommonConfiguration({
        formCanBeSubmitted: isValid,
        mandatoryFields: mandatoryFieldsState,
        missingFieldsDetails: missingData,
      }),
    )
  }, [
    validations,
    mandatoryFields,
    formIsLoaded,
    offerConfig,
    providerConfig,
    customerData,
    billingData,
    electricityMeterData,
    gasMeterData,
    propaneData,
    mainAddressData,
    telecomContractData,
    energyContractData,
    telecomCharacteristicsData,
    energyCharacteristicsData,
    invoicingAddressData,
    cityEligibility,
    pdlEligibilityData,
    providers,
    energyOrigin,
    energyConsumptionData,
    propaneVisibility,
    quoteReasonsList,
    energySuppliers,
  ])

  return { t }
}

export default useSubscriptionForm
