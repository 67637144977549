import inRangeCAR from '@/containers/SubscriptionFormPage/ModulesPanel/Modules/ConsumptionModule/Utilities/inRangeCAR'
import inMinHpHcRangeCAR from '@/containers/SubscriptionFormPage/ModulesPanel/Modules/ConsumptionModule/Utilities/inMinHpHcRangeCAR'
import inRangeCARMes from '@/containers/SubscriptionFormPage/ModulesPanel/Modules/ConsumptionModule/Utilities/inRangeCARMes'

const EnergyFieldsValidation = (
  field,
  validations,
  mandatoryFields,
  offerConfig,
  providerConfig,
  customerData,
  billingData,
  electricityMeterData,
  gasMeterData,
  mainAddressData,
  invoicingAddressData,
  energyContractData,
  energyCharacteristicsData,
  cityEligibility,
  pdlEligibilityData,
  providers,
  energyOrigin,
  energyConsumptionData,
  propaneData,
  propaneVisibility,
  quoteReasonsList,
  energySuppliers,
) => {
  const gasEligibility = cityEligibility?.gas ?? 0
  const elecEligibility = cityEligibility?.elec ?? 0
  const availableGasProviders = cityEligibility?.availableGasProvider ?? []
  const availableElectricityProviders = cityEligibility?.availableElectricityProvider ?? []
  const eldElectricity = cityEligibility?.elec_eld_details?.name ?? ''
  const eldGas = cityEligibility?.gas_eld_details?.name ?? ''
  const energyType = energyCharacteristicsData.energyType ?? ''
  const prestationType = energyCharacteristicsData.prestationType ?? ''
  const contractType = energyCharacteristicsData.contractType ?? ''
  const commitmentPeriod = offerConfig.commitment_period ?? 0
  const city = mainAddressData.city ?? ''
  const zipcode = mainAddressData.zipcode ?? ''
  const inseeCode = mainAddressData.inseeCode ?? ''
  const providerWorkflow = energyContractData.providerWorkflow ?? 0
  const birthdate = new Date(customerData.birthdate)
  const additionalInformation = null
  const carElectricityMinimum = providerConfig.minimumCarElectricity
  const carGasMainMinimum = providerConfig.minimunCarGas
  const carGasSecondaryMinimum = providerConfig.minimunCarGasSecondary
  const saleIdMandatory = providerConfig.saleIdMandatory
  const enedisYearlyCAR = pdlEligibilityData?.elec?.consumption?.yearlyExtrapolation ?? 0
  switch (field) {
    case 'zohoId':
      return {
        isValid: validations.zohoId(customerData.zohoId ?? ''),
        isMandatory: mandatoryFields.zohoId(),
      }
    case 'energyType':
      return {
        isValid: validations.energyType(
          energyType,
          providerWorkflow,
          availableGasProviders,
          eldGas,
          gasEligibility,
          availableElectricityProviders,
          eldElectricity,
          elecEligibility,
        ),
        isMandatory: mandatoryFields.energyType(),
      }
    case 'prestationType':
      return {
        isValid: validations.prestationType(prestationType),
        isMandatory: mandatoryFields.prestationType(),
      }
    case 'contractType':
      return {
        isValid: validations.contractType(contractType),
        isMandatory: mandatoryFields.contractType(),
      }
    case 'offerId':
      return {
        isValid: validations.offerId(energyContractData.offerId ?? 0),
        isMandatory: mandatoryFields.offerId(),
      }
    case 'providerId':
      return {
        isValid: validations.providerId(energyContractData.providerId ?? 0),
        isMandatory: mandatoryFields.providerId(),
      }
    case 'cityId':
      return {
        isValid: validations.cityId(city, zipcode, inseeCode),
        isMandatory: mandatoryFields.cityId(),
      }
    case 'debordement':
      return {
        isValid: validations.debordement(energyContractData.isDebordement),
        isMandatory: mandatoryFields.debordement(providerConfig),
      }
    case 'disclaimerCommitmentProOui':
      return {
        isValid: validations.disclaimerCommitmentProOui(energyCharacteristicsData.disclaimerCommitmentPro ?? ''),
        isMandatory: mandatoryFields.disclaimerCommitmentProOui(),
      }
    case 'disclaimerCommitmentPro':
      return {
        isValid: validations.disclaimerCommitmentPro(energyCharacteristicsData.disclaimerCommitmentPro ?? ''),
        isMandatory: mandatoryFields.disclaimerCommitmentPro(providerConfig, contractType, energyType, prestationType),
      }
    case 'commitmentEndDate':
      return {
        isValid: validations.commitmentEndDate(energyContractData.commitmentEndDate ?? null),
        isMandatory: mandatoryFields.commitmentEndDate(contractType, commitmentPeriod),
      }
    case 'realPayment':
      return {
        isValid: validations.realPayment(billingData.realPayment ?? ''),
        isMandatory: mandatoryFields.realPayment(offerConfig),
      }
    case 'pdlNo':
      return {
        isValid: validations.pdlNo(electricityMeterData.pdlNo ?? '', energyType),
        isMandatory: mandatoryFields.pdlNo(offerConfig),
      }
    case 'tariffOption':
      return {
        isValid: validations.tariffOption(electricityMeterData.tariffOption, energyType),
        isMandatory: mandatoryFields.tariffOption(offerConfig),
      }
    case 'pceNo':
      return {
        isValid: validations.pceNo(gasMeterData.pceNo, energyType),
        isMandatory: mandatoryFields.pceNo(offerConfig),
      }
    case 'gasIndex':
      return {
        isValid: validations.gasIndex(gasMeterData.index, energyType),
        isMandatory: mandatoryFields.gasIndex(),
      }
    case 'gasReadingDate':
      return {
        isValid: validations.gasReadingDate(gasMeterData.readingDate, energyType),
        isMandatory: mandatoryFields.gasReadingDate(),
      }
    case 'gasEffectiveDate':
      return {
        isValid: validations.gasEffectiveDate(gasMeterData.effectiveDate, energyType),
        isMandatory: mandatoryFields.gasEffectiveDate(providerConfig, prestationType),
      }
    case 'elecEffectiveDate':
      return {
        isValid: validations.elecEffectiveDate(electricityMeterData.effectiveDate, energyType),
        isMandatory: mandatoryFields.elecEffectiveDate(
          providerConfig,
          prestationType,
          pdlEligibilityData?.powerMeterDetails?.basicInformation?.linky2 ?? false,
        ),
      }
    case 'meterPower':
      return {
        isValid: validations.meterPower(electricityMeterData.meterPower, energyType),
        isMandatory: mandatoryFields.meterPower(offerConfig),
      }
    case 'elecIndex':
      return {
        isValid: validations.elecIndex(
          electricityMeterData.tariffOption,
          electricityMeterData.indexBaseHP,
          electricityMeterData.indexHC,
          energyType,
        ),
        isMandatory: mandatoryFields.elecIndex(electricityMeterData.meterOpening, providerConfig),
      }
    case 'elecReadingDate':
      return {
        isValid: validations.elecReadingDate(electricityMeterData.readingDate, energyType),
        isMandatory: mandatoryFields.elecReadingDate(electricityMeterData.meterOpening, providerConfig),
      }
    case 'dataCollectionAuth':
      return {
        isValid: validations.dataCollectionAuth(customerData.dataCollectionAuth ?? false),
        isMandatory: mandatoryFields.dataCollectionAuth(),
      }
    case 'civility':
      return {
        isValid: validations.civility(customerData.civility ?? '', customerData.dataCollectionAuth ?? false),
        isMandatory: mandatoryFields.civility(),
      }
    case 'civility2':
      return {
        isValid: validations.civility2(
          customerData.civility2 ?? '',
          customerData.secondName,
          customerData.dataCollectionAuth ?? false,
        ),
        isMandatory: mandatoryFields.civility2(customerData.secondName ?? false),
      }
    case 'name':
      return {
        isValid: validations.name(customerData.dataCollectionAuth ?? false, customerData.name ?? ''),
        isMandatory: mandatoryFields.name(),
      }
    case 'name2':
      return {
        isValid: validations.name2(customerData.secondName, customerData.dataCollectionAuth, customerData.name2 ?? ''),
        isMandatory: mandatoryFields.name2(customerData.secondName ?? false),
      }
    case 'surname':
      return {
        isValid: validations.surname(customerData.surname ?? ''),
        isMandatory: mandatoryFields.surname(),
      }
    case 'surname2':
      return {
        isValid: validations.surname2(
          customerData.secondName,
          customerData.dataCollectionAuth,
          customerData.surname2 ?? '',
        ),
        isMandatory: mandatoryFields.surname2(customerData.secondName ?? false),
      }
    case 'phone1':
      return {
        isValid: validations.phone1(customerData.phone1 ?? ''),
        isMandatory: mandatoryFields.phone1(),
      }
    case 'phone2':
      return {
        isValid: validations.phone2(customerData.phone2 ?? '', customerData.dataCollectionAuth ?? false),
        isMandatory: mandatoryFields.phone2(),
      }
    case 'alreadyClient':
      return {
        isValid: validations.alreadyClient(propaneData.alreadyClient ?? null),
        isMandatory: mandatoryFields.alreadyClient(
          contractType,
          energyType,
          providers,
          mainAddressData.housingType,
          mainAddressData.tenantType,
          propaneData,
          propaneVisibility,
          quoteReasonsList,
          energySuppliers,
        ),
      }
    case 'quoteReason':
      return {
        isValid: validations.quoteReason(propaneData.quoteReason ?? null),
        isMandatory: mandatoryFields.quoteReason(energyType),
      }
    case 'energySupplierPropane':
      return {
        isValid: validations.energySupplierPropane(propaneData.energySupplier ?? null),
        isMandatory: mandatoryFields.energySupplierPropane(
          contractType,
          energyType,
          providers,
          mainAddressData.housingType,
          mainAddressData.tenantType,
          propaneData,
          propaneVisibility,
          quoteReasonsList,
          energySuppliers,
        ),
      }
    case 'contactTime':
      return {
        isValid: validations.contactTime(propaneData.contactTime ?? null),
        isMandatory: mandatoryFields.contactTime(propaneData.quoteReason, propaneVisibility),
      }
    case 'tankType':
      return {
        isValid: validations.tankType(propaneData.tankType ?? null),
        isMandatory: mandatoryFields.tankType(propaneData.quoteReason, propaneVisibility),
      }
    case 'tankAccess':
      return {
        isValid: validations.tankAccess(propaneData.tankAccess ?? null),
        isMandatory: mandatoryFields.tankAccess(propaneData.quoteReason, propaneVisibility),
      }
    case 'car':
      return {
        isValid: validations.car(propaneData.car ?? null),
        isMandatory: mandatoryFields.car(energyType),
      }
    case 'presence':
      return {
        isValid: validations.presence(propaneData.presence ?? null),
        isMandatory: mandatoryFields.presence(propaneData.quoteReason, propaneVisibility),
      }
    case 'bottleNumber':
      return {
        isValid: validations.bottleNumber(propaneData.bottleNumber ?? null),
        isMandatory: mandatoryFields.bottleNumber(propaneData.quoteReason, propaneVisibility),
      }
    case 'bottleSize':
      return {
        isValid: validations.bottleSize(propaneData.bottleSize ?? null),
        isMandatory: mandatoryFields.bottleSize(propaneData.quoteReason, propaneVisibility),
      }
    case 'currentUse':
      return {
        isValid: validations.currentUse(propaneData.currentUse ?? null),
        isMandatory: mandatoryFields.currentUse(propaneData.quoteReason, propaneVisibility),
      }
    case 'heating':
      return {
        isValid: validations.heating(energyConsumptionData.heating),
        isMandatory: mandatoryFields.heating(billingData.realPayment, providerConfig, energyType),
      }
    case 'cooking':
      return {
        isValid: validations.cooking(energyConsumptionData.cooking),
        isMandatory: mandatoryFields.cooking(billingData.realPayment, providerConfig, energyType),
      }
    case 'hotwater':
      return {
        isValid: validations.hotwater(energyConsumptionData.hotwater),
        isMandatory: mandatoryFields.hotwater(billingData.realPayment, providerConfig, energyType),
      }
    case 'surface':
      return {
        isValid: validations.surface(energyConsumptionData.surface),
        isMandatory: mandatoryFields.surface(billingData.realPayment, providerConfig, energyType),
      }
    case 'carBase':
      if (energyConsumptionData.carBase < carElectricityMinimum) {
        return {
          isValid: false,
          isMandatory: mandatoryFields.carBase(
            electricityMeterData.tariffOption,
            providerConfig,
            energyType,
            billingData.realPayment,
          ),
        }
      }
      if (
        inRangeCARMes(
          parseInt(enedisYearlyCAR),
          energyConsumptionData.carBase,
          providerConfig.carLimitPercentMesStatus,
          providerConfig.carLimitPercentMes,
          prestationType,
        ) === false
      ) {
        return {
          isValid: false,
          isMandatory: mandatoryFields.carBase(
            electricityMeterData.tariffOption,
            providerConfig,
            energyType,
            billingData.realPayment,
          ),
        }
      }

      return {
        isValid: inRangeCAR(
          energyConsumptionData.carBaseOriginal,
          energyConsumptionData.carBase,
          providerConfig.useCarRangeRule,
        ),
        isMandatory: mandatoryFields.carBase(
          electricityMeterData.tariffOption,
          providerConfig,
          energyType,
          billingData.realPayment,
        ),
      }
    case 'carHp':
      if (
        inMinHpHcRangeCAR(energyConsumptionData.carHp, energyConsumptionData.carHc, carElectricityMinimum) === false
      ) {
        return {
          isValid: false,
          isMandatory: mandatoryFields.carHp(
            electricityMeterData.tariffOption,
            providerConfig,
            energyType,
            billingData.realPayment,
          ),
        }
      }

      if (
        inRangeCARMes(
          parseInt(enedisYearlyCAR * 0.7),
          energyConsumptionData.carHp,
          providerConfig.carLimitPercentMesStatus,
          providerConfig.carLimitPercentMes,
          prestationType,
        ) === false
      ) {
        return {
          isValid: false,
          isMandatory: mandatoryFields.carHp(
            electricityMeterData.tariffOption,
            providerConfig,
            energyType,
            billingData.realPayment,
          ),
        }
      }

      return {
        isValid: inRangeCAR(
          energyConsumptionData.carHpOriginal,
          energyConsumptionData.carHp,
          providerConfig.useCarRangeRule,
        ),
        isMandatory: mandatoryFields.carHp(
          electricityMeterData.tariffOption,
          providerConfig,
          energyType,
          billingData.realPayment,
        ),
      }
    case 'carHc':
      if (
        inMinHpHcRangeCAR(energyConsumptionData.carHp, energyConsumptionData.carHc, carElectricityMinimum) === false
      ) {
        return {
          isValid: false,
          isMandatory: mandatoryFields.carHc(
            electricityMeterData.tariffOption,
            providerConfig,
            energyType,
            billingData.realPayment,
          ),
        }
      }

      if (
        inRangeCARMes(
          parseInt(enedisYearlyCAR * 0.3),
          energyConsumptionData.carHc,
          providerConfig.carLimitPercentMesStatus,
          providerConfig.carLimitPercentMes,
          prestationType,
        ) === false
      ) {
        return {
          isValid: false,
          isMandatory: mandatoryFields.carHc(
            electricityMeterData.tariffOption,
            providerConfig,
            energyType,
            billingData.realPayment,
          ),
        }
      }

      return {
        isValid: inRangeCAR(
          energyConsumptionData.carHcOriginal,
          energyConsumptionData.carHc,
          providerConfig.useCarRangeRule,
        ),
        isMandatory: mandatoryFields.carHc(
          electricityMeterData.tariffOption,
          providerConfig,
          energyType,
          billingData.realPayment,
        ),
      }
    case 'carGas':
      if (energyConsumptionData.heating === 'gas' || energyConsumptionData.hotwater === 'gas') {
        return {
          isValid:
            energyConsumptionData.carGas >= carGasMainMinimum &&
            inRangeCAR(energyConsumptionData.carGasOriginal, energyConsumptionData.carGas),
          isMandatory: mandatoryFields.carGas(providerConfig, energyType, billingData.realPayment),
        }
      }
      return {
        isValid:
          energyConsumptionData.carGas >= carGasSecondaryMinimum &&
          inRangeCAR(energyConsumptionData.carGasOriginal, energyConsumptionData.carGas),
        isMandatory: mandatoryFields.carGas(providerConfig, energyType, billingData.realPayment),
      }
    case 'modificationReason':
      return {
        isValid: energyConsumptionData.modificationReason?.length > 0,
        isMandatory: mandatoryFields.modificationReason(
          energyConsumptionData,
          electricityMeterData.tariffOption,
          providerConfig,
          energyType,
          billingData.realPayment,
        ),
      }
    case 'monthlyAmount':
      return {
        isValid: validations.monthlyAmount(energyConsumptionData.monthlyAmount ?? 0),
        isMandatory: mandatoryFields.monthlyAmount(billingData.realPayment, providerConfig, offerConfig, energyType),
      }
    case 'billingFrequency':
      return {
        isValid: validations.billingFrequency(billingData.frequency ?? ''),
        isMandatory: mandatoryFields.billingFrequency(providerConfig.isIntegrated, energyType),
      }
    case 'invoiceSendingMode':
      return {
        isValid: validations.invoiceSendingMode(billingData.sendingMode ?? ''),
        isMandatory: mandatoryFields.invoiceSendingMode(providerConfig.isIntegrated, energyType),
      }
    case 'paymentMode':
      return {
        isValid: validations.paymentMode(billingData.paymentMode ?? ''),
        isMandatory: mandatoryFields.paymentMode(providerConfig.isIntegrated, energyType),
      }
    case 'paymentDate':
      return {
        isValid: validations.paymentDate(billingData.paymentDate ?? ''),
        isMandatory: mandatoryFields.paymentDate(
          billingData.paymentMode,
          offerConfig,
          energyType,
          providerConfig.isIntegrated,
        ),
      }
    case 'iban':
      return {
        isValid: validations.iban(billingData.iban ?? ''),
        isMandatory: mandatoryFields.iban(
          billingData.paymentMode,
          offerConfig,
          energyType,
          providerConfig.isIntegrated,
        ),
      }
    case 'bic':
      return {
        isValid: validations.bic(billingData.bic ?? ''),
        isMandatory: mandatoryFields.bic(billingData.paymentMode, offerConfig, providerConfig, energyType),
      }
    case 'accountHolderFirstname':
      return {
        isValid: validations.accountHolderFirstname(billingData.accountHolderFirstname ?? ''),
        isMandatory: mandatoryFields.accountHolderFirstname(
          billingData.differentAccountHolder,
          energyType,
          providerConfig.isIntegrated,
        ),
      }
    case 'accountHolderLastname':
      return {
        isValid: validations.accountHolderLastname(billingData.accountHolderLastname ?? ''),
        isMandatory: mandatoryFields.accountHolderLastname(
          billingData.differentAccountHolder,
          energyType,
          providerConfig.isIntegrated,
        ),
      }
    case 'residenceType':
      return {
        isValid: validations.residenceType(mainAddressData.residenceType?.length ?? ''),
        isMandatory: mandatoryFields.residenceType(mainAddressData.city ?? ''),
      }
    case 'housingType':
      return {
        isValid: validations.housingType(mainAddressData.housingType?.length ?? ''),
        isMandatory: mandatoryFields.housingType(mainAddressData.city ?? ''),
      }
    case 'tenantType':
      return {
        isValid: validations.tenantType(mainAddressData.tenantType?.length ?? ''),
        isMandatory: mandatoryFields.tenantType(mainAddressData.city ?? ''),
      }
    case 'address':
      return {
        isValid: validations.address(mainAddressData.address?.length ?? ''),
        isMandatory: mandatoryFields.address(mainAddressData.city ?? ''),
      }
    case 'country':
      return {
        isValid: validations.country(invoicingAddressData.country ?? ''),
        isMandatory: mandatoryFields.country(invoicingAddressData.showCheckbox),
      }
    case 'invoiceStreetName':
      return {
        isValid: validations.invoiceStreetName(invoicingAddressData.address ?? ''),
        isMandatory: mandatoryFields.invoiceStreetName(invoicingAddressData.showCheckbox),
      }
    case 'city':
      return {
        isValid: validations.city(invoicingAddressData.city ?? ''),
        isMandatory: mandatoryFields.city(invoicingAddressData.showCheckbox),
      }
    case 'invoiceZipcode':
      return {
        isValid: validations.invoiceZipcode(invoicingAddressData.zipcode ?? ''),
        isMandatory: mandatoryFields.invoiceZipcode(invoicingAddressData.showCheckbox),
      }
    case 'zipcode':
      return {
        isValid: validations.zipcode(zipcode),
        isMandatory: mandatoryFields.zipcode(invoicingAddressData.showCheckbox),
      }
    case 'activity':
      return {
        isValid: validations.activity(customerData.pro_activity ?? ''),
        isMandatory: mandatoryFields.activity(contractType),
      }
    case 'socialReason':
      return {
        isValid: validations.socialReason(customerData.pro_social_reason ?? ''),
        isMandatory: mandatoryFields.socialReason(contractType),
      }
    case 'birthdate':
      return {
        isValid: validations.birthdate(birthdate, customerData.dataCollectionAuth ?? false),
        isMandatory: mandatoryFields.birthdate(providerConfig, energyType),
      }
    case 'email':
      return {
        isValid: validations.email(customerData.email ?? '', customerData.dataCollectionAuth ?? false),
        isMandatory: mandatoryFields.email(),
      }
    case 'siret':
      return {
        isValid: validations.siret(customerData.pro_siret ?? ''),
        isMandatory: mandatoryFields.siret(contractType),
      }
    case 'energyOriginGas':
      return {
        isValid: validations.energyOriginGas(gasMeterData.energyOrigin ?? ''),
        isMandatory: mandatoryFields.energyOriginGas(energyOrigin, energyType),
      }
    case 'energyOriginElec':
      return {
        isValid: validations.energyOriginElec(electricityMeterData.energyOrigin ?? ''),
        isMandatory: mandatoryFields.energyOriginElec(energyOrigin, energyType),
      }
    case 'household':
      return {
        isValid: validations.household(mainAddressData.household ?? ''),
        isMandatory: mandatoryFields.household(),
      }
    case 'occupancy':
      return {
        isValid: validations.occupancy(mainAddressData.occupancy ?? ''),
        isMandatory: mandatoryFields.occupancy(),
      }
    case 'movingStartDate':
      return {
        isValid: validations.movingStartDate(mainAddressData.movingStartDate ?? ''),
        isMandatory: mandatoryFields.movingStartDate(),
      }
    case 'serviceNeeds':
      return {
        isValid: validations.serviceNeeds(additionalInformation?.serviceNeeds),
        isMandatory: mandatoryFields.serviceNeeds(),
      }
    case 'medicalNeeds':
      return {
        isValid: validations.medicalNeeds(additionalInformation?.medicalNeeds),
        isMandatory: mandatoryFields.medicalNeeds(),
      }
    case 'communicationPreference':
      return {
        isValid: validations.communicationPreference(additionalInformation?.communicationPreference),
        isMandatory: mandatoryFields.communicationPreference(),
      }
    case 'energySupplierElec':
      return {
        isValid: validations.energySupplierElec(electricityMeterData.energySupplier, energyType),
        isMandatory: mandatoryFields.energySupplierElec(),
      }
    case 'energySupplierGas':
      return {
        isValid: validations.energySupplierGas(gasMeterData.energySupplier ?? '', energyType),
        isMandatory: mandatoryFields.energySupplierGas(),
      }
    case 'elecMeterType':
      return {
        isValid: validations.elecMeterType(electricityMeterData.meterType ?? '', energyType),
        isMandatory: mandatoryFields.elecMeterType(),
      }
    case 'gasMeterType':
      return {
        isValid: validations.gasMeterType(gasMeterData.meterType, energyType),
        isMandatory: mandatoryFields.gasMeterType(),
      }
    case 'billAmountElec':
      return {
        isValid: validations.billAmountElec(electricityMeterData.billAmount ?? '', energyType),
        isMandatory: mandatoryFields.billAmountElec(),
      }
    case 'billAmountGas':
      return {
        isValid: validations.billAmountGas(gasMeterData.billAmount ?? '', energyType),
        isMandatory: mandatoryFields.billAmountGas(),
      }
    case 'comments':
      return {
        isValid: validations.comments(customerData.comments),
        isMandatory: mandatoryFields.comments(offerConfig),
      }
    case 'providersBlacklist':
      return {
        isValid: validations.providersBlacklist(billingData.providersBlacklist ?? []),
        isMandatory: mandatoryFields.providersBlacklist(providerConfig),
      }
    case 'saleIdMandatory':
      return {
        isValid: validations.saleIdMandatory(saleIdMandatory, energyContractData.providerExternalId ?? ''),
        isMandatory: mandatoryFields.saleIdMandatory(providerConfig),
      }
    default:
      console.error('No validation for field : ' + field)
      return false
  }
}

export default EnergyFieldsValidation
