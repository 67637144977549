import PropTypes from 'prop-types'
import { StyledHeadCell, StyledTable } from 'baseui/table-grid'
import { useStyletron } from 'baseui'
import { useTranslation } from 'react-i18next'
import TableRow from './TableRow'

const Table = ({ data, updateMethod, deleteMethod }) => {
  const [css] = useStyletron()
  const { t } = useTranslation()

  if (data.length === 0) {
    return ''
  }

  return (
    <StyledTable role='grid' $gridTemplateColumns='15% 50% 15% 20%' style={{ width: '100%' }}>
      <div role='row' className={css({ display: 'contents' })}>
        <StyledHeadCell>{t('subscriptionForm:comments_module.author')}</StyledHeadCell>
        <StyledHeadCell>{t('subscriptionForm:comments_module.comment')} </StyledHeadCell>
        <StyledHeadCell>{t('subscriptionForm:comments_module.created_at')}</StyledHeadCell>
        <StyledHeadCell>{t('subscriptionForm:comments_module.options')}</StyledHeadCell>
      </div>
      {
        // eslint-disable-next-line no-unused-vars
        Object.entries(data).map(([key, item], index) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <TableRow
              key={index}
              id={item.id}
              comment={item.comment}
              createdAt={item.formatted_created_at}
              authorName={item.user_name}
              authorId={item.user_id}
              striped={index % 2 === 0}
              updateMethod={updateMethod}
              deleteMethod={deleteMethod}
            />
          )
        })
      }
    </StyledTable>
  )
}

Table.propTypes = {
  data: PropTypes.array,
  updateMethod: PropTypes.func,
  deleteMethod: PropTypes.func,
}

export default Table
