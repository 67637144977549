export const fieldsConfig = {
  description_mes: {
    type: 'editor',
    group: 'content',
    order: 1,
  },
  description_cdf: {
    type: 'editor',
    group: 'content',
    order: 2,
  },
  signature_help: {
    type: 'editor',
    group: 'content',
    order: 3,
  },
  billing_script: {
    type: 'editor',
    group: 'content',
    order: 4,
  },
  prestations: {
    type: 'checkbox',
    group: 'contract',
    optionsList: {
      mes: 'mes',
      fmes: 'fmes',
      cdf: 'cdf',
    },
    order: 1,
  },
  phone_accepted: {
    type: 'checkbox',
    group: 'contract',
    optionsList: {
      french_fixe: 'french_fixe',
      french_mobile: 'french_mobile',
      foreign_fixe: 'foreign_fixe',
      foreign_mobile: 'foreign_mobile',
    },
    order: 2,
  },
  phone2_accepted: {
    type: 'checkbox',
    group: 'contract',
    optionsList: {
      french_fixe: 'french_fixe',
      french_mobile: 'french_mobile',
      foreign_fixe: 'foreign_fixe',
      foreign_mobile: 'foreign_mobile',
    },
    order: 3,
  },
  invoice_frequency: {
    type: 'checkbox',
    group: 'financial',
    optionsList: {
      annual: 'annual',
      bimonthly: 'bimonthly',
      quarterly: 'quarterly',
      fourmonthly: 'fourmonthly',
    },
    order: 5,
  },
  email_mandatory: {
    type: 'radio',
    group: 'contract',
    optionsList: {
      active: 'active',
      inactive: 'inactive',
    },
    order: 4,
  },
  number_of_payments: {
    type: 'radio',
    group: 'financial',
    optionsList: {
      10: '10',
      11: '11',
      12: '12',
    },
    order: 1,
  },
  amount_calculation: {
    type: 'radio',
    group: 'integration',
    optionsList: {
      mandatory: 'mandatory',
      active: 'active',
      inactive: 'inactive',
    },
    order: 1,
  },
  invoice_sending_mode: {
    type: 'checkbox',
    group: 'financial',
    optionsList: {
      letter: 'letter',
      mail: 'mail',
    },
    order: 2,
  },
  payment_mode: {
    type: 'checkbox',
    group: 'financial',
    optionsList: {
      prlvmt: 'prlvmt',
      chq: 'chq',
      cb: 'cb',
      tip: 'tip',
      mandat: 'mandat',
    },
    order: 4,
  },
  payment_mode_real_status: {
    type: 'radio',
    group: 'financial',
    optionsList: {
      active: 'active',
      inactive: 'inactive',
    },
    order: 6,
  },
  payment_mode_real_options: {
    type: 'checkbox',
    group: 'financial',
    optionsList: {
      prlvmt: 'prlvmt',
      chq: 'chq',
      cb: 'cb',
      tip: 'tip',
      mandat: 'mandat',
    },
    order: 7,
  },
  payment_rythm_real_options: {
    type: 'checkbox',
    group: 'financial',
    optionsList: {
      monthly: 'annual',
      bimonthly: 'bimonthly',
      quarterly: 'quarterly',
      fourmonthly: 'fourmonthly',
    },
    order: 8,
  },
  mes_contract_minimum_delay: {
    type: 'number',
    group: 'contract',
    order: 6,
  },
  cdf_contract_minimum_delay: {
    type: 'number',
    group: 'contract',
    order: 7,
  },
  status_sending_to_provider: {
    type: 'input',
    group: 'integration',
    order: 2,
  },
  payment_mode_mensuality_status: {
    type: 'radio',
    group: 'financial',
    optionsList: {
      active: 'active',
      inactive: 'inactive',
    },
    order: 3,
  },
  pdl_pce_mandatory: {
    type: 'radio',
    group: 'electricity_gas',
    optionsList: {
      active: 'active',
      inactive: 'inactive',
    },
    order: 1,
  },
  opt_tarif_power_mandatory: {
    type: 'radio',
    group: 'electricity_gas',
    optionsList: {
      active: 'active',
      inactive: 'inactive',
    },
    order: 2,
  },
  ending_contract_without_virtual_signature_mes: {
    type: 'radio',
    group: 'electricity_gas',
    optionsList: {
      active: 'active',
      inactive: 'inactive',
    },
    order: 3,
  },
  sending_contract_without_pdl_pce: {
    type: 'radio',
    group: 'electricity_gas',
    optionsList: {
      active: 'active',
      inactive: 'inactive',
    },
    order: 4,
  },
  payment_date_type: {
    type: 'radio',
    group: 'financial',
    optionsList: {
      fixe: 'fixe',
      range: 'range',
    },
    order: 3,
  },
  prlvmt_day_options: {
    type: 'prelevmentDaysOptions',
    group: 'financial',
    order: 4,
  },
  iban_mandatory: {
    type: 'radio',
    group: 'financial',
    optionsList: {
      active: 'active',
      inactive: 'inactive',
    },
    order: 1,
  },
  choose_prlvmt_day: {
    type: 'radio',
    group: 'financial',
    optionsList: {
      active: 'active',
      inactive: 'inactive',
    },
    order: 2,
  },
  birthday_mandatory: {
    type: 'radio',
    group: 'contract',
    optionsList: {
      active: 'active',
      inactive: 'inactive',
    },
    order: 5,
  },
  meter_comm_mandatory: {
    type: 'radio',
    group: 'electricity_gas',
    optionsList: {
      active: 'active',
      inactive: 'inactive',
    },
    order: 5,
  },
  unique_effective_date: {
    type: 'radio',
    group: 'contract',
    optionsList: {
      active: 'active',
      inactive: 'inactive',
    },
    order: 8,
  },
  car_mandatory: {
    type: 'radio',
    group: 'electricity_gas',
    optionsList: {
      active: 'active',
      inactive: 'inactive',
    },
    order: 6,
  },
}
