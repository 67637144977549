import PropTypes from 'prop-types'
import { Pagination, SIZE as paginationSize } from 'baseui/pagination'
import { mergeOverrides } from 'baseui'
import { SDPaginatorOverrides } from './styled-components'

const SDPaginator = ({ overrides, ...rest }) => {
  const newOverrides = mergeOverrides(SDPaginatorOverrides(), overrides)

  return <Pagination {...rest} size={paginationSize.mini} overrides={newOverrides} />
}

SDPaginator.propTypes = {
  overrides: PropTypes.object,
}

export default SDPaginator
