export default function inRangeCARMes(original, modified, mesPerentRuleStatus, ratio, prestationType) {
  if (
    !prestationType ||
    prestationType === 'cdf' ||
    original === 0 ||
    ratio === 0 ||
    mesPerentRuleStatus === false ||
    mesPerentRuleStatus === '0' ||
    mesPerentRuleStatus === 0
  ) {
    return true
  }

  const minAllowed = (original * ratio) / 100

  return parseInt(modified) >= parseInt(minAllowed)
}
