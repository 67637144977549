import { styled } from 'baseui'

export const popoverSharedOverrides = {
    Body: {
      style: ({ $theme }) => ({
        borderTopLeftRadius: $theme.borders.radius200,
        borderTopRightRadius: $theme.borders.radius200,
        borderBottomRightRadius: $theme.borders.radius200,
        borderBottomLeftRadius: $theme.borders.radius200,
        border: `2px solid ${$theme.colors.bg150}`,

        boxShadow: $theme.lighting.shadow600,
      }),
    },
    Inner: {
      style: ({ $theme }) => ({
        paddingTop: '1rem',
        paddingRight: '1rem',
        paddingBottom: '1rem',
        paddingLeft: '1rem',

        backgroundColor: $theme.colors.white,
        borderTopLeftRadius: $theme.borders.radius200,
        borderTopRightRadius: $theme.borders.radius200,
        borderBottomRightRadius: $theme.borders.radius200,
        borderBottomLeftRadius: $theme.borders.radius200,
      }),
    },
  },
  StyledValidationBlock = styled('div', () => ({
    display: 'flex',
    flexDirection: 'column',

    ':not(:last-child)': {
      margin: '0 0 1rem',
    },
  })),
  StyledValidationHeader = styled('div', () => ({
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    marginBottom: '.5rem',
  })),
  StyledValidationTitle = styled('h4', ({ $theme, status }) => ({
    margin: '0px',
    ...$theme.typography.HeadingMedium,
    color: $theme.colors[status],
    fontSize: $theme.typography.ParagraphXSmall,
    lineHeight: $theme.typography.ParagraphXSmall.lineHeight,
  })),
  titleIconOverrides = {
    Svg: {
      style: () => ({
        marginRight: '.5rem',
        ':last-child': {
          marginLeft: 'auto',
        },
      }),
    },
  },
  StyledValidationList = styled('ul', () => ({
    display: 'flex',
    flexDirection: 'column',
    margin: '0px',
    padding: '0px',
    listStyle: 'none',
    ':not(:last-child)': {
      margin: '0 0 1rem',
    },
  })),
  StyledValidationItem = styled('li', ({ $theme }) => ({
    padding: '0 0 0 .5rem',
    ...$theme.typography.ParagraphXSmall,
    ':not(:last-child)': {
      marginBottom: '.5rem',
    },
  })),
  StyledSubmission = styled('div', () => ({
    textAlign: 'right',
    ':first-child': {
      marginRight: '.5rem',
    },
  }))
