import { styled } from 'baseui'

export const Card = styled('div', ({ $theme }) => ({
  margin: '0 0 1rem 0',
  background: $theme.colors.bg100,
  padding: '0.5rem',
  borderRadius: $theme.borders.radius400,
  boxShadow: $theme.lighting.shadow400,
  display: 'flex',
  'align-items': 'center',
  'justify-content': 'space-evenly',
}))

export const ChangeUserContainer = styled('div', () => ({
  maxWidth: '35%',
  display: 'flex',
  flex: '1 1 auto',
}))

export const InfoFieldsContainer = styled('div', ({ $theme }) => ({
  display: 'flex',
  'flex-direction': 'column',
}))

export const StyledTitleWrapped = styled('h3', ({ $theme }) => ({
  flexGrow: 1,
  margin: 0,
  color: $theme.colors.primary,
  ...$theme.typography.HeadingXSmall,
  fontSize: '1.5rem',
  whiteSpace: 'pre-wrap',
  overflowWrap: 'break-word',
}))

export const Columns = styled('div', () => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '@media screen and (min-width: 1200px)': {
    flexDirection: 'row',
  },
}))

export const Column = styled('div', () => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  margin: '0 0 1rem 1rem',
  ':last-child': {
    flexDirection: 'column-reverse',
    justifyContent: 'space-between',
    margin: '0 0 0 1rem',
  },
  '@media screen and (min-width: 1200px)': {
    width: '100%',
    margin: '0 0 0 1rem',
  },
}))
