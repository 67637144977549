import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconCustomZoho = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'Zoho'
  size = size ?? 16
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 32 32' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <path d='M12.1 29.8c-1.3 0-2.2 0-3.1-.1-.7-.1-1.3-.3-1.7-.6-.5-.4-.9-.9-1-1.6-.1-.4-.2-1-.2-1.7 0-.5 0-1 .1-1.5.1-.6.2-1.3.5-2 .4-1.2 1.1-2.4 2-3.6 1.2-1.7 3.1-3.7 5.6-5.8.3-.3.6-.6.9-.8l.4-.4c1.3-1.2 1.7-1.8 1.9-2.1.1-.1.1-.2.2-.3l-6.5.1c-.5 0-1 0-1.4-.1-.7-.3-1.3-.6-1.8-.9-.8-.6-1.4-1.6-1.4-2.6v-.7c.2-1 .8-1.6 1.3-2 .8-.6 1.9-.8 3.2-.8l9.3-.1c.7 0 1.4 0 2 .1.6.1 1.3.3 1.8.8.3.3.6.7.8 1.4.2.6.2 1.2.2 1.6 0 .6 0 1.2-.1 1.9 0 .1 0 .3-.1.4v.4c-.2.7-.5 1.7-1 2.7-.6 1.2-1.9 3.2-4.4 5.5l-.5.5c-.1.1-.2.1-.2.2l-1.1 1.1c-.4.4-.7.8-1.1 1.1-.8.9-1.5 1.6-2.1 2.3-.2.2-.3.4-.4.6l6.3-.1c.8 0 1.4 0 2 .1.6.1 1.1.2 1.7.5.6.3.9.7 1.1 1 .5.5.8 1.2.7 1.9v.6c-.2 1-.7 1.7-1.5 2.1-.8.5-2 .7-3.7.7l-8.4.2h-.3zM20 7.1v1.6c-.1.7-.4 1.3-.6 1.7-.4.7-1.1 1.6-2.3 2.7-.1.1-.3.3-.5.4-.3.2-.6.5-.9.8-2.4 2-4.2 3.9-5.3 5.5-.8 1.1-1.4 2.1-1.8 3.2v.1c-.2.4-.3 1-.4 1.5v.1c-.1.4-.1.8-.1 1.1 0 .5.1.9.2 1.3.1.3.1.4.2.4.2.1.6.2.9.2.7.1 1.7.1 2.9.1l8.4-.2c1.8 0 2.5-.3 2.7-.4l.1-.1c.2-.1.4-.2.4-.6v-.6l-.3-.3c-.2-.3-.3-.4-.4-.5-.3-.1-.6-.2-1.1-.3-.4-.1-1-.1-1.6-.1l-9.4.1.4-1.3c.2-.7.7-1.5 1.5-2.5.6-.8 1.3-1.5 2.2-2.4.3-.4.7-.7 1.1-1.1l1.1-1.1c.1-.1.2-.2.4-.3.1-.1.3-.2.4-.4 2.3-2.1 3.5-3.9 4-4.9.4-.9.7-1.8.8-2.4 0-.1 0-.3.1-.4V7.7c.1-.5.1-1.1.1-1.6 0-.3-.1-.7-.2-1.2l-.2-.4c-.1-.1-.4-.2-.8-.2-.4-.1-1-.1-1.6-.1l-9.3.1c-.9 0-1.6.2-2.1.4-.2.3-.3.5-.4.7v.4c0 .4.2.7.6 1 .2.1.5.3 1 .3H20z' />
    </Icon>
  )
}

IconCustomZoho.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconCustomZoho
