import { FormControl } from 'baseui/form-control'
import { Checkbox } from 'baseui/checkbox'

const MultipleCheckbox = ({ options, selectedValue, errorText, label, onChange }) => {
  if (!options) {
    return
  }

  const items = []
  const selected = selectedValue ? selectedValue.split(';') : []
  const getIndexFromKey = currentKey => {
    for (const [key, value] of Object.entries(selected)) {
      if (value === currentKey) {
        return key
      }
    }
  }
  const updateChecked = (checked, key) => {
    const newValues = selected
    if (checked) {
      newValues.push(key)
    } else {
      if (newValues.includes(key)) {
        const index = getIndexFromKey(key)
        delete newValues[index]
      }
    }
    const filteredArray = newValues.filter(value => value.length > 0)
    onChange(filteredArray.join(';'))
  }
  if (options) {
    for (const [key, value] of Object.entries(options)) {
      let checked = false
      if (selected.includes(key)) {
        checked = true
      }

      items.push(
        <div
          style={{
            margin: '0 8px 0 0',
          }}
        >
          <Checkbox
            key={key}
            checked={checked}
            onChange={event => {
              event.preventDefault()
              updateChecked(event.currentTarget.checked, key)
            }}
          >
            {value}
          </Checkbox>
        </div>,
      )
    }
  }

  return (
    <FormControl label={label} error={errorText}>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'left',
        }}
      >
        {items}
      </div>
    </FormControl>
  )
}

export default MultipleCheckbox
