import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'
import StyledFormStatus from '../styled/StyledFormStatus'
import IconCarbonWarningAlt from '@/shared/icons/IconCarbonWarningAlt'
import IconCarbonCheckmarkOutline from '@/shared/icons/IconCarbonCheckmarkOutline'
import IconCarbonError from '@/shared/icons/IconCarbonError'

const ValidationStatusBadge = ({ status, text }) => {
  const [, theme] = useStyletron()
  const iconProps = {
    color: theme.colors[status],
    size: 20,
    overrides: {
      Svg: {
        style: () => ({
          marginRight: '.25rem',
        }),
      },
    },
  }
  const StatusIcon = () => {
    switch (status) {
      case 'positive':
        return <IconCarbonCheckmarkOutline {...iconProps} />
      case 'negative':
        return <IconCarbonError {...iconProps} />
      case 'warning':
      default:
        return <IconCarbonWarningAlt {...iconProps} />
    }
  }

  switch (status) {
    case 'warning':
    default:
      return (
        <StyledFormStatus status={status}>
          <StatusIcon />
          {text}
        </StyledFormStatus>
      )
  }
}

ValidationStatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default ValidationStatusBadge
