import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconCarbonProxiserveWaterHeater = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'ProxiserveWaterHeater'
  size = size ?? 16
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 32 32' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <rect x='8' y='14' width='6' height='2' />
      <rect x='8' y='6' width='12' height='2' />
      <rect x='8' y='10' width='12' height='2' />
      <rect x='8' y='24' width='6' height='2' />
      <path
        d='M14,9a4.9316,4.9316,0,0,0,4-1.9873,5.0192,5.0192,0,0,0,8,0A4.9316,4.9316,0,0,0,30,9V7a3.4376,3.4376,0,0,1-3.0513-2.3164A1.007,1.007,0,0,0,26,4a.9894.9894,0,0,0-.9468.6787A3.44,3.44,0,0,1,22,7a3.4376,3.4376,0,0,1-3.0513-2.3164A1.007,1.007,0,0,0,18,4a.971.971,0,0,0-.9468.6787A3.44,3.44,0,0,1,14,7H13V6A4,4,0,0,0,5,6V17.2617A7.0126,7.0126,0,1,0,13.8735,18H14a4.9316,4.9316,0,0,0,4-1.9873,5.0192,5.0192,0,0,0,8,0A4.9316,4.9316,0,0,0,30,18V16a3.4376,3.4376,0,0,1-3.0513-2.3164A1.007,1.007,0,0,0,26,13a.9894.9894,0,0,0-.9468.6787A3.44,3.44,0,0,1,22,16a3.4376,3.4376,0,0,1-3.0513-2.3164A1.007,1.007,0,0,0,18,13a.971.971,0,0,0-.9468.6787A3.44,3.44,0,0,1,14,16H13V9ZM7,6a2,2,0,0,1,4,0v6H7ZM9,28a4.9965,4.9965,0,0,1-2.499-9.3252L7,18.3857V14h4v4.3857l.499.2891A4.9965,4.9965,0,0,1,9,28Z'
        transform='translate(0 0)'
      />
    </Icon>
  )
}

IconCarbonProxiserveWaterHeater.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconCarbonProxiserveWaterHeater
