import PropTypes from 'prop-types'
import { FormControl } from 'baseui/form-control'
import SDDatePicker from '@/shared/components/SDComponents/SDDatePicker'
import { SIZE } from 'baseui/button'

const ByCreationDate = ({ label, onDateChangeHandler }) => {
  return (
    <FormControl label={label}>
      <SDDatePicker
        size={SIZE.compact}
        formatString='dd/MM/yyyy'
        onChange={({ date }) => {
          let finalDate = ''
          if (date) {
            finalDate = new Date(date + ' UTC')
            finalDate = finalDate.toISOString().slice(0, 10)
          }
          onDateChangeHandler(finalDate)
        }}
      />
    </FormControl>
  )
}

ByCreationDate.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  onDateChangeHandler: PropTypes.func,
}

export default ByCreationDate
