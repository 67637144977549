import PropTypes from 'prop-types'
import { mergeOverrides } from 'baseui'
import { ButtonGroup, SIZE } from 'baseui/button-group'
import { SDButtonGroupOverrides } from './styled-components'

const SDButtonGroup = ({ children, direction, error, size, overrides, ...rest }) => {
  const newOverrides = mergeOverrides(SDButtonGroupOverrides(direction, error), overrides)
  return (
    <ButtonGroup {...rest} size={size ?? SIZE.compact} overrides={newOverrides}>
      {children}
    </ButtonGroup>
  )
}

SDButtonGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  direction: PropTypes.string,
  error: PropTypes.string,
  overrides: PropTypes.object,
  size: PropTypes.string,
}

export default SDButtonGroup
