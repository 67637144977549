import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import CommonFallback from '@/shared/components/CommonFallback'
import styles from './ToolsPanel.module.css'
import PanelHeading from './PanelHeading'
import useResponsiveGrid from '@/shared/hooks/useResponsiveGrid'
import { useState, useEffect, lazy, Suspense } from 'react'
import useBusinessType from '@/shared/hooks/useBusinessType'

const ToolsPanel = ({ country, applicationType }) => {
  const { t } = useTranslation()
  const [countryAvailability, setCountryAvailability] = useState(false)
  const [originalLayouts, setOriginalLayouts] = useState(false)
  const [ToolsModule, setToolsModule] = useState('')
  const { getApplicationTypeFolder } = useBusinessType({
    loaderRoutesEnergy: {},
    loaderRoutesInsurance: {},
    loaderRoutes: {},
    modulesStatuses: {},
    modulesStatusesEnergy: {},
    modulesStatusesInsurance: {},
  })
  const importAvailability = async () => {
    const folder = getApplicationTypeFolder()
    try {
      const countryAvailability = await import(`./${folder}/toolsCountriesAvailability.js`)
      const availability = { ...(countryAvailability.default ?? {}) }
      setCountryAvailability(availability[country] ?? false)
      setOriginalLayouts(countryAvailability.originalLayout ?? false)
      const module = lazy(() => import(`./${folder}/ToolsList.jsx`))
      setToolsModule(module)
    } catch {
      setCountryAvailability(false)
    }
  }

  const { layoutChangeHandler, setLayouts, layouts } = useResponsiveGrid(
    country,
    applicationType + '-form-tools-change-position-',
    originalLayouts ?? {},
  )

  useEffect(() => {
    importAvailability()
  }, [country, applicationType])

  if (!countryAvailability || !originalLayouts || !applicationType) {
    return (
      <div className={styles.ToolsPanel}>
        <PanelHeading title={t('subscriptionTools:heading.tools')} subtitle={t('subscriptionTools:heading.help')} />
        <CommonFallback />
      </div>
    )
  }

  return (
    <div className={styles.ToolsPanel}>
      <PanelHeading title={t('subscriptionTools:heading.tools')} subtitle={t('subscriptionTools:heading.help')} />
      <Suspense fallback={<CommonFallback />}>
        <ToolsModule
          layouts={layouts}
          setLayouts={setLayouts}
          countryAvailability={countryAvailability}
          layoutChangeHandler={layoutChangeHandler}
          applicationType={applicationType}
        />
      </Suspense>
    </div>
  )
}

ToolsPanel.propTypes = {
  country: PropTypes.string,
  applicationType: PropTypes.string,
}

export default ToolsPanel
