import PropTypes from 'prop-types'
import { KIND } from 'baseui/button'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { ModalSection, ModalText } from '@/shared/components/SDComponents/SDModal/styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectAllAdditionalSalesData } from '@/containers/SubscriptionFormPage/SubscriptionFormSelectors'

const AdditionalSalesModalContent = ({ setIsOpen }) => {
  const { t } = useTranslation()
  const additionalSales = useSelector(selectAllAdditionalSalesData)
  const goToAS = event => {
    event.preventDefault()
    setIsOpen(false)
    window.scrollTo(0, document.body.scrollHeight)
  }

  const areThereAdditionalSales = () => {
    return Object.keys(additionalSales).some(sale => additionalSales[sale].zohoId)
  }

  if (areThereAdditionalSales()) {
    return <></>
  }

  return (
    <ModalSection>
      <ModalText>{t('subscriptionForm:header.modal.additional_sales_reminder')}</ModalText>
      <SDButton kind={KIND.secondary} block={true} onClick={goToAS}>
        {t('subscriptionForm:header.modal.go_to_additional_sales')}
      </SDButton>
    </ModalSection>
  )
}

AdditionalSalesModalContent.propTypes = {
  setIsOpen: PropTypes.func,
}

export default AdditionalSalesModalContent
