export const compareString = (a, b) => {
  if (a > b) {
    return 1
  }
  if (b > a) {
    return -1
  }
  return 0
}

export const compareReverseString = (a, b) => {
  if (a > b) {
    return -1
  }
  if (b > a) {
    return 1
  }
  return 0
}

export const compareNumber = (a, b) => a - b

export const compareDates = (a, b) => {
  if (a === null) return -1
  if (b === null) return 1
  const dateA = new Date(a)
  const dateB = new Date(b)

  return dateA.getTime() - dateB.getTime()
}
