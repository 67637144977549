export default function debounce(fn, delay) {
  let timeoutId
  function debounced(...args) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      fn(...args)
    }, delay)
  }
  return debounced
}
