import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'
import { FormControl } from 'baseui/form-control'
import { DatePicker } from 'baseui/datepicker'

export default function ObjectiveDate({ label, value, onChange }) {
  const [css, theme] = useStyletron()

  return (
    <div
      className={css({
        [theme.mediaQuery.medium]: {
          display: 'flex',
          alignItems: 'center',
        },
      })}
    >
      <div className={css({ display: 'flex' })}>
        <div
          className={css({
            width: '120px',
            marginRight: theme.sizing.scale300,
          })}
        >
          <FormControl label={label} caption='YYYY/MM/DD'>
            <DatePicker
              value={value}
              onChange={({ date }) => {
                onChange(date)
              }}
              mask='9999/99/99'
              placeholder={label}
            />
          </FormControl>
        </div>
      </div>
    </div>
  )
}

ObjectiveDate.propTypes = {
  value: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func,
}
