import { FormControl } from 'baseui/form-control'
import MultipleButtonGroup from './MultipleButtonGroup'
import SDInput from '@/shared/components/SDComponents/SDInput/SDInput'
import { SIZE } from 'baseui/input'

const Debordement = ({ updateOffer, t, providerState }) => {
  const providerDebordementStatus = providerState.selectedProvider.debordement_call_status ?? 0
  const offerValue = providerState.selectedOffer.debordement_call_status ?? '0'

  if (providerDebordementStatus === 0) {
    return
  }

  return (
    <>
      <FormControl key={'debordement'} label={t('organizationManagement:offer.debordement_yes_no')}>
        <MultipleButtonGroup
          options={{ 1: t('common:yes_text'), 0: t('common:no_text') }}
          onClick={value => updateOffer('debordement_call_status', value)}
          selectedValue={offerValue.toString()}
        />
      </FormControl>

      {parseInt(providerState.selectedOffer.debordement_call_status) === 1 && (
        <FormControl key={'debordement_zoho_id'} label={t('organizationManagement:offer.debordement_zoho_id')}>
          <SDInput
            size={SIZE.compact}
            onChange={e => updateOffer('debordement_call_zoho_id', e.target.value)}
            value={providerState.selectedOffer.debordement_call_zoho_id}
          />
        </FormControl>
      )}
    </>
  )
}

export default Debordement
