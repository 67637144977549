import { useEffect, useReducer } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import useHttp from '@/shared/hooks/useHttp'
import CommonFallback from '@/shared/components/CommonFallback'
import providerActions from './providerActions'
import providerData from './providerData'
import ProviderManagementContext from './ProviderManagementContext'
import Index from './OrganizationShow/Index'
import TelecomShow from './OrganizationShow/Telecom/TelecomShow'
import EnergyShow from './OrganizationShow/Energy/EnergyShow'
import InsuranceShow from './OrganizationShow/Insurance/InsuranceShow'
import Edit from './OrganizationForm/Edit'
import Add from './OrganizationForm/Add'
import { useSnackbar } from 'baseui/snackbar'
import IconCarbonCheckmarkOutline from '@/shared/icons/IconCarbonCheckmarkOutline'
import { useStyletron } from 'baseui'
import { useTranslation } from 'react-i18next'
import OfferAdd from './OfferForm/OfferAdd'
import OfferEdit from './OfferForm/OfferEdit'
import { useSelector } from 'react-redux'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import useBusinessType from '@/shared/hooks/useBusinessType'

const OrganizationRouter = () => {
  const { loaderRoutesEnergy, loaderRoutes, loaderRoutesInsurance, lang } = useSelector(selectAuthData)
  const { getApplicationUrl, type } = useBusinessType({
    loaderRoutesEnergy,
    loaderRoutes,
    loaderRoutesInsurance,
    modulesStatuses: {},
    modulesStatusesEnergy: {},
    modulesStatusesInsurance: {},
  })
  const initUrl = getApplicationUrl('organizations-management')
  const { sendRequest, getConfig } = useHttp()
  const [providerState, providerDispatch] = useReducer(providerActions, providerData)
  const [, theme] = useStyletron()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const renderShow = () => {
    switch (type) {
      case 'energie':
        return <EnergyShow />
      case 'assurance':
        return <InsuranceShow />
      default:
        return <TelecomShow />
    }
  }
  const snackMessage = () => {
    if (providerState.save.offerSaved) {
      return t('organizationManagement:offerSaved')
    }
    if (providerState.save.offerDeleted) {
      return t('organizationManagement:offerDeleted')
    }

    if (providerState.save.offerDuplicated) {
      return t('organizationManagement:offerDuplicated')
    }

    return t('organizationManagement:saved')
  }
  const Snack = () => {
    const { enqueue } = useSnackbar()
    useEffect(() => {
      if (
        providerState.save.saved ||
        providerState.save.offerSaved ||
        providerState.save.offerDeleted ||
        providerState.save.offerDuplicated
      ) {
        enqueue({
          message: snackMessage(),
          startEnhancer: () => {
            return <IconCarbonCheckmarkOutline color={theme.colors.positive} />
          },
        })
        providerDispatch({
          type: 'saving',
          payload: {
            saved: false,
            offerSaved: false,
            offerDeleted: false,
            offerDuplicated: false,
          },
        })
        initialize()
      }
    }, [
      providerState.save.saved,
      providerState.save.offerSaved,
      providerState.save.offerDeleted,
      providerState.save.offerDuplicated,
    ])

    return ''
  }
  const initialize = async () => {
    if (!initUrl) {
      return
    }

    await sendRequest(initUrl + '?lang=' + lang, getConfig('GET'), response =>
      providerDispatch({ type: 'initialize', payload: response }),
    )
  }

  useEffect(() => {
    if (providerState.save.offerSaved) {
      navigate('/' + type + '/organisations/provider/' + providerState.selectedProvider.uuid)
    }
  }, [providerState.save.offerSaved])

  useEffect(() => {
    if (providerState.save.saved) {
      navigate('/' + type + '/organisations/provider')
    }
  }, [providerState.save.saved])

  useEffect(() => {
    initialize()
  }, [])

  if (!providerState.initialized) {
    return <CommonFallback />
  }

  return (
    <ProviderManagementContext.Provider
      value={{
        providerState,
        providerDispatch,
      }}
    >
      <Routes>
        <Route exact path='' element={<Index />} />
        <Route exact path='add' element={<Add />} />
        <Route path='edit/:id' element={<Edit />} />
        <Route path=':id' element={renderShow()} />
        <Route path=':providerUuid/offers/add' element={<OfferAdd />} />
        <Route path=':providerUuid/offers/:offerId/edit' element={<OfferEdit />} />
      </Routes>
      <Snack />
    </ProviderManagementContext.Provider>
  )
}

export default OrganizationRouter
