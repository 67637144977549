import { call, put, select, takeLatest } from 'redux-saga/effects'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import {
  updatePageConfigurationIsLoading,
  updatePageConfigurationData,
  updatePageConfigurationError,
  updatePageConfigurationInitialization,
} from '@/store/PageConfiguration/PageConfigurationSlice'

export const pageConfigurationActions = {
  INITIALIZE: 'INITIALIZE',
}

export function* initializeSaga({ initUrl }) {
  try {
    const { authTokenSession, lang } = yield select(selectAuthData)
    // test token expiration

    yield put(updatePageConfigurationIsLoading(true))
    yield put(updatePageConfigurationInitialization(false))
    let countryUrl = initUrl
    const language = lang ?? 'fr'
    if (initUrl.includes('?')) {
      countryUrl = `${initUrl}&lang=${language}`
    } else {
      countryUrl = `${initUrl}?lang=${language}`
    }

    const request = yield call(fetch, countryUrl, {
      method: 'get',
      mode: 'cors',
      headers: {
        Accept: 'Application/json',
        Authorization: 'Bearer ' + authTokenSession,
      },
    })

    const { status } = request
    const data = yield call([request, request.json])
    if (status === 200) {
      yield put(updatePageConfigurationData(data))
      yield put(updatePageConfigurationInitialization(true))
    } else {
      yield put(updatePageConfigurationError(data))
    }
  } catch (error) {
    yield put(updatePageConfigurationError(JSON.parse(error)))
  } finally {
    yield put(updatePageConfigurationIsLoading(false))
  }
}

export default function* pageConfigurationSagas() {
  yield takeLatest(pageConfigurationActions.INITIALIZE, initializeSaga)
}
