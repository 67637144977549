import { useStyletron } from 'baseui'
import PropTypes from 'prop-types'
import { Notification } from 'baseui/notification'
import IconStatusError from '@/shared/icons/StatusSteps/IconStatusError'

const SubscriptionStatusError = ({ error }) => {
  const [css] = useStyletron()
  const errorStepStyle = css({
    display: 'flex',
    paddingLeft: '10px',
    paddingRight: '10px',
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  })
  const alertIcon = css({
    display: 'float',
    marginRight: '4px',
    verticalAlign: 'middle',
  })
  const getErrors = () => {
    const children = []
    error.forEach((error, index) => {
      if (index === 0) {
        children.push(
          <div>
            <span className={alertIcon}>
              <IconStatusError size={16} />
            </span>
            <span key={index} dangerouslySetInnerHTML={{ __html: error }}></span>
          </div>,
        )
      } else {
        children.push(<span key={index} dangerouslySetInnerHTML={{ __html: error }}></span>)
      }
    })
    return <div>{children}</div>
  }

  return (
    <>
      <div className={errorStepStyle}>
        <Notification
          kind={'negative'}
          overrides={{
            Body: {
              style: {
                fontSize: '12px',
                width: 'auto',
                borderRadius: '10px',
                border: '1px solid #C20000',
                textAlign: 'left',
                fontWeight: 'normal',
                color: '#565E66',
              },
            },
          }}
        >
          {' '}
          {getErrors()}
        </Notification>
      </div>
    </>
  )
}

SubscriptionStatusError.propTypes = {
  error: PropTypes.array,
}

export default SubscriptionStatusError
