export const SDSelectOverrides = () => ({
  ControlContainer: {
    style: ({ $theme, $isFocused, $disabled, $error }) => {
      const borderColor = $disabled ? $theme.colors.mono300 : $error ? $theme.colors.negative500 : $theme.colors.mono300
      const outlineColor = $disabled
        ? $theme.colors.mono300
        : $error
          ? $theme.colors.negative500
          : $theme.colors.primary500
      const textColor = $disabled ? $theme.colors.mono600 : $theme.colors.mono700
      const bgColor = $disabled ? $theme.colors.mono100 : $theme.colors.white

      return {
        alignItems: 'center',

        height: '2.5rem',

        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingLeft: 0,

        borderTopWidth: '0.0625rem',
        borderRightWidth: '0.0625rem',
        borderBottomWidth: '0.0625rem',
        borderLeftWidth: '0.0625rem',

        outline: $disabled || !($isFocused || $error) ? 'none' : `0.125rem solid ${outlineColor}`,
        outlineOffset: $isFocused || $error ? '-.125rem' : 0,

        color: textColor,

        borderTopColor: borderColor,
        borderRightColor: borderColor,
        borderBottomColor: borderColor,
        borderLeftColor: borderColor,

        backgroundColor: bgColor,
      }
    },
  },
  Input: {
    props: {
      autoComplete: 'off',
    },
  },
  Popover: {
    props: {
      overrides: {
        Body: {
          style: () => ({
            left: '1px',
            marginTop: '.5rem',
            backgroundColor: 'transparent',
            boxShadow: 'none',
          }),
        },
        Inner: {
          style: () => ({
            backgroundColor: 'transparent',
          }),
        },
      },
    },
  },
  DropdownContainer: {
    style: () => ({
      backgroundColor: 'transparent',
    }),
  },
  StatefulMenu: {
    props: {
      overrides: {
        List: {
          style: ({ $theme }) => ({
            backgroundColor: $theme.colors.white,

            border: `.0625rem solid ${$theme.colors.mono300}`,

            borderTopRightRadius: $theme.borders.radius200,
            borderBottomRightRadius: $theme.borders.radius200,
            borderBottomLeftRadius: $theme.borders.radius200,
            borderTopLeftRadius: $theme.borders.radius200,

            paddingTop: '1rem',
            paddingRight: '1rem',
            paddingBottom: '1rem',
            paddingLeft: '1rem',
          }),
        },
        Option: {
          props: {
            overrides: {
              ListItem: {
                style: ({ $theme, $isHighlighted }) => {
                  const outlineStyle = $isHighlighted ? `0.125rem solid ${$theme.colors.primary500}` : 'none'

                  return {
                    minHeight: '2rem',

                    paddingTop: '0',
                    paddingRight: '0',
                    paddingBottom: '0',
                    paddingLeft: '0',

                    outline: outlineStyle,
                    outlineOffset: '-.125rem',

                    color: $theme.colors.mono700,

                    backgroundColor: $theme.colors.white,

                    borderTopRightRadius: $theme.borders.radius200,
                    borderBottomRightRadius: $theme.borders.radius200,
                    borderBottomLeftRadius: $theme.borders.radius200,
                    borderTopLeftRadius: $theme.borders.radius200,
                    ':not(:last-child)': {
                      marginBottom: '.5rem',
                    },
                  }
                },
              },
            },
          },
        },
      },
    },
  },
  OptionContent: {
    style: ({ $theme, $selected }) => {
      const bgColour = $selected ? $theme.colors.bg100 : $theme.colors.white

      return {
        minHeight: '2rem',

        paddingTop: '.25rem',
        paddingRight: '.5rem',
        paddingBottom: '.25rem',
        paddingLeft: '.5rem',

        color: $selected ? $theme.colors.primary500 : 'inherit',

        fontWeight: 'normal',

        backgroundColor: bgColour,

        borderTopRightRadius: $theme.borders.radius200,
        borderBottomRightRadius: $theme.borders.radius200,
        borderBottomLeftRadius: $theme.borders.radius200,
        borderTopLeftRadius: $theme.borders.radius200,
      }
    },
  },
})
