import { useStyletron } from 'baseui'

const useLoginStyles = () => {
  const [css, theme] = useStyletron()
  const loginWrapper = css({
    color: theme.colors.accent700,
    background: 'linear-gradient(116.82deg, #4793DD 0%, #004791 100%)',
    height: '100vh',
  })
  const loginCard = css({
    marginLeft: 'auto',
    marginRight: 'auto',
    justifyContent: 'center',
  })
  const logo = css({
    justifyContent: 'center',
    display: 'flex',
  })
  const spacer = {
    height: '2px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    backgroundColor: '#A8BACD',
    marginTop: '10px',
    marginBottom: '10px',
  }
  const cardOverrides = {
    Root: {
      style: {
        display: 'flex',
        width: '328px',
        borderRadius: '1.5rem',
      },
    },
  }

  return {
    loginWrapper,
    loginCard,
    logo,
    spacer,
    cardOverrides,
  }
}

export default useLoginStyles
