import { FormControl } from 'baseui/form-control'
import PromoCodesComponent from '@/containers/OrganizationsManagement/OrganizationForm/Energy/PromoCodes/PromoCodesComponent'
import { Editor } from '@tinymce/tinymce-react'
import usePromoCodes from '@/containers/OrganizationsManagement/OrganizationForm/Energy/PromoCodes/usePromoCodes'

const PromoCodes = ({ providerState }) => {
  const
    {
      editorRef,
      t,
      updateProvider
    } = usePromoCodes()

  return (
    <>
      <FormControl
        label={t('organizationManagement:promo_section.code_list_label')}
        caption={t('organizationManagement:promo_section.code_list_caption')}
      >
        <PromoCodesComponent currentPromoCodesValues={providerState.selectedProvider.promo_codes} />
      </FormControl>
      <FormControl label={t('organizationManagement:promo_section.editor_description_label')}>
        <Editor
          id='promo-codes-text-input'
          apiKey={providerState.tinymceKey}
          initialValue={providerState.selectedProvider.promo_description}
          onInit={(evt, editor) => (editorRef.current = editor)}
          onChange={(e) => updateProvider('promo_description', e.target.getContent())}
          init={{
            height: 250,
            plugins: [
              'advlist',
              'autolink',
              'lists',
              'link',
              'image',
              'charmap',
              'preview',
              'anchor',
              'searchreplace',
              'visualblocks',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'code',
              'wordcount'
            ],
            toolbar:
          'undo redo | formatselect | ' +
          'bold italic backcolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help'
          }}
        />
      </FormControl>
    </>
  )
}

export default PromoCodes
