import { styled } from 'baseui'

const StyledFormHeader = styled('div', ({ $theme }) => ({
  gridArea: 'header',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignContent: 'center',
  alignItems: 'center',
  padding: '1rem',
  backgroundColor: $theme.colors.white,
  borderRadius: $theme.borders.radius400,
  boxShadow: $theme.lighting.shadow400,
  '@media screen and (min-width: 768px)': {
    flexDirection: 'row',
  },
}))

export default StyledFormHeader
