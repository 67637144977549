import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import { StyledLink } from 'baseui/link'
import { useTranslation } from 'react-i18next'

export default function PrestationFromZohoBadge({ prestation, zohoId, dealName }) {
  const { t } = useTranslation()
  const badgeColour = 'grey'
  const [css] = useStyletron()
  const colour = badgeColour
  const linkStyle = css({
    display: 'flex',
    width: '100%',
    border: `2px solid ${colour}`,
    borderRadius: '.25rem',
    backgroundColor: 'white',
  })
  const badgeStyle = css({
    display: 'flex',
    alignItems: 'center',
    padding: '.125rem .25rem .125rem .125rem',
    backgroundColor: colour,
    color: 'white',
    fontSize: '.75rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  })
  const contentStyle = css({
    padding: '.125rem .25rem',
    margin: '0 auto',
    fontSize: '.75rem',
    textAlign: 'center',
  })

  if (prestation === '-1') {
    return ''
  }
  return (
    <div className={linkStyle}>
      <div className={badgeStyle}>
        <span>{prestation}</span>
      </div>
      <div className={contentStyle}>
        <span>
          {zohoId?.length > 0 ? (
            <StyledLink href={'https://crm.zoho.com/crm/org52021474/tab/Potentials/' + zohoId} target='_blank'>
              {dealName}
            </StyledLink>
          ) : (
            t('subscriptionTable:no_reference')
          )}
        </span>
      </div>
    </div>
  )
}

PrestationFromZohoBadge.propTypes = {
  prestation: PropTypes.string,
  zohoId: PropTypes.string,
  dealName: PropTypes.string,
}
