import { StyledBodyCell, StyledHeadCell, StyledTable } from 'baseui/table-grid'
import { useStyletron } from 'baseui'
import { useTranslation } from 'react-i18next'

const RowsNestedRequestDetailsTable = ({ requestType, requestResponse, requestUrl }) => {
  const [css] = useStyletron()
  const { t } = useTranslation()
  const isSentRequest = () => {
    return requestType === 'SENT'
  }
  const parseJson = value => {
    try {
      return JSON.parse(value)
    } catch (e) {
      return value
    }
  }
  const formatResponse = () => {
    const requestParsed = requestType === 'RESPONSE' ? parseJson(requestResponse) : requestResponse
    return JSON.stringify(requestParsed, null, 2)
  }
  return (
    <div
      className={css({
        gridColumn: 'span 5',
        paddingTop: '5px',
        paddingBottom: '5px',
      })}
    >
      <StyledTable role='grid' $gridTemplateColumns={isSentRequest() ? 'auto auto' : 'auto'}>
        <div role='row' className={css({ display: 'contents' })}>
          {isSentRequest() && (
            <StyledHeadCell className={css({ backgroundColor: '#88EBB7' })}>
              {t('subscriptionForm:subscription_actions_module.export_logs.url_called_text')}
            </StyledHeadCell>
          )}
          <StyledHeadCell className={css({ backgroundColor: '#88EBB7' })}>
            {t('subscriptionForm:subscription_actions_module.export_logs.parameters_called_text')}
          </StyledHeadCell>
        </div>
        {isSentRequest() && (
          <div role='row' className={css({ display: 'contents' })}>
            <StyledBodyCell>{requestUrl}</StyledBodyCell>
          </div>
        )}

        <div role='row' className={css({ display: 'contents' })}>
          <StyledBodyCell>
            <pre>{formatResponse()}</pre>
          </StyledBodyCell>
        </div>
      </StyledTable>
    </div>
  )
}

export default RowsNestedRequestDetailsTable
