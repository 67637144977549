const ENV = {
  development: 'development',
  staging: 'staging',
  production: 'production',
}

const modeEnv = {
  isStaging: import.meta.env.VITE_APP_ENV === 'staging',
  isDevelopment: import.meta.env.VITE_APP_ENV === 'development',
  isProduction: import.meta.env.VITE_APP_ENV === 'production',
}

const commonConfig = {
  APP_URL: '',
}

const stagingConfig = {
  ...commonConfig,
  APP_URL: `https://fr.private.multi-provider-platform.app.staging.aws.selectra.io`,
  env: ENV.staging,
}

const developmentConfig = {
  ...stagingConfig,
  APP_URL: `http://localhost:8080`,
  env: ENV.development,
}

const productionConfig = {
  ...commonConfig,
  APP_URL: `https://hop.selectra.io`,
  env: ENV.production,
}

const runtimeConfigByEnv = {
  [ENV.development]: developmentConfig,
  [ENV.staging]: stagingConfig,
  [ENV.production]: productionConfig,
}

function getRuntimeConfig() {
  const allowedEnv = Object.values(ENV)
  if (!allowedEnv.includes(import.meta.env.VITE_APP_ENV)) {
    // eslint-disable-next-line no-console
    console.warn(
      `
        The desired env '${
          import.meta.env.VITE_APP_ENV
        }' has no runtime config. The valid environments are: ${allowedEnv}.
        Thus, 'development' environment config is given as fallback
        `,
    )
    return developmentConfig
  }
  const envRuntimeConfig = runtimeConfigByEnv[import.meta.env.VITE_APP_ENV]
  return { ...envRuntimeConfig, ...modeEnv }
}

export const runtimeConfig = getRuntimeConfig()
