import PropTypes from 'prop-types'
import { FormControl } from 'baseui/form-control'
import { TYPE } from 'baseui/select'
import SDSelect from '@/shared/components/SDComponents/SDSelect/SDSelect'

const GenericSelect = ({ onChange, list, getId, getName, filterId, label }) => {
  const selected = []
  const options = []
  if (list) {
    for (const [, element] of Object.entries(list)) {
      const elementId = getId(element)
      const data = {
        label: getName(element),
        id: elementId,
      }
      options.push(data)
      if (elementId === filterId) {
        selected.push(data)
      }
    }
  }

  return (
    <FormControl label={label}>
      <SDSelect type={TYPE.search} options={options} value={selected} onChange={onChange} />
    </FormControl>
  )
}

GenericSelect.propTypes = {
  onChange: PropTypes.func,
  getId: PropTypes.func,
  getName: PropTypes.func,
  label: PropTypes.string,
  list: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  filterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default GenericSelect
