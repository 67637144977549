import { call, put, select } from 'redux-saga/effects'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import getAPIConfig from '@/shared/tools/getAPIConfig'
import {
  addCommonFormErrors,
  deleteCommonFormErrors,
  updateOfferConfiguration,
  updateOfferModuleConfiguration,
  updateProviderConfiguration,
} from '../../../SubscriptionFormConfigurationSlice'
import { mapProviderConfigData } from './mapProviderConfigData'

export const offerModuleActions = {
  OFFER_MODULE_LOAD_PROVIDERS: 'OFFER_MODULE_LOAD_PROVIDERS',
  OFFER_MODULE_LOAD_OFFERS: 'OFFER_MODULE_LOAD_OFFERS',
  OFFER_MODULE_LOAD_PROVIDERS_OPTIONS: 'OFFER_MODULE_LOAD_PROVIDERS_OPTIONS',
  OFFER_MODULE_LOAD_OFFER_INFORMATION: 'OFFER_MODULE_LOAD_OFFER_INFORMATION',
  OFFER_MODULE_LOAD_PROVIDER_INFORMATION: 'OFFER_MODULE_LOAD_PROVIDER_INFORMATION',
  OFFER_MODULE_LOAD_ENERGY_ORIGIN: 'OFFER_MODULE_LOAD_ENERGY_ORIGIN',
}

export function* loadProviders({ params, errorTooManyRequests }) {
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { load_providers_url: url } = yield select(selectPageConfigurationData)
  const fullUrl = `${url}?lang=${lang}&${params}`
  yield put(deleteCommonFormErrors('load_provider_list'))
  yield put(updateOfferModuleConfiguration({ providerLoading: true }))

  try {
    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })
    const response = yield call(fetch, fullUrl, config)
    const isValid = response.status === 200
    const responseData = yield call([response, response.json])
    if (!isValid) {
      yield put(updateOfferModuleConfiguration({ providerLoading: false }))
      console.warn('loadProvidersError', responseData, response.status)
      return
    }
    if (response.status === 429) {
      yield put(updateOfferModuleConfiguration({ providerLoading: false }))
      yield put(addCommonFormErrors({ load_provider_list: errorTooManyRequests }))
      return
    }
    yield put(updateOfferModuleConfiguration({ offers: {}, providers: responseData, providerLoading: false }))
  } catch (error) {
    yield put(updateOfferModuleConfiguration({ providerLoading: false }))
    console.warn('loadProvidersError', error)
  }
}

export function* loadOffers({ params }) {
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { load_offers_url: url } = yield select(selectPageConfigurationData)
  const fullUrl = `${url}?lang=${lang}&${params}`
  yield put(updateOfferModuleConfiguration({ offerLoading: true }))

  try {
    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })
    const response = yield call(fetch, fullUrl, config)
    const isValid = response.status === 200
    const responseData = yield call([response, response.json])
    if (!isValid) {
      console.warn('loadOffersError', responseData, response.status)
      return
    }
    yield put(updateOfferModuleConfiguration({ offers: responseData.list, offerLoading: false }))
  } catch (error) {
    console.warn('loadOffersError', error)
  }
}

export function* loadProviderOptions({ providerId, productType }) {
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { load_provider_options_url: url } = yield select(selectPageConfigurationData)
  const fullUrl = `${url}/${providerId}/${productType}?lang=${lang}`

  try {
    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })
    const response = yield call(fetch, fullUrl, config)
    const isValid = response.status === 200
    const responseData = yield call([response, response.json])
    if (!isValid) {
      console.warn('loadProviderOptionsError', responseData, response.status)
      return
    }
    yield put(updateOfferModuleConfiguration({ providerOptions: responseData }))
  } catch (error) {
    console.warn('loadProviderOptionsError', error)
  }
}

export function* loadOfferInformation({ offerId }) {
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { load_offer_info_url: url } = yield select(selectPageConfigurationData)
  const fullUrl = `${url}?lang=${lang}&offer_id=${offerId}`
  try {
    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })
    const response = yield call(fetch, fullUrl, config)
    const isValid = response.status === 200
    const responseData = yield call([response, response.json])
    if (!isValid) {
      console.warn('loadOfferInformationError', responseData, response.status)
      return
    }
    yield put(updateOfferConfiguration(responseData.offer))
  } catch (error) {
    console.warn('loadOfferInformationError', error)
  }
}

export function* loadProviderInformation({ providerId }) {
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { load_organization_config_url: url } = yield select(selectPageConfigurationData)
  const fullUrl = `${url}?lang=${lang}&provider_id=${providerId}`

  try {
    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })
    const response = yield call(fetch, fullUrl, config)
    const isValid = response.status === 200
    const responseData = yield call([response, response.json])
    if (!isValid) {
      console.warn('loadProviderInformationError', responseData, response.status)
      return
    }
    yield put(updateProviderConfiguration(mapProviderConfigData(responseData)))
  } catch (error) {
    console.warn('loadProviderInformationError', error)
  }
}

export function* loadEnergyOrigin({ providerId }) {
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { load_energy_origin_url: url } = yield select(selectPageConfigurationData)
  const fullUrl = `${url}?lang=${lang}&id=${providerId}`

  try {
    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })
    const response = yield call(fetch, fullUrl, config)
    const isValid = response.status === 200
    const responseData = yield call([response, response.json])
    if (!isValid) {
      console.warn('loadEnergyOriginError', responseData, response.status)
      return
    }
    yield put(updateOfferModuleConfiguration({ energyOrigin: responseData }))
  } catch (error) {
    console.warn('loadEnergyOriginError', error)
  }
}
