import { FormControl } from 'baseui/form-control'
import SDInput from '@/shared/components/SDComponents/SDInput/SDInput'
import { SIZE } from 'baseui/input'
import { useState } from 'react'
import MultipleButtonGroup from './MultipleButtonGroup'

const PaymentDateFixe = ({ attributeId, attributeTechName, currentValue, updateOffer, t }) => {
  const currentValueArray = currentValue && currentValue.length > 0 ? currentValue.split(';') : []
  const [numberOfFields, setNumberOfFields] = useState(currentValueArray.length > 0 ? currentValueArray.length : 3)
  const [rangeData, setRangeData] = useState(currentValueArray)
  const updateCurrentData = (value, i) => {
    const newValues = [...rangeData]
    newValues[i] = value
    setRangeData(newValues)
  }
  const updateOfferDates = (value, i) => {
    const newValues = [...rangeData]
    newValues[i] = value
    const filteredArray = newValues.filter(value => value.length > 0)
    updateOffer('attr_' + attributeId, filteredArray.join(';'))
  }

  const generateFields = () => {
    const items = []
    for (let i = 0; i < numberOfFields; i++) {
      items.push(
        <FormControl
          key={'attr_fixe_' + i + '_' + attributeId}
          label={i === 0 ? t('organizationManagement:offer.start_dates') : ''}
          overrides={{
            ControlContainer: {
              style: () => ({
                paddingLeft: '2rem',
              }),
            },
          }}
        >
          <SDInput
            min='1'
            max='31'
            size={SIZE.compact}
            type='number'
            name={'counter_' + i}
            value={rangeData[i] ?? ''}
            onChange={e => {
              const i = e.target.name.split('_')[1]
              updateCurrentData(e.target.value, i)
            }}
            onBlur={e => {
              const i = e.target.name.split('_')[1]
              updateOfferDates(e.target.value, i)
            }}
          />
        </FormControl>,
      )
    }
    return items
  }

  return (
    <FormControl key={'attr_' + attributeId} label={t('organizationManagement:offer.attr_' + attributeTechName)}>
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'left',
          }}
        >
          {generateFields()}
        </div>
        <MultipleButtonGroup
          parentKey='add_more_less_btn'
          label=''
          options={{ add: '+', remove: '-' }}
          onClick={value => {
            if (value === 'add') {
              setNumberOfFields(numberOfFields + 1)
            } else {
              if (numberOfFields - 1 <= 0) {
                setNumberOfFields(1)
                setRangeData([rangeData[0]])
              } else {
                const max = numberOfFields - 1
                setNumberOfFields(max)
                const newValues = [...rangeData]
                const newValuesLimited = newValues.slice(0, max)
                setRangeData(newValuesLimited)
                updateOffer('attr_' + attributeId, newValuesLimited.join(';'))
              }
            }
          }}
        />
      </>
    </FormControl>
  )
}

export default PaymentDateFixe
