import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconCarbonCurrencyEuro = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'Currency euro'
  size = size ?? 16
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 32 32' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <path
        d='M17,26c-3.6162,0-6.3328-2.2974-7.4456-6H19V18H9.1321A15.2782,15.2782,0,0,1,9,16c0-.33.01-.6641.0259-1H19V13H9.2371C9.8447,9.3525,11.81,6,17,6c3.8533,0,5.5315,1.6465,7.1282,4.4893l1.7436-.9786C24.2649,6.6484,22.0779,4,17,4,10.6448,4,7,8.374,7,16c0,7.0654,4.1121,12,10,12,5.0779,0,7.2649-2.6484,8.8718-5.5107l-1.7436-.9786C22.5315,24.3535,20.8533,26,17,26Z'
        transform="translate(0 0)" />
    </Icon>
  )
}

IconCarbonCurrencyEuro.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconCarbonCurrencyEuro
