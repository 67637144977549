import { takeLatest, call, select } from 'redux-saga/effects'
import getAPIConfig from '@/shared/tools/getAPIConfig'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import {
  customerModuleActions,
  verifyEmail,
  verifySiret,
  registerToNewsletter
} from './ModulesPanel/Modules/CustomerModule/CustomerModuleSagas'
import {
  zohoModuleActions,
  verifyZohoId,
  updateZohoId,
  unlockMcpClient
} from './ModulesPanel/Modules/ZohoModule/ZohoModuleSagas'
import {
  characteristicsModuleActions,
  searchAddress,
  cityEligibilityCheck
} from './ModulesPanel/Modules/CharacteristicsModule/CharacteristicsModuleSagas'
import {
  propaneModuleActions,
  loadPropaneEnergySuppliers,
  loadPropaneQuoteReasons,
  loadPropaneVisibility
} from './ModulesPanel/Modules/PropaneModule/PropaneModuleSagas'
import {
  consumptionModuleActions,
  calculateOfferPrice
} from './ModulesPanel/Modules/ConsumptionModule/ConsumptionModuleSagas'
import {
  offerModuleActions,
  loadProviders,
  loadOffers,
  loadProviderOptions,
  loadOfferInformation,
  loadProviderInformation,
  loadEnergyOrigin
} from './ModulesPanel/Modules/OfferModule/OfferModuleSagas'
import {
  billingModuleActions,
  fetchPromoCode
} from './ModulesPanel/Modules/BillingPaymentModule/BillingPaymentModuleSagas'
import {
  pceModuleActions,
  checkDuplicatedMeter,
  requestMeterData,
  validateMeterSyntax
} from './ModulesPanel/Modules/PceModule/PceModuleSagas'
import {
  pdlModuleActions,
  requestPdlMeterData,
  validatePdlMeterSyntax,
  checkDuplicatedPdlMeter,
  retrievePdlCacheDataForKey
} from './ModulesPanel/Modules/PdlModule/PdlModuleSagas'
import {
  carEstimatorToolActions,
  carEstimatorRetrieveEstimation
} from './ToolsPanel/Modules/CarEstimationTool/CarEstimatorToolSagas'

export const subscriptionFormActions = {
  ...zohoModuleActions,
  ...customerModuleActions,
  ...characteristicsModuleActions,
  ...propaneModuleActions,
  ...offerModuleActions,
  ...billingModuleActions,
  ...pceModuleActions,
  ...pdlModuleActions,
  ...consumptionModuleActions,
  ...carEstimatorToolActions,
  GENERIC_RETRIEVE_CACHE_DATA: 'GENERIC_RETRIEVE_CACHE_DATA'
}

export function * retrieveCacheDataForKey ({ key, route, subscriptionId, callback }) {
  const { authTokenSession, lang } = yield select(selectAuthData)
  try {
    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })
    const response = yield call(fetch, route + '/' + key + '/' + subscriptionId + '?lang=' + lang, config)
    const responseData = yield call([response, response.json])
    yield call(callback, responseData, response.status)
  } catch (error) {
    console.warn('retrieveCacheDataError', error)
  }
}

export default function * subscriptionFormSagas () {
  yield takeLatest(subscriptionFormActions.ZOHO_MODULE_VERIFY_ID, verifyZohoId)
  yield takeLatest(subscriptionFormActions.ZOHO_MODULE_UPDATE_ID, updateZohoId)
  yield takeLatest(subscriptionFormActions.CUSTOMER_MODULE_VERIFY_EMAIL, verifyEmail)
  yield takeLatest(subscriptionFormActions.CUSTOMER_MODULE_VERIFY_SIRET, verifySiret)
  yield takeLatest(subscriptionFormActions.CUSTOMER_MODULE_REGISTER_TO_NEWSLETTER, registerToNewsletter)
  yield takeLatest(subscriptionFormActions.GENERIC_RETRIEVE_CACHE_DATA, retrieveCacheDataForKey)
  yield takeLatest(subscriptionFormActions.RETRIEVE_PDL_CACHE_DATA, retrievePdlCacheDataForKey)
  yield takeLatest(subscriptionFormActions.ZOHO_MODULE_UNLOCK_MCP_CLIENT, unlockMcpClient)
  yield takeLatest(subscriptionFormActions.SEARCH_ADDRESS, searchAddress)
  yield takeLatest(subscriptionFormActions.CITY_ELIGIBILITY_CHECK, cityEligibilityCheck)
  yield takeLatest(subscriptionFormActions.PROPANE_LOAD_ENERGY_SUPPLIERS, loadPropaneEnergySuppliers)
  yield takeLatest(subscriptionFormActions.OFFER_MODULE_LOAD_PROVIDERS, loadProviders)
  yield takeLatest(subscriptionFormActions.OFFER_MODULE_LOAD_OFFERS, loadOffers)
  yield takeLatest(subscriptionFormActions.OFFER_MODULE_LOAD_PROVIDERS_OPTIONS, loadProviderOptions)
  yield takeLatest(subscriptionFormActions.OFFER_MODULE_LOAD_OFFER_INFORMATION, loadOfferInformation)
  yield takeLatest(subscriptionFormActions.OFFER_MODULE_LOAD_PROVIDER_INFORMATION, loadProviderInformation)
  yield takeLatest(subscriptionFormActions.OFFER_MODULE_LOAD_ENERGY_ORIGIN, loadEnergyOrigin)
  yield takeLatest(subscriptionFormActions.BILLING_MODULE_FETCH_PROMO_CODE, fetchPromoCode)
  yield takeLatest(subscriptionFormActions.METER_MODULE_CHECK_DUPLICATED, checkDuplicatedMeter)
  yield takeLatest(subscriptionFormActions.METER_MODULE_CHECK_DUPLICATED_PDL, checkDuplicatedPdlMeter)
  yield takeLatest(subscriptionFormActions.METER_MODULE_REQUEST_DATA, requestMeterData)
  yield takeLatest(subscriptionFormActions.METER_MODULE_REQUEST_PDL_DATA, requestPdlMeterData)
  yield takeLatest(subscriptionFormActions.METER_MODULE_VALIDATE_METER_SYNTAX, validateMeterSyntax)
  yield takeLatest(subscriptionFormActions.METER_MODULE_VALIDATE_PDL_METER_SYNTAX, validatePdlMeterSyntax)
  yield takeLatest(subscriptionFormActions.CONSUMPTION_MODULE_CALCULATE_PRICE, calculateOfferPrice)
  yield takeLatest(subscriptionFormActions.CAR_ESTIMATOR_TOOL_ESTIMATE, carEstimatorRetrieveEstimation)
  yield takeLatest(subscriptionFormActions.PROPANE_LOAD_QUOTE_REASONS, loadPropaneQuoteReasons)
  yield takeLatest(subscriptionFormActions.PROPANE_LOAD_VISIBILITY, loadPropaneVisibility)
}
