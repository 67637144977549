import { useState } from 'react'
import PropTypes from 'prop-types'
import { StyledBodyCell } from 'baseui/table-grid'
import { useStyletron, withStyle } from 'baseui'
import { Button, KIND, SIZE } from 'baseui/button'
import { useTranslation } from 'react-i18next'
import { Input } from 'baseui/input'
import { useSelector } from 'react-redux'
import { selectAuthData } from '@/containers/Login/AuthSelectors'

const TableRow = ({ id, comment, createdAt, authorName, authorId, striped, updateMethod, deleteMethod }) => {
  const { t } = useTranslation()
  const [css] = useStyletron()
  const [isEditing, setIsEditing] = useState(false)
  const { userId } = useSelector(selectAuthData)
  const [stateComment, setComment] = useState(comment)
  const CenteredBodyCell = withStyle(StyledBodyCell, {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
  })

  return (
    <div role='row' className={css({ display: 'contents' })}>
      <CenteredBodyCell $striped={striped}>{authorName}</CenteredBodyCell>

      {isEditing && <Input value={stateComment} onChange={event => setComment(event.target.value)} />}
      {!isEditing && (
        <CenteredBodyCell $striped={striped}>
          <div>{comment}</div>
        </CenteredBodyCell>
      )}

      <CenteredBodyCell $striped={striped}>{createdAt}</CenteredBodyCell>

      <CenteredBodyCell $striped={striped}>
        {isEditing && userId === authorId && (
          <Button
            kind={KIND.secondary}
            size={SIZE.compact}
            onClick={event => {
              event.preventDefault()
              updateMethod(id, stateComment)
              setIsEditing(false)
            }}
          >
            {t('subscriptionForm:comments_module.update')}
          </Button>
        )}
        {!isEditing && userId === authorId && (
          <>
            <Button
              kind={KIND.secondary}
              size={SIZE.compact}
              onClick={event => {
                event.preventDefault()
                deleteMethod(id)
              }}
            >
              {t('subscriptionForm:comments_module.delete')}
            </Button>
            <Button kind={KIND.secondary} size={SIZE.compact} onClick={() => setIsEditing(true)}>
              {t('subscriptionForm:comments_module.update')}
            </Button>
          </>
        )}
      </CenteredBodyCell>
    </div>
  )
}

TableRow.propTypes = {
  id: PropTypes.number,
  comment: PropTypes.string,
  createdAt: PropTypes.string,
  authorName: PropTypes.string,
  striped: PropTypes.bool,
  updateMethod: PropTypes.func,
  deleteMethod: PropTypes.func,
}

export default TableRow
