import { call, put, select } from 'redux-saga/effects'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import getAPIConfig from '@/shared/tools/getAPIConfig'
import { updateCustomerModuleConfiguration } from '../../../SubscriptionFormConfigurationSlice'

export const customerModuleActions = {
  CUSTOMER_MODULE_VERIFY_EMAIL: 'CUSTOMER_MODULE_VERIFY_EMAIL',
  CUSTOMER_MODULE_VERIFY_SIRET: 'CUSTOMER_MODULE_VERIFY_SIRET',
  CUSTOMER_MODULE_REGISTER_TO_NEWSLETTER: 'CUSTOMER_MODULE_REGISTER_TO_NEWSLETTER',
}

export function* verifyEmail({ email, callback }) {
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { verify_email_url: url } = yield select(selectPageConfigurationData)
  const fullUrl = `${url}?lang=${lang}&value=${encodeURIComponent(email)}`
  yield put(updateCustomerModuleConfiguration({ emailIsLoading: true }))
  try {
    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })
    const response = yield call(fetch, fullUrl, config)
    const result = yield call([response, response.json])
    yield put(updateCustomerModuleConfiguration({ emailIsLoading: false }))
    yield call(callback, result)
  } catch (error) {
    console.warn('verifyEmailError', error)
  }
}

export function* verifySiret({ siret, callback }) {
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { verify_siret_url: url } = yield select(selectPageConfigurationData)
  const fullUrl = `${url}?lang=${lang}&siret=${encodeURIComponent(siret)}`
  yield put(updateCustomerModuleConfiguration({ siretIsLoading: true }))
  try {
    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })
    const response = yield call(fetch, fullUrl, config)
    const result = yield call([response, response.json])
    yield put(updateCustomerModuleConfiguration({ siretIsLoading: false }))
    yield call(callback, result)
  } catch (error) {
    console.warn('verifySiretError', error)
  }
}

export function* registerToNewsletter({ options, callback }) {
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { register_newsletter_url: url } = yield select(selectPageConfigurationData)

  try {
    const config = yield call(getAPIConfig, { method: 'post', authTokenSession, body: options })
    const response = yield call(fetch, url + '?lang=' + lang, config)
    const result = yield call([response, response.json])

    yield call(callback, result, response.status)
  } catch (error) {
    console.warn('registerToNewsletterError', error)
  }
}
