import { useState, Suspense, lazy, useEffect } from 'react'

const SaleElement = ({ vaType, path }) => {
  const [Sale, setSale] = useState('')
  useEffect(() => {
    try {
      const LoadedElement = lazy(() => import(`./${vaType}/${path}/${path}.jsx`))
      console.log('loaded sale element', LoadedElement)
      setSale(LoadedElement)
    } catch (error) {
      console.error(error)
    }
  }, [])

  if (Sale === '') {
    return ''
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Sale />
    </Suspense>
  )
}

export default SaleElement
