import { useState } from 'react'
import { Button, KIND } from 'baseui/button'
import { StyledBodyCell } from 'baseui/table-grid'
import { useStyletron, withStyle } from 'baseui'
import { ChevronDown, ChevronRight, Show } from 'baseui/icon'
import { useTranslation } from 'react-i18next'
import IconCarbonDownload from './IconCarbonDownload'
import { SIZE } from 'baseui/input'
import RowsNestedRequestDetailsTable from './RowsNestedRequestDetailsTable'

const RowsRequestTable = ({
  requestType,
  striped,
  workflowName,
  description,
  requestDate,
  requestData,
  requestUrl,
  index,
  id,
}) => {
  const [css] = useStyletron()
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  const CenteredBodyCell = withStyle(StyledBodyCell, {
    display: 'flex',
    alignItems: 'center',
  })
  const downloadFileName = id + '-' + requestType
  const directionText = () => {
    return requestType === 'SENT'
      ? t('subscriptionForm:subscription_actions_module.export_logs.direction_sent')
      : t('subscriptionForm:subscription_actions_module.export_logs.direction_receive')
  }
  const addZero = number => {
    return number < 10 ? `0${number}` : number
  }
  const formatDate = date => {
    const dateObject = new Date(date)
    return `${addZero(dateObject.getDate())}-${addZero(dateObject.getMonth() + 1)}-${dateObject.getFullYear()} ${dateObject.getHours()}:${dateObject.getMinutes()}:${dateObject.getSeconds()}`
  }
  const downloadJSON = (jsonData, downloadFileName) => {
    const jsonText = JSON.stringify(jsonData, null, 2)
    const blob = new Blob([jsonText], { type: 'text/plain' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = downloadFileName + '.json'
    a.click()
    window.URL.revokeObjectURL(url)
  }

  return (
    <div role='row' className={css({ display: 'contents' })}>
      <CenteredBodyCell $striped={striped}>
        <Button
          size={SIZE.mini}
          kind={striped ? KIND.tertiary : KIND.secondary}
          onClick={() => {
            expanded === index ? setExpanded(false) : setExpanded(index)
          }}
          shape='square'
        >
          {expanded === index ? <ChevronDown size={18} /> : <ChevronRight size={18} />}
        </Button>
        <span className={css({ marginLeft: '10px' })}>{directionText()}</span>
      </CenteredBodyCell>

      <CenteredBodyCell $striped={striped}>{workflowName}</CenteredBodyCell>

      <CenteredBodyCell $striped={striped}>{description}</CenteredBodyCell>

      <CenteredBodyCell $striped={striped}>{formatDate(requestDate)}</CenteredBodyCell>

      <CenteredBodyCell $striped={striped}>
        <Button
          kind={KIND.primary}
          size={SIZE.mini}
          onClick={() => {
            expanded === index ? setExpanded(false) : setExpanded(index)
          }}
          endEnhancer={() => <Show size={16} />}
          overrides={{
            BaseButton: {
              style: () => ({
                marginRight: '5px',
              }),
            },
          }}
          className={css({ marginRight: '5px' })}
        >
          {t('subscriptionForm:subscription_actions_module.export_logs.modal_view_button')}
        </Button>
        <Button
          kind={striped ? KIND.tertiary : KIND.secondary}
          size={SIZE.mini}
          onClick={() => downloadJSON(requestData, downloadFileName)}
          endEnhancer={() => <IconCarbonDownload size={16} />}
        >
          {t('subscriptionForm:subscription_actions_module.export_logs.modal_download_button')}
        </Button>
      </CenteredBodyCell>
      {expanded === index && (
        <RowsNestedRequestDetailsTable
          requestType={requestType}
          requestResponse={requestData}
          requestUrl={requestUrl}
        />
      )}
    </div>
  )
}

export default RowsRequestTable
