import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'

const PageTitle = ({ title, id = '' }) => {
  const [css, theme] = useStyletron()
  const headingStyle = css({
    margin: '0 0 1rem 0',
    color: theme.colors.white,
    ...theme.typography.HeadingMedium,
  })

  return (
    <h1 id={id} className={headingStyle}>
      {title}
    </h1>
  )
}

PageTitle.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
}

export default PageTitle
