export default function getSubscriptionStatusColour(status) {
  switch (status) {
    case 'Données client à renseigner':
    case 'Souscription web a finaliser':
    case 'Données compteur a renseigner':
      return {
        light: '#ffed78',
        dark: '#8b7900',
      }
    case 'BS complet':
    case 'Prêt pour la transmission':
      return {
        light: '#C6EE55',
        dark: '#5B6E27',
      }
    case 'Erreur lors du transfert':
    case 'Signé avec erreur':
      return {
        light: '#ffd07a',
        dark: '#40281f',
      }
    case 'Rétractation par le fournisseur':
    case 'Rétractation par Selectra':
      return {
        light: '#FEE9E9',
        dark: '#DC0909',
      }
    case 'Signé':
    case 'Activé':
      return {
        light: '#F1FFFF',
        dark: '#0C8A1E',
      }
    case 'Transféré':
      return {
        light: '#00bbbb',
        dark: '#003434',
      }
    default:
      return {
        light: '#c1d1e2',
        dark: '#565e66',
      }
  }
}
