import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useStyletron } from 'styletron-react'
import { useDispatch, useSelector } from 'react-redux'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import { dashboardActions } from '../../../DashboardSagas'

const useCommentModal = ({ handler, id }) => {
  const { t } = useTranslation()
  const [css] = useStyletron()
  const dispatch = useDispatch()
  const [comment, setComment] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setLoading] = useState(false)
  const { endpoints } = useSelector(selectPageConfigurationData)

  const handleResponse = success => {
    if (success) {
      handler()
      return
    }
    setLoading(false)
    setError(t('subscriptionTable:save_error'))
  }

  const saveCommentHandler = () => {
    setLoading(true)
    setError('')
    if (comment.length === 0) {
      setError(t('subscriptionTable:mandatory_field'))
      setLoading(false)
      return
    }

    dispatch({
      type: dashboardActions.ADD_COMMENT_SUBSCRIPTION,
      url: endpoints.addCommentUrl,
      comment,
      id,
      callback: handleResponse,
    })
  }

  return {
    t,
    css,
    comment,
    error,
    isLoading,
    saveCommentHandler,
    setComment,
    dateStyle: css({
      display: 'block',
      width: '100%',
      textAlign: 'right',
      fontSize: '.75rem',
      fontWeight: 'bold',
    }),
    commentsStyle: css({
      fontSize: '.75rem',
      fontStyle: 'italic',
    }),
  }
}

export default useCommentModal
