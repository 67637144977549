import PropTypes from 'prop-types'
import { ListItem, ListItemLabel } from 'baseui/list'
import { useStyletron } from 'baseui'
import IconCarbonEmail from '@/shared/icons/IconCarbonEmail'
import IconCarbonPhone from '@/shared/icons/IconCarbonPhone'
import IconCarbonInformation from '@/shared/icons/IconCarbonInformation'
import IconCarbonRule from '@/shared/icons/IconCarbonRule'
import IconCustomZoho from '@/shared/icons/IconCustomZoho'
import { useTranslation } from 'react-i18next'

const ClientInfo = ({ clientData }) => {
  const { t } = useTranslation()
  const [css] = useStyletron()
  const cardStyle = css({
    padding: '1rem',
    marginBottom: '2rem',
    backgroundColor: 'white',
    borderRadius: '1rem',
    boxShadow: '0 2px 6px rgba(20,96,170,.3)',
    width: '100%',
  })
  const newsletterStatus =
    typeof clientData.newsletter_status === 'string' ? clientData.newsletter_status.toLowerCase() : ''
  return (
    <div className={cardStyle}>
      <ul
        className={css({
          width: 'auto',
          paddingLeft: 0,
          paddingRight: 0,
        })}
      >
        <ListItem
          artwork={iconProps => <IconCarbonRule {...iconProps} color='black' />}
          endEnhancer={() => (
            <ListItemLabel>{(clientData.firstname ?? '') + ' ' + (clientData.lastname ?? '')}</ListItemLabel>
          )}
          sublist
        >
          <ListItemLabel>{t('callInformation:client_module.name')}</ListItemLabel>
        </ListItem>
        <ListItem
          artwork={iconProps => <IconCustomZoho {...iconProps} color='black' />}
          endEnhancer={() => <ListItemLabel>{clientData.lead_source ?? ''}</ListItemLabel>}
          sublist
        >
          <ListItemLabel>{t('callInformation:client_module.lead_source')}</ListItemLabel>
        </ListItem>

        <ListItem
          artwork={iconProps => <IconCarbonEmail {...iconProps} color='black' />}
          endEnhancer={() => <ListItemLabel>{clientData.email ?? ''}</ListItemLabel>}
          sublist
        >
          <ListItemLabel>{t('callInformation:client_module.email')}</ListItemLabel>
        </ListItem>

        <ListItem
          artwork={iconProps => <IconCarbonPhone {...iconProps} color='black' />}
          endEnhancer={() => <ListItemLabel>{clientData.phone ?? ''}</ListItemLabel>}
          sublist
        >
          <ListItemLabel>{t('callInformation:client_module.phone')}</ListItemLabel>
        </ListItem>

        <ListItem
          artwork={iconProps => <IconCarbonInformation {...iconProps} color='black' />}
          endEnhancer={() => <ListItemLabel>{newsletterStatus === 'false' ? 'Non' : 'Oui'}</ListItemLabel>}
          sublist
        >
          <ListItemLabel>{t('callInformation:client_module.nlbp_subscription')}</ListItemLabel>
        </ListItem>
      </ul>
    </div>
  )
}

ClientInfo.propTypes = {
  clientData: PropTypes.object,
}

export default ClientInfo
