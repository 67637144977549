import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'

import cloud from '../cloud.svg'
import headset from '../headset.svg'

export default function OriginBadge({ origin }) {
  const [css] = useStyletron()
  const colour = origin.toUpperCase().includes('TV') ? '#f28500' : '#006df2'
  const linkStyle = css({
    display: 'flex',
    padding: '.25rem',
    margin: '0 auto',
    width: '1.5rem',
    height: '1.5rem',
    background: colour,
    borderRadius: '.25rem',
  })
  const iconStyle = css({
    display: 'inline-block',
    height: '1rem',
    width: '1rem',
  })
  const svgStyle = css({
    width: '100%',
  })

  return (
    <div className={linkStyle}>
      <span className={iconStyle}>
        <img className={svgStyle} src={origin.toUpperCase().includes('TV') ? headset : cloud} alt={origin} />
      </span>
    </div>
  )
}

OriginBadge.propTypes = {
  origin: PropTypes.string,
}
