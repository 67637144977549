import PropTypes from 'prop-types'

import { Modal, ModalBody, ROLE, SIZE } from 'baseui/modal'

import { ModalContent, ModalSections, ModalTitle, SDModalOverrides, ModalSubmission } from './styled-components'

const SDModal = ({ isOpen, openHandler, sections, submissionSection, title }) => {
  return (
    <Modal
      autoFocus={false}
      onClose={() => openHandler(false)}
      closeable
      isOpen={isOpen}
      animate
      size={SIZE.default}
      role={ROLE.dialog}
      returnFocus={false}
      overrides={SDModalOverrides()}
    >
      <ModalBody>
        <ModalTitle>{title}</ModalTitle>
        <ModalContent>
          <ModalSections>{sections}</ModalSections>
          {submissionSection && <ModalSubmission>{submissionSection}</ModalSubmission>}
        </ModalContent>
      </ModalBody>
    </Modal>
  )
}

SDModal.propTypes = {
  backgroundImage: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  isOpen: PropTypes.bool.isRequired,
  openHandler: PropTypes.func.isRequired,
  sections: PropTypes.object,
  submissionSection: PropTypes.object,
  title: PropTypes.string,
}

export default SDModal
