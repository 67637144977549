import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconComplete = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? ''
  size = size ?? 60
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 40 40' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <circle cx='20' cy='20' r='20' fill='#0C8A1E' />
      <mask
        id='mask0_1507_42671'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='8'
        y='8'
        width='24'
        height='24'
      >
        <rect x='8' y='8' width='24' height='24' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_1507_42671)'>
        <path
          d='M17.5501 25.9996L11.8501 20.2996L13.2751 18.8746L17.5501 23.1496L26.7251 13.9746L28.1501 15.3996L17.5501 25.9996Z'
          fill='white'
        />
      </g>
    </Icon>
  )
}

IconComplete.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconComplete
