import PropTypes from 'prop-types'
import { Cell, Grid } from 'baseui/layout-grid'
import { FormControl } from 'baseui/form-control'
import { ModalHeader, ModalBody, ModalButton } from 'baseui/modal'
import { StyledTable } from 'baseui/table'
import { Textarea, SIZE } from 'baseui/textarea'
import useCommentModal from './useCommentsModal'
import CommentRow from './CommentRow'

const CommentsModal = ({ comments, id, handler }) => {
  const { t, css, isLoading, error, saveCommentHandler, setComment, comment } = useCommentModal({ handler, id })

  return (
    <>
      <ModalHeader>{t('subscriptionTable:comments')}</ModalHeader>
      <ModalBody>
        <Grid>
          <Cell span={12}>
            <div className={css({ height: '250px' })}>
              <StyledTable role='grid'>
                <CommentRow comments={comments} />
              </StyledTable>
            </div>
          </Cell>
          <Cell span={12}>
            <FormControl label={t('subscriptionTable:add_comment')} error={error}>
              <Textarea
                value={comment}
                onChange={e => setComment(e.target.value)}
                size={SIZE.mini}
                placeholder={t('subscriptionTable:write_comment')}
                clearable
              />
            </FormControl>
            <FormControl>
              <ModalButton
                className={css({ width: '100%' })}
                isLoading={isLoading}
                onClick={saveCommentHandler}
                size={SIZE.mini}
              >
                {t('subscriptionTable:validate_comment')}
              </ModalButton>
            </FormControl>
          </Cell>
        </Grid>
      </ModalBody>
    </>
  )
}

CommentsModal.propTypes = {
  comments: PropTypes.array,
  id: PropTypes.number,
  handler: PropTypes.func,
}

export default CommentsModal
