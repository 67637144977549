import { useState } from 'react'
import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'
import { FormControl } from 'baseui/form-control'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import SDInput from '@/shared/components/SDComponents/SDInput/SDInput'
import { SIZE } from 'baseui/button-group'
import SDButtonGroup from '@/shared/components/SDComponents/SDButtonGroup/SDButtonGroup'
import SDButtonGroupButton from '@/shared/components/SDComponents/SDButtonGroup/SDButtonGroupButton'
import SDSelect from '@/shared/components/SDComponents/SDSelect/SDSelect'
import { useTranslation } from 'react-i18next'
import HeadingAddEdit from '../HeadingAddEdit'
import { defaultValues } from './defaultValues'
import PromoCodes from '@/containers/OrganizationsManagement/OrganizationForm/Energy/PromoCodes/PromoCodes'

const EnergyForm = ({
  providerDispatch,
  providerState,
  saveProvider,
  title
}) => {
  const
    { t } = useTranslation()
  const [error, setError] = useState({
    car_electricity_minimum: '',
    car_gas_main_minimum: '',
    car_gas_secondary_minimum: ''
  })
  const [css, theme] = useStyletron()
  const cardStyle = css({
    padding: '1rem',
    marginBottom: '2rem'
  })
  const blockStyle = css({
    padding: '1rem',
    marginTop: '2rem',
    marginBottom: '2rem',
    backgroundColor: 'white',
    borderRadius: '1rem',
    boxShadow: '0 2px 6px rgba(20,96,170,.3)',
    width: '100%',
    color: theme.colors.colorSecondary
  })
  const sectionStyle = css({
    margin: '0.5rem 5px 5px 5px',
    color: theme.colors.primary
  })
  const nettingOptions = [
    { id: 3, label: t('organizationManagement:services_integration.status_option_transferred') },
    { id: 4, label: t('organizationManagement:services_integration.status_option_signed') },
    { id: 5, label: t('organizationManagement:services_integration.status_option_activated') }
  ]

  const updateProvider = (attributeName, value) => {
    providerDispatch({ type: 'updateProvider', payload: { [attributeName]: value } })
  }
  const yesNoButtonGroup = (keyPrefix, attributeName) => {
    const options = []
    options.push(
      <SDButtonGroupButton
        key={keyPrefix + '_yes'}
        onClick={(event) => {
          event.preventDefault()
          updateProvider(attributeName, 1)
        }}
      >
        {t('common:yes_text')}
      </SDButtonGroupButton>
    )
    options.push(
      <SDButtonGroupButton
        key={keyPrefix + '_no'}
        onClick={(event) => {
          event.preventDefault()
          updateProvider(attributeName, 0)
        }}
      >
        {t('common:no_text')}
      </SDButtonGroupButton>
    )

    return options
  }
  const isYesNoSelected = (currentValue) => {
    if (currentValue === 0 || currentValue === false) {
      return [1]
    }

    if (currentValue === 1 || currentValue === true) {
      return [0]
    }

    return []
  }
  const selectedMESEmergency = () => {
    const options = []
    if (providerState.selectedProvider.mes_emergency_express === 1) {
      options.push(0)
    }
    if (providerState.selectedProvider.mes_emergency_urgent === 1) {
      options.push(1)
    }

    return options
  }
  const selectedIntegrationMode = () => {
    const options = []
    if (providerState.selectedProvider.web_sales === 1) {
      options.push(0)
    }
    if (providerState.selectedProvider.tele_sales === 1) {
      options.push(1)
    }

    return options
  }
  const nettingSelected = () => {
    const selected = []

    for (const [, option] of Object.entries(nettingOptions)) {
      if (option.id === providerState.selectedProvider.zoho_potential_net_status) {
        selected.push(option)
      }
    }

    return selected
  }

  return (
    <div className={cardStyle}>
      <HeadingAddEdit
        title={title}
        saveHandler={saveProvider}
      />
      <FlexGrid
        flexGridColumnCount={3}
        flexGridColumnGap='scale800'
        flexGridRowGap='scale800'
      >
        <FlexGridItem>
          <div className={blockStyle}>
            <h2 className={sectionStyle}>{t('organizationManagement:general_info.title')}</h2>
            <FormControl label={t('organizationManagement:name')}>
              <SDInput
                value={providerState.selectedProvider.name ?? ''}
                onChange={(event) => {
                  event.preventDefault()
                  updateProvider('name', event.target.value)
                }}
              />
            </FormControl>
            <FormControl label={t('organizationManagement:general_info.zoho_prefix')}>
              <SDInput
                value={providerState.selectedProvider.zoho_prefix ?? ''}
                onChange={(event) => {
                  event.preventDefault()
                  updateProvider('zoho_prefix', event.target.value)
                }}
              />
            </FormControl>
            <FormControl label={t('organizationManagement:general_info.sale_id_mandatory')}>
              <SDButtonGroup
                size={SIZE.compact}
                selected={isYesNoSelected(providerState.selectedProvider.sale_id_mandatory)}
              >
                {yesNoButtonGroup('sale_id_mandatory', 'sale_id_mandatory')}
              </SDButtonGroup>
            </FormControl>
            <FormControl label={t('organizationManagement:general_info.debordement_call_status')}>
              <SDButtonGroup
                size={SIZE.compact}
                selected={isYesNoSelected(providerState.selectedProvider.debordement_call_status)}
              >
                {yesNoButtonGroup('debordement_call', 'debordement_call_status')}
              </SDButtonGroup>
            </FormControl>
            {providerState.selectedProvider.debordement_call_status === 1 &&
              <FormControl label={t('organizationManagement:general_info.debordement_call_lead_source')}>
                <SDInput
                  value={providerState.selectedProvider.debordement_call_lead_source ?? ''}
                  onChange={(event) => {
                    event.preventDefault()
                    updateProvider('debordement_call_lead_source', event.target.value)
                  }}
                />
              </FormControl>}
            <FormControl label={t('organizationManagement:general_info.allow_consumption_historic_on_mes')}>
              <SDButtonGroup
                size={SIZE.compact}
                selected={isYesNoSelected(providerState.selectedProvider.allow_consumption_historic_on_mes)}
              >
                {yesNoButtonGroup('historical_consumption_in_mes', 'allow_consumption_historic_on_mes')}
              </SDButtonGroup>
            </FormControl>
            <FormControl label={t('organizationManagement:general_info.allow_cdf_on_mes')}>
              <SDButtonGroup
                size={SIZE.compact}
                selected={isYesNoSelected(providerState.selectedProvider.allow_cdf_on_mes)}
              >
                {yesNoButtonGroup('cdf_delayed', 'allow_cdf_on_mes')}
              </SDButtonGroup>
            </FormControl>
            <FormControl label={t('organizationManagement:general_info.car_range_rule_active')}>
              <SDButtonGroup
                size={SIZE.compact}
                selected={isYesNoSelected(providerState.selectedProvider.car_range_rule_active)}
              >
                {yesNoButtonGroup('active_car_limitation', 'car_range_rule_active')}
              </SDButtonGroup>
            </FormControl>
            <FormControl label={t('organizationManagement:general_info.disclaimer_commitment_pro')}>
              <SDButtonGroup
                size={SIZE.compact}
                selected={isYesNoSelected(providerState.selectedProvider.allow_disclaimer_commitment_pro)}
              >
                {yesNoButtonGroup('pro_active_cdf_engaged', 'allow_disclaimer_commitment_pro')}
              </SDButtonGroup>
            </FormControl>
            <FormControl label={t('organizationManagement:general_info.change_meter_option')}>
              <SDButtonGroup
                size={SIZE.compact}
                selected={isYesNoSelected(providerState.selectedProvider.can_change_meter_option)}
              >
                {yesNoButtonGroup('active_tariff_option_change', 'can_change_meter_option')}
              </SDButtonGroup>
            </FormControl>
            <FormControl label={t('organizationManagement:general_info.change_meter_power')}>
              <SDButtonGroup
                size={SIZE.compact}
                selected={isYesNoSelected(providerState.selectedProvider.can_change_meter_power)}
              >
                {yesNoButtonGroup('active_power_change', 'can_change_meter_power')}
              </SDButtonGroup>
            </FormControl>
            <FormControl label={t('organizationManagement:general_info.real_payment_car_mandatory')}>
              <SDButtonGroup
                size={SIZE.compact}
                selected={isYesNoSelected(providerState.selectedProvider.real_payment_car_mandatory)}
              >
                {yesNoButtonGroup('mandatory_car_in_real_payment', 'real_payment_car_mandatory')}
              </SDButtonGroup>
            </FormControl>
            <FormControl label={t('organizationManagement:general_info.mes_emergency')}>
              <SDButtonGroup selected={selectedMESEmergency()}>
                <SDButtonGroupButton
                  key='express'
                  onClick={(event) => {
                    event.preventDefault()
                    updateProvider('mes_emergency_express', providerState.selectedProvider.mes_emergency_express === 1 ? 0 : 1)
                  }}
                >
                  {t('organizationManagement:general_info.option_mes_emergency_express')}
                </SDButtonGroupButton>
                <SDButtonGroupButton
                  key='urgent'
                  onClick={(event) => {
                    event.preventDefault()
                    updateProvider('mes_emergency_urgent', providerState.selectedProvider.mes_emergency_urgent === 1 ? 0 : 1)
                  }}
                >
                  {t('organizationManagement:general_info.option_mes_emergency_urgent')}
                </SDButtonGroupButton>
              </SDButtonGroup>
            </FormControl>
            <FormControl label={t('organizationManagement:general_info.index_mandatory_non_communicant_meters')}>
              <SDButtonGroup
                size={SIZE.compact}
                selected={isYesNoSelected(providerState.selectedProvider.energy_index_mandatory_non_communicant_meters)}
              >
                {yesNoButtonGroup('energy_index_mandatory_non_communicant_meters', 'energy_index_mandatory_non_communicant_meters')}
              </SDButtonGroup>
            </FormControl>
            <FormControl label={t('organizationManagement:general_info.energy_car_limit_mes_status')}>
              <SDButtonGroup
                size={SIZE.compact}
                selected={isYesNoSelected(providerState.selectedProvider.energy_car_limit_mes_status)}
              >
                {yesNoButtonGroup('energy_car_limit_mes_status', 'energy_car_limit_mes_status')}
              </SDButtonGroup>
            </FormControl>
            {providerState.selectedProvider.energy_car_limit_mes_status === 1 &&
              <FormControl
                label={t('organizationManagement:general_info.energy_car_limit_mes_percent')}
                caption={t('organizationManagement:general_info.energy_car_limit_mes_percent_caption')}
              >
                <SDInput
                  value={providerState.selectedProvider.energy_car_limit_mes_percent ?? '50'}
                  onChange={(event) => {
                    event.preventDefault()
                    updateProvider('energy_car_limit_mes_percent', event.target.value)
                  }}
                />
              </FormControl>}

            <FormControl
              label={t('organizationManagement:general_info.car_electricity_minimum')}
              caption={t('organizationManagement:general_info.car_electricity_minimum_caption').replace('_MIN_', defaultValues.carElectricty)}
              error={error.car_electricity_minimum}
            >
              <SDInput
                value={providerState.selectedProvider.car_electricity_minimum ?? ''}
                type='number'
                min={1}
                onChange={(event) => {
                  event.preventDefault()
                  if (event.target.value === '' || event.target.value > 0) {
                    setError({ ...error, car_electricity_minimum: '' })
                    updateProvider('car_electricity_minimum', event.target.value)
                  } else {
                    setError({ ...error, car_electricity_minimum: t('organizationManagement:general_info.car_minimum_error') })
                  }
                }}
              />
            </FormControl>
            <FormControl
              label={t('organizationManagement:general_info.car_gas_main_minimum')}
              caption={t('organizationManagement:general_info.car_gas_main_minimum_caption').replace('_MIN_', defaultValues.carGasMain)}
              error={error.car_gas_main_minimum}
            >
              <SDInput
                value={providerState.selectedProvider.car_gas_main_minimum ?? ''}
                type='number'
                min={1}
                onChange={(event) => {
                  event.preventDefault()
                  if (event.target.value === '' || event.target.value > 0) {
                    setError({ ...error, car_gas_main_minimum: '' })
                    updateProvider('car_gas_main_minimum', event.target.value)
                  } else {
                    setError({ ...error, car_gas_main_minimum: t('organizationManagement:general_info.car_minimum_error') })
                  }
                }}
              />
            </FormControl>
            <FormControl
              label={t('organizationManagement:general_info.car_gas_secondary_minimum')}
              caption={t('organizationManagement:general_info.car_gas_secondary_minimum_caption').replace('_MIN_', defaultValues.carGasSecondary)}
              error={error.car_gas_secondary_minimum}
            >
              <SDInput
                value={providerState.selectedProvider.car_gas_secondary_minimum ?? ''}
                min={1}
                type='number'
                onChange={(event) => {
                  event.preventDefault()
                  if (event.target.value === '' || event.target.value > 0) {
                    setError({ ...error, car_gas_secondary_minimum: '' })
                    updateProvider('car_gas_secondary_minimum', event.target.value)
                  } else {
                    setError({ ...error, car_gas_secondary_minimum: t('organizationManagement:general_info.car_minimum_error') })
                  }
                }}
              />
            </FormControl>
          </div>
        </FlexGridItem>

        <FlexGridItem>
          <div className={blockStyle}>
            <h2 className={sectionStyle}>{t('organizationManagement:finance_section.title')}</h2>
            <FormControl
              label={t('organizationManagement:finance_section.iban_banned_countries_label')}
              caption={t('organizationManagement:finance_section.iban_banned_countries_caption')}
            >
              <SDInput
                value={providerState.selectedProvider.iban_banned_countries ?? ''}
                onChange={(event) => {
                  event.preventDefault()
                  updateProvider('iban_banned_countries', event.target.value)
                }}
              />
            </FormControl>
            <FormControl
              label={t('organizationManagement:finance_section.banned_bic_label')}
              caption={t('organizationManagement:finance_section.banned_bic_caption')}
            >
              <SDInput
                value={providerState.selectedProvider.banned_bic ?? ''}
                onChange={(event) => {
                  event.preventDefault()
                  updateProvider('banned_bic', event.target.value)
                }}
              />
            </FormControl>
            <FormControl label={t('organizationManagement:finance_section.banned_bic_message')}>
              <SDInput
                value={providerState.selectedProvider.banned_bic_message ?? ''}
                onChange={(event) => {
                  event.preventDefault()
                  updateProvider('banned_bic_message', event.target.value)
                }}
              />
            </FormControl>
            <FormControl label={t('organizationManagement:finance_section.bic_mandatory')}>
              <SDButtonGroup
                size={SIZE.compact}
                selected={isYesNoSelected(providerState.selectedProvider.bic_mandatory)}
              >
                {yesNoButtonGroup('mandatory_bic', 'bic_mandatory')}
              </SDButtonGroup>
            </FormControl>
            <FormControl label={t('organizationManagement:finance_section.monthly_amount_service')}>
              <SDButtonGroup
                size={SIZE.compact}
                selected={isYesNoSelected(providerState.selectedProvider.monthly_amount_service)}
              >
                {yesNoButtonGroup('monthly_amount_service', 'monthly_amount_service')}
              </SDButtonGroup>
            </FormControl>
            <FormControl label={t('organizationManagement:finance_section.allow_different_cardholder')}>
              <SDButtonGroup
                size={SIZE.compact}
                selected={isYesNoSelected(providerState.selectedProvider.allow_different_cardholder)}
              >
                {yesNoButtonGroup('allow_different_cardholder', 'allow_different_cardholder')}
              </SDButtonGroup>
            </FormControl>
            <FormControl label={t('organizationManagement:finance_section.saving_accounts_allowed')}>
              <SDButtonGroup
                size={SIZE.compact}
                selected={isYesNoSelected(providerState.selectedProvider.saving_accounts_allowed)}
              >
                {yesNoButtonGroup('saving_accounts_allowed', 'saving_accounts_allowed')}
              </SDButtonGroup>
            </FormControl>
          </div>
        </FlexGridItem>

        <FlexGridItem>
          <div className={blockStyle}>
            <h2 className={sectionStyle}>{t('organizationManagement:services_integration.title')}</h2>
            <FormControl label={t('organizationManagement:services_integration.subscription_integration_mode')}>
              <SDButtonGroup selected={selectedIntegrationMode()}>
                <SDButtonGroupButton
                  key='web_sales'
                  onClick={(event) => {
                    event.preventDefault()
                    updateProvider('web_sales', providerState.selectedProvider.web_sales === 1 ? 0 : 1)
                  }}
                >
                  {t('organizationManagement:services_integration.option_integration_web_sales')}
                </SDButtonGroupButton>
                <SDButtonGroupButton
                  key='tele_sales'
                  onClick={(event) => {
                    event.preventDefault()
                    updateProvider('tele_sales', providerState.selectedProvider.tele_sales === 1 ? 0 : 1)
                  }}
                >
                  {t('organizationManagement:services_integration.option_integration_tele_sales')}
                </SDButtonGroupButton>
              </SDButtonGroup>
            </FormControl>

            <FormControl label={t('organizationManagement:services_integration.zoho_potential_net_status')}>
              <SDSelect
                options={nettingOptions}
                value={nettingSelected()}
                onChange={params => updateProvider('zoho_potential_net_status', params.value[0]?.id ?? '')}
              />
            </FormControl>

            <FormControl label={t('organizationManagement:services_integration.resending_status')}>
              <SDInput
                value={providerState.selectedProvider.resending_status ?? ''}
                onChange={(event) => {
                  event.preventDefault()
                  updateProvider('resending_status', event.target.value)
                }}
              />
            </FormControl>

            <FormControl label={t('organizationManagement:services_integration.api_integration')}>
              <SDButtonGroup
                size={SIZE.compact}
                selected={isYesNoSelected(providerState.selectedProvider.apiIntegration)}
              >
                {yesNoButtonGroup('api_integration', 'apiIntegration')}
              </SDButtonGroup>
            </FormControl>
            {providerState.selectedProvider.apiIntegration === 1 &&
              <FormControl label={t('organizationManagement:services_integration.api_workflow')}>
                <SDInput
                  value={providerState.selectedProvider.api_workflow ?? ''}
                  onChange={(event) => {
                    event.preventDefault()
                    updateProvider('api_workflow', event.target.value)
                  }}
                />
              </FormControl>}
          </div>
        </FlexGridItem>

        <FlexGridItem>
          <div className={blockStyle}>
            <h2 className={sectionStyle}>{t('organizationManagement:promo_section.title')}</h2>
            <PromoCodes providerState={providerState} />
          </div>

        </FlexGridItem>

      </FlexGrid>
    </div>
  )
}

EnergyForm.propTypes = {
  providerState: PropTypes.object,
  providerDispatch:
  PropTypes.func,
  saveProvider: PropTypes.func,
  title: PropTypes.string
}

export default EnergyForm
