import FieldsSwitch from '../Fields/FieldsSwitch'

const Financial = ({ t, errors, providerState, updateOffer }) => {
  const fields = providerState.viewParameters.attributes?.general ?? {}
  const optionsFields = providerState.viewParameters.attributes?.options ?? {}

  return (
    <>
      <FieldsSwitch group='financial' fields={fields} t={t} updateOffer={updateOffer} providerState={providerState} />
      <FieldsSwitch
        group='financial'
        fields={optionsFields}
        t={t}
        updateOffer={updateOffer}
        providerState={providerState}
      />
    </>
  )
}

export default Financial
