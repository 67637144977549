import PropTypes from 'prop-types'
import { Editor } from '@tinymce/tinymce-react'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { useTranslation } from 'react-i18next'
import useWelcomeMessage from './useWelcomeMessage'
import SDNotification from '@/shared/components/SDComponents/SDNotification'

const WelcomeMessage = ({ data, setData }) => {
  const {
    editorRef,
    isEditingWelcomeText,
    setIsEditingWelcomeText,
    saveWelcomeMessage,
    isLoading,
    errorMessage,
    error
  } = useWelcomeMessage(
    data,
    setData,
  )
  const { t } = useTranslation()

  if (!isEditingWelcomeText) {
    return (
      <>
        <div dangerouslySetInnerHTML={{ __html: data.welcomeMessage }} />
        {data.isAdmin && <SDButton onClick={() => setIsEditingWelcomeText(true)}>{t('common:edit')}</SDButton>}
        {
          error === false &&
          <SDNotification type={'positive'}>
            {t('home:welcome_message.save_ok')}
          </SDNotification>
        }
      </>
    )
  }

  return (
    <>
      <Editor
        apiKey={data.tinymceKey}
        initialValue={data.welcomeMessage}
        onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          height: 250,
        }}
      />
      <SDButton onClick={() => setIsEditingWelcomeText(false)}>{t('common:cancel')}</SDButton>
      <SDButton onClick={saveWelcomeMessage} isLoading={isLoading}>
        {t('common:save')}
      </SDButton>
      {
        error && errorMessage &&
        <SDNotification type={'warning'}>
          {t('home:welcome_message.save_ko')}
          {errorMessage}
        </SDNotification>
      }

    </>
  )
}

WelcomeMessage.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
}

export default WelcomeMessage
