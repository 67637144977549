import PropTypes from 'prop-types'
import { SIZE } from 'baseui/button'
import { Cell, Grid } from 'baseui/layout-grid'
import { FormControl } from 'baseui/form-control'
import { ModalHeader, ModalBody, ModalButton } from 'baseui/modal'
import { StyledTable } from 'baseui/table'
import useCallLogModal from './useCallLogModal'
import CallRows from './CallRows'

const CallLogModal = ({ calls, handler, id }) => {
  const { t, css, isLoading, error, addCallHandler } = useCallLogModal({ handler, id })

  return (
    <>
      <ModalHeader>{t('subscriptionTable:call_log')}</ModalHeader>
      <ModalBody>
        <Grid>
          <Cell span={12}>
            <FormControl>
              <div className={css({ height: '250px' })}>
                <StyledTable role='grid'>
                  <CallRows calls={calls} />
                </StyledTable>
              </div>
            </FormControl>
          </Cell>
          <Cell span={12}>
            <FormControl error={error}>
              <ModalButton
                className={css({ width: '100%' })}
                isLoading={isLoading}
                onClick={addCallHandler}
                size={SIZE.mini}
              >
                {t('subscriptionTable:add_call')}
              </ModalButton>
            </FormControl>
          </Cell>
        </Grid>
      </ModalBody>
    </>
  )
}

CallLogModal.propTypes = {
  calls: PropTypes.array,
  handler: PropTypes.func,
  id: PropTypes.string,
}

export default CallLogModal
