const createGoal = async (objectiveId, goals, token) => {
  const formData = createGoalFormData(goals)
  fetch(`/admin/api/goals/objectives/${objectiveId}/goals`, {
    method: 'POST',
    body: JSON.stringify(formData),
    headers: {
      'X-CSRF-TOKEN': token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
}
const deleteGoal = async (goalId, token) => {
  return await fetch(`/admin/api/goals/goals/${goalId}`, {
    method: 'DELETE',
    headers: {
      'X-CSRF-TOKEN': token,
    },
  })
}
const fetchAgentGoals = async () => {
  const response = await fetch('/admin/api/goals/goals')
  if (response.status === 200) {
    return await response.json()
  }
  return []
}
const createGoalFormData = goals => {
  const goalsToSend = []
  goals.forEach(goal => {
    goalsToSend.push({
      userId: goal.userId,
      productId: goal.productId,
      productType: goal.productType,
      objective: goal.objective,
    })
  })
  return { goals: goalsToSend }
}
const fetchObjectiveGoals = async objectiveId => {
  const url = `/admin/api/goals/objectives/${objectiveId}/goals`
  const response = await fetch(url)
  const goals = await response.json()

  return goals
}

export { createGoal, deleteGoal, fetchAgentGoals, fetchObjectiveGoals }
