export const snackBarOverrides = () => ({
  Root: {
    style: ({ $theme }) => {
      return {
        backgroundColor: 'none',

        borderTopLeftRadius: $theme.borders.radius400,
        borderTopRightRadius: $theme.borders.radius400,
        borderBottomRightRadius: $theme.borders.radius400,
        borderBottomLeftRadius: $theme.borders.radius400,

        boxShadow: $theme.lighting.shadow700,
      }
    },
  },
  Content: {
    style: ({ $theme }) => ({
      paddingTop: '1rem',
      paddingRight: '1rem',
      paddingBottom: '1rem',
      paddingLeft: '1rem',

      backgroundColor: $theme.colors.positive150,

      borderTopLeftRadius: $theme.borders.radius400,
      borderTopRightRadius: $theme.borders.radius400,
      borderBottomRightRadius: $theme.borders.radius400,
      borderBottomLeftRadius: $theme.borders.radius400,
      border: `2px solid ${$theme.colors.positive}`,

      color: $theme.colors.positive,
    }),
  },
  StartEnhancerContainer: {
    style: () => ({
      paddingLeft: '0',
      marginRight: '.5rem',
    }),
  },
  Message: {
    style: ({ $theme }) => ({
      marginTop: '0',
      marginBottom: '0',
      paddingRight: '0',
      paddingLeft: '0',

      ...$theme.typography.ParagraphSmall,
    }),
  },
})
