import { useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from '@/containers/SubscriptionFormPage/ModulesPanel/ModulesPanel.module.css'
import { SnackbarProvider, useSnackbar } from 'baseui/snackbar'
import IconCarbonCheckmarkOutline from '@/shared/icons/IconCarbonCheckmarkOutline'
import { snackBarOverrides } from './styled-component'
import { useStyletron } from 'baseui'
import { useTranslation } from 'react-i18next'

const ConfirmationModule = showSaveNotification => {
  const [, theme] = useStyletron()
  const { t } = useTranslation()
  const getSuccessfulSaveText = () => {
    return <p>{t('objectives:save_confirmation')}</p>
  }
  const Snack = () => {
    const { enqueue } = useSnackbar()
    useEffect(() => {
      if (showSaveNotification.showSaveNotification === true) {
        enqueue({
          message: getSuccessfulSaveText(),
          startEnhancer: () => {
            return <IconCarbonCheckmarkOutline color={theme.colors.positive} />
          },
        })
      }
    }, [showSaveNotification])
    useEffect(() => {
      if (showSaveNotification.showSaveNotification === false) {
        enqueue({
          message: <p>{t('objectives:save_error')}</p>,
          startEnhancer: () => {
            return <IconCarbonCheckmarkOutline color={theme.colors.negative} />
          },
        })
      }
    }, [])

    return ''
  }

  return (
    <div className={styles.ConfirmationModule}>
      <SnackbarProvider overrides={snackBarOverrides()}>
        <Snack />
      </SnackbarProvider>
    </div>
  )
}

ConfirmationModule.propTypes = {
  showSaveNotification: PropTypes.bool,
}

export default ConfirmationModule
