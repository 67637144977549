import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'
import { StyledHeader, StyledTitle } from '@/shared/components/Panel/styled-components'

const SDCard = ({ title, content, bgcolor }) => {
  const [css, theme] = useStyletron()
  const cardStyle = css({
    backgroundColor: bgcolor ?? 'white',
    color: theme.colors.accent700,
    padding: '1rem',
    margin: '1rem',
    borderRadius: '1rem',
    boxShadow: theme.lighting.shadow400,
  })
  return (
    <div className={cardStyle}>
      <StyledHeader>
        <StyledTitle>{title}</StyledTitle>
      </StyledHeader>
      {content}
    </div>
  )
}
SDCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  bgcolor: PropTypes.string,
}

export default SDCard
