import { SIZE, MODE } from 'baseui/button-group'
import SDButtonGroup from '@/shared/components/SDComponents/SDButtonGroup/SDButtonGroup'
import SDButtonGroupButton from '@/shared/components/SDComponents/SDButtonGroup/SDButtonGroupButton'
import { Cell, Grid } from 'baseui/layout-grid'
import { useState } from 'react'

const OfferTableHeading = ({ headingStyle, t, onClick }) => {
  const [selected, setSelected] = useState(0)

  return (
    <>
      <Grid>
        <Cell span={9}>
          <h2 className={headingStyle}>{t('organizationManagement:offers_list')}</h2>
        </Cell>
        <Cell span={3}>
          <SDButtonGroup
            mode={MODE.radio}
            size={SIZE.mini}
            onClick={(event, index) => {
              setSelected(index)
              onClick(index)
            }}
            selected={selected}
          >
            {[t('organizationManagement:table.filter_recent'), t('organizationManagement:table.filter_all')].map(
              (value, index) => {
                return <SDButtonGroupButton key={index}>{value}</SDButtonGroupButton>
              },
            )}
          </SDButtonGroup>
        </Cell>
      </Grid>
      <br />
    </>
  )
}

export default OfferTableHeading
