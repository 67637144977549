import PropTypes from 'prop-types'
import ToolsPanel from '@/containers/SubscriptionFormPage/ToolsPanel/ToolsPanel'
import ModulesPanel from '@/containers/SubscriptionFormPage/ModulesPanel/ModulesPanel'
import FormHeader from '@/shared/components/FormHeader/FormHeader'
import { StyledForm } from './styled-components'
import useSubscriptionForm from './useSubscriptionForm'

const SubscriptionForm = ({ id, loadViewParametersRoute, country, saveRoute, applicationType }) => {
  useSubscriptionForm({ applicationType, initUrl: loadViewParametersRoute, id, saveRoute })

  return (
    <StyledForm>
      <FormHeader applicationType={applicationType} />
      <ModulesPanel country={country} applicationType={applicationType} />
      <ToolsPanel country={country} applicationType={applicationType} />
    </StyledForm>
  )
}

SubscriptionForm.propTypes = {
  id: PropTypes.string,
  saveRoute: PropTypes.string,
  token: PropTypes.string,
  country: PropTypes.string,
  loadViewParametersRoute: PropTypes.string,
  applicationType: PropTypes.string,
}

export default SubscriptionForm
