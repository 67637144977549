import { forwardRef } from 'react'
import { useStyletron } from 'baseui'
import { Tag, VARIANT as TAG_VARIANT } from 'baseui/tag'
import { StyledInput } from 'baseui/input'

const InputReplacement = forwardRef(({ tags, removeTag, ...restProps }, ref) => {
  const [css] = useStyletron()
  return (
    <div
      className={css({
        flex: '1 1 0%',
        flexWrap: 'wrap',
        display: 'flex',
        alignItems: 'center',
      })}
    >
      {tags.map((tag, index) => (
        <Tag variant={TAG_VARIANT.solid} onActionClick={() => removeTag(tag)} key={index}>
          {tag}
        </Tag>
      ))}
      <StyledInput ref={ref} {...restProps} />
    </div>
  )
})

InputReplacement.displayName = 'InputReplacement'

export default InputReplacement
