import { useContext, useEffect } from 'react'
import { useStyletron } from 'baseui'
import Heading from './Heading'
import ProviderManagementContext from '../ProviderManagementContext'

const Index = () => {
  const providerContext = useContext(ProviderManagementContext)
  const providerDispatch = providerContext.providerDispatch
  const [css] = useStyletron()
  const cardStyle = css({
    padding: '1rem',
    marginBottom: '2rem',
    color: 'white',
  })

  useEffect(() => {
    providerDispatch({ type: 'reset' })
  }, [])

  return (
    <div className={cardStyle}>
      <Heading />
    </div>
  )
}

export default Index
