import { SIZE } from 'baseui/button'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { Table } from 'baseui/table-semantic'
import { Modal, ModalHeader, ModalBody } from 'baseui/modal'
import { TYPE } from 'baseui/select'

import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import SDPaginator from '@/shared/components/SDComponents/SDPaginator/SDPaginator'
import SDInput from '@/shared/components/SDComponents/SDInput/SDInput'
import AddEditModal from './AddEditModal'
import Title from '@/shared/components/PageTitle'
import useUsersManagement from './useUsersManagement'
import { useSelector } from 'react-redux'
import AddEditModalNonSelectra from './AddEditModal/AddEditModalNonSelectra'
import { selectAuthData } from '@/containers/Login/AuthSelectors'

const UsersManagement = () => {
  const { isSelectraAdmin } = useSelector(selectAuthData)

  const {
    users,
    setSearchValue,
    setPage,
    pageConfigurationIsLoading,
    t,
    isOpen,
    close,
    updateUser,
    changeUserStatus,
    selectedUser,
    setIsOpen,
    usersError,
  } = useUsersManagement()

  const flexGridItemProps = {
    display: 'flex',
    alignItems: 'center',
  }

  const COLUMNS = [
    t('usersManagement:table.organization'),
    t('usersManagement:table.name'),
    t('usersManagement:table.email'),
    t('usersManagement:table.user_type'),
    t('usersManagement:table.zoho_id'),
    t('usersManagement:table.hangout'),
    '',
    '',
    '',
  ]

  const transformUser = users => {
    if (!users) {
      return []
    }
    return users.map(user => {
      return [
        user.organisation?.name ?? '',
        user.firstname + ' ' + user.name,
        user.email,
        user.role === 'user' ? t('usersManagement:role_user') : t('usersManagement:role_admin'),
        user.zoho_id,
        user.hangouts_space_name ?? t('usersManagement:not_defined'),
        <SDButton
          id={'user-' + user.id + '-edit-btn'}
          key={user.id}
          size={SIZE.compact}
          onClick={() => updateUser(user)}
        >
          {t('common:edit')}
        </SDButton>,
        <SDButton key={user.id} size={SIZE.compact} onClick={() => changeUserStatus('active', user.id, user.is_active)}>
          {user.is_active === 1 ? t('common:deactivate') : t('usersManagement:activate')}
        </SDButton>,
        <SDButton
          key={user.id}
          size={SIZE.compact}
          onClick={() => {
            if (window.confirm(t('usersManagement:alert'))) {
              changeUserStatus('delete', user.id, user.is_active)
            }
          }}
        >
          {t('common:delete')}
        </SDButton>,
      ]
    })
  }

  if (pageConfigurationIsLoading) {
    return <p>{t('common:fetching_data')}</p>
  }

  if (usersError.error) {
    return <Title title={usersError.error ?? 'Une erreur est survenue'} />
  }

  return (
    <>
      <Title title={t('usersManagement:title')} id='users-title' />
      {users.total && (
        <h2>
          {users.total} {t('usersManagement:users_found')}
        </h2>
      )}
      <FlexGrid flexGridColumnCount={2} marginBottom='.5rem'>
        <FlexGridItem {...flexGridItemProps}>
          <SDButton key={0} block={false} size={SIZE.compact} onClick={() => updateUser(null)}>
            {t('usersManagement:create_button')}
          </SDButton>
        </FlexGridItem>
        <FlexGridItem {...flexGridItemProps}>
          <SDInput
            id='userSearch'
            type={TYPE.search}
            placeholder={t('usersManagement:search_user')}
            onBlur={event => {
              setSearchValue(event.target.value)
            }}
          />
        </FlexGridItem>
      </FlexGrid>
      <Table columns={COLUMNS} data={transformUser(users.data)} />
      {users.data && (
        <SDPaginator
          numPages={users.lastPage ?? 1}
          currentPage={users.currentPage ?? 1}
          onPageChange={({ nextPage }) => {
            if (parseInt(nextPage) <= parseInt(users.lastPage)) {
              setPage(nextPage)
            }
          }}
        />
      )}
      <Modal onClose={close} isOpen={isOpen}>
        <ModalHeader>
          {selectedUser.id === 0 ? t('usersManagement:modal.header_create') : t('usersManagement:modal.header_update')}
        </ModalHeader>
        <ModalBody>
          {isSelectraAdmin ? <AddEditModal setIsOpen={setIsOpen} /> : <AddEditModalNonSelectra setIsOpen={setIsOpen} />}
        </ModalBody>
      </Modal>
    </>
  )
}

export default UsersManagement
