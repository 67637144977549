export const mapCommonSubscriptionData = viewParameters => {
  const currentContract = viewParameters?.current_subscriber ?? {}

  return {
    contractLoaded: true,
    customer: retrieveCustomerData(currentContract),
    billing: retrieveBillingData(currentContract),
    mainAddress: retrieveMainAddressData(viewParameters, currentContract),
    invoiceAddress: retrieveInvoiceAddressData(viewParameters),
  }
}

export const retrieveInvoiceAddressData = viewParameters => {
  const invoicingAddress = viewParameters.invoicing_address ?? {}

  if (Object.keys(invoicingAddress).length === 0) {
    return {
      showCheckbox: false,
      address: '',
      streetNumber: '',
      city: '',
      inseeCode: '',
      country: '',
      zipcode: '',
      id: '',
      residenceType: '',
      housingType: '',
      tenantType: '',
      floor: '',
      appartmentNumber: '',
      additionalInformation: '',
    }
  }

  return {
    showCheckbox: true,
    address: invoicingAddress.street_name,
    streetNumber: invoicingAddress.street_number,
    city: invoicingAddress.city,
    inseeCode: invoicingAddress.city_id,
    country: invoicingAddress.country,
    zipcode: invoicingAddress.zipcode,
    id: invoicingAddress.city_id,
    residenceType: invoicingAddress.residence_type ?? '',
    housingType: invoicingAddress.housing_type ?? '',
    tenantType: invoicingAddress.tenant_type ?? '',
    floor: invoicingAddress.floor ?? '',
    appartmentNumber: invoicingAddress.appartment_number ?? '',
    additionalInformation: invoicingAddress.additional_information ?? '',
  }
}

export const retrieveMainAddressData = (viewParameters, currentContract) => {
  const mainAddress = viewParameters.main_address ?? {}
  if (Object.keys(mainAddress).length === 0) {
    return {
      address: '',
      streetNumber: '',
      label: '',
      city: '',
      inseeCode: '',
      id: '',
      zipcode: '',
      housingType: '',
      household: '',
      occupancy: '',
      floor: '',
      tenantType: '',
      residenceType: '',
      appartmentNumber: '',
      additionalInformation: '',
      movingStartDate: '',
      providedByGas: '',
    }
  }

  const streetNumber = mainAddress.street_number ? mainAddress.street_number + ' ' : ''
  const streetName = mainAddress.street_name ?? ''
  const zipcode = mainAddress.zipcode ?? ''
  const city = mainAddress.city ?? ''

  return {
    address: mainAddress.street_name,
    streetNumber,
    label: streetNumber + streetName + ' ' + zipcode + ' ' + city,
    city: mainAddress.city,
    inseeCode: currentContract.city_id,
    id: currentContract.city_id,
    zipcode: mainAddress.zipcode,
    floor: mainAddress.floor ?? '',
    appartmentNumber: mainAddress.appartment_number ?? '',
    residenceType: mainAddress.residence_type ?? '',
    housingType: mainAddress.housing_type ?? '',
    tenantType: mainAddress.tenant_type ?? '',
    additionalInformation: mainAddress.additional_information ?? '',
    household: mainAddress.household ?? '',
    occupancy: mainAddress.occupancy ?? '',
    movingStartDate: mainAddress.moving_start_date ?? '',
    providedByGas: mainAddress.provided_by_gas ?? '',
  }
}

export const retrieveBillingData = currentContract => {
  return {
    iban: currentContract.banking?.iban ?? '',
    bic: currentContract.banking?.bic ?? '',
    businessCard: currentContract.business_card === 1,
    realPayment: currentContract.banking?.payment_mode_real ?? '',
    paymentMode: currentContract.banking?.payment_mode ?? '-1',
    frequency: currentContract.banking?.invoice_frequency ?? '-1',
    sendingMode: currentContract.banking?.invoice_sending_mode ?? '-1',
    paymentDate: currentContract.banking?.payment_date ?? '',
    differentAccountHolder:
      currentContract.banking?.owner_lastname && currentContract.lastname !== currentContract.banking?.owner_lastname,
    accountHolderFirstname: currentContract.banking?.owner_lastname ?? '',
    accountHolderLastname: currentContract.banking?.owner_firstname ?? '',
    promoCode: currentContract.banking?.promotional_code ?? '',
    providersBlacklist: JSON.parse(currentContract.product_energy?.blacklist_providers ?? null), // TO DO: check if we have to move it in energy contract instead as stored in product_energy
  }
}

export const retrieveCustomerData = currentContract => {
  return {
    zohoId: currentContract.zoho_id ?? '',
    name: currentContract.firstname ?? '',
    name2: currentContract.firstname2 ?? '',
    surname: currentContract.lastname ?? '',
    surname2: currentContract.lastname2 ?? '',
    email: currentContract.email ?? '',
    neverbounce_result: currentContract.email?.length > 0 ? 'valid' : '',
    secondName: currentContract.firstname2?.length > 0 || currentContract.lastname2?.length > 0,
    dataCollectionAuth: currentContract.disclaimer === 1,
    civility: currentContract.civility ?? '',
    civility2: currentContract.civility2 ?? '',
    phone1: currentContract.phone1 ?? '',
    phone2: currentContract.phone2 ?? '',
    birthdate: currentContract.birthday ?? '',
    callReason: currentContract.call_reason_id,
    referralFirstName: currentContract.referral_firstname ?? '',
    referralSecondName: currentContract.referral_lastname ?? '',
    isNewsletterRegistered: currentContract.newsletter,
    isEkomiRegistered: currentContract.ekomi_interaction,
    pro_social_reason: currentContract.pro_social_reason ?? '',
    pro_siret: currentContract.pro_siret ?? '',
    pro_activity: currentContract.pro_activity ?? '',
    pro_ape_code: currentContract.pro_ape_code ?? '',
    web_callback: currentContract.web_callback
      ? {
          callbackDate: currentContract.web_callback.split('T')[0] ?? '',
          callbackTime: currentContract.web_callback.split('T')[1] ?? '',
        }
      : { callbackDate: '', callbackTime: '' },
    comments: getLastComment(currentContract.comments ?? []),
  }
}

export const filterLastComment = comments => {
  if (!comments.isArray && typeof comments === 'string') {
    return comments
  }
  let lastCommentTime = new Date(comments[0].created_at).getTime()
  let lastComment = comments[0].comment
  // eslint-disable-next-line func-names
  comments.forEach(function (item) {
    if (new Date(item.created_at).getTime() >= lastCommentTime) {
      lastCommentTime = new Date(item.created_at).getTime()
      lastComment = item.comment
    }
  })
  return lastComment ?? ''
}
export const getLastComment = comments => {
  if (comments.length === 0) {
    return ''
  }
  if (comments.length === 1) {
    return comments[0].comment ?? ''
  }
  return filterLastComment(comments)
}
