import { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useStyletron } from 'baseui'
import { StyledTable, StyledHead, StyledHeadCell, StyledBody } from 'baseui/table'
import { Button } from 'baseui/button'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import ObjectiveListItem from './ObjectiveListItem'
import { deleteObjective } from './objectiveRequest'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectAuthData } from '@/containers/Login/AuthSelectors'

const columns = ['Description', 'Date de début', 'Date de fin', 'Actions']
export default function ObjectiveList({ setIsAble }) {
  const { t } = useTranslation()
  const { authTokenSession } = useSelector(selectAuthData)
  const [css, theme] = useStyletron()
  const cardStyle = css({
    padding: '1rem',
    marginBottom: '2rem',
    backgroundColor: 'white',
    borderRadius: '1rem',
    boxShadow: '0 2px 6px rgba(20,96,170,.3)',
  })
  const headingStyle = css({
    margin: '1rem 10px 10px 10px',
    color: theme.colors.primary,
    ...theme.typography.HeadingMedium,
  })
  const [data, setData] = useState([])
  const formatDate = dateString => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }
  const fetchObjectives = async () => {
    const response = await fetch('/admin/api/goals/objectives')
    if (response.status === 200) {
      setIsAble(true)
      const objectivesFormated = []
      const objectives = await response.json()
      objectives.map(item => {
        const objective = { ...item, end: formatDate(item.end), start: formatDate(item.start) }
        objectivesFormated.push(objective)
      })
      setData(objectivesFormated)
    } else {
      setIsAble(false)
    }
  }
  const deleteObjetiveHandler = async ({ id }) => {
    await deleteObjective(id, authTokenSession)
    setData(data.filter(objective => objective.id !== id))
  }

  useEffect(() => {
    fetchObjectives()
  }, [])

  return (
    <>
      <div className={cardStyle}>
        <h1 className={headingStyle}>{t('objectives:module_title')}</h1>
      </div>
      <FlexGrid flexGridColumnCount={1} flexGridColumnGap='scale800' flexGridRowGap='scale800'>
        <FlexGridItem>
          <NavLink to='/telecom/objectives/create'>
            <Button>{t('objectives:create_button')}</Button>
          </NavLink>
        </FlexGridItem>
        <FlexGridItem>
          <div className={css({ height: '400px' })}>
            <StyledTable>
              <StyledHead>
                {columns.map((col, index) => (
                  <StyledHeadCell key={index}>{col}</StyledHeadCell>
                ))}
              </StyledHead>
              <StyledBody>
                {data.map(item => (
                  <ObjectiveListItem objective={item} key={item.id} onDeleteClick={deleteObjetiveHandler} />
                ))}
              </StyledBody>
            </StyledTable>
          </div>
        </FlexGridItem>
      </FlexGrid>
    </>
  )
}
