import { call, select } from 'redux-saga/effects'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import getAPIConfig from '@/shared/tools/getAPIConfig'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'

export const carEstimatorToolActions = {
  CAR_ESTIMATOR_TOOL_ESTIMATE: 'CAR_ESTIMATOR_TOOL_ESTIMATE',
}

export function* carEstimatorRetrieveEstimation({ params, callback }) {
  const { authTokenSession, lang } = yield select(selectAuthData)
  try {
    const { estimation_tool_url: url } = yield select(selectPageConfigurationData)
    const entries = params.entries()
    const entriesParameters = Object.fromEntries(entries)
    const config = yield call(getAPIConfig, { method: 'post', authTokenSession, body: entriesParameters })
    const response = yield call(fetch, url + '?lang=' + lang, config)
    const responseData = yield call([response, response.json])
    yield call(callback, responseData, response.status)
  } catch (error) {
    console.warn('estimateConsumptionError', error)
  }
}
