import { useState } from 'react'
import { Input } from 'baseui/input'
import InputReplacement from './InputReplacement'
import usePromoCodes from '@/containers/OrganizationsManagement/OrganizationForm/Energy/PromoCodes/usePromoCodes'

const PromoCodesComponent = ({ currentPromoCodesValues }) => {
  const
    {
      t,
      updateProvider
    } = usePromoCodes()
  const [value, setValue] = useState('')
  const [tags, setTags] = useState(currentPromoCodesValues ?? [])
  const addTag = tag => {
    const updatedTags = [...tags, tag]
    setTags(updatedTags)
    updateProvider('promo_codes', updatedTags)
  }
  const removeTag = tag => {
    const updatedTags = tags.filter(t => t !== tag)
    setTags(updatedTags)
    updateProvider('promo_codes', updatedTags)
  }
  const handleKeyDown = event => {
    switch (event.keyCode) {
      // Enter
      case 13: {
        if (!value) return
        addTag(value)
        setValue('')
        return
      }
      // Backspace
      case 8: {
        if (value || !tags.length) return
        removeTag(tags[tags.length - 1])
      }
    }
  }

  return (
    <Input
      placeholder={tags.length ? '' : t('organizationManagement:promo_section.input_placeholder')}
      value={value}
      onChange={e => {
        setValue(e.currentTarget.value)
      }}
      overrides={{
        Input: {
          style: { width: 'auto', flexGrow: 1 },
          component: InputReplacement,
          props: {
            tags,
            removeTag,
            onKeyDown: handleKeyDown
          }
        }
      }}
    />
  )
}

export default PromoCodesComponent
