import { useStyletron, withStyle } from 'baseui'
import { StyledTable } from 'baseui/table-grid'

const useCallInformationStyles = () => {
  const [css] = useStyletron()
  const cardStyle = css({
    marginTop: '1rem',
    color: 'white',
  })
  const cardStyleLastSales = css({
    padding: '1rem',
    marginBottom: '2rem',
    backgroundColor: 'white',
    borderRadius: '1rem',
    boxShadow: '0 2px 6px rgba(20,96,170,.3)',
    width: '100%',
    height: '450px',
  })

  const cardStyleLastAdditionalSales = css({
    padding: '1rem',
    marginBottom: '2rem',
    backgroundColor: 'white',
    borderRadius: '1rem',
    boxShadow: '0 2px 6px rgba(20,96,170,.3)',
    width: '100%',
    height: '450px',
  })
  const cardStyleLastCalls = css({
    padding: '1rem',
    marginBottom: '2rem',
    backgroundColor: 'white',
    borderRadius: '1rem',
    boxShadow: '0 2px 6px rgba(20,96,170,.3)',
    height: '450px',
  })

  const displayContents = css({
    display: 'contents',
  })

  const headerStyle = css({
    marginBottom: '.2rem',
    color: 'white',
    gridArea: 'header',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '.1rem',
    '@media screen and (min-width: 768px)': {
      flexDirection: 'row',
    },
  })
  const itemProps = {
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
  const wideItemProps = {
    ...itemProps,
    overrides: {
      Block: {
        style: ({ $theme }) => ({
          width: `calc((200% - ${$theme.sizing.scale800}) / 3)`,
        }),
      },
    },
  }

  const headCellOverrides = {
    HeadCell: {
      style: {
        padding: '0',
      },
    },
    SortableLabel: {
      style: {
        padding: '.5rem',
        width: '100%',
        fontSize: '12px',
      },
    },
  }

  const Table = withStyle(StyledTable, {
    alignContent: 'start',
  })

  const overrides = {
    Root: {
      style: {
        maxHeight: '300px',
      },
    },
  }

  return {
    cardStyle,
    cardStyleLastSales,
    cardStyleLastAdditionalSales,
    cardStyleLastCalls,
    displayContents,
    headerStyle,
    itemProps,
    wideItemProps,
    headCellOverrides,
    Table,
    overrides,
  }
}

export default useCallInformationStyles
