import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Input, SIZE } from 'baseui/input'
import { StatefulDataTable, StringColumn, CustomColumn } from 'baseui/data-table'

import { deleteGoal } from '../goalRequest'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { selectAuthData } from '@/containers/Login/AuthSelectors'

export default function Example(props) {
  const { value } = props
  const { authTokenSession } = useSelector(selectAuthData)

  const { t } = useTranslation()
  const [currentCell, setCurrentCell] = useState()
  const [rows, setRows] = useState(value)
  const columns = [
    StringColumn({
      title: t('objectives:users'),
      mapDataToValue: data => {
        return `${data.userFirstName} ${data.userName}`
      },
    }),
    StringColumn({
      title: t('objectives:products'),
      mapDataToValue: data => {
        if (data.productType === 'Selectra\\Energy\\Shared\\AdditionalSales\\Domain\\Models\\ProductsCarbonOffset') {
          return `Carbon ${data.productName}`
        } else {
          if (data.providerName?.length > 0) {
            return `${data.providerName} ${data.productName}`
          } else {
            return `${data.productName}`
          }
        }
      },
    }),
    StringColumn({
      title: t('objectives:status'),
      mapDataToValue: data => {
        if (data.id) {
          return `${data.current} (${data.status} %)`
        } else {
          return '0 (0%)'
        }
      },
    }),
  ]

  const deleteGoalObjective = (rowId, value, key) => {
    rows.map((row, i) => {
      if (i === rowId) {
        window.confirm('êtes-vous certain de vouloir supprimer cet objectif?')
        removeRow(row.id)
      }
    })
  }

  const changeObjective = (rowId, value, key) => {
    if (value > 15) {
      window.alert(`${value} est un objectif trop élevé`)
    } else {
      const nextRows = rows.map((row, i) => {
        if (i === rowId) {
          const nextData = { ...row.data }
          nextData.objective = value
          return { ...row, data: nextData }
        }
        return row
      })
      setRows(nextRows)
      props.onChange?.(nextRows)
    }
  }

  async function removeRow(id) {
    const nextRows = rows.filter(row => row.id !== id)
    const promises = rows
      .filter(row => row.id === id)
      .map(async row => {
        if (row.data.id) {
          await deleteGoal(row.data.id, authTokenSession)
        }
      })
    setRows(nextRows)
    props.onChange?.(nextRows)
    await Promise.all(promises)
  }

  useEffect(() => {
    setRows(value)
  }, [value])

  return (
    <div style={{ height: '300px' }}>
      <StatefulDataTable
        rowHeight={47}
        columns={[
          ...columns,
          CustomColumn({
            title: t('objectives:quantity'),
            mapDataToValue: data => data.objective,
            renderCell: data => {
              const { value, y } = data
              const key = `${y}_objective`
              return (
                <Input
                  key={key}
                  value={value}
                  type={'number'}
                  min={1}
                  onFocus={() => setCurrentCell(key)}
                  onBlur={() => setCurrentCell(null)}
                  onChange={e => {
                    changeObjective(y, e.target.value, key)
                  }}
                  placeholder='Nombre de ventes'
                  clearOnEscape
                  size={SIZE.mini}
                  autoFocus={key === currentCell}
                />
              )
            },
          }),
          CustomColumn({
            title: t('objectives:delete_button'),
            mapDataToValue: data => data,
            renderCell: data => {
              const { value, y } = data
              const key = `${y}_supprimer`
              return (
                <SDButton
                  key={key}
                  value={value}
                  onClick={e => {
                    e.preventDefault()
                    deleteGoalObjective(y, e.target.value, key)
                  }}
                  size={SIZE.compact}
                >
                  {t('objectives:delete_button')}
                </SDButton>
              )
            },
          }),
        ]}
        rows={rows}
      />
    </div>
  )
}
