import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconCustomEnedis = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'ContentView'
  size = size ?? 16
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 32 32' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <path d='M23.9 26.9h-.1.1zM25.8 25.9c-.5.5-1.2.9-1.9 1 .7-.2 1.4-.5 1.9-1zM23.7 26.9c-.2.1-.4.1-.7.1.3 0 .5 0 .7-.1z' />
      <path d='M23 3H9C5.7 3 3 5.7 3 9v20h20c3.3 0 6-2.7 6-6V9c0-3.3-2.7-6-6-6zM5 9c0-2.2 1.8-4 4-4h14c2.2 0 4 1.8 4 4v12H13c-1.1 0-2-.9-2-2v-2h8c2.2 0 4-1.8 4-4v-1c0-2.2-1.8-4-4-4h-6c-2.2 0-4 1.8-4 4v1h2v-1c0-1.1.9-2 2-2h6c1.1 0 2 .9 2 2v1c0 1.1-.9 2-2 2H5V9zm20.8 16.9c-.5.5-1.2.9-1.9 1h-.1c-.3.1-.5.1-.8.1H5V17h4v2c0 2.2 1.8 4 4 4h14c0 1.1-.5 2.1-1.2 2.9z' />
    </Icon>
  )
}

IconCustomEnedis.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconCustomEnedis
