import { useEffect, useContext, useState } from 'react'
import { useStyletron } from 'baseui'
import { useParams } from 'react-router-dom'
import Heading from '../Heading'
import { useTranslation } from 'react-i18next'
import ProviderManagementContext from '../../ProviderManagementContext'
import useHttp from '@/shared/hooks/useHttp'
import EnergyOffersTable from '../../OffersList/Energy/EnergyOffersTable'
import OfferTableHeading from '../Common/OfferTableHeading'
import useFilterOffers from '../Common/useFilterOffers'
import { useSelector } from 'react-redux'
import { selectAuthData } from '@/containers/Login/AuthSelectors'

const EnergyShow = () => {
  const { lang } = useSelector(selectAuthData)
  const providerContext = useContext(ProviderManagementContext)
  const providerState = providerContext.providerState
  const providerDispatch = providerContext.providerDispatch
  const { sendRequest, getConfig } = useHttp()
  const [filteredOffers, setFilteredOffers] = useState(providerState.selectedProvider.offers ?? [])
  const { t } = useTranslation()
  const { id } = useParams()
  const [css, theme] = useStyletron()
  const { filterOffers } = useFilterOffers({ offers: providerState.selectedProvider.offers, setFilteredOffers })
  const cardStyle = css({
    padding: '1rem',
    marginBottom: '2rem',
    color: 'white',
  })
  const blockStyle = css({
    padding: '1rem',
    marginBottom: '2rem',
    backgroundColor: 'white',
    borderRadius: '1rem',
    boxShadow: '0 2px 6px rgba(20,96,170,.3)',
    width: '100%',
    color: theme.colors.contentSecondary,
  })
  const headingStyle = css({
    margin: '0.5rem 5px 5px 5px',
    color: theme.colors.primary,
  })
  const loadProviderData = async providerId => {
    await sendRequest(
      providerState.routes.providerInformation.replace('*', providerId) + '?lang=' + lang,
      getConfig('GET'),
      json => providerDispatch({ type: 'setProvider', payload: json }),
    )
  }

  useEffect(() => {
    setFilteredOffers(providerState.selectedProvider.offers)
    filterOffers(0)
  }, [providerState.selectedProvider.offers])

  useEffect(() => {
    loadProviderData(id)
  }, [id])

  return (
    <div className={cardStyle}>
      <Heading />
      {providerState.selectedProvider.uuid && (
        <>
          <div key='infos' className={blockStyle}>
            <h2 className={headingStyle}>{t('organizationManagement:provider_info')}</h2>
            <p key='name'>
              {t('organizationManagement:name')} : {providerState.selectedProvider.name}
            </p>
            <p key='zoho_prefix'>
              {t('organizationManagement:general_info.zoho_prefix')} : {providerState.selectedProvider.zoho_prefix}
            </p>
          </div>
          <div key='offer_table' className={blockStyle}>
            <OfferTableHeading headingStyle={headingStyle} t={t} onClick={filterOffers} />
            {filteredOffers && filteredOffers.length > 0 && (
              <EnergyOffersTable
                items={filteredOffers}
                providerUuid={providerState.selectedProvider.uuid}
                deleteRoute={providerState.routes.offers.delete}
                duplicateRoute={providerState.routes.offers.duplicate}
                providerDispatch={providerDispatch}
                loadProviderData={loadProviderData}
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default EnergyShow
