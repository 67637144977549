import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconCarbonSummaryKPI = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'SummaryKPI'
  size = size ?? 16
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 32 32' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <path d='M7.6667,27.5027,2,22.4484l1.3311-1.4927,5.6411,5.0316,7.6906-7.4449a1.9282,1.9282,0,0,1,2.6736-.0084L22.96,21.9983l5.5791-5.9735L30,17.3905l-5.5812,5.9758a1.996,1.996,0,0,1-2.8379.08l-3.5765-3.4191-7.666,7.4206A1.9629,1.9629,0,0,1,7.6667,27.5027Z' />
      <polygon points='30 11 26 11 28 8 30 11' />
      <path d='M22,4H18V6h4V8H19v2h3v2H18v2h4a2.0027,2.0027,0,0,0,2-2V6A2.0023,2.0023,0,0,0,22,4Z' />
      <path d='M16,14H10V10a2.002,2.002,0,0,1,2-2h2V6H10V4h4a2.0023,2.0023,0,0,1,2,2V8a2.0023,2.0023,0,0,1-2,2H12v2h4Z' />
      <polygon points='6 12 6 4 4 4 4 5 2 5 2 7 4 7 4 12 2 12 2 14 8 14 8 12 6 12' />
    </Icon>
  )
}

IconCarbonSummaryKPI.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconCarbonSummaryKPI
