import MultipleButtonGroup from '../Fields/MultipleButtonGroup'
import { FormControl } from 'baseui/form-control'
import SDButtonGroup from '@/shared/components/SDComponents/SDButtonGroup/SDButtonGroup'
import SDButtonGroupButton from '@/shared/components/SDComponents/SDButtonGroup/SDButtonGroupButton'
import { MODE, SIZE } from 'baseui/button-group'
import FieldsSwitch from '../Fields/FieldsSwitch'
import Debordement from '../Fields/Debordement'

const Contract = ({ t, errors, providerState, updateOffer }) => {
  const fields = providerState.viewParameters.attributes?.general ?? {}
  const optionsFields = providerState.viewParameters.attributes?.options ?? {}

  return (
    <>
      <FormControl label={t('organizationManagement:offer.is_active')} error={errors('active')}>
        <SDButtonGroup
          mode={MODE.radio}
          size={SIZE.mini}
          selected={providerState.selectedOffer.active}
          error={errors('active')}
        >
          <SDButtonGroupButton onClick={(event, index) => updateOffer('active', 0)}>
            {t('organizationManagement:offer.is_active_option_no')}
          </SDButtonGroupButton>
          <SDButtonGroupButton onClick={(event, index) => updateOffer('active', 1)}>
            {t('organizationManagement:offer.is_active_option_yes')}
          </SDButtonGroupButton>
        </SDButtonGroup>
      </FormControl>
      <MultipleButtonGroup
        label={t('organizationManagement:offer.energy_type')}
        errorText={errors('energy_type')}
        onClick={value => updateOffer('energy_type', value)}
        selectedValue={providerState.selectedOffer.energy_type}
        options={providerState.viewParameters.energy_choice ?? null}
      />
      <MultipleButtonGroup
        label={t('organizationManagement:offer.contract_type')}
        errorText={errors('contract_type')}
        onClick={value => updateOffer('contract_type', value)}
        selectedValue={providerState.selectedOffer.contract_type}
        options={providerState.viewParameters.contract_choice ?? null}
      />

      { providerState.selectedOffer.contract_type === 'pro' &&
        <FormControl label={t('organizationManagement:offer.commitment_period')} error={errors('commitment_period')}>
        <SDButtonGroup
          mode={MODE.radio}
          size={SIZE.mini}
          selected={providerState.selectedOffer.commitment_period}
          error={errors('commitment_period')}
        >
          <SDButtonGroupButton onClick={(event, index) => updateOffer('commitment_period', 0)}>
            {t('common:no_text')}
          </SDButtonGroupButton>
          <SDButtonGroupButton onClick={(event, index) => updateOffer('commitment_period', 1)}>
            {t('common:yes_text')}
          </SDButtonGroupButton>
        </SDButtonGroup>
        </FormControl>
      }

      <FieldsSwitch group='contract' fields={fields} t={t} updateOffer={updateOffer} providerState={providerState} />
      <FieldsSwitch
        group='contract'
        fields={optionsFields}
        t={t}
        updateOffer={updateOffer}
        providerState={providerState}
      />
      <Debordement providerState={providerState} t={t} updateOffer={updateOffer} />
    </>
  )
}

export default Contract
