import IconCarbonHome from '@/shared/icons/IconCarbonHome'
import IconCarbonContentView from '@/shared/icons/IconCarbonContentView'
import IconCarbonTaskAdd from '@/shared/icons/IconCarbonTaskAdd'
import IconCarbonEvents from '@/shared/icons/IconCarbonEvents'
import IconCarbonIndustry from '@/shared/icons/IconCarbonIndustry'
import IconCarbonPower from '@/shared/icons/IconCarbonPower'
import IconCarbonChart3D from '@/shared/icons/IconCarbonChart3D'
import IconCarbonSettingsServices from '@/shared/icons/IconCarbonSettingsServices'
import IconCarbonCarbonAccounting from '@/shared/icons/IconCarbonCarbonAccounting'
import IconCarbonScript from '@/shared/icons/IconCarbonScript'
import IconCarbonToolKit from '@/shared/icons/IconCarbonToolKit'
import IconCarbonLightning from '@/shared/icons/IconCarbonLightning'
import IconCarbonMobile from '@/shared/icons/IconCarbonMobile'
import IconCarbonBuilding from '@/shared/icons/IconCarbonBuilding'
import IconCarbonRule from '@/shared/icons/IconCarbonRule'
import IconCarbonMonConseillerPerso from '@/shared/icons/IconCarbonMonConseillerPerso'
import IconCarbonSearch from '@/shared/icons/IconCarbonSearch'

export default function getIcon(id) {
  switch (id) {
    case 'home':
      return props => <IconCarbonHome {...props} />
    case 'overview':
      return props => <IconCarbonContentView {...props} />
    case 'form':
      return props => <IconCarbonTaskAdd {...props} />
    case 'users':
      return props => <IconCarbonEvents {...props} />
    case 'offices':
      return props => <IconCarbonBuilding {...props} />
    case 'providers':
      return props => <IconCarbonIndustry {...props} />
    case 'providerOptions':
      return props => <IconCarbonToolKit {...props} />
    case 'servicesStatus':
      return props => <IconCarbonSettingsServices {...props} />
    case 'assistances':
      return props => <IconCarbonChart3D {...props} />
    case 'monConseillerPersoProducts':
      return props => <IconCarbonMonConseillerPerso {...props} />
    case 'ipAddresses':
    case 'insurance-app':
      return props => <IconCarbonRule {...props} />
    case 'carbonOffset':
      return props => <IconCarbonCarbonAccounting {...props} />
    case 'logout':
      return props => <IconCarbonPower {...props} />
    case 'devTools':
      return props => <IconCarbonScript {...props} />
    case 'energy-app':
      return props => <IconCarbonLightning {...props} />
    case 'telecom-app':
      return props => <IconCarbonMobile {...props} />
    case 'contactPage':
      return props => <IconCarbonSearch {...props} />
  }
}
