import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import { StyledBodyCell } from 'baseui/table-grid'

import PhoneBadge from './PhoneBadge'

const ContactCell = ({ name, numberOfCalls, phone, selected, striped, surname, noContactDataText, zohoLink }) => {
  const [css] = useStyletron()
  const columnStyle = css({
    display: 'flex',
    flexDirection: 'column',
  })
  const rowStyle = css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '.25rem',
  })
  const nameStyle = css({
    textTransform: 'uppercase',
  })
  const cellStyle = css({
    padding: '.5rem',
    backgroundColor: selected ? '#c1d1e2' : 'none',
  })

  if (!phone && !name && !surname) {
    return (
      <StyledBodyCell className={cellStyle} $striped={striped && !selected}>
        <p>{noContactDataText}</p>
      </StyledBodyCell>
    )
  }

  return (
    <StyledBodyCell className={cellStyle} $striped={striped && !selected}>
      <div className={columnStyle}>
        <div className={rowStyle}>
          <span className={nameStyle}>{surname}</span>
        </div>
        <div className={rowStyle}>{name}</div>
        <div className={rowStyle}>
          <PhoneBadge callNo={numberOfCalls} phone={phone} zohoLink={zohoLink} />
        </div>
      </div>
    </StyledBodyCell>
  )
}

ContactCell.propTypes = {
  name: PropTypes.string,
  numberOfCalls: PropTypes.number,
  phone: PropTypes.string,
  selected: PropTypes.bool,
  striped: PropTypes.bool,
  surname: PropTypes.string,
  noContactDataText: PropTypes.string,
  zohoLink: PropTypes.string,
}

export default ContactCell
