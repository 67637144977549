import { StyledBodyCell } from 'baseui/table-grid'
import { useStyletron, withStyle } from 'baseui'
import PropTypes from 'prop-types'
import { StyledRow } from 'baseui/table'
import IconCarbonLightning from '@/shared/icons/IconCarbonLightning'
import IconCarbonRule from '@/shared/icons/IconCarbonRule'
import IconCarbonMobile from '@/shared/icons/IconCarbonMobile'
import IconCarbonFinance from '@/shared/icons/IconCarbonFinance'
import IconCarbonHome from '@/shared/icons/IconCarbonHome'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import useContactPage from '@/containers/ContactPage/useContactPage'

const SubscriptionsRowsTable = ({
  striped,
  subscriptionId,
  email,
  firstName,
  lastName,
  phone,
  createdAt,
  productEnergyCount,
  productInsuranceCount,
  productTelecomCount,
  addressCount,
  bankingCount,
  urlCreateCache,
}) => {
  const {
    t,
    requestCreateContactCacheHandler,
  } = useContactPage()
  const [css] = useStyletron()
  const CenteredBodyCell = withStyle(StyledBodyCell, {
    display: 'flex',
    alignItems: 'center',
  })

  const addZero = number => {
    return number < 10 ? `0${number}` : number
  }
  const formatDate = date => {
    const dateObject = new Date(date)
    return `${addZero(dateObject.getDate())}-${addZero(dateObject.getMonth() + 1)}-${dateObject.getFullYear()} ${dateObject.getHours()}:${dateObject.getMinutes()}:${dateObject.getSeconds()}`
  }

  return (
    <StyledRow role='row' className={css({ backgroundColor: striped ? '#f7f7f7' : '#ffffff' })}>
      <CenteredBodyCell $style={{ width: '10%' }}>{subscriptionId}</CenteredBodyCell>
      <CenteredBodyCell $style={{ width: '20%' }}>{email}</CenteredBodyCell>
      <CenteredBodyCell $style={{ width: '15%' }}>{firstName}</CenteredBodyCell>
      <CenteredBodyCell $style={{ width: '15%' }}>{lastName}</CenteredBodyCell>
      <CenteredBodyCell $style={{ width: '10%' }}>{phone}</CenteredBodyCell>
      <CenteredBodyCell $style={{ width: '10%' }}>{formatDate(createdAt)}</CenteredBodyCell>
      <CenteredBodyCell $style={{ width: '10%' }}>
        {productEnergyCount === 1 && <IconCarbonLightning size={24} title='Energy' />}
        {productInsuranceCount === 1 && <IconCarbonRule size={24} title='Insurance' />}
        {productTelecomCount === 1 && <IconCarbonMobile size={24} title='Telecom' />}
        {addressCount === 1 && <IconCarbonHome size={24} title='Address' />}
        {bankingCount === 1 && <IconCarbonFinance size={24} title='Banking' />}
      </CenteredBodyCell>
      <CenteredBodyCell $style={{ width: '10%' }}>
        <SDButton
          onClick={event => {
            event.preventDefault()
            requestCreateContactCacheHandler(urlCreateCache,subscriptionId)
          }}>{t('contactPage:subscriptions_table_results.button_action_go_contact')}</SDButton>
      </CenteredBodyCell>
    </StyledRow>
  )
}

SubscriptionsRowsTable.propTypes = {
  striped: PropTypes.bool,
  subscriptionId: PropTypes.number,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phone: PropTypes.string,
  createdAt: PropTypes.string,
  productEnergyCount: PropTypes.number,
  productInsuranceCount: PropTypes.number,
  productTelecomCount: PropTypes.number,
  addressCount: PropTypes.number,
  bankingCount: PropTypes.number,
  urlCreateCache: PropTypes.string,
}

export default SubscriptionsRowsTable
