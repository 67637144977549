import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconCustomMesAllocs = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'ContentView'
  size = size ?? 16
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 32 32' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <path d='M27,10H21a3.0033,3.0033,0,0,0-3,3v6a2.0023,2.0023,0,0,0,2,2v7a2.0023,2.0023,0,0,0,2,2h4a2.0023,2.0023,0,0,0,2-2V21a2.0023,2.0023,0,0,0,2-2V13A3.0033,3.0033,0,0,0,27,10Zm1,9H26v9H22V19H20V13a1.0009,1.0009,0,0,1,1-1h6a1.0009,1.0009,0,0,1,1,1Z' />
      <path d='M20,5a4,4,0,1,1,4,4A4.0042,4.0042,0,0,1,20,5Zm2,0a2,2,0,1,0,2-2A2.0023,2.0023,0,0,0,22,5Z' />
      <path d='M14,16V13a3.0033,3.0033,0,0,0-3-3H5a3.0033,3.0033,0,0,0-3,3v3H0v2H16V16ZM4,13a1.0009,1.0009,0,0,1,1-1h6a1.0009,1.0009,0,0,1,1,1v3H4Z' />
      <path d='M4,5A4,4,0,1,1,8,9,4.0042,4.0042,0,0,1,4,5ZM6,5A2,2,0,1,0,8,3,2.0023,2.0023,0,0,0,6,5Z' />
    </Icon>
  )
}

IconCustomMesAllocs.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconCustomMesAllocs
