import { energyFormParametersGeneration } from './SubscriptionForm/Energy/energyFormParametersGeneration'
import { insuranceFormParametersGeneration } from './SubscriptionForm/Insurance/insuranceFormParametersGeneration'
import { telecomFormParametersGeneration } from './SubscriptionForm/Telecom/telecomFormParametersGeneration'

const SaveForm = async (
  action = '',
  headers,
  applicationType = 'telecom',
  customerData,
  billingData,
  electricityMeterData,
  gasMeterData,
  mainAddressData,
  invoicingAddressData,
  telecomContractData,
  energyContractData,
  telecomCharacteristicsData,
  energyCharacteristicsData,
  energyConsumptionData,
  energyEstimationData,
  propaneData,
  insuranceContractData,
  insuranceCharacteristicsData,
  id,
  country,
  saveUrl,
  callback
) => {
  let options = null
  if (applicationType === 'telecom') {
    options = telecomFormParametersGeneration(
      customerData,
      billingData,
      mainAddressData,
      telecomContractData,
      telecomCharacteristicsData,
      id,
      country,
      action
    )
  }
  if (applicationType === 'assurance') {
    options = insuranceFormParametersGeneration(
      customerData,
      billingData,
      mainAddressData,
      insuranceContractData,
      insuranceCharacteristicsData,
      id,
      country,
      action
    )
  }
  if (applicationType === 'energie') {
    options = energyFormParametersGeneration(
      customerData,
      billingData,
      electricityMeterData,
      gasMeterData,
      propaneData,
      energyContractData,
      energyCharacteristicsData,
      energyConsumptionData,
      energyEstimationData,
      mainAddressData,
      invoicingAddressData,
      id,
      country,
      action,
      {}
    )
  }
  headers.body = options
  await fetch(saveUrl, headers)
    .then(response => {
      response.json().then(json => callback(json, response.status, action))
    })
    .catch(error => {
      console.error(error)
      console.error('Form submission error')
    })
}

export default SaveForm
