const mapProviderList = list => {
  if (!list) {
    return []
  }
  const providers = []
  for (const [, provider] of Object.entries(list)) {
    const data = {
      label: provider.name,
      uuid: provider.uuid,
    }
    providers.push(data)
  }
  return providers
}
const providerActions = (state, action) => {
  if (action.type === 'initialize') {
    return {
      ...state,
      initialized: true,
      routes: { ...(action.payload.routesSpa ?? {}) },
      tinymceKey: action.payload.tinymceKey,
      general: {
        providersList: mapProviderList(action.payload.organisations ?? []),
        title: action.payload.title,
      },
    }
  }

  if (action.type === 'setViewParameters') {
    return {
      ...state,
      viewParameters: {
        parametersLoaded: true,
        ...(action.payload ?? {}),
      },
    }
  }

  if (action.type === 'setProvider') {
    return {
      ...state,
      selectedProvider: {
        ...(action.payload ?? {}),
        apiIntegration: action.payload.api_workflow && action.payload.api_workflow.length > 0 ? 1 : 0,
      },
    }
  }

  if (action.type === 'updateProvider') {
    return {
      ...state,
      selectedProvider: {
        ...state.selectedProvider,
        ...(action.payload ?? {}),
      },
    }
  }

  if (action.type === 'setErrors') {
    return {
      ...state,
      errors: {
        ...(action.payload ?? {}),
      },
    }
  }

  if (action.type === 'saving') {
    return {
      ...state,
      save: {
        ...state.save,
        ...(action.payload ?? {}),
      },
    }
  }

  if (action.type === 'reset') {
    return {
      ...state,
      selectedProvider: {
        uuid: '',
      },
    }
  }

  if (action.type === 'setOffer') {
    return {
      ...state,
      selectedOffer: {
        ...(action.payload ?? {}),
      },
    }
  }

  if (action.type === 'updateOffer') {
    return {
      ...state,
      selectedOffer: {
        ...state.selectedOffer,
        ...(action.payload ?? {}),
      },
    }
  }

  if (action.type === 'resetOffer') {
    return {
      ...state,
      selectedOffer: {
        uuid: '',
        active: true,
      },
    }
  }
}

export default providerActions
