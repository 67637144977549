import { FlexGridItem } from 'baseui/flex-grid'
import { ProgressBar } from 'baseui/progress-bar'
import { Card, StyledBody, StyledAction } from 'baseui/card'

export default function AgentGoalItem({ goal }) {
  const { current, quantity, product, status } = goal

  return (
    <FlexGridItem>
      <Card title={product.name}>
        <StyledBody>
          <ProgressBar value={status} />
        </StyledBody>

        <StyledAction>
          <p>Current: {current}</p>
          <p>Objective: {quantity}</p>
        </StyledAction>
      </Card>
    </FlexGridItem>
  )
}
