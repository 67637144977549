import { useDispatch } from 'react-redux'
import { addCommonFormErrors, updateCommonConfiguration } from '../SubscriptionFormConfigurationSlice'
import SaveForm from '../SaveForm'

const useSaveForm = () => {
  const dispatch = useDispatch()

  const savedCallback = (result, status, action) => {
    dispatch(updateCommonConfiguration({ formSubmitted: false }))
    if (status === 401) {
      window.location.href = '/login'
      return
    }
    if (result.errors) {
      dispatch(addCommonFormErrors(result.errors))
      return
    }
    if (result.save_error?.save) {
      dispatch(addCommonFormErrors({ monthly_amount: [result.save_error?.save] }))
      return
    }
    if (status !== 200 || !result.success) return
    const isEditable = action === 'save_and_send' ? false : result.isStillEditable ?? true
    dispatch(
      updateCommonConfiguration({
        isEditable,
        realStatus: result.realStatus,
        canSendSubscription: result.canSendSubscription ?? false,
        showSaveNotification: true,
        saveAction: action,
        subscriptionStatus: result.displayLightStatus,
      }),
    )
  }

  const submitSubscriptionForm = async ({
    actionName,
    headerConfig,
    applicationType,
    customerData,
    billingData,
    electricityMeterData,
    gasMeterData,
    mainAddressData,
    invoicingAddressData,
    telecomContractData,
    energyContractData,
    telecomCharacteristicsData,
    energyCharacteristicsData,
    energyConsumptionData,
    energyEstimationData,
    propaneData,
    insuranceContractData,
    insuranceCharacteristicsData,
    id,
    country,
    saveUrl,
  }) => {
    dispatch(updateCommonConfiguration({ showSaveNotification: false, formSubmitted: true, errors: {} }))
    await SaveForm(
      actionName,
      headerConfig,
      applicationType,
      customerData,
      billingData,
      electricityMeterData,
      gasMeterData,
      mainAddressData,
      invoicingAddressData,
      telecomContractData,
      energyContractData,
      telecomCharacteristicsData,
      energyCharacteristicsData,
      energyConsumptionData,
      energyEstimationData,
      propaneData,
      insuranceContractData,
      insuranceCharacteristicsData,
      id,
      country,
      saveUrl,
      savedCallback,
    )
  }

  return {
    submitSubscriptionForm,
  }
}

export default useSaveForm
