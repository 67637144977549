import PropTypes from 'prop-types'
import { Table } from 'baseui/table-semantic'
import { useTranslation } from 'react-i18next'

const CarbonOffsetsList = ({ items }) => {
  const { t } = useTranslation()
  const COLUMNS = [
    t('carbonOffsetConfiguration:id'),
    t('carbonOffsetConfiguration:name'),
    t('carbonOffsetConfiguration:price'),
    t('carbonOffsetConfiguration:description'),
    t('carbonOffsetConfiguration:energy_type'),
    t('carbonOffsetConfiguration:zoho_id'),
    t('common:edit'),
  ]
  return <Table columns={COLUMNS} data={items} />
}

CarbonOffsetsList.propTypes = {
  items: PropTypes.array,
}
export default CarbonOffsetsList
