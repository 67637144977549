import { useEffect, useState } from 'react'
import { Spinner } from 'baseui/spinner'
import { Card } from '@/containers/SubscriptionFormPage/AdditionalSalesPanel/styled-components'
import ObjectiveForm from './Objective/ObjectiveForm'
import { useParams } from 'react-router-dom'
import { fetchObjective, updateObjective } from './objectiveRequest'
import { createGoal, fetchObjectiveGoals } from './goalRequest'
import { useTranslation } from 'react-i18next'
import { useStyletron } from 'baseui'
import { useSelector } from 'react-redux'
import { selectAuthData } from '@/containers/Login/AuthSelectors'

const ObjectiveEdit = () => {
  const [objective, setObjective] = useState(null)
  const { authTokenSession } = useSelector(selectAuthData)
  const [showSaveNotification, setShowSaveNotification] = useState()
  const { objectiveId } = useParams()
  const [css, theme] = useStyletron()
  const { t } = useTranslation()
  const headingStyle = css({
    margin: '1rem 10px 30px 30px',
    color: 'white',
    ...theme.typography.HeadingMedium,
  })
  const onSubmitHandler = async data => {
    const { objective, goals } = data
    try {
      await updateObjective(objective, authTokenSession)
      setShowSaveNotification(true)

      const promises = new Promise(async (resolve, reject) => {
        try {
          await createGoal(objective.id, goals, authTokenSession)
        } catch (e) {
          reject(e)
          setShowSaveNotification(false)
        }
      })

      await promises
    } catch (e) {
      setShowSaveNotification(false)
      console.error(e)
    }
  }
  const fetchObjectives = async () => {
    const response = await Promise.all([fetchObjective(objectiveId), fetchObjectiveGoals(objectiveId)]),
      objective = {
        objective: {
          ...response[0],
          start: new Date(response[0].start),
          end: new Date(response[0].end),
        },
        goals: [
          ...response[1].map(goal => ({
            id: `${goal.user.id}_${goal.product.id}`,
            data: {
              userName: goal.user.name,
              userFirstName: goal.user.firstName,
              id: goal.id,
              userId: goal.user.id,
              productId: goal.product.id,
              productType: goal.product.type,
              productName: goal.product.name,
              providerName: goal.product.provider_name,
              objective: goal.quantity,
              current: goal.current,
              status: goal.status,
            },
          })),
        ],
      }
    setObjective(objective)
  }

  useEffect(() => {
    fetchObjectives()
  }, [])

  useEffect(() => {
    setShowSaveNotification()
  }, [onSubmitHandler])
  if (!objective) {
    return (
      <>
        <Spinner />
      </>
    )
  }

  return (
    <>
      <h1 className={headingStyle}>{t('objectives:edit_objective')}</h1>
      <Card>
        <ObjectiveForm data={objective} onSubmit={onSubmitHandler} showSaveNotification={showSaveNotification} />
      </Card>
    </>
  )
}

export default ObjectiveEdit
