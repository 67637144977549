import { Button, KIND, SIZE } from 'baseui/button'
import { useTranslation } from 'react-i18next'
import { FormControl } from 'baseui/form-control'
import { CardForm } from '../styled-components'
import PropTypes from 'prop-types'
import { Input } from 'baseui/input'

const Form = ({ newComment, setNewComment, createMethod }) => {
  const { t } = useTranslation()
  const overridesInput = {
    Root: {
      style: () => ({
        height: '3rem',
      }),
    },
  }
  const overridesButton = {
    Root: {
      style: () => ({
        height: '3rem',
      }),
    },
  }

  return (
    <CardForm>
      <FormControl>
        <Input
          overrides={overridesInput}
          size={SIZE.compact}
          placeholder={t('subscriptionForm:comments_module.write_comment')}
          value={newComment}
          onChange={event => setNewComment(event.target.value)}
          autoComplete='off'
        />
      </FormControl>
      <Button
        overrides={overridesButton}
        kind={KIND.secondary}
        size={SIZE.mini}
        onClick={event => {
          event.preventDefault()
          createMethod(newComment)
        }}
      >
        {t('subscriptionForm:comments_module.create_comment_button')}
      </Button>
    </CardForm>
  )
}

Form.propTypes = {
  newComment: PropTypes.string,
  setNewComment: PropTypes.func,
  createMethod: PropTypes.func,
}

export default Form
