import { useStyletron } from 'baseui'

const useDashboardStyles = () => {
  const [css] = useStyletron()
  const cardStyle = css({
    padding: '1rem',
    marginBottom: '2rem',
    backgroundColor: 'white',
    borderRadius: '1rem',
    boxShadow: '0 2px 6px rgba(20,96,170,.3)',
  })
  const paginationStyle = css({
    margin: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto',
    backgroundColor: 'rgba( 255, 255, 255, 0.5 )',
  })

  return {
    cardStyle,
    paginationStyle,
  }
}

export default useDashboardStyles
