import { all, fork } from 'redux-saga/effects'

import authSagas from '@/containers/Login/AuthSagas'
import pageConfigurationSagas from '@/store/PageConfiguration/PageConfigurationSagas'
import usersManagementSagas from '@/containers/UsersManagement/UsersManagementSagas'
import servicesStatusSagas from '../containers/ServicesStatus/ServicesStatusSagas'
import carbonOffsetsSagas from '../containers/CarbonOffsetManagement/CarbonOffsetsSagas'
import officesManagementSagas from '../containers/OfficesManagement/OfficesManagementSagas'
import assistancesSagas from '../containers/AssistancesManagement/AssistancesSagas'
import dashboardSagas from '../containers/DashboardPage/DashboardSagas'
import subscriptionFormSagas from '../containers/SubscriptionFormPage/SubscriptionFormSagas'
import monConseillerPersoSagas from '../containers/MonConseillerPersoProductsManagement/MonConseillerPersoSagas'

export default function* rootSaga() {
  yield all([fork(authSagas)])
  yield all([fork(pageConfigurationSagas)])
  yield all([fork(usersManagementSagas)])
  yield all([fork(servicesStatusSagas)])
  yield all([fork(carbonOffsetsSagas)])
  yield all([fork(officesManagementSagas)])
  yield all([fork(assistancesSagas)])
  yield all([fork(monConseillerPersoSagas)])
  yield all([fork(dashboardSagas)])
  yield all([fork(subscriptionFormSagas)])
}
