import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { clearAuth } from '@/containers/Login/AuthSlice'

const useHttp = () => {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const { authTokenSession: token } = useSelector(selectAuthData)
  const dispatch = useDispatch()

  const getConfig = method => {
    return {
      headers: {
        Accept: 'Application/json',
        Authorization: 'Bearer ' + token,
      },
      method,
      mode: 'cors',
    }
  }
  const sendRequest = useCallback(async (url, config = {}, callback) => {
    setIsLoading(true)
    try {
      const response = await fetch(url, config)
      const data = response.status !== 204 ? await response.json() : {}

      if (response.status === 401) {
        const errorMessage =
          data.message ||
          "Erreur: vous tentez d'accéder à Hop sur une IP non autorisée. Si ce message s'affiche alors qu'il ne le devrait pas, demandez l'aide de votre manager."
        alert(errorMessage)
        dispatch(clearAuth())
        window.location.href = '/login'
        return
      }

      setIsLoading(false)
      return callback(data, response.status)
    } catch (error) {
      console.error(error.message)
      setError(error.message || 'An error occurred')
      setIsLoading(false)
    }
  }, [])

  return {
    error,
    isLoading,
    sendRequest,
    getConfig,
  }
}

export default useHttp
