import PropTypes from 'prop-types'
import { StyledBodyCell, StyledHeadCell, StyledTable } from 'baseui/table-grid'
import { useStyletron, withStyle } from 'baseui'
import { useTranslation } from 'react-i18next'
import TableRow from './TableRow'

const Table = ({ data }) => {
  withStyle(StyledBodyCell, {
    display: 'flex',
    alignItems: 'center',
  })
  const [css] = useStyletron()
  const { t } = useTranslation()

  return (
    <StyledTable role='grid' $gridTemplateColumns='33% 33% 33%'>
      <div role='row' className={css({ display: 'contents' })}>
        <StyledHeadCell>
          {t('subscriptionForm:subscription_actions_module.historic_logs.head_value_name')}
        </StyledHeadCell>
        <StyledHeadCell>
          {t('subscriptionForm:subscription_actions_module.historic_logs.head_past_value')}
        </StyledHeadCell>
        <StyledHeadCell>
          {t('subscriptionForm:subscription_actions_module.historic_logs.head_new_value')}
        </StyledHeadCell>
      </div>
      {Object.entries(data).map(([key, item], index) => {
        return (
          <TableRow
            fieldKey={key}
            model={item.model}
            oldValue={item.old_value}
            newValue={item.new_value}
            striped={index % 2 === 0}
          />
        )
      })}
    </StyledTable>
  )
}

Table.propTypes = {
  data: PropTypes.array,
}

export default Table
