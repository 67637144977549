const useFilterOffers = ({ offers, setFilteredOffers }) => {
  const offerInactiveDateIsLessThan30Days = inactiveDate => {
    if (!inactiveDate) return false
    const date = new Date(inactiveDate)
    const today = new Date()
    const diffTime = Math.abs(date - today)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    return diffDays <= 30
  }

  const filterOffers = index => {
    if (!offers) return
    if (index === 0) {
      const offersFiltered = offers.filter(offer => {
        return offer.active || offerInactiveDateIsLessThan30Days(offer.inactive_date)
      })
      setFilteredOffers(offersFiltered)
    } else {
      setFilteredOffers(offers)
    }
  }

  return {
    filterOffers,
  }
}

export default useFilterOffers
