import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import useImportScript from '@/shared/hooks/useImportScript'
import useHttp from '@/shared/hooks/useHttp'

const useVonageDialer = () => {
  const {
    authTokenSession,
    vonageStatus: shouldLoadDialer,
    extraRoutes,
    vonageDialerStatus,
  } = useSelector(selectAuthData)
  const { sendRequest, getConfig } = useHttp()
  const { loadScript } = useImportScript()
  const [zohoId, setZohoId] = useState(null)
  const eventHandlers = {
    CALL_START: async event => handleCallStart(event),
    CALL_END: async event => handleCallEnd(event),
    default: event => {
      console.error('Unhandled event', event)
    },
  }
  const onDialerEvent = async event => {
    console.log('onDialerEvent', event.type, event)
    const handler = eventHandlers[event.type] || eventHandlers.default
    await handler(event)
  }

  useEffect(() => {
    if (!shouldLoadDialer || !vonageDialerStatus) {
      return
    }

    loadScript('https://apps.gunify-dev.vonage.com/cti/common/vonage.dialer.sdk.js', false, authTokenSession, {
      'data-provider': 'cc',
      'data-cc-domain': 'emea.newvoicemedia.com',
    })

    const interval = setInterval(() => {
      const localVonageDialer = window.VonageDialer
      if (localVonageDialer) {
        loadVonageSpecifications(localVonageDialer)
        clearInterval(interval)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [shouldLoadDialer, vonageDialerStatus])

  const loadVonageSpecifications = localVonageDialer => {
    localVonageDialer.enableClickToDial(true)

    if (
      !window.localStorage.getItem('vTop') &&
      !window.localStorage.getItem('vRight') &&
      !window.localStorage.getItem('vBottom') &&
      !window.localStorage.getItem('vLeft')
    ) {
      window.localStorage.setItem('vRight', window.innerWidth / 2)
    }

    localVonageDialer.init(
      {
        debug: true,
        provider: 'cc',
      },
      dialer => {
        dialer.setOnDialerEvent(onDialerEvent)
        dialer.setVisibility(true)
      },
    )
    localVonageDialer.setProvider('cc', 'emea.newvoicemedia.com')
  }

  const handleCallStart = async event => {
    console.log('CALL START', extraRoutes['put-cache-call'], event.data)
    const { data } = event
    const direction = data?.direction ?? ''

    if (direction.toLowerCase() === 'outbound') {
      console.log('Outbound call, no need to save information in cache')
      return
    }
    const contact = data?.contact?.extraAttributes ?? {}
    const options = new URLSearchParams()
    options.append('zoho_id', contact?.ContactID ?? '')
    options.append('lead_source', contact.FriendlyName ?? '')
    options.append('phone', data?.phoneNumber ?? '')
    options.append('email', contact?.ContactEmail ?? '')
    options.append('firstname', contact?.ContactFirst ?? '')
    options.append('lastname', contact?.ContactLast ?? '')
    options.append('newsletter_status', contact?.ContactNLBP ?? 'false')
    options.append('direction', direction)

    const config = getConfig('POST')
    config.body = options

    sendRequest(extraRoutes['put-cache-call'], config, (response, status) => {
      if (status === 200) {
        console.log('Call information added in cache, redirection to information page')
        setZohoId(response.zoho_id)
        window.location.href = '/call-information/' + response.zoho_id
      } else {
        alert('Call information not added in cache')
        console.log('Call information not added in cache ..', options, response)
      }
    })
  }

  const handleCallEnd = async event => {
    console.log('callEnded', event.data) // VGIP Call Detail Record

    const { data } = event
    const durationSeconds = parseInt(data.vcc?.callduration)
    const options = new URLSearchParams()
    options.append('zoho_id', data.contact?.extraAttributes?.ContactID ?? zohoId)
    options.append('type_call', data.direction)
    options.append('guid_id', data.id)
    options.append('duration', new Date(durationSeconds * 1000).toISOString().substring(14, 19))

    const config = getConfig('POST')
    config.body = options

    sendRequest(window.location.origin + '/admin/calls/record-call', config, (response, status) => {
      if (status === 201) {
        console.log('Call saved successfully')
      }

      if (status !== 201) {
        alert('Error saving call')
        console.error(response)
      }
    })

    setZohoId(null)
  }

  return { handleCallStart }
}

export default useVonageDialer
