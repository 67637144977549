import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import useBusinessType from '@/shared/hooks/useBusinessType'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { useTranslation } from 'react-i18next'
import useModal from '@/shared/hooks/useModal'
import useHttp from '@/shared/hooks/useHttp'

const useIpAddressWhitelistManagement = () => {
  const { loaderRoutesEnergy, loaderRoutes, loaderRoutesInsurance, lang } = useSelector(selectAuthData)
  const { sendRequest, isLoading, getConfig } = useHttp()
  const { getApplicationUrl } = useBusinessType({
    loaderRoutesEnergy,
    loaderRoutes,
    loaderRoutesInsurance,
    modulesStatuses: {},
    modulesStatusesEnergy: {},
    modulesStatusesInsurance: {}
  })
  const initIpAddressesUrl = getApplicationUrl('ip-addresses-management')
  const [ipAddressesUrls, setIpAddressesUrls] = useState([])
  const [ipAddressWhitelists, setIpAddressWhitelists] = useState([])
  const [ipWhitelistSelected, setIpWhitelistSelected] = useState({
    id: null,
    name: null,
    ip_addresses: null
  })

  const { t } = useTranslation()
  const { isOpen, close, setIsOpen } = useModal()

  const initialize = async () => {
    await sendRequest(initIpAddressesUrl + '?lang=' + lang, getConfig('GET'), json => {
      setIpAddressesUrls(json)
    })
  }

  const refreshList = async () => {
    if (!ipAddressesUrls.list) {
      return
    }

    await sendRequest(ipAddressesUrls.list + '?lang=' + lang, getConfig('GET'), json => {
      setIpAddressWhitelists(json)
    })
  }

  const openIpAddressesModal = ipAddressWhitelist => {
    const ipAddressWhitelistFormatted = {
      id: ipAddressWhitelist?.id ?? 0,
      name: ipAddressWhitelist?.id ? ipAddressWhitelist.name : '',
      ip_addresses: ipAddressWhitelist?.id ? ipAddressWhitelist.ip_addresses : '',
      organization_id: ipAddressWhitelist?.organization_id ? ipAddressWhitelist.organization_id : ''
    }
    setIpWhitelistSelected(ipAddressWhitelistFormatted)

    setIsOpen(true)
  }

  useEffect(() => {
    initialize()
  }, [])

  useEffect(() => {
    refreshList()
  }, [ipAddressesUrls])

  return {
    ipAddressWhitelists,
    isLoading,
    t,
    isOpen,
    close,
    openIpAddressesModal,
    ipWhitelistSelected,
    setIsOpen,
    setIpWhitelistSelected,
    ipAddressesUrls,
    refreshList
  }
}

export default useIpAddressWhitelistManagement
