import PropTypes from 'prop-types'

import { FormControl } from 'baseui/form-control'
import { HOPInput } from '@/shared/components/HOPComponents/HOPInput'
import HOPSelect from '@/shared/components/HOPComponents/HOPSelect'
import { SIZE } from 'baseui/button'
import SDTextarea from '@/shared/components/SDComponents/SDTextarea'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'

import useCarbonOffsetsForm from './useCarbonOffsetsForm'

const CarbonOffsetsForm = ({ onCancel, setIsOpen }) => {
  const { selectedCarbonOffset, submitHandler, t, formErrors, updateCarbonOffsetData } = useCarbonOffsetsForm(setIsOpen)

  const list = [
    {
      label: t('common:energy_elec_long'),
      id: 'elec',
    },
    {
      label: t('common:energy_gas'),
      id: 'gas',
    },
    {
      label: t('common:energy_dual_long'),
      id: 'dual',
    },
    {
      label: t('carbonOffsetConfiguration:energy_type_unavailable'),
      id: '',
    },
  ]
  const convertList = list => {
    const convertedList = {}
    list.map(element => {
      convertedList[element.id] = element.label
    })
    return convertedList
  }

  return (
    <form onSubmit={submitHandler}>
      <FormControl label={t('carbonOffsetConfiguration:name')} error={formErrors.name ? formErrors.name[0] : ''}>
        <HOPInput
          currentValue={selectedCarbonOffset.name}
          handleValue={value => {
            updateCarbonOffsetData({ name: value })
          }}
        />
      </FormControl>
      <FormControl
        label={t('carbonOffsetConfiguration:zoho_prefix')}
        error={formErrors.zoho_prefix ? formErrors.zoho_prefix[0] : ''}
      >
        <HOPInput
          currentValue={selectedCarbonOffset.zoho_prefix}
          handleValue={value => {
            updateCarbonOffsetData({ zoho_prefix: value })
          }}
        />
      </FormControl>
      <FormControl label={t('carbonOffsetConfiguration:price')} error={formErrors.price ? formErrors.price[0] : ''}>
        <HOPInput
          currentValue={selectedCarbonOffset.price}
          handleValue={value => {
            updateCarbonOffsetData({ price: value })
          }}
        />
      </FormControl>
      <FormControl
        label={t('carbonOffsetConfiguration:description')}
        error={formErrors.description ? formErrors.description[0] : ''}
      >
        <SDTextarea
          value={selectedCarbonOffset.description}
          onChange={e => {
            updateCarbonOffsetData({ description: e.target.value })
          }}
        />
      </FormControl>
      <FormControl
        label={t('carbonOffsetConfiguration:energy_type')}
        error={formErrors.energy_type ? formErrors.energy_type[0] : ''}
      >
        <HOPSelect
          optionsList={convertList(list)}
          placeholder={
            selectedCarbonOffset.energy_type
              ? selectedCarbonOffset.energy_type
              : t('carbonOffsetConfiguration:place_holder')
          }
          selectedValue={selectedCarbonOffset.energy_type}
          onChangeHandler={params => {
            const energyTypeSelected = params.value[0].id
            updateCarbonOffsetData({ energy_type: energyTypeSelected })
          }}
        />
      </FormControl>
      <FormControl
        label={t('carbonOffsetConfiguration:zoho_id')}
        error={formErrors.zoho_id ? formErrors.zoho_id[0] : ''}
      >
        <HOPInput
          currentValue={selectedCarbonOffset.zoho_id}
          handleValue={value => {
            updateCarbonOffsetData({ zoho_id: value })
          }}
        />
      </FormControl>
      <FormControl label={t('carbonOffsetConfiguration:spb_code')}>
        <HOPInput
          currentValue={selectedCarbonOffset.spb_code}
          handleValue={value => {
            updateCarbonOffsetData({ spb_code: value })
          }}
        />
      </FormControl>
      <SDButton
        size={SIZE.compact}
        block={false}
        overrides={{
          BaseButton: {
            style: () => ({
              marginRight: '.5rem',
            }),
          },
        }}
        type='submit'
      >
        {selectedCarbonOffset.id ? t('common:edit') : t('carbonOffsetConfiguration:create')}
      </SDButton>
      <SDButton size={SIZE.compact} block={false} type='button' onClick={onCancel}>
        {t('common:cancel')}
      </SDButton>
    </form>
  )
}

CarbonOffsetsForm.propTypes = {
  onCancel: PropTypes.func,
  setIsOpen: PropTypes.func,
}

export default CarbonOffsetsForm
