import PropTypes from 'prop-types'
import { Editor } from '@tinymce/tinymce-react'
import { FormControl } from 'baseui/form-control'
import { HOPInput } from '@/shared/components/HOPComponents/HOPInput'
import HOPSelect from '@/shared/components/HOPComponents/HOPSelect'
import { SIZE } from 'baseui/button'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import useAssistanceForm from './useAssistanceForm'

const AssistanceForm = ({ onCancel, tinymceKey }) => {
  const {
    t,
    selectedAssistance,
    formValue,
    setFormValue,
    submitHandler,
    statusList,
    descriptionEditorRef,
    knowMoreEditorRef,
    errors,
  } = useAssistanceForm({ close: onCancel })

  return (
    <form onSubmit={submitHandler}>
      <FormControl label={t('assistancesConfiguration:name')} error={errors?.name ? errors?.name[0] : ''}>
        <HOPInput
          currentValue={formValue.name}
          handleValue={value => {
            setFormValue({ ...formValue, name: value })
          }}
        />
      </FormControl>
      <FormControl label={t('assistancesConfiguration:zoho_id')} error={errors?.zoho_id ? errors?.zoho_id[0] : ''}>
        <HOPInput
          currentValue={formValue.zoho_id}
          handleValue={value => {
            setFormValue({ ...formValue, zoho_id: value })
          }}
        />
      </FormControl>
      <FormControl
        label={t('assistancesConfiguration:zoho_prefix')}
        error={errors?.potential_name ? errors?.potential_name[0] : ''}
      >
        <HOPInput
          currentValue={formValue.zoho_prefix}
          handleValue={value => {
            setFormValue({ ...formValue, zoho_prefix: value })
          }}
        />
      </FormControl>
      <FormControl label={t('assistancesConfiguration:price')} error={errors?.price ? errors?.price[0] : ''}>
        <HOPInput
          currentValue={formValue.price}
          handleValue={value => {
            setFormValue({ ...formValue, price: value })
          }}
        />
      </FormControl>
      <FormControl label={t('assistancesConfiguration:status')} error={errors?.status ? errors?.status[0] : ''}>
        <HOPSelect
          optionsList={statusList}
          placeholder={
            formValue.status === 0 || formValue.status === 1
              ? parseInt(formValue.status) === 0
                ? t('assistancesConfiguration:status_inactive')
                : t('assistancesConfiguration:status_active')
              : t('assistancesConfiguration:place_holder')
          }
          selectedValue={formValue.status}
          onChangeHandler={params => {
            setFormValue({ ...formValue, status: params.value[0].id })
          }}
        />
      </FormControl>
      <FormControl
        label={t('assistancesConfiguration:description')}
        error={errors?.description ? errors?.description[0] : ''}
      >
        <Editor
          apiKey={tinymceKey}
          init={{
            plugins: [
              'advlist',
              'autolink',
              'lists',
              'link',
              'image',
              'charmap',
              'preview',
              'anchor',
              'searchreplace',
              'visualblocks',
              'code',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'code',
              'wordcount',
            ],
            toolbar:
              'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
          }}
          initialValue={formValue.description}
          onInit={(evt, editor) => {
            descriptionEditorRef.current = editor
          }}
          onChange={e => {
            setFormValue({ ...formValue, description: e.target.value })
          }}
        />
      </FormControl>
      <FormControl
        label={t('assistancesConfiguration:know_more')}
        error={errors?.know_more ? errors?.know_more[0] : ''}
      >
        <Editor
          apiKey={tinymceKey}
          init={{
            plugins: [
              'advlist',
              'autolink',
              'lists',
              'link',
              'image',
              'charmap',
              'preview',
              'anchor',
              'searchreplace',
              'visualblocks',
              'code',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'code',
              'wordcount',
            ],
            toolbar:
              'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
          }}
          initialValue={formValue.know_more}
          onInit={(evt, editor) => {
            knowMoreEditorRef.current = editor
          }}
          onChange={e => {
            setFormValue({ ...formValue, know_more: e.target.value })
          }}
        />
      </FormControl>
      <SDButton
        size={SIZE.compact}
        block={false}
        overrides={{
          BaseButton: {
            style: () => ({
              marginRight: '.5rem',
            }),
          },
        }}
        type='submit'
      >
        {selectedAssistance.id ? t('common:edit') : t('assistancesConfiguration:create')}
      </SDButton>
      <SDButton size={SIZE.compact} block={false} type='button' onClick={onCancel}>
        {t('common:cancel')}
      </SDButton>
    </form>
  )
}

AssistanceForm.propTypes = {
  onCancel: PropTypes.func,
  tinymceKey: PropTypes.string,
}

export default AssistanceForm
