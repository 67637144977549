import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useStyletron } from 'baseui'
import { updateDashboardFilters } from '../DashboardSlice'

const useDashboardFilters = ({ filters }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [css, theme] = useStyletron()
  const dispatchFilters = filters => {
    dispatch(updateDashboardFilters(filters))
  }
  const mapValues = (value, filter, returnIndex = false) => {
    const mapping = {
      status: [
        { string: 'treated', index: 0 },
        { string: 'nonTreated', index: 1 },
        { string: 'error', index: 2 },
        { string: 'abandonedSelectra', index: 3 },
        { string: 'abandonedProvider', index: 4 },
      ],
      origin: [
        { string: 'tv', index: 0 },
        { string: 'web', index: 1 },
      ],
      energy: [
        { string: 'elec', index: 0 },
        { string: 'gas', index: 1 },
        { string: 'dual', index: 2 },
        { string: 'propane', index: 3 },
      ],
      productType: [
        { string: 'box', index: 0 },
        { string: 'mobile', index: 1 },
      ],
      productTypeInsurance: [
        { string: 'home', index: 0 },
        { string: 'car', index: 1 },
        { string: 'health', index: 1 },
        { string: 'other', index: 1 },
      ],
      prestation: [
        { string: 'cdf', index: 0 },
        { string: 'mes', index: 1 },
      ],
      prestationInsurance: [
        { string: 'cdf', index: 0 },
        { string: 'new', index: 1 },
      ],
      prestationEnergy: [
        { string: 'cdf', index: 0 },
        { string: 'mes', index: 1 },
        { string: 'fmes', index: 2 },
      ],
      contractType: [
        { string: 'part', index: 0 },
        { string: 'pro', index: 1 },
      ],
      details: [
        { string: 'owned', index: 0 },
        { string: 'withCallback', index: 1 },
      ],
      custom: [
        { string: 'ohmCdf', index: 0 },
        { string: 'ohmCdfRelaunch', index: 1 },
      ],
      priority: [
        { string: 'high', index: 0 },
        { string: 'low', index: 1 },
      ],
    }

    let mappedValue

    if (typeof value === 'number') {
      mapping[filter].forEach(entry => {
        if (returnIndex) {
          if (entry.string === value) {
            mappedValue = [entry.index]
          }
        } else {
          if (entry.index === value) {
            mappedValue = entry.string
          }
        }
      })
    } else if (typeof value === 'string') {
      mapping[filter].forEach(entry => {
        if (returnIndex) {
          if (entry.string === value) {
            mappedValue = [entry.index]
          }
        } else {
          if (entry.string === value) {
            mappedValue = entry.string
          }
        }
      })
    } else if (typeof value === 'object') {
      mappedValue = new Array()
      mapping[filter].forEach(entry => {
        if (returnIndex) {
          if (value.includes(entry.string)) {
            mappedValue.push(entry.index)
          }
        }
      })
    }

    return mappedValue
  }
  const cleanNonCustomFilters = () => {
    for (const [key] of Object.entries(filters)) {
      if (key !== 'custom') {
        filters[key] = key === 'details' ? [] : ''
      }
    }
    return filters
  }
  const manageCheckboxGroup = (event, index, filter) => {
    const payload = { ...filters }
    if (filter !== 'custom') {
      payload.custom = ''
    }

    if (filters[filter]) {
      if (!filters[filter].includes(mapValues(index, filter))) {
        dispatchFilters({
          ...payload,
          [filter]: filter === 'priority' ? [mapValues(index, filter)] : [...filters[filter], mapValues(index, filter)],
        })
      } else {
        dispatchFilters({
          ...payload,
          [filter]: filters[filter].filter(value => value !== mapValues(index, filter)),
        })
      }
    } else {
      dispatchFilters({
        ...payload,
        [filter]: [mapValues(index, filter)],
      })
    }
  }
  const manageRadioGroup = (event, index, filter) => {
    const getter = filters[filter]
    let payload = { ...filters }
    const getValue = mapValues(index, filter)
    if (filter !== 'custom') {
      payload.custom = ''
    }

    if (filter === 'custom') {
      payload = { ...cleanNonCustomFilters() }
    }

    if (getter === getValue) {
      dispatchFilters({
        ...payload,
        [filter]: '',
      })
    } else {
      dispatchFilters({
        ...payload,
        [filter]: mapValues(index, filter),
      })
    }
  }

  return {
    t,
    css,
    theme,
    dispatchFilters,
    manageCheckboxGroup,
    manageRadioGroup,
    mapValues,
  }
}

export default useDashboardFilters
