import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconCarbonFire = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'Fire'
  size = size ?? 16
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 32 32' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <path d='M24.832,16.9688c-.2724-.6465-.5815-1.38-.8833-2.2852-.79-2.3682,1.7344-4.9522,1.7583-4.9766L24.293,8.293c-.1407.1406-3.4234,3.4775-2.2417,7.0234.3261.978.6513,1.749.938,2.43A9.3812,9.3812,0,0,1,24,22a6.24,6.24,0,0,1-4.1892,5.293,8.52,8.52,0,0,0-2.1038-8l-1.0444-1.0445-.5815,1.3575C14.2449,23.89,12.06,25.76,10.7747,26.54A5.8437,5.8437,0,0,1,8,22a9.6239,9.6239,0,0,1,.9287-3.6289A11.3329,11.3329,0,0,0,10,14V12.2217c.8735.36,2,1.3037,2,3.7783v2.6035l1.7432-1.9341c3.1118-3.4546,2.4624-7.5678,1.206-10.3081A4.4859,4.4859,0,0,1,18,11h2c0-5.5371-4.5786-7-7-7H11l1.1992,1.5986c.1377.1856,2.8628,3.9278,1.3535,7.688A4.9426,4.9426,0,0,0,9,10H8v4a9.6239,9.6239,0,0,1-.9287,3.6289A11.3329,11.3329,0,0,0,6,22c0,3.8477,3.8232,8,10,8s10-4.1523,10-8A11.3771,11.3771,0,0,0,24.832,16.9688ZM12.8352,27.5264a16.4987,16.4987,0,0,0,4.3665-5.5987,6.1053,6.1053,0,0,1,.2573,5.9717A11.3213,11.3213,0,0,1,16,28,10.3278,10.3278,0,0,1,12.8352,27.5264Z' />
    </Icon>
  )
}

IconCarbonFire.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconCarbonFire
