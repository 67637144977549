import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'
import { Spinner } from 'baseui/spinner'

const SpinnerText = ({ text }) => {
  const [css] = useStyletron()

  return (
    <div
      className={css({
        alignItems: 'center',
        display: 'flex',
      })}
    >
      <Spinner size='32px' />
      <p
        className={css({
          marginLeft: '10px',
        })}
      >
        {text}
      </p>
    </div>
  )
}

SpinnerText.propTypes = {
  text: PropTypes.string,
}

export default SpinnerText
