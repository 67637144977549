import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import { Icon } from 'baseui/icon'

const IconCarbonDocumentUnknown = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'Document unknown'
  size = size ?? 16
  color = color ?? theme.colors.primary

  return (
    <Icon data-baseweb='icon' ref={ref} viewBox='0 0 32 32' size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <circle cx='9' cy='28.5' r='1.5' />
      <path d='M10 25H8v-4h2a2 2 0 0 0 0-4H8a2.002 2.002 0 0 0-2 2v.5H4V19a4.005 4.005 0 0 1 4-4h2a4 4 0 0 1 0 8z' />
      <path d='m27.7 9.3-7-7A.908.908 0 0 0 20 2H10a2.006 2.006 0 0 0-2 2v8h2V4h8v6a2.006 2.006 0 0 0 2 2h6v16H14v2h12a2.006 2.006 0 0 0 2-2V10a.91.91 0 0 0-.3-.7zM20 10V4.4l5.6 5.6z' />
    </Icon>
  )
}

IconCarbonDocumentUnknown.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconCarbonDocumentUnknown
