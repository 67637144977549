import PropTypes from 'prop-types'
import { Select, SIZE } from 'baseui/select'
import { mergeOverrides } from 'baseui'
import { SDSelectOverrides } from './styled-components'

const SDSelect = ({ overrides, ...rest }) => {
  const newOverrides = mergeOverrides(SDSelectOverrides(), overrides)

  return (
    <Select
      {...rest}
      maxDropdownHeight='320px'
      labelKey='label'
      valueKey='label'
      size={SIZE.compact}
      overrides={newOverrides}
    />
  )
}

SDSelect.propTypes = {
  overrides: PropTypes.object,
}

export default SDSelect
