import primitives from './themePrimitives'

const selectraThemeOverrides = {
  borders: {
    buttonBorderRadius: '.5rem',
    inputBorderRadius: '.5rem',
    radius100: '.24rem',
    radius200: '.5rem',
    radius300: '.75rem',
    radius400: '1rem',
  },
  colors: {
    borderFocus: primitives.primary,
    borderSelected: primitives.primary,
    contentPrimary: primitives.mono800,
    inputFill: primitives.white,
    inputFillDisabled: primitives.mono100,
    inputFillActive: primitives.white,
    inputBorder: primitives.mono300,
    inputEnhancerFill: primitives.primary,
    inputEnhancerFillDisabled: primitives.mono300,
    inputPlaceholder: primitives.mono500,

    menuFill: primitives.white,
    menuFillHover: primitives.bg050,
    menuFontSelected: primitives.primary,
    menuFontHighlighted: primitives.primary,

    buttonSecondaryFill: primitives.bg050,
    buttonSecondaryText: primitives.primary,
    buttonSecondaryHover: primitives.bg100,
    buttonSecondaryActive: primitives.bg150,

    buttonTertiaryFill: primitives.white,
    buttonTertiaryText: primitives.primary,
    buttonTertiaryHover: primitives.bg050,
    buttonTertiaryActive: primitives.bg150,

    tableBackground: primitives.white,
    tableStripedBackground: primitives.bg050,
  },
  lighting: {
    shadow400: '0px 1px 3px rgba(0, 0, 17, 0.24)',
    shadow500: '0px 2px 6px rgba(0, 0, 17, 0.24)',
    shadow600: '0px 4px 12px rgba(0, 0, 17, 0.25)',
    shadow700: '0px 8px 24px rgba(0, 0, 17, 0.24)',
  },
  grid: {
    columns: [12, 12, 12],
    unit: 'rem',
    gutters: [1, 1, 1],
    margins: [0, 0, 0],
  },
  typography: {
    HeadingXSmall: {
      fontWeight: 700,
      fontFamily: '"Trebuchet MS", sans-serif',
    },
    HeadingSmall: {
      fontWeight: 700,
      fontFamily: '"Trebuchet MS", sans-serif',
    },
    HeadingMedium: {
      fontWeight: 700,
      fontFamily: '"Trebuchet MS", sans-serif',
    },
    HeadingLarge: {
      fontWeight: 700,
      fontFamily: '"Trebuchet MS", sans-serif',
    },
    HeadingXLarge: {
      fontWeight: 700,
      fontFamily: '"Trebuchet MS", sans-serif',
    },
    HeadingXXLarge: {
      fontWeight: 700,
      fontFamily: '"Trebuchet MS", sans-serif',
    },
    DisplayXSmall: {
      fontWeight: 700,
      fontFamily: '"Trebuchet MS", sans-serif',
    },
    DisplaySmall: {
      fontWeight: 700,
      fontFamily: '"Trebuchet MS", sans-serif',
    },
    DisplayMedium: {
      fontWeight: 700,
      fontFamily: '"Trebuchet MS", sans-serif',
    },
    DisplayLarge: {
      fontWeight: 700,
      fontFamily: '"Trebuchet MS", sans-serif',
    },
    DisplayXLarge: {
      fontWeight: 700,
      fontFamily: '"Trebuchet MS", sans-serif',
    },
    DisplayXXLarge: {
      fontWeight: 700,
      fontFamily: '"Trebuchet MS", sans-serif',
    },
    headingsFont: '"Trebuchet MS", sans-serif',
    bodyFont: 'Helvetica',
  },
}

export default selectraThemeOverrides
