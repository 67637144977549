import { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import CommonFallback from '@/shared/components/CommonFallback'
import { useTranslation } from 'react-i18next'
import ProviderManagementContext from '../ProviderManagementContext'
import useHttp from '@/shared/hooks/useHttp'
import TelecomOfferForm from './Telecom/TelecomOfferForm'
import EnergyOfferForm from './Energy/EnergyOfferForm'
import useBusinessType from '@/shared/hooks/useBusinessType'
import SaveTelecomOffer from './Telecom/SaveTelecomOffer'
import SaveEnergyOffer from './Energy/SaveEnergyOffer'
import SaveInsuranceOffer from './Insurance/saveInsuranceOffer'
import InsuranceOfferForm from './Insurance/InsuranceOfferForm'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { useSelector } from 'react-redux'

const OfferEdit = () => {
  const { lang } = useSelector(selectAuthData)
  const { type } = useBusinessType({
    loaderRoutesEnergy: {},
    loaderRoutesInsurance: {},
    loaderRoutes: {},
    modulesStatuses: {},
    modulesStatusesEnergy: {},
    modulesStatusesInsurance: {},
  })
  const providerContext = useContext(ProviderManagementContext)
  const providerState = providerContext.providerState
  const providerDispatch = providerContext.providerDispatch
  const { sendRequest, getConfig } = useHttp()
  const { t } = useTranslation()
  const { offerId, providerUuid } = useParams()
  const loadOfferData = async offerId => {
    await sendRequest(
      providerState.routes.offers.information.replace('*', offerId) + '?lang=' + lang,
      getConfig('GET'),
      json => providerDispatch({ type: 'setOffer', payload: json }),
    )
  }
  const loadProviderData = async providerUuid => {
    await sendRequest(
      providerState.routes.providerInformation.replace('*', providerUuid) + '?lang=' + lang,
      getConfig('GET'),
      json => providerDispatch({ type: 'setProvider', payload: json }),
    )
  }
  const saveOffer = () => {
    if (type === 'telecom') {
      SaveTelecomOffer(
        providerState,
        providerDispatch,
        providerState.routes.offers.save + '?lang=' + lang,
        getConfig('POST'),
        sendRequest,
      )
    }
    if (type === 'energie') {
      SaveEnergyOffer(
        providerState,
        providerDispatch,
        providerState.routes.offers.save + '?lang=' + lang,
        getConfig('POST'),
        sendRequest,
      )
    }
    if (type === 'assurance') {
      SaveInsuranceOffer(
        providerState,
        providerDispatch,
        providerState.routes.offers.save + '?lang=' + lang,
        getConfig('POST'),
        sendRequest,
      )
    }
  }

  useEffect(() => {
    providerDispatch({ type: 'setErrors', payload: {} })
    loadOfferData(offerId)
  }, [offerId])

  useEffect(() => {
    loadProviderData(providerUuid)
  }, [providerUuid])

  if (!providerState.selectedOffer.id) {
    return <CommonFallback />
  }
  return (
    <>
      {type === 'telecom' && (
        <TelecomOfferForm
          providerDispatch={providerDispatch}
          providerState={providerState}
          lang={lang}
          saveOffer={saveOffer}
          title={t('organizationManagement:title_edit_offer')
            .replace('%providerName%', providerState.selectedProvider.name)
            .replace('%offerName%', providerState.selectedOffer.name)}
        />
      )}
      {type === 'energie' && (
        <EnergyOfferForm
          providerDispatch={providerDispatch}
          providerState={providerState}
          saveOffer={saveOffer}
          title={t('organizationManagement:title_edit_offer')
            .replace('%providerName%', providerState.selectedProvider.name)
            .replace('%offerName%', providerState.selectedOffer.name)}
        />
      )}
      {type === 'assurance' && (
        <InsuranceOfferForm
          providerDispatch={providerDispatch}
          providerState={providerState}
          saveOffer={saveOffer}
          title={t('organizationManagement:title_edit_offer')
            .replace('%providerName%', providerState.selectedProvider.name)
            .replace('%offerName%', providerState.selectedOffer.name)}
        />
      )}
    </>
  )
}

OfferEdit.propTypes = {
  token: PropTypes.string,
}

export default OfferEdit
