import PropTypes from 'prop-types'
import { StyledBodyCell } from 'baseui/table-grid'
import useCommentModal from './useCommentsModal'

const CommentRow = ({ comments }) => {
  const { t, commentStyle, displayContentStyle, dateStyle } = useCommentModal({ handler: null, id: null })

  if (comments.length === 0) {
    return (
      <div role='row' className={displayContentStyle}>
        <StyledBodyCell>
          <span className={commentStyle}>{t('subscriptionTable:no_comments')}</span>
        </StyledBodyCell>
      </div>
    )
  }

  const reversedComments = [...comments].reverse()

  return reversedComments.map((row, index) => {
    const striped = index % 2 === 0
    const date = new Date(row.createdAt).toLocaleString('fr-FR')
    return (
      <div role='row' className={displayContentStyle} key={index}>
        <StyledBodyCell $striped={striped}>
          <span className={commentStyle}>{row.comment}</span>
          <span className={dateStyle}>{date}</span>
        </StyledBodyCell>
      </div>
    )
  })
}

CommentRow.propTypes = {
  comments: PropTypes.array,
}

export default CommentRow
