import { useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import ObjectiveCreate from './ObjectiveCreate'
import ObjectiveList from './ObjectiveList'
import ObjectiveEdit from './ObjectiveEdit'
import { useStyletron } from 'baseui'
import { useTranslation } from 'react-i18next'

const Objectives = () => {
  const [isAble, setIsAble] = useState(true)
  const { t } = useTranslation()
  const [css, theme] = useStyletron()
  const cardStyle = css({
    padding: '1rem',
    marginBottom: '2rem',
    backgroundColor: 'white',
    borderRadius: '1rem',
    boxShadow: '0 2px 6px rgba(20,96,170,.3)',
  })
  const headingStyle = css({
    margin: '1rem 10px 10px 10px',
    color: theme.colors.primary,
    ...theme.typography.HeadingMedium,
  })

  return (
    <>
      {!isAble && (
        <div className={cardStyle}>
          <h1 className={headingStyle}>{t('objectives:unauthorized_access')}</h1>
        </div>
      )}
      {isAble && (
        <Routes>
          <Route path='create' element={<ObjectiveCreate />} />
          <Route index path='' element={<ObjectiveList setIsAble={setIsAble} />} />
          <Route path=':objectiveId' element={<ObjectiveEdit />} />
        </Routes>
      )}
    </>
  )
}

export default Objectives
