import { useDispatch, useSelector } from 'react-redux'
import useBusinessType from '@/shared/hooks/useBusinessType'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { useTranslation } from 'react-i18next'
import { carbonOffsetsActions } from '../CarbonOffsetsSagas'
import { useSnackbar } from 'baseui/snackbar'
import { Check } from 'baseui/icon'
import { selectCarbonOffsetFormErrors, selectSelectedCarbonOffset } from '../CarbonOffsetsSelectors'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import { updateCarbonOffsetFormErrors, updateSelectedCarbonOffset } from '../CarbonOffsetsSlice'

const useCarbonOffsetsForm = setIsOpen => {
  const dispatch = useDispatch()
  const { loaderRoutesEnergy, loaderRoutes, loaderRoutesInsurance } = useSelector(selectAuthData)
  const { getApplicationUrl } = useBusinessType({
    loaderRoutesEnergy,
    loaderRoutesInsurance,
    loaderRoutes,
    modulesStatuses: {},
    modulesStatusesEnergy: {},
    modulesStatusesInsurance: {},
  })
  const initUrl = getApplicationUrl('carbon-offset')
  const { enqueue } = useSnackbar()
  const { site_id } = useSelector(selectPageConfigurationData)
  const selectedCarbonOffset = useSelector(selectSelectedCarbonOffset)
  const formErrors = useSelector(selectCarbonOffsetFormErrors)

  const { t } = useTranslation()

  const submitHandler = event => {
    event.preventDefault()
    dispatch(updateCarbonOffsetFormErrors({}))
    const carbonOffsetData = {
      id: selectedCarbonOffset.id ?? 0,
      name: selectedCarbonOffset.name,
      price: selectedCarbonOffset.price,
      description: selectedCarbonOffset.description,
      zoho_id: selectedCarbonOffset.zoho_id,
      energy_type: selectedCarbonOffset.energy_type ?? '',
      potential_name: selectedCarbonOffset.zoho_prefix,
      spb_code: selectedCarbonOffset.spb_code,
      site_id,
    }

    if (selectedCarbonOffset.id) {
      const id = selectedCarbonOffset.id
      dispatch({
        type: carbonOffsetsActions.UPDATE_CARBON_OFFSET,
        carbonOffsetData,
        id,
        initUrl,
        updateSuccessNotification,
      })
    } else {
      dispatch({
        type: carbonOffsetsActions.CREATE_CARBON_OFFSET,
        carbonOffsetData,
        initUrl,
        updateSuccessNotification,
      })
    }
  }

  const updateSuccessNotification = message => {
    setIsOpen(false)
    enqueue({
      message,
      startEnhancer: ({ size }) => <Check size={size} />,
    })
  }

  const updateCarbonOffsetData = newData => {
    dispatch(updateSelectedCarbonOffset({ ...selectedCarbonOffset, ...newData }))
  }

  return {
    t,
    submitHandler,
    selectedCarbonOffset,
    formErrors,
    updateCarbonOffsetData,
  }
}

export default useCarbonOffsetsForm
