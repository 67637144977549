import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconCarbonTag = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'Tag'
  size = size ?? 16
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 32 32' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <path
        d='M18.52,30a3,3,0,0,1-2.12-.88L2.88,15.61A3,3,0,0,1,2,13.49V5A3,3,0,0,1,5,2h8.49a3,3,0,0,1,2.12.88L29.12,16.39a3,3,0,0,1,0,4.25l-8.48,8.48A3,3,0,0,1,18.52,30ZM5,4A1,1,0,0,0,4,5v8.49a1,1,0,0,0,.3.71L17.81,27.71a1,1,0,0,0,1.41,0l8.49-8.49a1,1,0,0,0,0-1.41L14.2,4.3a1,1,0,0,0-.71-.3H5Z'
        transform='translate(0 0)'
      />
      <path d='M10,14a4,4,0,1,1,4-4A4,4,0,0,1,10,14Zm0-6a2,2,0,1,0,2,2A2,2,0,0,0,10,8Z' transform='translate(0 0)' />
    </Icon>
  )
}

IconCarbonTag.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconCarbonTag
