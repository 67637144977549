import { useStyletron } from 'baseui'

const useHomeStyles = () => {
  const [css, theme] = useStyletron()
  const cardStyle = css({
    padding: '1rem',
    marginBottom: '2rem',
    backgroundColor: 'white',
    borderRadius: '1rem',
    boxShadow: '0 2px 6px rgba(20,96,170,.3)',
  })
  const cardBonuses = css({
    padding: '1rem',
    marginBottom: '2rem',
    background: theme.colors.bg050,
    borderRadius: '1rem',
    boxShadow: '0 2px 6px rgba(20,96,170,.3)',
  })
  const headingStyle = css({
    margin: '1rem 10px 10px 10px',
    color: theme.colors.primary,
    ...theme.typography.HeadingMedium,
  })
  const parent = css({
    display: 'flex',
  })
  const child = css({
    flex: 1,
    width: '50%',
    margin: '0.2rem',
  })

  return {
    cardStyle,
    cardBonuses,
    headingStyle,
    parent,
    child,
  }
}

export default useHomeStyles
