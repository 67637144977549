import { call, put, select, takeLatest } from 'redux-saga/effects'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import getAPIConfig from '@/shared/tools/getAPIConfig'
import { initializeSaga } from '@/store/PageConfiguration/PageConfigurationSagas'
import { updateMonConseillerPersoFormErrors } from './MonConseillerPersoSlice'

export const monConseillerPersoActions = {
  UPDATE_MON_CONSEILLER_PERSO: 'UPDATE_MON_CONSEILLER_PERSO',
  CREATE_MON_CONSEILLER_PERSO: 'CREATE_MON_CONSEILLER_PERSO',
}

export function* updateMonConseillerPerso({ monConseillerPersoData, id, initUrl, updateSuccessNotification }) {
  try {
    yield put(updateMonConseillerPersoFormErrors({}))
    const { authTokenSession } = yield select(selectAuthData)
    const { routes } = yield select(selectPageConfigurationData)
    const updateRoute = routes.update + '?id=' + id

    const config = yield call(getAPIConfig, { method: 'put', authTokenSession, body: monConseillerPersoData })
    const response = yield call(fetch, updateRoute, config)
    const { errors } = yield call([response, response.json])

    if (response.status === 200) {
      yield call(updateSuccessNotification, 'MonConseillerPerso bien mise à jour')
      yield call(initializeSaga, { initUrl })
    } else {
      yield put(updateMonConseillerPersoFormErrors(errors))
    }
  } catch (error) {
    console.error(error)
  }
}

export function* createMonConseillerPerso({ monConseillerPersoData, initUrl, updateSuccessNotification }) {
  try {
    yield put(updateMonConseillerPersoFormErrors({}))
    const { authTokenSession } = yield select(selectAuthData)
    const { routes } = yield select(selectPageConfigurationData)

    const config = yield call(getAPIConfig, { method: 'post', authTokenSession, body: monConseillerPersoData })
    const response = yield call(fetch, routes.store, config)
    const { errors } = yield call([response, response.json])

    if (response.status === 200) {
      yield call(updateSuccessNotification, 'MonConseillerPerso crée')
      yield call(initializeSaga, { initUrl })
    } else {
      yield put(updateMonConseillerPersoFormErrors(errors))
    }
  } catch (error) {
    console.error(error)
  }
}

export default function* monConseillerPersoSagas() {
  yield takeLatest(monConseillerPersoActions.UPDATE_MON_CONSEILLER_PERSO, updateMonConseillerPerso)
  yield takeLatest(monConseillerPersoActions.CREATE_MON_CONSEILLER_PERSO, createMonConseillerPerso)
}
