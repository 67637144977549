import SDSelect from '@/shared/components/SDComponents/SDSelect/SDSelect'
import PropTypes from 'prop-types'
import { TYPE } from 'baseui/select'

const HOPSelect = ({
  hasError = false,
  optionsList,
  selectedValue,
  onChangeHandler,
  placeholder = '',
  clearable = false,
  type = TYPE.select,
  disabled = false,
}) => {
  const options = []
  const selected = []

  if (optionsList) {
    for (const [id, name] of Object.entries(optionsList)) {
      const data = {
        label: name,
        id,
      }
      options.push(data)

      if (id === selectedValue) {
        selected.push(data)
      }
    }
  }

  return (
    <SDSelect
      disabled={disabled}
      error={hasError}
      options={options}
      clearable={clearable}
      value={selected}
      onChange={onChangeHandler}
      placeholder={placeholder}
      type={type}
    />
  )
}

HOPSelect.propTypes = {
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  optionsList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChangeHandler: PropTypes.func,
  hasError: PropTypes.bool,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool,
}

export default HOPSelect
