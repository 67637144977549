import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'
import { useTranslation } from 'react-i18next'
import HeadingAddEdit from '../../OrganizationForm/HeadingAddEdit'
import { useEffect, useState } from 'react'
import { ORIENTATION, Tab, Tabs } from 'baseui/tabs-motion'
import { Card } from '../../../SubscriptionFormPage/AdditionalSalesPanel/styled-components'
import useHttp from '@/shared/hooks/useHttp'
import CommonFallback from '@/shared/components/CommonFallback'
import ContentTab from './Tabs/ContentTab'
import GeneralTab from './Tabs/GeneralTab'

const EnergyOfferForm = ({ providerDispatch, providerState, saveOffer, title }) => {
  const [activeTab, setActiveTab] = useState(0)
  const { t } = useTranslation()
  const [css, theme] = useStyletron()
  const { sendRequest, getConfig } = useHttp()
  const cardStyle = css({
    padding: '1rem',
    marginBottom: '2rem',
    color: 'white',
  })
  const blockStyle = css({
    padding: '1rem',
    marginBottom: '2rem',
    backgroundColor: 'white',
    borderRadius: '1rem',
    boxShadow: '0 2px 6px rgba(20,96,170,.3)',
    width: '100%',
    color: theme.colors.contentSecondary,
  })
  const sectionStyle = css({
    margin: '0.5rem 5px 5px 5px',
    color: theme.colors.primary,
  })
  const updateOffer = (attributeName, value) => {
    providerDispatch({ type: 'updateOffer', payload: { [attributeName]: value } })
  }
  const loadViewParameters = async () => {
    await sendRequest(providerState.routes.offers.viewParameters, getConfig('GET'), json =>
      providerDispatch({ type: 'setViewParameters', payload: json }),
    )
  }
  const errors = field => {
    const errorField = providerState.errors[field] !== undefined ? providerState.errors[field][0] ?? false : false
    if (errorField !== undefined) {
      return errorField
    }
  }

  useEffect(() => {
    loadViewParameters()
  }, [])

  if (!providerState.viewParameters.parametersLoaded) {
    return <CommonFallback />
  }

  return (
    <>
      <div className={cardStyle}>
        <HeadingAddEdit title={title} saveHandler={saveOffer} />
      </div>
      <div className={cardStyle}>
        <Card>
          <Tabs
            activeKey={activeTab}
            onChange={({ activeKey }) => {
              setActiveTab(activeKey)
            }}
            orientation={ORIENTATION.vertical}
          >
            <Tab title='General' key='0'>
              <GeneralTab
                blockStyle={blockStyle}
                providerState={providerState}
                updateOffer={updateOffer}
                errors={errors}
                t={t}
                sectionStyle={sectionStyle}
              />
            </Tab>
            <Tab title='Contenu' key='1'>
              <ContentTab
                fields={providerState.viewParameters.attributes?.texts ?? {}}
                blockStyle={blockStyle}
                providerState={providerState}
                updateOffer={updateOffer}
                t={t}
              />
            </Tab>
          </Tabs>
        </Card>
      </div>
    </>
  )
}

EnergyOfferForm.propTypes = {
  providerState: PropTypes.object,
  providerDispatch: PropTypes.func,
  saveOffer: PropTypes.func,
  title: PropTypes.string,
}

export default EnergyOfferForm
