import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconDossier = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'Calendar'
  size = size ?? 60
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 40 40' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>

      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 39C30.4934 39 39 30.4934 39 20C39 9.50659 30.4934 1 20 1C9.50659 1 1 9.50659 1 20C1 30.4934 9.50659 39 20 39ZM20 37.1C10.5559 37.1 2.9 29.4441 2.9 20C2.9 10.5559 10.5559 2.9 20 2.9C29.4441 2.9 37.1 10.5559 37.1 20C37.1 29.4441 29.4441 37.1 20 37.1Z'
        fill='#004791'
      />
      <path
        d='M20 13.1258C20.25 13.1258 20.4623 13.0381 20.637 12.8628C20.8123 12.6881 20.9 12.4674 20.9 12.2008C20.9 11.9508 20.8123 11.7381 20.637 11.5628C20.4623 11.3881 20.25 11.3008 20 11.3008C19.75 11.3008 19.5377 11.3881 19.363 11.5628C19.1877 11.7381 19.1 11.9591 19.1 12.2258C19.1 12.4758 19.1877 12.6881 19.363 12.8628C19.5377 13.0381 19.75 13.1258 20 13.1258ZM13.3 28.5008C12.8 28.5008 12.375 28.3258 12.025 27.9758C11.675 27.6258 11.5 27.2008 11.5 26.7008V13.3008C11.5 12.8008 11.675 12.3758 12.025 12.0258C12.375 11.6758 12.8 11.5008 13.3 11.5008H17.725C17.8583 11.0174 18.1373 10.6131 18.562 10.2878C18.9873 9.96311 19.4667 9.80078 20 9.80078C20.55 9.80078 21.0377 9.96311 21.463 10.2878C21.8877 10.6131 22.1667 11.0174 22.3 11.5008H26.7C27.2 11.5008 27.625 11.6758 27.975 12.0258C28.325 12.3758 28.5 12.8008 28.5 13.3008V26.7008C28.5 27.2008 28.325 27.6258 27.975 27.9758C27.625 28.3258 27.2 28.5008 26.7 28.5008H13.3ZM13.3 27.0008H26.7C26.7667 27.0008 26.8333 26.9674 26.9 26.9008C26.9667 26.8341 27 26.7675 27 26.7008V13.3008C27 13.2341 26.9667 13.1674 26.9 13.1008C26.8333 13.0341 26.7667 13.0008 26.7 13.0008H24.5V13.8008C24.5 14.3008 24.325 14.7301 23.975 15.0888C23.625 15.4468 23.2 15.6258 22.7 15.6258H17.3C16.8 15.6258 16.375 15.4468 16.025 15.0888C15.675 14.7301 15.5 14.3008 15.5 13.8008V13.0008H13.3C13.2333 13.0008 13.1667 13.0341 13.1 13.1008C13.0333 13.1674 13 13.2341 13 13.3008V26.7008C13 26.7675 13.0333 26.8341 13.1 26.9008C13.1667 26.9674 13.2333 27.0008 13.3 27.0008Z'
        fill='white'
      />
    </Icon>
  )
}

IconDossier.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconDossier
