import PropTypes from 'prop-types'
import { StyledBodyCell } from 'baseui/table-grid'
import StatusBadge from './StatusBadge'
import { useStyletron } from 'baseui'
import { useTranslation } from 'react-i18next'
import getSubscriptionFromZohoStatusColour from './getSubscriptionFromZohoStatusColour'

const StatusAdditionalSalesCell = ({ status, striped }) => {
  const { t } = useTranslation()
  const [css] = useStyletron()
  const cellStyle = css({
    padding: '.5rem',
    backgroundColor: 'none',
  })
  const isNoStatus = () => {
    return status !== null && status !== undefined && status.length > 0 && status !== '0'
  }
  const colour = getSubscriptionFromZohoStatusColour(status)
  const computedStatus = isNoStatus() ? status : t('subscriptionTable:no_status')

  return (
    <StyledBodyCell className={cellStyle} $striped={striped}>
      <StatusBadge status={computedStatus} colour={colour} />
    </StyledBodyCell>
  )
}

StatusAdditionalSalesCell.propTypes = {
  status: PropTypes.string,
  striped: PropTypes.bool,
  retrievedFrom: PropTypes.string,
}

export default StatusAdditionalSalesCell
