import { useState } from 'react'
import { SIZE, KIND } from 'baseui/button'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { StyledSubmission } from '../form-header-styled-components'
import SDModal from '@/shared/components/SDComponents/SDModal/SDModal'
import { ModalSection, ModalText } from '@/shared/components/SDComponents/SDModal/styled-components'
import { useTranslation } from 'react-i18next'
import useFormStatus from '../../../../containers/SubscriptionFormPage/useFormStatus'
import { useSelector } from 'react-redux'
import {
  selectCommonFormConfigurationData,
  selectOfferConfiguration,
} from '../../../../containers/SubscriptionFormPage/SubscriptionFormConfigurationSelectors'

const SubscriptionSignHelper = () => {
  const { t } = useTranslation()
  const { realStatus } = useSelector(selectCommonFormConfigurationData)
  const offerConfig = useSelector(selectOfferConfiguration)
  const { formIsLoaded } = useFormStatus()
  const signatureHelpText = offerConfig.attributes?.signature_help
  const [isOpen, setIsOpen] = useState(false)
  const areThereOfferSignHelp = () => {
    return offerConfig.attributes?.signature_help && realStatus === 'Z0100'
  }
  const showModal = event => {
    event.preventDefault()
    setIsOpen(true)
  }
  const showComponent = () => {
    return formIsLoaded && areThereOfferSignHelp()
  }
  const modalSections = () => {
    if (signatureHelpText !== '') {
      return (
        <ModalSection>
          <ModalText>
            <div dangerouslySetInnerHTML={{ __html: signatureHelpText }}></div>
          </ModalText>
        </ModalSection>
      )
    }
  }

  if (!showComponent()) {
    return ''
  }

  return (
    <StyledSubmission>
      <SDButton
        size={SIZE.compact}
        kind={KIND.secondary}
        overrides={{
          BaseButton: {
            style: () => ({
              marginRight: '1rem',
            }),
          },
        }}
        onClick={event => showModal(event)}
      >
        {t('subscriptionForm:header.sign_help')}
      </SDButton>
      <SDModal
        backgroundImage={false}
        openHandler={setIsOpen}
        isOpen={isOpen}
        sections={modalSections()}
        title={t('subscriptionForm:header.sign_help')}
      />
    </StyledSubmission>
  )
}

export default SubscriptionSignHelper
