import { styled } from 'baseui'

export const Card = styled('div', ({ $theme }) => ({
  margin: '0 0 1rem 0',
  background: $theme.colors.bg100,
  padding: '1rem',
  borderRadius: $theme.borders.radius400,
  boxShadow: $theme.lighting.shadow400,
}))

export const Title = styled('h2', ({ $theme }) => ({
  color: $theme.colors.primary,
  ...$theme.typography.font650,
}))

export const Columns = styled('div', () => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '@media screen and (min-width: 1200px)': {
    flexDirection: 'row',
  },
}))

export const Column = styled('div', () => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  margin: '0 0 1rem 1rem',
  ':last-child': {
    flexDirection: 'column-reverse',
    justifyContent: 'space-between',
    margin: '0 0 0 1rem',
  },
  '@media screen and (min-width: 1200px)': {
    width: '100%',
    margin: '0 0 0 1rem',
  },
}))

export const ValidationCard = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  margin: '0 0 1rem 0',
  padding: '1rem',
  backgroundColor: $theme.colors.bg050,
  borderRadius: $theme.borders.radius200,
  boxShadow: $theme.lighting.shadow400,
}))

export const ValidationTitle = styled('h3', ({ $theme }) => ({
  color: $theme.colors.primary,
  ...$theme.typography.font650,
  fontSize: $theme.typography.ParagraphSmall.fontSize,
  lineHeight: $theme.typography.ParagraphSmall.lineHeight,
}))

export const ValidationBadge = styled('div', ({ $theme, status }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 0 .5rem 0',
  padding: '.125rem',
  borderRadius: $theme.borders.radius100,
  ...$theme.typography.font650,
  fontSize: $theme.typography.ParagraphSmall.fontSize,
  lineHeight: $theme.typography.ParagraphSmall.lineHeight,
  color: status === 'valid' ? $theme.colors.positive600 : $theme.colors.negative600,
  textAlign: 'center',
  backgroundColor: status === 'valid' ? $theme.colors.positive200 : $theme.colors.negative200,
  border: `solid 1px ${status === 'valid' ? $theme.colors.positive600 : $theme.colors.negative600}`,
  ':last-child': {
    margin: '0',
  },
}))

export const tabsOverrides = isAdded => ({
  Root: {
    style: {
      width: '100%',
    },
  },
  TabList: {
    style: {
      width: '40%',
      fontSize: '1rem',
      '@media screen and (min-width: 1200px)': {
        width: '30%',
      },
      '@media screen and (min-width: 1400px)': {
        width: '28%',
      },
      '@media screen and (min-width: 1600px)': {
        width: '22%',
      },
    },
  },
  TabHighlight: {
    style: ({ $theme }) => ({
      backgroundColor: isAdded ? $theme.colors.positive500 : $theme.colors.primary,
    }),
  },
  TabBorder: {
    style: ({ $theme }) => ({
      backgroundColor: $theme.colors.mono300,
    }),
  },
})

export const titleTabOverrides = () => ({
  Tab: {
    style: ({ $theme }) => ({
      'justify-content': 'start',
      fontWeight: 'bold',
      background: $theme.colors.bg100,
      outlineOffset: '-2px',
      ...$theme.typography.font550,
      padding: '.1rem',
      color: 'black',
      fontSize: '0.8rem',
      '@media screen and (min-width: 800px)': {
        fontSize: '0.8rem',
      },
      '@media screen and (min-width: 1200px)': {
        fontSize: '0.85rem',
      },
      '@media screen and (min-width: 1400px)': {
        fontSize: '0.9rem',
      },
      '@media screen and (min-width: 1600px)': {
        fontSize: '0.9rem',
      },
    }),
  },
})

export const tabOverrides = (isSelected, isAdded) => ({
  Tab: {
    style: ({ $theme }) => ({
      'justify-content': 'start',
      background: isSelected ? (isAdded ? $theme.colors.positive150 : $theme.colors.bg150) : $theme.colors.bg100,
      borderRadius: '.5rem 0 0 .5rem',
      outlineOffset: '-2px',
      ...$theme.typography.font550,
      fontSize: '.7rem',
      paddingTop: '.5rem',
      paddingRight: '.5rem',
      paddingBottom: '.5rem',
      paddingLeft: '1rem',
      color: isAdded ? $theme.colors.positive500 : isSelected ? $theme.colors.primary : $theme.colors.mono800,
      ':hover': {
        outline: `2px ${isAdded ? $theme.colors.positive500 : $theme.colors.primary} solid`,
        background: $theme.colors.bg10,
      },
      '@media screen and (min-width: 800px)': {
        fontSize: '0.70rem',
      },
      '@media screen and (min-width: 1200px)': {
        fontSize: '0.75rem',
      },
      '@media screen and (min-width: 1400px)': {
        fontSize: '0.85rem',
      },
    }),
  },

  TabPanel: {
    style: () => ({
      width: '50%',
      paddingTop: '0',
      paddingRight: '0',
      paddingBottom: '0',
      paddingLeft: '0',
      '@media screen and (min-width: 1200px)': {
        width: '67%',
      },
    }),
  },

  ArtworkContainer: {
    style: () => ({
      marginRight: '.25rem',
    }),
  },
})

export const ProviderOverrides = () => ({
  Svg: {
    style: () => ({
      marginLeft: '.25rem',
    }),
  },
})

export const IconOverrides = () => ({
  Svg: {
    style: () => ({
      marginRight: '.25rem',
    }),
  },
})
