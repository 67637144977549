import useHttp from '@/shared/hooks/useHttp'
import { useState } from 'react'
import { SORT_DIRECTION } from 'baseui/table-grid'
import { compareDates, compareNumber, compareReverseString, compareString } from '@/shared/tools/compareFunctions'
import { useSelector } from 'react-redux'
import { selectAuthData } from '@/containers/Login/AuthSelectors'

const useOfferManagement = ({ t, duplicateRoute, deleteRoute, providerDispatch, loadProviderData, providerUuid }) => {
  const { sendRequest, getConfig } = useHttp()
  const { lang } = useSelector(selectAuthData)
  const deleteOffer = id => {
    if (window.confirm(t('organizationManagement:confirm_deletion'))) {
      sendRequest(deleteRoute.replace('*', id) + '?lang=' + lang, getConfig('DELETE'), (json, status) => {
        if (status === 200) {
          providerDispatch({ type: 'saving', payload: { offerDeleted: true } })
          loadProviderData(providerUuid)
        }
      })
    }
  }

  const duplicateOffer = id => {
    sendRequest(duplicateRoute.replace('*', id) + '?lang=' + lang, getConfig('POST'), (json, status) => {
      if (status === 200) {
        providerDispatch({ type: 'saving', payload: { offerDuplicated: true } })
        loadProviderData(providerUuid)
      }
    })
  }

  const url = new URL(window.location.href)
  const [sort, setSort] = useState({
    sortingKey: url.searchParams.get('sortingKey') ?? null,
    direction: url.searchParams.get('direction') ?? null,
  })
  const setSorting = state => {
    Object.keys(state).forEach(key => {
      url.searchParams.set(key, state[key])
    })

    window.history.pushState({ path: url.href }, null, url.href)

    setSort(state)
  }

  const handleSorting = key => {
    if (sort.direction === SORT_DIRECTION.ASC) {
      setSorting({
        sortingKey: key,
        direction: SORT_DIRECTION.DESC,
      })
    } else {
      setSorting({
        sortingKey: key,
        direction: SORT_DIRECTION.ASC,
      })
    }
  }

  const getSortedData = data => {
    const array = data.slice(0)

    switch (sort.sortingKey) {
      case 'name':
        return sort.direction === SORT_DIRECTION.ASC
          ? array.sort((a, b) => {
              return compareString(a.name, b.name)
            })
          : array.sort((a, b) => {
              return compareReverseString(a.name, b.name)
            })

      case 'energy_type':
        return sort.direction === SORT_DIRECTION.ASC
          ? array.sort((a, b) => {
              return compareString(a.energy_type, b.energy_type)
            })
          : array.sort((a, b) => {
              return compareReverseString(a.energy_type, b.energy_type)
            })
      case 'product_type':
        return sort.direction === SORT_DIRECTION.ASC
          ? array.sort((a, b) => {
              return compareString(a.product_type, b.product_type)
            })
          : array.sort((a, b) => {
              return compareReverseString(a.product_type, b.product_type)
            })
      case 'complete':
        array.sort((a, b) => {
          return compareNumber(a.is_complete, b.is_complete)
        })
        return sort.direction === SORT_DIRECTION.ASC ? array : array.reverse()
      case 'status':
        array.sort((a, b) => {
          return compareNumber(a.active, b.active)
        })
        return sort.direction === SORT_DIRECTION.ASC ? array : array.reverse()

      case 'inactiveDate':
        array.sort((a, b) => {
          return compareDates(a.inactive_date, b.inactive_date)
        })
        return sort.direction === SORT_DIRECTION.ASC ? array : array.reverse()
      default:
        return data
    }
  }

  return {
    deleteOffer,
    duplicateOffer,
    handleSorting,
    sort,
    getSortedData,
  }
}

export default useOfferManagement
