import PropTypes from 'prop-types'
import { FlexGrid } from 'baseui/flex-grid'
import BonusCard from './BonusCard'
import { useStyletron } from 'baseui'

const TopSalesAgents = ({ salesAgentsBySite }) => {
  const icons = ['🥇', '🥈', '🥉']
  const SalesAgents = salesAgents => {
    const agents = salesAgents.salesAgents
    const agentsDetails = []
    let medal = 0

    Array.from(agents).forEach(agent => {
      if (agent.infos.total_primes !== '0,00 €') {
        agentsDetails.push(<BonusCard key={agent.id} title={icons[medal] + agent.sales_agent_name} />)
      }
      medal++
    })

    return agentsDetails
  }

  const SitesList = () => {
    const sites = []
    const [css, theme] = useStyletron()
    for (const site in salesAgentsBySite) {
      if ('error' in salesAgentsBySite[site] || salesAgentsBySite[site].length === 0) {
        continue
      }

      sites.push(
        <div
          className={css({
            fontSize: '18px',
            textAlign: 'center',
            margin: 'auto',
            minHeight: '25rem',
          })}
          key={site}
        >
          <span
            className={css({
              color: theme.colors.primary,
              fontWeight: 'bold',
              fontSize: '1.75rem',
            })}
          >
            {site.replace('Stats', '')}
          </span>
          <SalesAgents salesAgents={salesAgentsBySite[site]} />
        </div>,
      )
    }
    return sites
  }

  return (
    <FlexGrid flexGridColumnCount={3} flexGridColumnGap='scale800' flexGridRowGap='scale800'>
      <SitesList />
    </FlexGrid>
  )
}

TopSalesAgents.propTypes = {
  salesAgentsBySite: PropTypes.object,
}

export default TopSalesAgents
