import { useContext } from 'react'
import { useStyletron } from 'baseui'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { KIND, SIZE } from 'baseui/button'
import { TYPE } from 'baseui/select'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { useNavigate } from 'react-router-dom'
import SDSelect from '@/shared/components/SDComponents/SDSelect/SDSelect'
import { useTranslation } from 'react-i18next'
import ProviderManagementContext from '../ProviderManagementContext'
import { useSelector } from 'react-redux'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import useBusinessType from '@/shared/hooks/useBusinessType'

const Heading = () => {
  const providerContext = useContext(ProviderManagementContext)
  const providerState = providerContext.providerState
  const { loaderRoutesEnergy, loaderRoutes, loaderRoutesInsurance } = useSelector(selectAuthData)
  const { type } = useBusinessType({
    loaderRoutesEnergy,
    loaderRoutes,
    loaderRoutesInsurance,
    modulesStatuses: {},
    modulesStatusesEnergy: {},
    modulesStatusesInsurance: {},
  })
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [css, theme] = useStyletron()
  const headingStyle = css({
    margin: '1rem 10px 10px 10px',
    color: 'white',
    ...theme.typography.HeadingMedium,
  })
  const itemProps = {
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
  }
  const selectedItem = () => {
    const selected = []
    if (!providerState.selectedProvider.uuid || providerState.general.providersList.length <= 0) {
      return selected
    }
    providerState.general.providersList.map(provider => {
      if (provider.uuid === providerState.selectedProvider.uuid) {
        selected.push(provider)
      }
    })

    return selected
  }

  return (
    <>
      <h1 className={headingStyle}>{providerState.general.title}</h1>
      <FlexGrid flexGridColumnCount={3} flexGridColumnGap='scale800' flexGridRowGap='scale800' marginBottom='scale800'>
        <FlexGridItem {...itemProps}>
          <SDSelect
            type={TYPE.search}
            options={providerState.general.providersList}
            value={selectedItem()}
            onChange={params => {
              const providerId = params.value[0]?.uuid ?? ''
              if (providerId !== '') {
                navigate('/' + type + '/organisations/provider/' + providerId)
              } else {
                navigate('/' + type + '/organisations/provider')
              }
            }}
          />
        </FlexGridItem>
        <FlexGridItem {...itemProps}>
          {providerState.selectedProvider.uuid && (
            <>
              <SDButton
                onClick={() =>
                  navigate('/' + type + '/organisations/provider/edit/' + providerState.selectedProvider.uuid)
                }
                size={SIZE.mini}
                kind={KIND.secondary}
                className={css({
                  marginRight: '10px',
                })}
              >
                {t('common:edit')}
              </SDButton>
              <SDButton
                onClick={() =>
                  navigate(
                    '/' + type + '/organisations/provider/' + providerState.selectedProvider.uuid + '/offers/add',
                  )
                }
                size={SIZE.mini}
                kind={KIND.secondary}
                className={css({
                  marginRight: '10px',
                })}
              >
                {t('organizationManagement:add_offer_btn')}
              </SDButton>
            </>
          )}
          <SDButton
            onClick={() => navigate('/' + type + '/organisations/provider/add')}
            size={SIZE.mini}
            kind={KIND.tertiary}
          >
            {t('organizationManagement:add_provider_btn')}
          </SDButton>
        </FlexGridItem>
      </FlexGrid>
    </>
  )
}

export default Heading
