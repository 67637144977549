import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useBusinessType from '@/shared/hooks/useBusinessType'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { pageConfigurationActions } from '@/store/PageConfiguration/PageConfigurationSagas'
import {
  selectPageConfigurationIsLoading,
  selectPageConfigurationData,
} from '@/store/PageConfiguration/PageConfigurationSelectors'
import { useTranslation } from 'react-i18next'
import { titleIconOverrides } from './styled-components'
import { updateServicesStatusList } from './ServicesStatusSlice'
import { selectServicesStatusList } from './ServicesStatusSelectors'
import { servicesStatusActions } from './ServicesStatusSagas'
import retrieveAdditionalSalesIcons from '@/shared/icons/retrieveAdditionalSalesIcons'

const useServiceStatus = () => {
  const dispatch = useDispatch()
  const { loaderRoutesEnergy, loaderRoutes, loaderRoutesInsurance } = useSelector(selectAuthData)
  const { getApplicationUrl } = useBusinessType({
    loaderRoutesEnergy,
    loaderRoutes,
    loaderRoutesInsurance,
    modulesStatuses: {},
    modulesStatusesEnergy: {},
    modulesStatusesInsurance: {},
  })
  const initUrl = getApplicationUrl('services-status')
  const { services: loadedServices } = useSelector(selectPageConfigurationData)
  const services = useSelector(selectServicesStatusList)
  const isLoading = useSelector(selectPageConfigurationIsLoading)

  const { t } = useTranslation()

  const getServiceName = (splittedService, defaultValue) => {
    let textServiceName = defaultValue
    if (splittedService[0] === 'sales') {
      textServiceName = splittedService[1]
    }
    if (splittedService[0] === 'modules' || splittedService[0] === 'ekomi') {
      delete splittedService[splittedService.length - 1]
      const cleaned = splittedService.filter(n => n)
      textServiceName = cleaned.join('-')
    }

    return textServiceName
  }

  const getServiceIcon = serviceName => {
    const realServiceName =
      serviceName !== 'ekomi-status' && serviceName !== 'enedis-api'
        ? serviceName.substr(0, serviceName.length - 2)
        : serviceName
    return retrieveAdditionalSalesIcons(realServiceName, 24, titleIconOverrides)
  }

  const submitService = (event, service) => {
    const status = event.target.checked ? '1' : '0'
    dispatch({ type: servicesStatusActions.UPDATE_SERVICE_STATUS, service, status })
  }

  useEffect(() => {
    dispatch({ type: pageConfigurationActions.INITIALIZE, initUrl })
  }, [])

  useEffect(() => {
    dispatch(updateServicesStatusList(loadedServices))
  }, [loadedServices])

  return {
    t,
    services,
    isLoading,
    getServiceName,
    getServiceIcon,
    submitService,
  }
}

export default useServiceStatus
