import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconCarbonEngie = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'Engie'
  size = size ?? 16
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 32 32' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <path d='M28,7.4c-3.2-2.2-7.4-3.6-12-3.6S7.2,5.2,4,7.4c3.2-1.1,6.9-1.8,12-1.8S24.8,6.3,28,7.4' />
      <path
        d='M24.7,24.1c-7,1.5-15-0.1-15-5.1c0-3.8,3.5-5.4,6.2-5.6c2.9-0.2,5.1,0.4,6.3,1.6c0.7,0.8,0.5,1.8-0.9,1.9
		C20,17,17.6,17,15.5,17.1c-1.2,0-1.8,0.7-1.8,1.5c0,0.7,0.5,1.4,1.8,1.4c1.9,0,6.7,0,9.1,0c1.6,0,2.4-0.9,2.4-2.8
		c0-2.9-2.6-7.4-10.2-7.4c-6.9,0-11.9,3.5-11.9,9.3c0,8.2,11.8,10.7,20.4,7.6c0.8-0.3,1.6-0.9,1.3-1.8
		C26.4,24.2,25.7,23.9,24.7,24.1'
      />
    </Icon>
  )
}

IconCarbonEngie.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconCarbonEngie
