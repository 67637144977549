import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'
import ToolsUtilities from './bones/ToolsUtilities'
import Preview from './bones/Preview'
import useDashboardPreviewer from './useDashboardPreviewer'

const DashboardPreviewer = ({ updateTable }) => {
  const { t, currentSubscription } = useDashboardPreviewer()
  const [css] = useStyletron()
  const cardStyle = css({
    minHeight: '400px',
    padding: '1rem',
    marginBottom: '2rem',
    backgroundColor: 'white',
    borderRadius: '1rem',
    boxShadow: '0 2px 6px rgba(20,96,170,.3)',
  })

  if (!currentSubscription) {
    return (
      <div className={cardStyle}>
        <p>{t('subscriptionTable:no_subscription_selected')}</p>
      </div>
    )
  }

  return (
    <div className={cardStyle}>
      <ToolsUtilities subscription={currentSubscription} updateTable={updateTable} />
      <Preview data={currentSubscription.subscriberData} subscriberId={currentSubscription.id} />
    </div>
  )
}

DashboardPreviewer.propTypes = {
  updateTable: PropTypes.func,
}

export default DashboardPreviewer
