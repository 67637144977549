import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  services: {},
}

const updateServicesStatusListHandler = (state, action) => {
  state.services = action.payload
}

export const ServicesStatusSlice = createSlice({
  name: 'servicesStatus',
  initialState,
  reducers: {
    updateServicesStatusList: updateServicesStatusListHandler,
  },
})

export const { updateServicesStatusList } = ServicesStatusSlice.actions

export default ServicesStatusSlice.reducer
