import PropTypes from 'prop-types'
import { SortableHeadCell } from 'baseui/table-grid'
import { useTranslation } from 'react-i18next'

import SDCard from '@/shared/components/SDComponents/SDCard'
import OwnerCell from '@/containers/DashboardPage/DashboardTable/bones/OwnerCell'
import AddressCell from '@/containers/DashboardPage/DashboardTable/bones/AddressCell'
import DefaultCell from '@/containers/DashboardPage/DashboardTable/bones/DefaultCell'
import ZohoCell from '@/containers/DashboardPage/DashboardTable/bones/ZohoCell'
import AdditionalTypeCell from '@/containers/DashboardPage/DashboardTable/bones/AdditionalTypeCell'
import StatusAdditionalSalesCell from '@/containers/DashboardPage/DashboardTable/bones/StatusAdditionalSalesCell'
import useCallInformationStyles from './useCallInformationStyles'
import SpinnerText from './SpinnerText'

const LastAdditionalSales = ({ hopAdditionalSales, hopAdditionalSalesLoading }) => {
  const { cardStyleLastAdditionalSales, displayContents, headCellOverrides, Table, overrides } =
    useCallInformationStyles()

  const { t } = useTranslation()

  if (hopAdditionalSalesLoading || !hopAdditionalSales) {
    return <SDCard content={<SpinnerText text={t('callInformation:additional_sales_module.loading')} />} />
  }
  if (hopAdditionalSales.length <= 0) {
    return <SDCard content={t('callInformation:additional_sales_module.no_content')} />
  }

  return (
    <div className={cardStyleLastAdditionalSales}>
      <Table role='grid' $gridTemplateColumns='10% 20% 20% 10% 20% 20%' overrides={overrides}>
        <div role='row' className={displayContents}>
          <SortableHeadCell
            title={t('callInformation:additional_sales_module.header_fields.status')}
            overrides={headCellOverrides}
          />
          <SortableHeadCell
            title={t('callInformation:additional_sales_module.header_fields.additional_sale_type')}
            overrides={headCellOverrides}
          />
          <SortableHeadCell
            title={t('callInformation:additional_sales_module.header_fields.dates')}
            overrides={headCellOverrides}
          />
          <SortableHeadCell
            title={t('callInformation:additional_sales_module.header_fields.owner')}
            overrides={headCellOverrides}
          />
          <SortableHeadCell
            title={t('callInformation:additional_sales_module.header_fields.address')}
            overrides={headCellOverrides}
          />
          <SortableHeadCell
            title={t('callInformation:additional_sales_module.header_fields.zoho_id')}
            overrides={headCellOverrides}
          />
        </div>

        {hopAdditionalSales.map((element, index) => {
          const striped = index % 2 === 0
          const streetNumber = element.address.streetNumber ?? ''
          const streetName = element.address.streetName ?? ''
          const zipcode = element.address.zipcode ?? ''
          const city = element.address.city ?? ''
          return (
            <div role='row' className={displayContents} key={element.id}>
              <StatusAdditionalSalesCell status={element.status} striped={striped} />

              <AdditionalTypeCell additionalSaleType={element.salesType} striped={striped} />

              <DefaultCell value={new Date(element.createdTime).toLocaleString()} striped={striped} />

              <OwnerCell name={element.owner} striped={striped} />

              <AddressCell
                firstLine={streetNumber + ' ' + streetName}
                secondLine={zipcode + ' ' + city}
                striped={striped}
              />

              <ZohoCell zohoId={element.zohoId} striped={striped} />
            </div>
          )
        })}
      </Table>
    </div>
  )
}

LastAdditionalSales.propTypes = {
  hopAdditionalSales: PropTypes.array,
  hopAdditionalSalesLoading: PropTypes.bool,
}

export default LastAdditionalSales
