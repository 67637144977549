import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'

import {
  StyledValidationBlock,
  StyledValidationList,
  StyledValidationItem,
  StyledValidationTitle,
  StyledValidationHeader,
  titleIconOverrides,
} from '../form-header-styled-components'
import { fieldsInformation } from '../config/fieldsInformation'
// import IconCarbonJumpLink from '@/shared/icons/IconCarbonJumpLink';
import IconCarbonDocumentUnknown from '@/shared/icons/IconCarbonDocumentUnknown'
import IconCarbonHome from '@/shared/icons/IconCarbonHome'
import IconCarbonFinance from '@/shared/icons/IconCarbonFinance'
import IconCarbonConcept from '@/shared/icons/IconCarbonConcept'
import IconCarbonSummaryKPI from '@/shared/icons/IconCarbonSummaryKPI'
import IconCarbonUser from '@/shared/icons/IconCarbonUser'
import IconCarbonTag from '@/shared/icons/IconCarbonTag'
import IconCarbonLightning from '@/shared/icons/IconCarbonLightning'
import IconCustomZoho from '@/shared/icons/IconCustomZoho'
import IconCarbonFire from '@/shared/icons/IconCarbonFire'
import IconCarbonCalendar from '@/shared/icons/IconCarbonCalendar'

import { useTranslation } from 'react-i18next'

const ValidationList = ({ errors, missing, status }) => {
  const { t } = useTranslation()
  const [, theme] = useStyletron()
  const iconProps = {
    size: 20,
    color: theme.colors[status],
    overrides: titleIconOverrides,
  }
  const getModuleIcon = module => {
    switch (module) {
      case 'AddressModule':
        return <IconCarbonHome {...iconProps} />
      case 'BillingPaymentModule':
        return <IconCarbonFinance {...iconProps} />
      case 'CharacteristicsModule':
        return <IconCarbonConcept {...iconProps} />
      case 'ConsumptionModule':
        return <IconCarbonSummaryKPI {...iconProps} />
      case 'EffectiveDateModule':
        return <IconCarbonCalendar {...iconProps} />
      case 'CustomerModule':
        return <IconCarbonUser {...iconProps} />
      case 'OfferModule':
        return <IconCarbonTag {...iconProps} />
      case 'PDLModule':
      case 'PropaneModule':
        return <IconCarbonLightning {...iconProps} />
      case 'PCEModule':
        return <IconCarbonFire {...iconProps} />
      case 'ZohoModule':
        return <IconCustomZoho {...iconProps} />
      case 'AdditionalInformationModule':
        return <IconCarbonConcept {...iconProps} />
      default:
        return <IconCarbonDocumentUnknown {...iconProps} />
    }
  }
  const list = {
    AddressModule: {},
    BillingPaymentModule: {},
    CharacteristicsModule: {},
    ConsumptionModule: {},
    CustomerModule: {},
    OfferModule: {},
    PDLModule: {},
    PCEModule: {},
    ZohoModule: {},
    AdditionalInformationModule: {},
    PropaneModule: {},
    EffectiveDateModule: {},
  }
  const blocks = []

  if (Object.keys(errors).length > 0) {
    Object.keys(errors).forEach(entry => {
      const module = fieldsInformation[entry]
      if (module) {
        list[module][entry] = errors[entry][0]
      } else {
        console.error(`No entry for error '${entry}' at ValidationList fieldsInformation`)
      }
    })
  } else {
    Object.keys(missing).forEach(entry => {
      const module = fieldsInformation[entry]
      if (module) {
        list[module][entry] = missing[entry][0]
      } else {
        console.error(`No entry for missing field '${entry}' at ValidationList fieldsInformation`)
      }
    })
  }

  Object.keys(list).forEach(module => {
    if (Object.keys(list[module]).length > 0) {
      blocks.push(
        <StyledValidationBlock key={`${module}-list`}>
          <StyledValidationHeader>
            {getModuleIcon(module)}
            <StyledValidationTitle status={status}>{t('subscriptionForm:header.' + module)}</StyledValidationTitle>
          </StyledValidationHeader>
          <StyledValidationList>
            {Object.entries(list[module]).map(([index, text]) => {
              return <StyledValidationItem key={text ?? index}>{text}</StyledValidationItem>
            })}
          </StyledValidationList>
        </StyledValidationBlock>,
      )
    }
  })

  return blocks
}

ValidationList.propTypes = {
  errors: PropTypes.object.isRequired,
  missing: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
}

export default ValidationList
