import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import ClientInfo from './ClientInfo'
import LastSales from './LastSales'
import LastCalls from './LastCalls'
import LastAdditionalSales from './LastAdditionalSales'
import SDNotification from '@/shared/components/SDComponents/SDNotification'
import { Block } from 'baseui/block'
import StyledFormHeader from '@/shared/components/FormHeader/styled/StyledFormHeader'
import useCallInformation from './useCallInformation'
import useCallInformationStyles from './useCallInformationStyles'
import CreateSaleButtons from './CreateSaleButtons'

const CallInformation = () => {
  const {
    isLoading,
    isEmpty,
    createSale,
    createSaleIsLoading,
    data,
    currentCallData,
    t,
    hopSubscriptions,
    hopSubscriptionsLoading,
    hopAdditionalSales,
    hopAdditionalSalesLoading,
    lastCalls,
    lastCallsLoading,
    authorizedBusinessTypes,
  } = useCallInformation()
  const { cardStyle, headerStyle, itemProps, wideItemProps } = useCallInformationStyles()

  if (isLoading) {
    return (
      <div className={cardStyle}>
        <p>{t('common:fetching_data')}</p>
      </div>
    )
  }

  if (isEmpty) {
    return (
      <div className={cardStyle}>
        <p>{t('callInformation:empty_info_client')}</p>
      </div>
    )
  }

  return (
    <>
      <div className={headerStyle}>
        <Block>
          {t('callInformation:presentation_text') && data.user_firstname && (
            <SDNotification type='warning' icon>
              {t('callInformation:presentation_text').replace('#FIRSTNAME#', data.user_firstname)}
            </SDNotification>
          )}
        </Block>
        <Block>
          <CreateSaleButtons
            authorizedBusinessTypes={authorizedBusinessTypes}
            createSaleIsLoading={createSaleIsLoading}
            createSale={createSale}
            t={t}
          />
        </Block>
      </div>

      <StyledFormHeader>{currentCallData && <ClientInfo clientData={currentCallData} />}</StyledFormHeader>

      <div className={cardStyle}>
        <FlexGrid
          flexGridColumnCount={3}
          flexGridColumnGap='scale800'
          flexGridRowGap='scale800'
          marginBottom='scale800'
        >
          <FlexGridItem {...{ ...wideItemProps, ...{ justifyContent: 'left' } }}>
            {currentCallData.zoho_id && (
              <LastSales hopSubscriptions={hopSubscriptions} hopSubscriptionsLoading={hopSubscriptionsLoading} />
            )}
          </FlexGridItem>

          <FlexGridItem display='none' />

          <FlexGridItem display='none' />

          <FlexGridItem {...{ ...wideItemProps, ...{ alignItems: 'flex-start' } }}>
            {currentCallData.zoho_id && (
              <LastAdditionalSales
                hopAdditionalSales={hopAdditionalSales}
                hopAdditionalSalesLoading={hopAdditionalSalesLoading}
              />
            )}
          </FlexGridItem>

          <FlexGridItem display='none' />

          <FlexGridItem {...{ ...itemProps, ...{ alignItems: 'flex-start' } }}>
            {currentCallData.zoho_id && <LastCalls lastCalls={lastCalls} lastCallsloading={lastCallsLoading} />}
          </FlexGridItem>
        </FlexGrid>
      </div>
    </>
  )
}

export default CallInformation
