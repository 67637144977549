import FieldsSwitch from '../Fields/FieldsSwitch'
import MultipleButtonGroup from '../Fields/MultipleButtonGroup'

const Integration = ({ t, errors, providerState, updateOffer }) => {
  const fields = providerState.viewParameters.attributes?.general ?? {}

  return (
    <>
      <MultipleButtonGroup
        label={t('organizationManagement:offer.sales_destination_choice')}
        errorText={errors('sales_destination')}
        onClick={value => updateOffer('sales_destination', value)}
        selectedValue={providerState.selectedOffer.sales_destination}
        options={providerState.viewParameters.sales_destination_choice ?? null}
        castKeyToInteger={true}
      />
      <FieldsSwitch group='integration' fields={fields} t={t} updateOffer={updateOffer} providerState={providerState} />
    </>
  )
}

export default Integration
