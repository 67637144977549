import { useState } from 'react'
import { BaseProvider, createTheme } from 'baseui'
import selectraPrimitives from '@/shared/theme/themePrimitives'
import selectraOverrides from '@/shared/theme/themeOverrides'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { FormControl } from 'baseui/form-control'
import PropTypes from 'prop-types'
import SDInput from '@/shared/components/SDComponents/SDInput/SDInput'
import { TYPE } from 'baseui/select'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { SIZE } from 'baseui/input'
import useHttp from '@/shared/hooks/useHttp'
import { useSelector } from 'react-redux'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { Textarea } from 'baseui/textarea'

const ImportPodTool = ({ pdlUrl, pceUrl }) => {
  const { lang } = useSelector(selectAuthData)
  const { sendRequest, getConfig } = useHttp()
  const [zohoId, setZohoId] = useState('')
  const [pdl, setPdl] = useState('')
  const [pce, setPce] = useState('')
  const [pdlCustom, setPdlCustom] = useState('')
  const removeNonNumericCharacters = string => string.replace(/\D/g, '')
  const checkIsValidPdl = () => !(zohoId && zohoId.length === 19 && pdl && pdl.length === 14)
  const checkIsValidPce = () => !(zohoId && zohoId.length === 19 && pce && pce.length === 14)
  const handlePodChange = (pod, setPod) => {
    setPod(removeNonNumericCharacters(pod))
  }
  const handleZohoIdChange = zohoId => {
    setZohoId(removeNonNumericCharacters(zohoId))
  }
  const flexGridItemProps = {
    display: 'flex',
    alignItems: 'center',
  }
  const selectraTheme = createTheme(selectraPrimitives, selectraOverrides)
  const requestSearchHandler = url => {
    sendRequest(url + '?lang=' + lang, getConfig('POST'), (response, statusCode) => {
      if (statusCode === 200) {
        alert(
          '🟢 Fake Pod data inserted properly in the cache. 🟢 Go to the form and use the zoho id and the pdl inserted. The cache key is: ' +
            response,
        )
      } else {
        console.error('Fake Pod data not added in cache.', response)
        alert('🔴 ERROR: Fake Pod data not added in cache. 🔴')
      }
    })
  }

  const requestCustomPdlHandler = url => {
    const pdlCustomFormatted = pdlCustom.replace(/\\n/g, '').replace(/\\t/g, '');
    const pdlCustomObject = JSON.parse(pdlCustomFormatted)
    const config = getConfig('POST')
    config.body = JSON.stringify(pdlCustomObject)
    config.headers['Content-Type'] = 'application/json'
    sendRequest(url + '?lang=' + lang, config, (response, statusCode) => {
      if (statusCode === 200) {
        alert(
          '🟢 Fake Pod data inserted properly in the cache. 🟢 Go to the form and use the zoho id and the pdl inserted. The cache key is: ' +
          response,
        )
      } else {
        console.error('Fake Pod data not added in cache.', response)
        alert('🔴 ERROR: Fake Pod data not added in cache. 🔴')
      }
    })
  }

  const Inputs = ({ setPod, podValue, podName }) => {
    return (
      <>
        <SDInput
          type={TYPE.search}
          placeholder='Zoho Id'
          onChange={event => handleZohoIdChange(event.target.value)}
          value={zohoId}
        />
        <SDInput
          type={TYPE.search}
          placeholder={podName}
          onChange={event => handlePodChange(event.target.value, setPod)}
          value={podValue}
        />
      </>
    )
  }

  const PdlForm = ({ meterOption }) => {
    return (
      <>
        <Inputs setPod={setPdl} podValue={pdl} podName='pdl' />
        <SDButton
          size={SIZE.compact}
          block
          disabled={checkIsValidPdl()}
          onClick={event => {
            event.preventDefault()
            const url = pdlUrl.replace('*zoho_id*', zohoId).replace('*pdl*', pdl).replace('*meter_option*', meterOption)
            requestSearchHandler(url)
          }}
        >
          {`Insert PDL (Meter option: ${meterOption})`}
        </SDButton>
      </>
    )
  }

  const PdlCustomForm = () => {
    return (
      <>
        <Inputs setPod={setPdl} podValue={pdl} podName='pdl' />
        <Textarea
          size={SIZE.compact}
          value={pdlCustom}
          onChange={event => {
            setPdlCustom(event.target.value)
          }}
        />
        <SDButton
          size={SIZE.compact}
          block
          disabled={checkIsValidPdl()}
          onClick={event => {
            event.preventDefault()
            const url = pdlUrl.replace('*zoho_id*', zohoId).replace('*pdl*', pdl).replace('*meter_option*', 'pdlCustom')
            requestCustomPdlHandler(url)
          }}
        >
          Insert Custom PDL
        </SDButton>
      </>
    )
  }

  const PceForm = () => {
    return (
      <>
        <Inputs setPod={setPce} podValue={pce} podName='pce' />
        <SDButton
          size={SIZE.compact}
          block
          disabled={checkIsValidPce()}
          onClick={event => {
            event.preventDefault()
            const url = pceUrl.replace('*zoho_id*', zohoId).replace('*pce*', pce)
            requestSearchHandler(url)
          }}
        >
          Insert PCE
        </SDButton>
      </>
    )
  }

  return (
    <FormControl label='Insert the ZOHO ID and the PDL/PCE to save in the cache.'>
      <BaseProvider theme={selectraTheme}>
        <FlexGrid flexGridColumnCount={2} marginBottom='.5rem' flexGridColumnGap='scale800'>
          <FlexGridItem {...flexGridItemProps}>
            <PdlForm meterOption='base' />
          </FlexGridItem>
        </FlexGrid>

        <FlexGrid flexGridColumnCount={2} marginBottom='.5rem' flexGridColumnGap='scale800'>
          <FlexGridItem {...flexGridItemProps}>
            <PdlForm meterOption='hphc' />
          </FlexGridItem>
        </FlexGrid>

        <FlexGrid flexGridColumnCount={2} marginBottom='.5rem' flexGridColumnGap='scale800'>
          <FlexGridItem {...flexGridItemProps}>
            <PceForm />
          </FlexGridItem>
        </FlexGrid>

        <FlexGrid flexGridColumnCount={2} marginBottom='.5rem' flexGridColumnGap='scale800'>
          <FlexGridItem {...flexGridItemProps}>
            <PdlCustomForm />
          </FlexGridItem>
        </FlexGrid>

      </BaseProvider>
    </FormControl>
  )
}

ImportPodTool.propTypes = {
  pdlUrl: PropTypes.string,
  pceUrl: PropTypes.string,
}

export default ImportPodTool
