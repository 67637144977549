import { EnergyOfferParametersGeneration } from './EnergyOfferParametersGeneration'

const SaveEnergyOffer = async (providerState, providerDispatch, url, headers, sendRequest) => {
  providerDispatch({
    type: 'saving',
    payload: { loading: true },
  })

  headers.body = EnergyOfferParametersGeneration(providerState.selectedOffer, providerState.selectedProvider.id)

  await sendRequest(url, headers, (json, status) => {
    if (status === 200) {
      providerDispatch({ type: 'saving', payload: { offerSaved: true } })
      providerDispatch({ type: 'setErrors', payload: {} })
    }
    if (status === 422) {
      providerDispatch({ type: 'setErrors', payload: json.errors })
    }

    if (json.errors) {
      console.error(json.errors)
    }

    providerDispatch({ type: 'saving', payload: { loading: false } })
  })
}

export default SaveEnergyOffer
