import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconCarbonWarningAlt = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'Warning alt'
  size = size ?? 16
  color = color ?? theme.colors.primary

  return (
    <Icon data-baseweb='icon' ref={ref} viewBox='0 0 32 32' size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <path d='M16,23a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,16,23Z' />
      <rect x='15' y='12' width='2' height='9' />
      <path d='M29,30H3a1,1,0,0,1-.8872-1.4614l13-25a1,1,0,0,1,1.7744,0l13,25A1,1,0,0,1,29,30ZM4.6507,28H27.3493l.002-.0033L16.002,6.1714h-.004L4.6487,27.9967Z' />
    </Icon>
  )
}

IconCarbonWarningAlt.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconCarbonWarningAlt
