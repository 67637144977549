import { Button, KIND, SIZE } from 'baseui/button'
import useHttp from '@/shared/hooks/useHttp'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import IconCarbonTrashCan from '@/shared/icons/IconCarbonTrashCan'
import { selectPageConfigurationData } from '../../../../store/PageConfiguration/PageConfigurationSelectors'
import { selectCommonFormConfigurationData } from '../../SubscriptionFormConfigurationSelectors'
import { updateCommonConfiguration } from '../../SubscriptionFormConfigurationSlice'
import useFormStatus from '../../useFormStatus'

const RetractSale = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { abandon_dossier_url: retractSaleUrl, create_comment_url: createCommentUrl } =
    useSelector(selectPageConfigurationData)
  const { id } = useSelector(selectCommonFormConfigurationData)
  const { sendRequest, getConfig } = useHttp()
  const { isAdmin } = useSelector(selectAuthData)
  const { isRetracted } = useFormStatus()

  const retractSubscription = () => {
    const comment = prompt(t('subscriptionForm:subscription_actions_module.retract_button.question'))
    if (comment === null || comment === '') {
      alert(t('subscriptionForm:subscription_actions_module.retract_button.comment_is_mandatory'))
      return
    }

    const retractSale = getConfig('POST')
    const formDataRetractation = new FormData()
    formDataRetractation.append('subscriber_id', id)
    formDataRetractation.append('comment', 'ABANDON: ' + comment)

    retractSale.body = formDataRetractation
    sendRequest(retractSaleUrl, retractSale, (response, status) => {
      if (status !== 200) {
        alert('Retract sale failed.')
        console.error(response)
        return
      }
      dispatch(
        updateCommonConfiguration({
          realStatus: response.new_status,
          isEditable: false,
          showSaveNotification: true,
          subscriptionStatus: response.text,
          isRetracted: true,
        }),
      )
    })
  }

  if (isAdmin === false) {
    return ''
  }

  return (
    <Button
      id='retract-sale-button'
      kind={KIND.secondary}
      size={SIZE.compact}
      onClick={event => {
        event.preventDefault()
        retractSubscription()
      }}
      disabled={isRetracted}
      endEnhancer={() => <IconCarbonTrashCan size={24} />}
    >
      {t('subscriptionForm:subscription_actions_module.retract_button.button_text')}
    </Button>
  )
}

export default RetractSale
