import PropTypes from 'prop-types'
import { StyledBodyCell } from 'baseui/table-grid'
import { useStyletron, withStyle } from 'baseui'
import { Block } from 'baseui/block'

const TableRow = ({ fieldKey, model, oldValue, newValue, striped }) => {
  const [css] = useStyletron()
  const CenteredBodyCell = withStyle(StyledBodyCell, {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
  })

  return (
    <div role='row' className={css({ display: 'contents' })}>
      <CenteredBodyCell $striped={striped}>{model + ' : ' + fieldKey}</CenteredBodyCell>

      <CenteredBodyCell $striped={striped}>{oldValue ?? '-'}</CenteredBodyCell>

      <CenteredBodyCell $striped={striped}>
        <Block maxWidth='100%'>{newValue}</Block>
      </CenteredBodyCell>
    </div>
  )
}

TableRow.propTypes = {
  fieldKey: PropTypes.string,
  model: PropTypes.string,
  oldValue: PropTypes.string,
  newValue: PropTypes.string,
}

export default TableRow
