import PaymentDateRange from './PaymentDateRange'
import PaymentDateFixe from './PaymentDateFixe'

const PrelevementDaysOptions = ({ attributeId, attributeTechName, currentValue, updateOffer, providerState, t }) => {
  const retrievePaymentDateTypeId = () => {
    const fields = providerState.viewParameters.attributes?.options ?? {}
    let fieldId = null
    Object.entries(fields).map(([, element]) => {
      if (element.tech_name === 'payment_date_type') {
        fieldId = element.attribute_id
      }
    })
    return fieldId
  }
  const paymentDateTypeId = retrievePaymentDateTypeId()
  const paymentDateType = providerState.selectedOffer['attr_' + paymentDateTypeId] ?? ''

  if (!paymentDateType) {
    return
  }

  if (paymentDateType === 'fixe') {
    return (
      <PaymentDateFixe
        t={t}
        attributeId={attributeId}
        currentValue={currentValue}
        attributeTechName={attributeTechName}
        updateOffer={updateOffer}
      />
    )
  }

  return (
    <PaymentDateRange
      t={t}
      attributeId={attributeId}
      currentValue={currentValue}
      attributeTechName={attributeTechName}
      updateOffer={updateOffer}
    />
  )
}

export default PrelevementDaysOptions
