import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import { StatefulTooltip } from 'baseui/tooltip'

import { StyledSidebarButton, tooltipOverrides } from '../styled-components'
import IconCarbonUnknown from '@/shared/icons/IconCarbonUnknown'

const SidebarButton = ({ classes, clickHandler, icon, isSelected, text, url }) => {
  const [, theme] = useStyletron()
  const iconProps = {
    size: 24,
    color: isSelected ? theme.colors.white : theme.colors.primary,
    title: '',
  }
  return (
    <StatefulTooltip content={text} popoverMargin={8} overrides={tooltipOverrides()}>
      <StyledSidebarButton
        className={classes}
        onClick={clickHandler ?? null}
        $isSelected={isSelected ?? false}
        href={url}
      >
        {icon ? icon(iconProps) : <IconCarbonUnknown {...iconProps} />}
      </StyledSidebarButton>
    </StatefulTooltip>
  )
}

SidebarButton.propTypes = {
  classes: PropTypes.string,
  clickHandler: PropTypes.func,
  icon: PropTypes.func,
  isSelected: PropTypes.bool,
  text: PropTypes.string.isRequired,
  url: PropTypes.string,
}

export default SidebarButton
