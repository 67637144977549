import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import { StyledBodyCell } from 'baseui/table-grid'
import PrestationBadge from './PrestationBadge'
import PrestationFromZohoBadge from './PrestationFromZohoBadge'
import { useTranslation } from 'react-i18next'

const OfferCell = ({ provider, offer, retrievedFrom, dealName, prestation, zohoId, striped, selected }) => {
  const { t } = useTranslation()
  const [css] = useStyletron()
  const columnStyle = css({
    display: 'flex',
    flexDirection: 'column',
  })
  const rowStyle = css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '.25rem',
  })
  const cellStyle = css({
    padding: '.5rem',
    backgroundColor: selected ? '#c1d1e2' : 'none',
  })

  if (!provider && !offer && (!prestation || prestation === '-1')) {
    return (
      <StyledBodyCell className={cellStyle} $striped={striped && !selected}>
        <p>{t('subscriptionTable:no_offer_data')}</p>
      </StyledBodyCell>
    )
  }
  return (
    <StyledBodyCell className={cellStyle} $striped={striped && !selected}>
      <div className={columnStyle}>
        <div className={rowStyle}>{provider}</div>
        <div className={rowStyle}>{offer}</div>
        <div className={rowStyle}>
          {retrievedFrom === 'hop' && <PrestationBadge prestation={prestation} />}
          {retrievedFrom === 'zoho' && (
            <PrestationFromZohoBadge prestation={prestation} zohoId={zohoId} dealName={dealName} />
          )}
        </div>
      </div>
    </StyledBodyCell>
  )
}

OfferCell.propTypes = {
  provider: PropTypes.string,
  offer: PropTypes.string,
  retrievedFrom: PropTypes.string,
  dealName: PropTypes.string,
  prestation: PropTypes.string,
  id: PropTypes.string,
  zohoId: PropTypes.string,
  striped: PropTypes.bool,
  selected: PropTypes.bool,
}

export default OfferCell
