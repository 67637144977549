import { useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import ProviderManagementContext from '@/containers/OrganizationsManagement/ProviderManagementContext'

const usePromoCodes = () => {
  const { t } = useTranslation()
  const editorRef = useRef(null)
  const providerContext = useContext(ProviderManagementContext)
  const providerDispatch = providerContext.providerDispatch
  const updateProvider = (attributeName, value) => {
    providerDispatch({ type: 'updateProvider', payload: { [attributeName]: value } })
  }

  return {
    editorRef,
    t,
    updateProvider
  }
}

export default usePromoCodes
