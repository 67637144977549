import PropTypes from 'prop-types'
import { FormControl } from 'baseui/form-control'
import { SIZE, MODE } from 'baseui/button-group'
import SDButtonGroup from '@/shared/components/SDComponents/SDButtonGroup/SDButtonGroup'
import SDButtonGroupButton from '@/shared/components/SDComponents/SDButtonGroup/SDButtonGroupButton'

const GenericButtonGroup = ({ selected, onClick, label, values }) => {
  return (
    <FormControl label={label}>
      <SDButtonGroup mode={MODE.radio} size={SIZE.mini} selected={selected} onClick={onClick}>
        {values.map((value, index) => {
          return <SDButtonGroupButton key={index}>{value}</SDButtonGroupButton>
        })}
      </SDButtonGroup>
    </FormControl>
  )
}

GenericButtonGroup.propTypes = {
  selected: PropTypes.array,
  onClick: PropTypes.func,
  label: PropTypes.string,
  values: PropTypes.array
}

export default GenericButtonGroup
