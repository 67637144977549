import { FormControl } from 'baseui/form-control'
import { SIZE } from 'baseui/button-group'
import SDButtonGroup from '@/shared/components/SDComponents/SDButtonGroup/SDButtonGroup'
import SDButtonGroupButton from '@/shared/components/SDComponents/SDButtonGroup/SDButtonGroupButton'

const MultipleButtonGroup = ({
  options,
  onClick,
  selectedValue,
  errorText,
  label,
  castKeyToInteger = false,
  parentKey = '',
}) => {
  const items = []
  const selected = []
  let index = 0

  if (options) {
    for (const [key, value] of Object.entries(options)) {
      if (castKeyToInteger) {
        if (parseInt(key) === parseInt(selectedValue)) {
          selected.push(index)
        }
      } else {
        if (key === selectedValue) {
          selected.push(index)
        }
      }

      index++
      items.push(
        <SDButtonGroupButton
          key={key}
          onClick={event => {
            event.preventDefault()
            if (castKeyToInteger) {
              onClick(parseInt(key))
            } else {
              onClick(key)
            }
          }}
        >
          {value}
        </SDButtonGroupButton>,
      )
    }
  }

  return (
    <FormControl label={label} error={errorText}>
      <SDButtonGroup key={parentKey} size={SIZE.compact} selected={selected} error={errorText}>
        {items}
      </SDButtonGroup>
    </FormControl>
  )
}

export default MultipleButtonGroup
