import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { selectedDashboard } from '../DashboardSelectors'
import { updateDashboardPage } from '../DashboardSlice'

const useDashboardPaginator = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { data } = useSelector(selectedDashboard)
  const dispatchPage = page => {
    dispatch(updateDashboardPage(page))
  }

  return {
    t,
    data,
    dispatchPage,
  }
}

export default useDashboardPaginator
