import PropTypes from 'prop-types'
import { Table } from 'baseui/table-semantic'
import { useTranslation } from 'react-i18next'
import { SIZE } from 'baseui/button'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'

const AssistancesList = ({ items, page, elementsPerPage, updateAssistance }) => {
  const { t } = useTranslation()
  const COLUMNS = [
    t('assistancesConfiguration:name'),
    t('assistancesConfiguration:price'),
    t('assistancesConfiguration:status'),
    t('assistancesConfiguration:zoho_id'),
    t('common:edit'),
  ]
  const transformAssistance = assistanceList => {
    if (!assistanceList) {
      return []
    }
    let elements = assistanceList.map(assistance => {
      return [
        assistance.name,
        assistance.price,
        parseInt(assistance.status) === 0
          ? t('assistancesConfiguration:status_inactive')
          : t('assistancesConfiguration:status_active'),
        assistance.zoho_id,
        <SDButton key={assistance.id} block={true} size={SIZE.compact} onClick={() => updateAssistance(assistance)}>
          {t('common:edit')}
        </SDButton>,
      ]
    })
    elements = elements.slice((page - 1) * elementsPerPage, page * elementsPerPage)
    return elements
  }
  return <Table columns={COLUMNS} data={transformAssistance(items)} />
}

AssistancesList.propTypes = {
  items: PropTypes.array,
  page: PropTypes.number,
  elementsPerPage: PropTypes.number,
  updateAssistance: PropTypes.func,
}
export default AssistancesList
