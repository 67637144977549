import { call, put, select } from 'redux-saga/effects'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import getAPIConfig from '@/shared/tools/getAPIConfig'
import { updateConsumptionModuleConfiguration } from '../../../SubscriptionFormConfigurationSlice'

export const consumptionModuleActions = {
  CONSUMPTION_MODULE_CALCULATE_PRICE: 'CONSUMPTION_MODULE_CALCULATE_PRICE',
}

export function* calculateOfferPrice({ url, params, callback, method, workflow }) {
  const { authTokenSession, lang } = yield select(selectAuthData)
  yield put(updateConsumptionModuleConfiguration({ offerPriceLoading: true }))
  let config
  try {
    if (method === 'get') {
      config = yield call(getAPIConfig, { method, authTokenSession })
    } else {
      const entries = params.entries()
      const entriesParameters = Object.fromEntries(entries)
      config = yield call(getAPIConfig, { method, authTokenSession, body: entriesParameters })
    }
    const response = yield call(fetch, url + '?lang=' + lang, config)
    const isValid = response.status === 200
    yield put(updateConsumptionModuleConfiguration({ offerPriceLoading: false }))
    const responseData = yield call([response, response.json])
    if (!isValid) {
      console.warn('calculateOfferPriceError', responseData, response.status)
    }
    yield call(callback, responseData, workflow)
  } catch (error) {
    console.warn('calculateOfferPriceError', error)
  }
}
