/* eslint-disable camelcase */
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { Check } from 'baseui/icon'
import { useSnackbar } from 'baseui/snackbar'
import { selectOfficesManagementSelectedOffice } from '../OfficesManagementSelectors'
import { updateOfficesManagementSelectedOffice } from '../OfficesManagementSlice'
import { officesManagementActions } from '../OfficesManagementSagas'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'

const useAddEditModal = ({ setIsOpen }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [formErrors, setFormErrors] = useState({})
  const { enqueue } = useSnackbar()
  const selectedOffice = useSelector(selectOfficesManagementSelectedOffice)
  const { routes } = useSelector(selectPageConfigurationData)

  const submitHandler = event => {
    event.preventDefault()
    const { uuid = '', name, mcp_agent_id } = selectedOffice

    const officeData = {
      name,
      mcp_agent_id: mcp_agent_id[0] ? mcp_agent_id[0].id : '',
    }

    if (uuid) {
      const officeDataWithId = { ...officeData, uuid }
      return dispatch({
        type: officesManagementActions.UPDATE_OFFICE,
        officeData: officeDataWithId,
        updateOfficeSuccessNotification,
        setFormErrors,
      })
    }

    dispatch({
      type: officesManagementActions.CREATE_OFFICE,
      officeData,
      updateOfficeSuccessNotification,
      setFormErrors,
    })
  }

  const updateOfficeSuccessNotification = message => {
    setIsOpen(false)
    enqueue({
      message,
      startEnhancer: ({ size }) => <Check size={size} />,
    })
  }

  const updateSelectedOffice = officeInformation => {
    dispatch(updateOfficesManagementSelectedOffice({ ...selectedOffice, ...officeInformation }))
  }

  return {
    t,
    formErrors,
    selectedOffice,
    updateSelectedOffice,
    submitHandler,
    searchUsersUrl: routes?.search_users ?? '',
  }
}

export default useAddEditModal
