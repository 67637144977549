import formatUTCDate from '@/shared/tools/formatUTCDate'
export const telecomFormParametersGeneration = (
  customerData,
  billingData,
  mainAddressData,
  telecomContractData,
  telecomCharacteristicsData,
  id,
  country,
  action,
) => {
  // eslint-disable-next-line compat/compat
  const options = new URLSearchParams()

  if (action !== '') {
    options.append('action_requested', action)
  }

  options.append('id', id)
  options.append('zoho_id', customerData.zohoId)
  options.append('prestation_type', telecomCharacteristicsData.prestationType)
  options.append('product_type', telecomCharacteristicsData.productType)
  options.append('referral_firstname', customerData.referralFirstName)
  options.append('referral_lastname', customerData.referralSecondName)
  options.append('city_id', mainAddressData.inseeCode ?? '')
  options.append('city', mainAddressData.city ?? '')
  options.append('zipcode', mainAddressData.zipcode ?? '')
  options.append('conso_street_name', mainAddressData.address ?? '')
  options.append('street_number', mainAddressData.streetNumber ?? '')
  options.append('conso_floor', mainAddressData.floor ?? '')
  options.append('conso_appartment_number', mainAddressData.appartmentNumber ?? '')
  options.append('conso_additional_information', mainAddressData.additionalInformation ?? '')

  options.append('offer_id', telecomContractData.offerId ? parseInt(telecomContractData.offerId) : '')
  options.append('organisation_id', telecomContractData.providerId ? parseInt(telecomContractData.providerId) : '')
  options.append('lang', country)
  options.append('_form_mode', 'telesales')
  options.append('provider_external_id', telecomContractData.providerExternalId)
  options.append('providers_options', JSON.stringify(telecomContractData.providerOptions))

  options.append('iban', billingData.iban ?? '')

  if (billingData.businessCard === true) {
    options.append('business_card', '1')
  }

  if (billingData.bic.length > 0) {
    options.append('bic', billingData.bic)
  }

  options.append('disclaimer', customerData.dataCollectionAuth ? '1' : '0')
  options.append('civility', customerData.civility ?? '-1')
  options.append('lastname', customerData.surname ?? '')
  options.append('firstname', customerData.name ?? '')
  options.append('email', customerData.email ?? '')
  options.append('neverbounce_result', customerData.neverbounce_result)
  options.append('phone1', customerData.phone1 ?? '')
  if (customerData.web_callback.callbackTime !== '' && customerData.web_callback.callbackDate !== '') {
    const date = new Date(customerData.web_callback.callbackDate + ' ' + customerData.web_callback.callbackTime)
    options.append('web_callback', formatUTCDate(date) ?? '')
  }

  options.append('phone2', customerData.phone2 ?? '')
  options.append('pro_social_reason', customerData.pro_social_reason)
  if (customerData.pro_siret?.length > 0) {
    options.append('pro_siret', customerData.pro_siret)
  }

  if (customerData.secondName) {
    options.append('secondName', '1')
    options.append('civility2', customerData.civility2 ?? '-1')
    options.append('lastname2', customerData.surname2)
    options.append('firstname2', customerData.name2)
  }

  if (customerData.comments !== '') {
    options.append('subscription_comments', customerData.comments)
  }

  return options
}
