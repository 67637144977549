import { Cell, Grid } from 'baseui/layout-grid'
import PropTypes from 'prop-types'
import ByCreationDate from './Filters/ByCreationDate'
import GenericInput from './Filters/GenericInput'
import GenericButtonGroup from './Filters/GenericButtonGroup'
import GenericSelect from './Filters/GenericSelect'
import useDashboardFilters from './useDashboardFilters'

const DashboardFilters = ({ type, filters, providersList, usersList, applicationType }) => {
  const { t, css, theme, dispatchFilters, manageCheckboxGroup, manageRadioGroup, mapValues } = useDashboardFilters({
    filters
  })

  const { status, energy, productType, prestation, productTypeInsurance, prestationInsurance, prestationEnergy, contractType, details, origin, priority, providerId, userId } = filters

  const getProviderLabel = appType => {
    if (appType === 'energie') return t('dashboard:filters.by_provider_energie')
    if (appType === 'telecom') return t('dashboard:filters.by_provider_telecom')
    return t('dashboard:filters.by_provider_insurance')
  }

  const showFilterOutboundPriority = origin === 'web' || type === 'websales'

  return (
    <div
      className={css({
        marginBottom: '2rem',
        backgroundColor: 'white',
        padding: '1rem',
        borderRadius: '1rem',
        boxShadow: '0 2px 6px rgba(20,96,170,.3)'
      })}
    >
      <Grid>
        <Cell span={12}>
          <h4
            className={css({
              fontSize: '1rem',
              color: theme.colors.primary
            })}
          >
            {t('dashboard:filters.title')}
          </h4>
        </Cell>
        <Cell span={12}>
          <Grid>
            <Cell span={4}>
              <GenericButtonGroup
                values={['Vente finalisée', 'En cours', 'Erreur', 'Abandonné Selectra', 'Abandonné Fournisseur']}
                selected={mapValues(status, 'status', true)}
                onClick={(event, index) => {
                  manageRadioGroup(event, index, 'status')
                }}
                label={t('dashboard:filters.status')}
              />
            </Cell>
            {applicationType === 'energie' && (
              <Cell span={3}>
                <GenericButtonGroup
                  values={['Electricité', 'Gaz', 'Dual', 'Propane']}
                  selected={mapValues(energy, 'energy', true)}
                  onClick={(event, index) => {
                    manageRadioGroup(event, index, 'energy')
                  }}
                  label={t('dashboard:filters.by_energy')}
                />
              </Cell>
            )}
            {applicationType === 'telecom' && (
              <>
                <Cell span={3}>
                  <GenericButtonGroup
                    values={['Box', 'Mobile']}
                    selected={mapValues(productType, 'productType', true)}
                    onClick={(event, index) => {
                      manageRadioGroup(event, index, 'productType')
                    }}
                    label={t('dashboard:filters.by_product_type')}
                  />
                </Cell>
                <Cell span={2}>
                  <GenericButtonGroup
                    values={['Changement opérateur', 'Déménagement']}
                    selected={mapValues(prestation, 'prestation', true)}
                    onClick={(event, index) => {
                      manageRadioGroup(event, index, 'prestation')
                    }}
                    label={t('dashboard:filters.prestation_type')}
                  />
                </Cell>
              </>
            )}
            {applicationType === 'assurance' && (
              <>
                <Cell span={3}>
                  <GenericButtonGroup
                    values={['Habitation', 'Auto', 'Santé', 'Autre']}
                    selected={mapValues(productTypeInsurance, 'productTypeInsurance', true)}
                    onClick={(event, index) => {
                      manageRadioGroup(event, index, 'productTypeInsurance')
                    }}
                    label={t('dashboard:filters.by_product_type')}
                  />
                </Cell>
                <Cell span={2}>
                  <GenericButtonGroup
                    values={["Changement d'assurance", 'Nouvelle souscription']}
                    selected={mapValues(prestationInsurance, 'prestationInsurance', true)}
                    onClick={(event, index) => {
                      manageRadioGroup(event, index, 'prestationInsurance')
                    }}
                    label={t('dashboard:filters.prestation_type')}
                  />
                </Cell>
              </>
            )}
            {applicationType === 'energie' && (
              <>
                <Cell span={2}>
                  <GenericButtonGroup
                    values={['CDF', 'MES', '1ere MES']}
                    selected={mapValues(prestationEnergy, 'prestationEnergy', true)}
                    onClick={(event, index) => {
                      manageRadioGroup(event, index, 'prestationEnergy')
                    }}
                    label={t('dashboard:filters.prestation_type')}
                  />
                </Cell>
                <Cell span={2}>
                  <GenericButtonGroup
                    values={['Part', 'Pro']}
                    selected={mapValues(contractType, 'contractType', true)}
                    onClick={(event, index) => {
                      manageRadioGroup(event, index, 'contractType')
                    }}
                    label={t('dashboard:filters.contract_type')}
                  />
                </Cell>
              </>
            )}
            <Cell span={2}>
              <GenericInput
                label={t('dashboard:filters.contract_id')}
                onBlur={event =>
                  dispatchFilters({
                    ...filters,
                    subscriptionId: event.target.value,
                    custom: ''
                  })}
              />
            </Cell>
            <Cell span={2}>
              <GenericSelect
                label={getProviderLabel(applicationType)}
                onChange={params => {
                  dispatchFilters({
                    ...filters,
                    providerId: params.value[0]?.id ?? '',
                    custom: ''
                  })
                }}
                list={providersList}
                getId={provider => provider.id}
                getName={provider => provider.name}
                filterId={providerId}
              />
            </Cell>
            <Cell span={2}>
              <GenericInput
                label={t('dashboard:filters.lastname')}
                onBlur={event =>
                  dispatchFilters({
                    ...filters,
                    lastname: event.target.value,
                    custom: ''
                  })}
              />
            </Cell>
            <Cell span={2}>
              <GenericInput
                label={t('dashboard:filters.firstname')}
                onBlur={event =>
                  dispatchFilters({
                    ...filters,
                    firstname: event.target.value,
                    custom: ''
                  })}
              />
            </Cell>
            <Cell span={3}>
              <GenericInput
                label={t('dashboard:filters.email')}
                onBlur={event =>
                  dispatchFilters({
                    ...filters,
                    email: event.target.value,
                    custom: ''
                  })}
              />
            </Cell>
            <Cell span={2}>
              <GenericInput
                label={t('dashboard:filters.phone')}
                onBlur={event =>
                  dispatchFilters({
                    ...filters,
                    phone: event.target.value,
                    custom: ''
                  })}
              />
            </Cell>
            <Cell span={3}>
              <GenericInput
                label={t('dashboard:filters.zoho_id')}
                onBlur={event =>
                  dispatchFilters({
                    ...filters,
                    zohoId: event.target.value,
                    custom: ''
                  })}
              />
            </Cell>
            <Cell span={2}>
              <ByCreationDate
                label='Date de création du'
                onDateChangeHandler={date => {
                  dispatchFilters({
                    ...filters,
                    creationFrom: date,
                    custom: ''
                  })
                }}
              />
            </Cell>
            <Cell span={2}>
              <ByCreationDate
                label='au'
                onDateChangeHandler={date => {
                  dispatchFilters({
                    ...filters,
                    creationTo: date,
                    custom: ''
                  })
                }}
              />
            </Cell>
            {applicationType === 'energie' && (
              <>
                <Cell span={3}>
                  <GenericInput
                    label={t('dashboard:filters.meter_id_elec')}
                    onBlur={event =>
                      dispatchFilters({
                        ...filters,
                        meterIdElectricity: event.target.value,
                        custom: ''
                      })}
                  />
                </Cell>
                <Cell span={3}>
                  <GenericInput
                    label={t('dashboard:filters.meter_id_gas')}
                    onBlur={event =>
                      dispatchFilters({
                        ...filters,
                        meterIdGas: event.target.value,
                        custom: ''
                      })}
                  />
                </Cell>
              </>
            )}
            <Cell span={2}>
              <GenericSelect
                label={t('dashboard:filters.by_user')}
                onChange={params => {
                  dispatchFilters({
                    ...filters,
                    userId: params.value[0]?.id ?? '',
                    custom: ''
                  })
                }}
                list={usersList}
                getId={user => user.id}
                getName={user => user.firstname + ' ' + user.name}
                filterId={userId}
              />
            </Cell>
            <Cell span={3}>
              <GenericButtonGroup
                values={['Mes souscriptions', 'Demande de rappel']}
                selected={mapValues(details, 'details', true)}
                onClick={(event, index) => {
                  manageCheckboxGroup(event, index, 'details')
                }}
                label={t('dashboard:filters.by_details')}
              />
            </Cell>
            <Cell span={3}>
              <GenericButtonGroup
                values={['Telesales', 'Websales']}
                selected={mapValues(origin, 'origin', true)}
                onClick={(event, index) => {
                  manageRadioGroup(event, index, 'origin')
                }}
                label={t('dashboard:filters.by_origin')}
              />
            </Cell>
            {/*
              <Cell span={ 3 }>
                <GenericButtonGroup
                  values={ ['OHM RÉSILIÉ', 'OHM RÉSILIÉ À RELANCER'] }
                  selected={ mapValues(filters.custom, 'custom', true) }
                  onClick={ (event, index) => {
                    manageRadioGroup(event, index, 'custom');
                  }}
                  label={ t('dashboard:filters.by_custom_filter') }
                />
              </Cell>
                */}
            {showFilterOutboundPriority && (
              <Cell span={3}>
                <GenericButtonGroup
                  values={[
                    t('dashboard:by_outbound_priority.filters.high_priority'),
                    t('dashboard:by_outbound_priority.filters.low_priority')
                  ]}
                  selected={mapValues(priority, 'priority', true)}
                  onClick={(event, index) => {
                    manageCheckboxGroup(event, index, 'priority')
                  }}
                  label={t('dashboard:by_outbound_priority.label')}
                />
              </Cell>
            )}
          </Grid>
        </Cell>
      </Grid>
    </div>
  )
}

DashboardFilters.propTypes = {
  type: PropTypes.string,
  filters: PropTypes.object,
  providersList: PropTypes.object,
  usersList: PropTypes.array,
  applicationType: PropTypes.string
}
export default DashboardFilters
