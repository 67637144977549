import { useState } from 'react'
import PropTypes from 'prop-types'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { useTranslation } from 'react-i18next'
import calculateDaysUntilDate from './calculateDaysUntilDate'
import FormationForm from './FormationForm/FormationForm'

const SpeedFormation = ({ data, setData }) => {
  const { t } = useTranslation()
  const [isEditing, setIsEditing] = useState(false)

  return (
    <>
      {isEditing && <FormationForm data={data} setData={setData} setIsEditing={setIsEditing} />}
      {!isEditing && (
        <div>
          {data.speedFormation ? (
            <>
              <div dangerouslySetInnerHTML={{ __html: data.speedFormation.text }} />
              {calculateDaysUntilDate(data.speedFormation.date, t)}
            </>
          ) : (
            <p>{t('home:speed_formation.not_created_yet')}</p>
          )}
        </div>
      )}
      {!isEditing && data.isAdmin && (
        <SDButton id='speed-formation-edit-button' onClick={() => setIsEditing(true)}>
          {t('common:edit')}
        </SDButton>
      )}
    </>
  )
}

SpeedFormation.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
}

export default SpeedFormation
