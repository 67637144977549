import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import useHttp from '@/shared/hooks/useHttp'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import useBusinessType from '@/shared/hooks/useBusinessType'
import SearchConctactFromZohoForm from '@/containers/SubscriptionFormPage/SubscriptionForm/SearchContactFromZohoForm'

const CreateForm = () => {
  const [data, setData] = useState(null)
  const { loaderRoutes, loaderRoutesEnergy, loaderRoutesInsurance, authTokenSession, lang, isExternal } =
    useSelector(selectAuthData)
  const { getApplicationUrl, type: applicationType } = useBusinessType({
    loaderRoutesEnergy,
    loaderRoutes,
    loaderRoutesInsurance,
    modulesStatuses: {},
    modulesStatusesEnergy: {},
    modulesStatusesInsurance: {}
  })
  const initUrl = getApplicationUrl('telesales-add') ?? ''
  const zohoSearchUrl = loaderRoutes['zoho-search-client'] ?? ''
  const zohoSearchResponseUrl = loaderRoutes['zoho-search-client-response'] ?? ''
  const token = authTokenSession
  const { sendRequest, getConfig } = useHttp({ token })
  const initialize = (params) => {
    const search = window.location.search ?? ''
    let countryUrl = initUrl

    if (search) {
      countryUrl += search + '&lang=' + lang
    } else {
      countryUrl += '?lang=' + lang
    }

    if (params) {
      countryUrl += params
    }

    sendRequest(countryUrl, getConfig('GET'), json => {
      setData(json)
    })
  }

  useEffect(() => {
    if (isExternal) {
      return
    }
    initialize()
  }, [])

  if (isExternal) {
    return (
      <SearchConctactFromZohoForm
        zohoSearchUrl={zohoSearchUrl}
        zohoSearchResponseUrl={zohoSearchResponseUrl}
        createSubscription={initialize}
        subscriptionId={data?.id}
        applicationType={applicationType}
      />
    )
  }

  if (!data?.id) {
    return <p>Creation du contrat en cours....</p>
  }

  return <Navigate to={'/' + applicationType + '/subscription/edit/' + data.id} />
}

export default CreateForm
