import { call, put, select, takeLatest } from 'redux-saga/effects'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import {
  updateUsersManagementIsLoadingUsers,
  updateUsersManagementUsers,
  updateUsersManagementUsersError,
} from './UsersManagementSlice'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import getAPIConfig from '@/shared/tools/getAPIConfig'

export const userManagementActions = {
  LOAD_LIST: 'LOAD_LIST',
  UPDATE_USER: 'UPDATE_USER',
  CREATE_USER: 'CREATE_USER',
  UPDATE_USER_STATUS: 'UPDATE_USER_STATUS',
}

export function* loadUsersSaga({ url }) {
  try {
    const { authTokenSession } = yield select(selectAuthData)
    //test token expiration
    yield put(updateUsersManagementIsLoadingUsers(true))
    //reset to default normal state
    yield put(updateUsersManagementUsersError({}))
    yield put(updateUsersManagementUsers({}))

    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })
    const response = yield call(fetch, url, config)

    const { status } = response
    const data = yield call([response, response.json])
    if (status === 200) {
      yield put(updateUsersManagementUsers(data))
    } else {
      yield put(updateUsersManagementUsersError(data))
    }
  } catch (error) {
    yield put(updateUsersManagementUsersError(JSON.parse(error)))
  } finally {
    yield put(updateUsersManagementIsLoadingUsers(false))
  }
}

export function* updateUser({ userData, updateUserSuccessNotification, setFormErrors }) {
  try {
    const { authTokenSession, lang } = yield select(selectAuthData)
    const { routes } = yield select(selectPageConfigurationData)

    // yield put(updateUsersManagementIsLoadingUsers(true))
    const countryUrl = `${routes.update}?lang=${lang}`
    const config = yield call(getAPIConfig, { method: 'put', authTokenSession, body: userData })
    const response = yield call(fetch, countryUrl, config)

    const { success, errors = {}, message } = yield call([response, response.json])
    // TODO: change success for HTTP status

    if (success) {
      yield call(updateUserSuccessNotification, message)
      const url = `${routes.list}?page=1&lang=${lang}`
      yield call(loadUsersSaga, { url })
    } else {
      if (Object.keys(errors).length === 0 && typeof message === "string") {
        alert(message)
      }
      yield call(setFormErrors, errors)
    }
  } catch (error) {
    yield call(setFormErrors, error)
  }
}

export function* createUser({ userData, updateUserSuccessNotification, setFormErrors }) {
  try {
    const { authTokenSession, lang } = yield select(selectAuthData)
    const { routes } = yield select(selectPageConfigurationData)

    // yield put(updateUsersManagementIsLoadingUsers(true))
    const countryUrl = `${routes.store}?lang=${lang}`
    const config = yield call(getAPIConfig, { method: 'post', authTokenSession, body: userData })
    const response = yield call(fetch, countryUrl, config)

    const { success, errors = {}, message } = yield call([response, response.json])
    // TODO: change success for HTTP status

    if (success) {
      yield call(updateUserSuccessNotification, message)
      const url = `${routes.list}?page=1&lang=${lang}`
      yield call(loadUsersSaga, { url })
    } else {
      if (Object.keys(errors).length === 0 && typeof message === "string") {
        alert(message)
      }
      yield call(setFormErrors, errors)
    }
  } catch (error) {
    yield call(setFormErrors, error)
  }
}
export function* updateUserStatus({ status, userId, isUserActive }) {
  try {
    const { authTokenSession, lang } = yield select(selectAuthData)
    const { routes } = yield select(selectPageConfigurationData)
    const route = routes.delete
      .replace('%id%', userId)
      .replace('%status%', status)
      .replace('%state%', status === 'delete' ? 1 : +!isUserActive)
    const countryUrl = `${route}?lang=${lang}`
    const config = yield call(getAPIConfig, { method: 'post', authTokenSession, body: {} })
    const response = yield call(fetch, countryUrl, config)

    const { success } = yield call([response, response.json])

    if (!success) return

    const url = `${routes.list}?page=1&lang=${lang}`
    yield call(loadUsersSaga, { url })
  } catch (error) {
    console.error(error)
  }
}

export default function* usersManagementSagas() {
  yield takeLatest(userManagementActions.LOAD_LIST, loadUsersSaga)
  yield takeLatest(userManagementActions.UPDATE_USER, updateUser)
  yield takeLatest(userManagementActions.CREATE_USER, createUser)
  yield takeLatest(userManagementActions.UPDATE_USER_STATUS, updateUserStatus)
}
