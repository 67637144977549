import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Card, StyledBody } from 'baseui/card'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { InfoFieldsContainer } from '../styled-components'
import { useStyletron } from 'baseui'
import {
  selectEnergyContractSubscriptionData,
  selectTelecomContractSubscriptionData,
  selectInsuranceContractSubscriptionData,
} from '../../SubscriptionFormSelectors'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'

const InfoFields = () => {
  const { t } = useTranslation()
  const [css, theme] = useStyletron()
  const { isAdmin } = useSelector(selectAuthData)

  const energyData = useSelector(selectEnergyContractSubscriptionData)
  const telecomData = useSelector(selectTelecomContractSubscriptionData)
  const insuranceData = useSelector(selectInsuranceContractSubscriptionData)

  const energyProviderExternalId = energyData?.providerExternalId
  const telecomProviderExternalId = telecomData?.providerExternalId
  const insuranceProviderExternalId = insuranceData?.providerExternalId

  const { current_subscriber: subscription } = useSelector(selectPageConfigurationData)
  const providerExternalId = telecomProviderExternalId || energyProviderExternalId || insuranceProviderExternalId

  if (isAdmin === false) {
    return ''
  }

  return (
    <InfoFieldsContainer>
      <Card overrides={{ Root: { style: { borderRadius: '1.1rem' } } }}>
        <StyledBody>
          <p>
            <span
              className={css({
                color: theme.colors.accent,
              })}
            >
              {t('subscriptionForm:subscription_actions_module.extra_fields.provider_external_id')}:
            </span>
            {providerExternalId}
          </p>
          <p>
            <span
              className={css({
                color: theme.colors.accent,
              })}
            >
              {t('subscriptionForm:subscription_actions_module.extra_fields.token')}:
            </span>
            {subscription?.email_token ?? ''}
          </p>
        </StyledBody>
      </Card>
    </InfoFieldsContainer>
  )
}

export default InfoFields
