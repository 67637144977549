import { useStyletron } from 'baseui'

const useDashboardTableStyles = () => {
  const [css] = useStyletron()
  const headCellOverrides = {
    HeadCell: {
      style: {
        padding: '0',
      },
    },
    SortableLabel: {
      style: {
        padding: '.5rem',
        width: '100%',
        fontSize: '12px',
      },
    },
  }
  const loadingStyle = css({
    height: '500px',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  })
  const tableHeight = css({ height: '500px' })
  const tableRow = css({
    display: 'contents',
    ':hover': {
      border: 'solid 1px crimson',
    },
  })
  const headingDisplay = css({ display: 'contents' })

  return {
    headCellOverrides,
    loadingStyle,
    tableHeight,
    tableRow,
    headingDisplay,
  }
}

export default useDashboardTableStyles
