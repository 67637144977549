import { useContext } from 'react'
import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { KIND } from 'baseui/button'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ProviderManagementContext from '../ProviderManagementContext'
import StyledFormHeader from '@/shared/components/FormHeader/styled/StyledFormHeader'
import useBusinessType from '@/shared/hooks/useBusinessType'

const HeadingAddEdit = ({ title, saveHandler }) => {
  const navigate = useNavigate()
  const { type } = useBusinessType({
    loaderRoutesEnergy: {},
    loaderRoutes: {},
    modulesStatuses: {},
    modulesStatusesEnergy: {},
  })
  const providerContext = useContext(ProviderManagementContext)
  const providerState = providerContext.providerState
  const { t } = useTranslation()
  const [css, theme] = useStyletron()
  const headingStyle = css({
    margin: '1rem 10px 10px 10px',
    color: theme.colors.primary500,
    ...theme.typography.HeadingMedium,
  })
  const itemProps = {
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
  }

  return (
    <StyledFormHeader>
      <SDButton onClick={() => navigate('/' + type + '/organisations/provider/' + providerState.selectedProvider.uuid)}>
        {t('common:back')}
      </SDButton>
      <h1 className={headingStyle}>{title}</h1>
      <FlexGrid flexGridColumnCount={3} flexGridColumnGap='scale800' flexGridRowGap='scale800' marginBottom='scale800'>
        <FlexGridItem {...itemProps}>
          <SDButton
            onClick={saveHandler}
            kind={KIND.primary}
            isLoading={providerState.save.loading}
            disabled={providerState.save.loading}
            className={css({
              marginRight: '10px',
            })}
          >
            {t('common:save')}
          </SDButton>
        </FlexGridItem>
      </FlexGrid>
    </StyledFormHeader>
  )
}

HeadingAddEdit.propTypes = {
  title: PropTypes.string,
  saveHandler: PropTypes.func,
}

export default HeadingAddEdit
