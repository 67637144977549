import PropTypes from 'prop-types'
import { Cell, Grid } from 'baseui/layout-grid'
import { FormControl } from 'baseui/form-control'
import { ModalHeader, ModalBody, ModalButton } from 'baseui/modal'
import { Textarea, SIZE } from 'baseui/textarea'
import useAbandonModal from './useAbandonModal'

const AbandonModal = ({ handler, id }) => {
  const { t, css, reason, error, isLoading, sendReason, setReason } = useAbandonModal({ handler, id })

  return (
    <>
      <ModalHeader>{t('subscriptionTable:abandon_subscription')}</ModalHeader>
      <ModalBody>
        <Grid>
          <Cell span={12}>
            <FormControl label={t('subscriptionTable:abandon_why')} error={error}>
              <Textarea
                value={reason}
                onChange={e => setReason(e.target.value)}
                size={SIZE.mini}
                placeholder={t('subscriptionTable:write_comment')}
                clearable
              />
            </FormControl>
            <FormControl>
              <ModalButton
                className={css({ width: '100%' })}
                isLoading={isLoading}
                onClick={sendReason}
                size={SIZE.mini}
              >
                {t('subscriptionTable:abandon')}
              </ModalButton>
            </FormControl>
          </Cell>
        </Grid>
      </ModalBody>
    </>
  )
}

AbandonModal.propTypes = {
  handler: PropTypes.func,
  id: PropTypes.number,
  endpoints: PropTypes.object,
}

export default AbandonModal
