import { Block } from 'baseui/block'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import useDashboardStyles from '../useDashboardStyles'
import useDashboardPaginator from './useDashboardPaginator'

const DashboardPaginator = () => {
  const { paginationStyle } = useDashboardStyles()
  const { t, data, dispatchPage } = useDashboardPaginator()

  if (!data.prev_cursor && !data.next_cursor) {
    return ''
  }

  return (
    <Block className={paginationStyle}>
      {data.prev_cursor && (
        <SDButton
          onClick={event => {
            event.preventDefault()
            dispatchPage(data.prev_cursor)
          }}
        >
          {t('common:previous')}
        </SDButton>
      )}
      {data.next_cursor && (
        <SDButton
          onClick={event => {
            event.preventDefault()
            dispatchPage(data.next_cursor)
          }}
        >
          {t('common:next')}
        </SDButton>
      )}
    </Block>
  )
}

export default DashboardPaginator
