import { withStyle } from 'baseui'
import { Spinner } from 'baseui/spinner'

const SSBSpinner = withStyle(Spinner, ({ $theme }) => ({
  margin: '0 auto',
  width: '.875rem',
  height: '.875rem',
  borderTopColor: $theme.colors.primary500,
  borderRightColor: $theme.colors.mono100,
  borderBottomColor: $theme.colors.mono100,
  borderLeftColor: $theme.colors.mono100,
}))

export default SSBSpinner
