import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconCarbonChargingStation = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'Charging Station'
  size = size ?? 16
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 32 32' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <polygon points='11.857 21.514 10.143 20.486 12.234 17 7.234 17 11.143 10.486 12.857 11.514 10.766 15 15.766 15 11.857 21.514' />
      <path d='M30,7H29V4H27V7H26v6h1V24.5a1.5,1.5,0,0,1-3,0V16a1,1,0,0,0-1-1H19V5a1,1,0,0,0-1-1H5A1,1,0,0,0,4,5V26H2v2H21V26H19V17h3v7.5a3.5,3.5,0,0,0,7,0V13h1ZM17,26H6V6H17Z' />
    </Icon>
  )
}

IconCarbonChargingStation.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconCarbonChargingStation
