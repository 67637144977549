import { call, put, select, takeLatest } from 'redux-saga/effects'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import getAPIConfig from '@/shared/tools/getAPIConfig'
import { initializeSaga } from '@/store/PageConfiguration/PageConfigurationSagas'
import { updateAssistancesFormErrors } from './AssistancesSlice'

export const assistancesActions = {
  UPDATE_ASSISTANCE: 'UPDATE_ASSISTANCE',
  CREATE_ASSISTANCE: 'CREATE_ASSISTANCE',
}

export function* updateAssistance({ assistanceData, id, initUrl, updateSuccessNotification }) {
  try {
    yield put(updateAssistancesFormErrors({}))
    const { authTokenSession, lang } = yield select(selectAuthData)
    const { routes } = yield select(selectPageConfigurationData)
    const updateRoute = routes.update + '?lang=' + lang + '&id=' + id

    const config = yield call(getAPIConfig, { method: 'put', authTokenSession, body: assistanceData })
    const response = yield call(fetch, updateRoute, config)
    const { errors } = yield call([response, response.json])

    if (response.status === 200) {
      yield call(updateSuccessNotification, 'Assistance bien mise à jour')
      yield call(initializeSaga, { initUrl: initUrl + '?lang=' + lang })
    } else {
      yield put(updateAssistancesFormErrors(errors))
    }
  } catch (error) {
    console.error(error)
  }
}

export function* createAssistance({ assistanceData, initUrl, updateSuccessNotification }) {
  try {
    yield put(updateAssistancesFormErrors({}))
    const { authTokenSession, lang } = yield select(selectAuthData)
    const { routes } = yield select(selectPageConfigurationData)

    const config = yield call(getAPIConfig, { method: 'post', authTokenSession, body: assistanceData })
    const response = yield call(fetch, routes.store + '?lang=' + lang, config)
    const { errors } = yield call([response, response.json])

    if (response.status === 200) {
      yield call(updateSuccessNotification, 'Assistance crée')
      yield call(initializeSaga, { initUrl: initUrl + '?lang=' + lang })
    } else {
      yield put(updateAssistancesFormErrors(errors))
    }
  } catch (error) {
    console.error(error)
  }
}

export default function* assistancesSagas() {
  yield takeLatest(assistancesActions.UPDATE_ASSISTANCE, updateAssistance)
  yield takeLatest(assistancesActions.CREATE_ASSISTANCE, createAssistance)
}
