import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconCarbonGaranka = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'Garanka'
  size = size ?? 16
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 32 32' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <path
        d='M22.6,6c-2-1.3-4.3-2-6.6-2C12.1,4,8.3,5.9,6,9.4c-3.7,5.5-2.2,13,3.4,16.6c5.5,3.7,13,2.2,16.6-3.4
	C29.7,17.1,28.2,9.7,22.6,6 M18.6,11.7l2.6-2.6c0.5-0.5,1.4-0.5,1.9,0c0,0,0,0,0,0c0.5,0.5,0.5,1.4,0,1.9l-2.6,2.6
	c-0.5,0.5-1.4,0.5-1.9,0C18.1,13.1,18.1,12.2,18.6,11.7 M9,9.1c0.5-0.5,1.4-0.5,1.9,0c0,0,0,0,0,0l2.6,2.6c0.5,0.5,0.5,1.4,0,1.9
	c0,0,0,0,0,0c-0.5,0.5-1.4,0.5-1.9,0L9,11C8.5,10.5,8.5,9.6,9,9.1C9,9.1,9,9.1,9,9.1 M7.4,17.5c-0.7,0-1.3-0.6-1.3-1.3
	c0-0.7,0.6-1.3,1.3-1.3H11c0.7,0,1.3,0.6,1.3,1.3c0,0,0,0,0,0c0,0.7-0.6,1.3-1.3,1.3c0,0,0,0,0,0H7.4z M13.5,20.6l-2.6,2.6
	c-0.5,0.5-1.4,0.5-1.9,0c-0.5-0.5-0.5-1.4,0-1.9l2.6-2.6c0.5-0.5,1.4-0.5,1.9,0C14,19.3,14,20.1,13.5,20.6 M17.4,24.8
	c0,0.7-0.6,1.3-1.3,1.3c0,0,0,0,0,0c-0.7,0-1.3-0.6-1.3-1.3c0,0,0,0,0,0v-3.6c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3V24.8z
	 M17.4,11.2c0,0.7-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3c0,0,0,0,0,0V7.5c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3V11.2z
	 M23.1,23.2c-0.5,0.5-1.4,0.5-1.9,0c0,0,0,0,0,0l-2.6-2.6c-0.5-0.5-0.5-1.4,0-1.9c0.5-0.5,1.4-0.5,1.9,0l2.6,2.6
	C23.6,21.8,23.6,22.7,23.1,23.2 M24.7,17.5H21c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3h3.6c0.7,0,1.3,0.6,1.3,1.3
	C26,16.9,25.4,17.5,24.7,17.5'
      />
    </Icon>
  )
}

IconCarbonGaranka.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconCarbonGaranka
