/* eslint-disable camelcase */
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { Check } from 'baseui/icon'
import { useSnackbar } from 'baseui/snackbar'

import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import useModal from '@/shared/hooks/useModal'
import { selectUsersManagementSelectedUser } from '../UsersManagementSelectors'
import { updateUsersManagementSelectedUser } from '../UsersManagementSlice'
import { userManagementActions } from '../UsersManagementSagas'

const useAddEditModal = ({ setIsOpen }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { close } = useModal()
  const {
    organizations,
    offices,
    sites,
    ip_addresses: fullIpAddressWhitelists,
  } = useSelector(selectPageConfigurationData)
  const [formErrors, setFormErrors] = useState({})
  const { enqueue } = useSnackbar()
  const selectedUser = useSelector(selectUsersManagementSelectedUser)
  let ipAddressWhitelists = fullIpAddressWhitelists

  const isSelectraOrganisation = selectedOrganization => {
    let isSelectraOrganization = false
    organizations.map(element => {
      if (parseInt(element.id) === parseInt(selectedOrganization)) {
        isSelectraOrganization = parseInt(element.is_selectra) === 1
      }
    })

    return isSelectraOrganization
  }

  if (isSelectraOrganisation(selectedUser.organisation_id)) {
    ipAddressWhitelists = [{ id: 0, name: '-- VIDE --' }, ...fullIpAddressWhitelists]
  }

  const submitHandler = event => {
    event.preventDefault()
    const {
      id = 0,
      name,
      firstname,
      email,
      role,
      zoho_id,
      tde_id = '',
      organisation_id,
      authorized_sites = [],
      hangouts_space_name = '',
      password = '',
      is_cousin_hub,
      calendly_url,
      office_uuid,
      ip_address_whitelist_id,
    } = selectedUser

    const authorizedSites = authorized_sites.map(({ id }) => id)

    const userData = {
      name,
      firstname,
      email,
      role,
      zoho_id,
      tde_id,
      organisation_id,
      authorized_sites: authorizedSites,
      hangouts_space_name,
      is_cousin_hub,
      calendly_url,
      office_uuid,
      ip_address_whitelist_id,
    }

    if (id) {
      const useDataWithId = { ...userData, id }

      return dispatch({
        type: userManagementActions.UPDATE_USER,
        userData: useDataWithId,
        updateUserSuccessNotification,
        setFormErrors,
      })
    }

    const useDataWithPassword = { ...userData, password }

    dispatch({
      type: userManagementActions.CREATE_USER,
      userData: useDataWithPassword,
      updateUserSuccessNotification,
      setFormErrors,
    })
  }

  const updateUserSuccessNotification = message => {
    setIsOpen(false)
    enqueue({
      message,
      startEnhancer: ({ size }) => <Check size={size} />,
    })
  }

  const updateSelectedUser = userInformation => {
    dispatch(updateUsersManagementSelectedUser({ ...selectedUser, ...userInformation }))
  }

  const sitesOptions = sites?.map(element => ({
    id: element.id,
    label: element.business_type ?? element.domain_url ?? '',
  }))

  const roleList = [
    {
      label: t('usersManagement:roles.admin'),
      id: 'admin',
    },
    {
      label: t('usersManagement:roles.user'),
      id: 'user',
    },
  ]

  const convertList = list => {
    const convertedList = {}
    list.map(element => {
      convertedList[element.id ?? element.uuid] = element.label ?? element.name ?? ''
    })
    return convertedList
  }

  const convertOrganizationsList = list =>
    list.reduce(
      (org, item) => {
        const data = { id: item.id, label: item.name }
        if (item.type === 'comp') {
          org[t('usersManagement:organization_types.comp')].push(data)
        } else if (item.type === 'provider') {
          if (item.business_type === 'energy') {
            org[t('usersManagement:organization_types.provider_energy')].push(data)
          } else if (item.business_type === 'telecom') {
            org[t('usersManagement:organization_types.provider_telecom')].push(data)
          }
        }
        return org
      },
      {
        [t('usersManagement:organization_types.comp')]: [],
        [t('usersManagement:organization_types.provider_energy')]: [],
        [t('usersManagement:organization_types.provider_telecom')]: [],
      },
    )

  const findSelectedOrganization = (list, id) => {
    for (const item of list) {
      if (item.id === id) {
        return { id: item.id, label: item.name }
      }
    }

    return ''
  }

  const generateSitesSelectedValues = currentValues => {
    if (!currentValues) {
      return []
    }

    const result = currentValues.map(element => {
      if (element === 0) {
        return null
      }

      if (element.id) {
        return element
      }

      return {
        id: element,
      }
    })

    return result.filter(element => element !== null)
  }

  const nameOfOrganization = organizationId => {
    let name = t('common:choose')

    if (!organizationId) {
      return name
    }

    organizations.map(element => {
      if (parseInt(element.id) === parseInt(organizationId)) {
        name = element.name
      }
    })

    return name
  }

  const nameOfOffice = officeUuid => {
    let name = t('common:choose')

    if (!officeUuid) {
      return name
    }

    offices.map(element => {
      if (element.uuid === officeUuid) {
        name = element.name
      }
    })

    return name
  }

  return {
    t,
    close,
    organizations,
    offices,
    formErrors,
    selectedUser,
    updateSelectedUser,
    sitesOptions,
    submitHandler,
    roleList,
    convertList,
    convertOrganizationsList,
    findSelectedOrganization,
    generateSitesSelectedValues,
    nameOfOrganization,
    isSelectraOrganisation,
    nameOfOffice,
    ipAddressWhitelists,
  }
}

export default useAddEditModal
