import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'

export default function PrestationBadge({ prestation }) {
  let badgeColour

  switch (prestation) {
    case 'cdf':
      badgeColour = 'purple'
      break
    case 'mes':
      badgeColour = 'goldenrod'
      break
    default:
      badgeColour = 'grey'
  }

  const [css] = useStyletron()
  const colour = badgeColour
  const linkStyle = css({
    display: 'flex',
    width: '100%',
    border: `2px solid ${colour}`,
    borderRadius: '.25rem',
    backgroundColor: 'white',
  })
  const badgeStyle = css({
    display: 'flex',
    alignItems: 'center',
    padding: '.125rem .25rem .125rem .125rem',
    backgroundColor: colour,
    color: 'white',
    fontSize: '.75rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  })

  if (prestation === '-1') {
    return ''
  }

  return (
    <div className={linkStyle}>
      <div className={badgeStyle}>
        <span>{prestation}</span>
      </div>
    </div>
  )
}

PrestationBadge.propTypes = {
  prestation: PropTypes.string,
  offerId: PropTypes.string,
}
