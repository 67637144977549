import PropTypes from 'prop-types'
import { Table } from 'baseui/table-semantic'
import { useTranslation } from 'react-i18next'
import { SIZE } from 'baseui/button'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'

const MonConseillerPersoList = ({ items, page, elementsPerPage, updateMonConseillerPerso }) => {
  const { t } = useTranslation()
  const COLUMNS = [
    t('monConseillerPersoConfiguration:name'),
    t('monConseillerPersoConfiguration:price'),
    t('monConseillerPersoConfiguration:status'),
    t('monConseillerPersoConfiguration:zoho_id'),
    t('common:edit'),
  ]
  const transformMonConseillerPerso = monConseillerPersoList => {
    if (!monConseillerPersoList) {
      return []
    }
    let elements = monConseillerPersoList.map(monConseillerPerso => {
      return [
        monConseillerPerso.name,
        monConseillerPerso.price,
        parseInt(monConseillerPerso.status) === 0
          ? t('monConseillerPersoConfiguration:status_inactive')
          : t('monConseillerPersoConfiguration:status_active'),
        monConseillerPerso.zoho_id,
        <SDButton
          key={monConseillerPerso.id}
          block={true}
          size={SIZE.compact}
          onClick={() => updateMonConseillerPerso(monConseillerPerso)}
        >
          {t('common:edit')}
        </SDButton>,
      ]
    })
    elements = elements.slice((page - 1) * elementsPerPage, page * elementsPerPage)
    return elements
  }
  return <Table columns={COLUMNS} data={transformMonConseillerPerso(items)} />
}

MonConseillerPersoList.propTypes = {
  items: PropTypes.array,
  page: PropTypes.number,
  elementsPerPage: PropTypes.number,
  updateMonConseillerPerso: PropTypes.func,
}
export default MonConseillerPersoList
