import { useState } from 'react'
import useToken from './useToken'

export default function useResponsiveGrid(country, storageName, defaultLayout) {
  const tokenTool = useToken()
  const getFromLS = () => {
    try {
      return JSON.parse(tokenTool.get(storageName + country)) || {}
    } catch (e) {
      console.error(e)
    }
    return false
  }
  const originalLayouts = {
    ...defaultLayout,
    ...getFromLS(country),
  }
  const [layouts, setLayouts] = useState(originalLayouts)
  const saveToLS = layouts => {
    tokenTool.set(storageName + country, JSON.stringify(layouts))
  }
  const layoutChangeHandler = layouts => {
    saveToLS(layouts)
    setLayouts(layouts)
  }
  return {
    layoutChangeHandler,
    layouts,
    setLayouts,
  }
}
