import { styled } from 'baseui'

export const StyledForm = styled('div', () => ({
  display: 'grid',
  columnGap: '1.25rem',
  rowGap: '1.25rem',
  gridTemplateColumns: '100%',
  gridTemplateRows: 'repeat(4, auto)',
  gridTemplateAreas: `
      "header"
      "modules"
      "tools"
      "additionalSales"
    `,

  '@media (min-width: 768px)': {
    gridTemplateColumns: 'minmax(0, 60%) minmax(0, 40%)',
    gridTemplateRows: 'repeat(3, auto)',
    gridTemplateAreas: `
      "header header"
      "modules tools"
      "additionalSales tools"
    `,
  },

  '@media (min-width: 960px)': {
    gridTemplateColumns: 'repeat(3, minmax(0, 33.33%))',
    gridTemplateAreas: `
      "header header header"
      "modules modules tools"
      "additionalSales additionalSales tools"
    `,
  },

  '@media (min-width:1200px)': {
    gridTemplateColumns: 'repeat(4, minmax(0, 25%))',
    gridTemplateAreas: `
      "header header header header"
      "modules modules modules tools"
      "additionalSales additionalSales additionalSales tools"
    `,
  },
}))
