import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'
import { FormControl } from 'baseui/form-control'
import { Editor } from '@tinymce/tinymce-react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import SDInput from '@/shared/components/SDComponents/SDInput/SDInput'
import { useTranslation } from 'react-i18next'
import HeadingAddEdit from '../../OrganizationForm/HeadingAddEdit'
import SDButtonGroup from '@/shared/components/SDComponents/SDButtonGroup/SDButtonGroup'
import { MODE, SIZE } from 'baseui/button-group'
import SDButtonGroupButton from '@/shared/components/SDComponents/SDButtonGroup/SDButtonGroupButton'

const TelecomOfferForm = ({ providerDispatch, providerState, saveOffer, title, lang }) => {
  const { t } = useTranslation()
  const [css, theme] = useStyletron()
  const productsTypes = []
  const selectedProductType = []
  const getProductTypes = () => {
    if (lang === 'be') {
      return {
        box: t('organizationManagement:offer.product_type.box'),
        mobile: t('organizationManagement:offer.product_type.mobile'),
        tv: t('organizationManagement:offer.product_type.tv'),
        landline: t('organizationManagement:offer.product_type.landline'),
      }
    }
    return {
      box: t('organizationManagement:offer.product_type.box'),
      mobile: t('organizationManagement:offer.product_type.mobile'),
    }
  }
  const cardStyle = css({
    padding: '1rem',
    marginBottom: '2rem',
    color: 'white',
  })
  const blockStyle = css({
    padding: '1rem',
    marginTop: '2rem',
    marginBottom: '2rem',
    backgroundColor: 'white',
    borderRadius: '1rem',
    boxShadow: '0 2px 6px rgba(20,96,170,.3)',
    width: '100%',
    color: theme.colors.contentSecondary,
  })
  const sectionStyle = css({
    margin: '0.5rem 5px 5px 5px',
    color: theme.colors.primary,
  })
  const updateOffer = (attributeName, value) => {
    providerDispatch({ type: 'updateOffer', payload: { [attributeName]: value } })
  }
  const errors = field => {
    const errorField = providerState.errors[field] !== undefined ? providerState.errors[field][0] ?? false : false
    if (errorField !== undefined) {
      return errorField
    }
  }
  let index = 0
  for (const [key] of Object.entries(getProductTypes())) {
    if (key === providerState.selectedOffer.product_type) {
      selectedProductType.push(index)
    }
    index++
    productsTypes.push(
      <SDButtonGroupButton
        id={'product-type-' + key}
        key={key}
        onClick={event => {
          event.preventDefault()
          updateOffer('product_type', key)
        }}
      >
        {t('subscriptionForm:characteristics_module.product_type.' + key)}
      </SDButtonGroupButton>,
    )
  }

  return (
    <div className={cardStyle}>
      <HeadingAddEdit title={title} saveHandler={saveOffer} />
      <FlexGrid flexGridColumnCount={3} flexGridColumnGap='scale800' flexGridRowGap='scale800'>
        <FlexGridItem>
          <div className={blockStyle}>
            <h2 className={sectionStyle}>{t('organizationManagement:general_info.title')}</h2>
            <FormControl label={t('organizationManagement:offer.name')} error={errors('name')}>
              <SDInput
                error={errors('name')}
                value={providerState.selectedOffer.name ?? ''}
                onChange={event => {
                  event.preventDefault()
                  updateOffer('name', event.target.value)
                }}
              />
            </FormControl>
            <FormControl label={t('organizationManagement:offer.is_active')} error={errors('active')}>
              <SDButtonGroup
                mode={MODE.radio}
                size={SIZE.mini}
                selected={providerState.selectedOffer.active}
                error={errors('active')}
              >
                <SDButtonGroupButton onClick={(event, index) => updateOffer('active', 0)}>
                  {t('organizationManagement:offer.is_active_option_no')}
                </SDButtonGroupButton>
                <SDButtonGroupButton onClick={(event, index) => updateOffer('active', 1)}>
                  {t('organizationManagement:offer.is_active_option_yes')}
                </SDButtonGroupButton>
              </SDButtonGroup>
            </FormControl>
            <FormControl label={t('organizationManagement:offer.product_type')} error={errors('product_type')}>
              <SDButtonGroup
                mode={MODE.radio}
                size={SIZE.mini}
                selected={selectedProductType}
                error={errors('product_type')}
              >
                {productsTypes}
              </SDButtonGroup>
            </FormControl>
            <FormControl label={t('organizationManagement:offer.zoho_id')} error={errors('zoho_id')}>
              <SDInput
                error={errors('zoho_id')}
                value={providerState.selectedOffer.zoho_id ?? ''}
                onChange={event => {
                  event.preventDefault()
                  updateOffer('zoho_id', event.target.value)
                }}
              />
            </FormControl>
          </div>
        </FlexGridItem>
      </FlexGrid>
      {lang !== 'be' && (
        <div className={blockStyle}>
          <FormControl label={t('organizationManagement:offer.description')}>
            <Editor
              apiKey={providerState.tinymceKey}
              init={{
                plugins: [
                  'advlist',
                  'autolink',
                  'lists',
                  'link',
                  'image',
                  'charmap',
                  'preview',
                  'anchor',
                  'searchreplace',
                  'visualblocks',
                  'code',
                  'fullscreen',
                  'insertdatetime',
                  'media',
                  'table',
                  'code',
                  'wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
              }}
              initialValue={providerState.selectedOffer.description ?? ''}
              onChange={e => {
                updateOffer('description', e.target.getContent())
              }}
            />
          </FormControl>
        </div>
      )}
    </div>
  )
}

TelecomOfferForm.propTypes = {
  providerState: PropTypes.object,
  providerDispatch: PropTypes.func,
  saveOffer: PropTypes.func,
  title: PropTypes.string,
  lang: PropTypes.string,
}

export default TelecomOfferForm
