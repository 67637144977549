import PropTypes from 'prop-types'
import { StyledBodyCell } from 'baseui/table-grid'
import StatusBadge from './StatusBadge'
import { ACCESSIBILITY_TYPE, StatefulTooltip } from 'baseui/tooltip'
import { useStyletron } from 'baseui'
import getSubscriptionStatusColour from './getSubscriptionStatusColour'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { SIZE } from 'baseui/button'
import getSubscriptionFromZohoStatusColour from './getSubscriptionFromZohoStatusColour'
import { useTranslation } from 'react-i18next'

const StatusAndActionsCell = ({
  status,
  retrievedFrom,
  errors,
  striped,
  selected,
  subscriptionId = null,
  subscriberProductType,
}) => {
  const { t } = useTranslation()
  const [css] = useStyletron()
  const columnStyle = css({
    display: 'flex',
    flexDirection: 'column',
  })
  const rowStyle = css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '.25rem',
  })
  const listStyle = css({
    padding: '0',
    margin: '0',
    listStyle: 'none',
  })
  const cellStyle = css({
    padding: '.5rem',
    backgroundColor: selected ? '#c1d1e2' : 'none',
  })
  const colour =
    retrievedFrom === 'hop' ? getSubscriptionStatusColour(status) : getSubscriptionFromZohoStatusColour(status)
  const computedStatus = status.length > 0 ? status : t('subscriptionTable:no_status')

  if (errors && errors.length > 0) {
    return (
      <StyledBodyCell className={cellStyle} $striped={striped && !selected}>
        <StatefulTooltip
          content={() => {
            const children = []
            errors.forEach((error, index) => {
              children.push(
                <li key={index}>
                  {error}
                  <br />
                </li>,
              )
            })

            return <ul className={listStyle}>{children}</ul>
          }}
          showArrow
          returnFocus
          autoFocus
          accessibilityType={ACCESSIBILITY_TYPE.tooltip}
        >
          <span>
            <StatusBadge status={computedStatus} colour={colour} />
          </span>
        </StatefulTooltip>
      </StyledBodyCell>
    )
  }

  return (
    <StyledBodyCell className={cellStyle} $striped={striped && !selected}>
      <div className={columnStyle}>
        <div className={rowStyle}>
          <StatusBadge status={computedStatus} colour={colour} />
        </div>
        <div className={rowStyle}>
          {subscriptionId !== null && (
            <SDButton
              size={SIZE.mini}
              block={true}
              onClick={e => {
                e.preventDefault()
                window.open(
                  '/' + t('common:' + subscriberProductType) + '/subscription/edit/' + subscriptionId,
                  '_blank',
                )
              }}
            >
              {t('common:edit')}
            </SDButton>
          )}
          {subscriptionId === null && 'N/A'}
        </div>
      </div>
    </StyledBodyCell>
  )
}

StatusAndActionsCell.propTypes = {
  status: PropTypes.string,
  retrievedFrom: PropTypes.string,
  editLink: PropTypes.string,
  errors: PropTypes.array,
  striped: PropTypes.bool,
  selected: PropTypes.bool,
  subscriberProductType: PropTypes.string,
}

export default StatusAndActionsCell
