import { useStyletron } from 'baseui'
import useServiceStatus from './useServiceStatus'
import { FormControl } from 'baseui/form-control'
import { Checkbox, LABEL_PLACEMENT, STYLE_TYPE } from 'baseui/checkbox'
import {
  checkboxFCOverrides,
  checkboxOverrides,
  StyledFormServicesPanel,
  StyledHeader,
  StyledServiceCard,
  StyledTitle,
} from './styled-components'

const ServicesStatus = () => {
  const { t, services, isLoading, getServiceName, getServiceIcon, submitService } = useServiceStatus()

  const [css] = useStyletron()
  const cardStyle = css({
    padding: '1rem',
    marginBottom: '2rem',
    backgroundColor: 'white',
    borderRadius: '1rem',
    boxShadow: '0 2px 6px rgba(20,96,170,.3)',
  })

  if (isLoading || !services) {
    return (
      <div className={cardStyle}>
        <p>{t('common:fetching_data')}</p>
      </div>
    )
  }

  return (
    <StyledFormServicesPanel>
      {Object.keys(services).map((serviceName, index) => {
        const splittedService = serviceName.split('-')
        const textServiceName = getServiceName(splittedService, serviceName)
        return (
          <StyledServiceCard key={index}>
            <StyledHeader>
              {getServiceIcon(serviceName)}
              <StyledTitle>{t('servicesManagement:' + textServiceName + '.title')}</StyledTitle>
              <FormControl overrides={checkboxFCOverrides}>
                <Checkbox
                  disabled={isLoading}
                  overrides={checkboxOverrides}
                  checked={services[serviceName]}
                  checkmarkType={STYLE_TYPE.toggle_round}
                  onChange={event => submitService(event, serviceName)}
                  labelPlacement={LABEL_PLACEMENT.left}
                />
              </FormControl>
            </StyledHeader>
          </StyledServiceCard>
        )
      })}
    </StyledFormServicesPanel>
  )
}

export default ServicesStatus
