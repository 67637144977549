import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconCarbonDownload = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'Download'
  size = size ?? 16
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 32 32' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <path d='M26,24v4H6V24H4v4H4a2,2,0,0,0,2,2H26a2,2,0,0,0,2-2h0V24Z' />
      <polygon points='26 14 24.59 12.59 17 20.17 17 2 15 2 15 20.17 7.41 12.59 6 14 16 24 26 14' />
    </Icon>
  )
}

export default IconCarbonDownload
