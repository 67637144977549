import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconLineRed = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? ''
  size = size ?? 16
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 54 4' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <rect width='54' height='4' rx='2' fill='#C20000' />
    </Icon>
  )
}

IconLineRed.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconLineRed
