/* eslint-disable camelcase */
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Check } from 'baseui/icon'
import { useSnackbar } from 'baseui/snackbar'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import useModal from '@/shared/hooks/useModal'
import useHttp from '@/shared/hooks/useHttp'
import { useSelector } from 'react-redux'

const useAddEditModal = ({ setIsOpen, ipWhitelistSelected, ipAddressesUrls, refreshList }) => {
  const { t } = useTranslation()
  const { lang } = useSelector(selectAuthData)
  const { close } = useModal()
  const [formErrors, setFormErrors] = useState({})

  const { enqueue } = useSnackbar()
  const { sendRequest, isLoading, getConfig } = useHttp()

  const updateUserSuccessNotification = message => {
    setIsOpen(false)
    enqueue({
      message,
      startEnhancer: ({ size }) => <Check size={size} />
    })
  }

  const submitHandler = (organizationId) => {
    if (ipWhitelistSelected.id) {
      const headers = getConfig('PUT')
      const options = new URLSearchParams()
      options.append('id', ipWhitelistSelected.id ?? '')
      options.append('name', ipWhitelistSelected.name ?? '')
      options.append('ip_addresses', ipWhitelistSelected.ip_addresses ?? '')
      options.append('organization_id', organizationId)
      headers.body = options
      sendRequest(ipAddressesUrls.update + '?lang=' + lang, headers, (json, status) => {
        if (status === 200) {
          refreshList()
          updateUserSuccessNotification(json.message)
          return
        }

        setFormErrors(json.errors)
      })
      return
    }

    const headers = getConfig('POST')
    const options = new URLSearchParams()
    options.append('name', ipWhitelistSelected.name ?? '')
    options.append('ip_addresses', ipWhitelistSelected.ip_addresses ?? '')
    options.append('organization_id', organizationId)
    headers.body = options
    sendRequest(ipAddressesUrls.create + '?lang=' + lang, headers, (json, status) => {
      if (status === 201) {
        refreshList()
        updateUserSuccessNotification(json.message)
        return
      }

      setFormErrors(json.errors)
    })
  }

  return {
    t,
    close,
    formErrors,
    submitHandler
  }
}

export default useAddEditModal
