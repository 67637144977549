import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { useSelector } from 'react-redux'
import useHttp from '@/shared/hooks/useHttp'
import IconCarbonLightning from '@/shared/icons/IconCarbonLightning'
import IconCarbonRule from '@/shared/icons/IconCarbonRule'
import IconCarbonMobile from '@/shared/icons/IconCarbonMobile'

const useCallInformation = () => {
  const { sendRequest, getConfig } = useHttp()
  const { authorizedSites } = useSelector(selectAuthData)
  const authorizedBusinessTypes = []
  authorizedSites.forEach(site => {
    authorizedBusinessTypes.push(site.business_type)
  })
  const { loaderRoutes } = useSelector(selectAuthData)
  const initUrl = loaderRoutes['call-information'] ?? ''
  const { t } = useTranslation()
  const [createSaleIsLoading, setCreateSaleIsLoading] = useState(false)
  const [data, setData] = useState(null)
  const [currentCallData, setCurrentCallData] = useState(null)
  const isLoading = !data
  const isEmpty = !currentCallData
  const zohoId = currentCallData?.zoho_id ?? null
  const [hopSubscriptions, setHopSubscriptions] = useState(null)
  const [hopSubscriptionsLoading, setHopSubscriptionsLoading] = useState(false)
  const [hopAdditionalSales, setHopAdditionalSales] = useState(null)
  const [hopAdditionalSalesLoading, setAdditionalSalesLoading] = useState(false)
  const [lastCalls, setLastCalls] = useState(null)
  const [lastCallsLoading, setLastCallsLoading] = useState(false)

  const businessDataMatch = {
    energy: { Icon: IconCarbonLightning, titleKey: 'callInformation:create_energy_sale_button', sale: 'energy', path: 'energie' },
    insurance: { Icon: IconCarbonRule, titleKey: 'callInformation:create_insurance_sale_button', sale: 'insurance', path: 'assurance' },
    telecom: { Icon: IconCarbonMobile, titleKey: 'callInformation:create_telecom_sale_button', sale: 'telecom', path: 'telecom' },
  }

  const initialize = loadUrl => {
    sendRequest(loadUrl, getConfig('GET'), (response, statusCode) => {
      if (statusCode === 200) {
        setData(response)
        setCurrentCallData(response.current_call_info ?? null)
      }
    })
  }
  const createSale = async (type, path) => {
    setCreateSaleIsLoading(true)
    const parameters = []
    if (currentCallData.zoho_id) {
      parameters.push('zoho_id=' + encodeURIComponent(currentCallData.zoho_id))
    }
    if (currentCallData.phone) {
      parameters.push('phone1=' + encodeURIComponent(currentCallData.phone))
    }
    if (currentCallData.email) {
      parameters.push('email=' + encodeURIComponent(currentCallData.email))
    }
    if (currentCallData.firstname) {
      parameters.push('firstname=' + encodeURIComponent(currentCallData.firstname))
    }
    if (currentCallData.lastname) {
      parameters.push('lastname=' + encodeURIComponent(currentCallData.lastname))
    }
    parameters.push('bypassCallInformation=true')
    const url = (data['create_' + type + '_sale_url'] ?? '') + '?' + parameters.join('&');

    sendRequest(
      url,
      getConfig('GET'),
      (response, statusCode) => {
        if (statusCode === 201 && response.id) {
          window.open(window.location.origin + '/' + path + '/subscription/edit/' + response.id, '_blank')
        }
        setCreateSaleIsLoading(false)
      },
    )
  }

  const loadHopSubscriptions = async () => {
    setHopSubscriptionsLoading(true)
    sendRequest(
      data.load_hop_subscriptions_url + '?zoho_id=' + encodeURIComponent(zohoId),
      getConfig('GET'),
      (response, statusCode) => {
        if (statusCode === 200) {
          setHopSubscriptions(response)
        }
        setHopSubscriptionsLoading(false)
      },
    )
  }

  const loadHopAdditionalSales = async () => {
    setAdditionalSalesLoading(true)
    sendRequest(
      data.load_hop_additional_sales_url + '?zoho_id=' + encodeURIComponent(zohoId),
      getConfig('GET'),
      (response, statusCode) => {
        if (statusCode === 200) {
          setHopAdditionalSales(response)
        }
        setAdditionalSalesLoading(false)
      },
    )
  }

  const loadLastCalls = async () => {
    setLastCallsLoading(true)
    sendRequest(
      data.load_last_calls_url + '?zoho_id=' + encodeURIComponent(zohoId),
      getConfig('GET'),
      (response, statusCode) => {
        if (statusCode === 200) {
          setLastCalls(response)
        }
        setLastCallsLoading(false)
      },
    )
  }

  useEffect(() => {
    if (zohoId === null) {
      return
    }
    loadHopSubscriptions()
    loadHopAdditionalSales()
    loadLastCalls()
  }, [zohoId])

  useEffect(() => {
    const path = location.pathname.split('/')
    const zohoId = path[path.length - 1]
    const loadUrl = initUrl.replace('*', zohoId)
    initialize(loadUrl)
  }, [location.pathname])

  return {
    isLoading,
    isEmpty,
    createSale,
    createSaleIsLoading,
    data,
    currentCallData,
    t,
    hopSubscriptions,
    hopSubscriptionsLoading,
    hopAdditionalSales,
    hopAdditionalSalesLoading,
    lastCalls,
    lastCallsLoading,
    authorizedBusinessTypes,
    businessDataMatch
  }
}

export default useCallInformation
