import PropTypes from 'prop-types'
import { HeadingSmall } from 'baseui/typography'
import { StyledTable, StyledBodyCell } from 'baseui/table-grid'
import { useStyletron } from 'baseui'
import IconPreview from '@/shared/icons/IconCarbonPreview'
import { useTranslation } from 'react-i18next'

const Preview = ({ data, subscriberId }) => {
  const { t } = useTranslation()
  const [css] = useStyletron()
  const Detail = ({ infoKey, value, striped }) => {
    if (!value) {
      return ''
    }

    return (
      <StyledBodyCell
        $striped={striped}
        className={css({
          fontSize: '.75rem',
        })}
      >
        <strong>{t('subscriptionTable:' + infoKey)}</strong>: {value}
      </StyledBodyCell>
    )
  }
  const Tables = () => {
    const tables = []

    Object.keys(data).forEach((table, key) => {
      const rows = []

      if (data[table] && table !== 'comments') {
        Object.keys(data[table]).forEach((key, index) => {
          const striped = index % 2 === 0

          if (key === 'iban' || key === 'bic') {
            return
          }

          rows.push(
            <div role='row' key={index} className={css({ display: 'contents' })}>
              <Detail infoKey={key} value={data[table][key]} striped={striped} />
            </div>,
          )
        })

        tables.push(
          <div key={key}>
            <HeadingSmall>{t('subscriptionTable:' + table)}</HeadingSmall>
            <StyledTable
              role='grid'
              className={css({
                marginBottom: '1rem',
              })}
            >
              {rows}
            </StyledTable>
          </div>,
        )
      }
    })

    return tables
  }

  Detail.propTypes = {
    infoKey: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    striped: PropTypes.bool,
  }

  return (
    <>
      <HeadingSmall>
        <IconPreview size={24} />
        {t('subscriptionTable:preview')}
      </HeadingSmall>
      <div
        className={css({
          maxHeight: '400px',
          overflowX: 'scroll',
        })}
      >
        <Tables />
      </div>
    </>
  )
}

Preview.propTypes = {
  data: PropTypes.object,
  subscriberId: PropTypes.number,
}

export default Preview
