import { styled } from 'baseui'

export const StyledSidebarMenu = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '1rem',
  height: '100%',
  width: '100%',
  backgroundColor: $theme.colors.white,
}))

export const StyledSidebarButton = styled('a', ({ $theme, $isSelected }) => ({
  display: 'flex',
  padding: '.25rem',
  backgroundColor: $isSelected ? $theme.colors.primary : $theme.colors.white,
  outlineOffset: '-.125rem',

  borderTopLeftRadius: $theme.borders.radius200,
  borderTopRightRadius: $theme.borders.radius200,
  borderBottomRightRadius: $theme.borders.radius200,
  borderBottomLeftRadius: $theme.borders.radius200,

  ':hover': {
    outline: `.125rem solid ${$theme.colors.primary}`,
  },

  ':not(:last-child)': {
    marginBottom: '1rem',
  },
}))

export const StyledSidebarDivButton = styled('div', ({ $theme, $isSelected }) => ({
  display: 'flex',
  padding: '.25rem',
  backgroundColor: $isSelected ? $theme.colors.primary : $theme.colors.white,
  outlineOffset: '-.125rem',
  width: 'auto',

  borderTopLeftRadius: $theme.borders.radius200,
  borderTopRightRadius: $theme.borders.radius200,
  borderBottomRightRadius: $theme.borders.radius200,
  borderBottomLeftRadius: $theme.borders.radius200,

  ':hover': {
    outline: `.125rem solid ${$theme.colors.primary}`,
  },

  marginBottom: '1rem',
}))

export const logoOverrides = () => ({
  Svg: {
    style: () => ({
      height: 'auto',
      marginBottom: '0.5rem',
    }),
  },
})

export const tooltipOverrides = () => ({
  Body: {
    style: ({ $theme }) => ({
      margin: '.25rem',

      backgroundColor: 'none',

      boxShadow: $theme.lighting.shadow600,

      borderTopLeftRadius: $theme.borders.radius200,
      borderTopRightRadius: $theme.borders.radius200,
      borderBottomRightRadius: $theme.borders.radius200,
      borderBottomLeftRadius: $theme.borders.radius200,
    }),
  },
  Inner: {
    style: ({ $theme }) => ({
      backgroundColor: $theme.colors.white,

      color: $theme.colors.mono700,

      borderTopLeftRadius: $theme.borders.radius200,
      borderTopRightRadius: $theme.borders.radius200,
      borderBottomRightRadius: $theme.borders.radius200,
      borderBottomLeftRadius: $theme.borders.radius200,
      border: `2px solid ${$theme.colors.bg150}`,
    }),
  },
})
