import { SIZE } from 'baseui/button'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { Modal, ModalHeader, ModalBody } from 'baseui/modal'

import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import AddEditModal from './AddEditModal'
import Title from '@/shared/components/PageTitle'
import useIpAddressWhitelistManagement from './useIpAddressWhitelistManagement'
import IpAddressWhitelistsTable from './IpAddressesTable/IpAddressWhitelistsTable'

const IpAddressWhitelistManagement = () => {
  const {
    ipAddressWhitelists,
    isLoading,
    t,
    isOpen,
    close,
    openIpAddressesModal,
    setIsOpen,
    ipWhitelistSelected,
    setIpWhitelistSelected,
    ipAddressesUrls,
    refreshList
  } = useIpAddressWhitelistManagement()

  const flexGridItemProps = {
    display: 'flex',
    alignItems: 'center'
  }

  if (isLoading) {
    return <p>{t('common:fetching_data')}</p>
  }

  return (
    <>
      <Title title={t('ipAddressesManagement:title')} id='ip-addresses-title' />
      <FlexGrid flexGridColumnCount={2} marginBottom='.5rem'>
        <FlexGridItem {...flexGridItemProps}>
          <SDButton key={0} block={false} size={SIZE.compact} onClick={() => openIpAddressesModal(null)}>
            {t('ipAddressesManagement:create_button')}
          </SDButton>
        </FlexGridItem>
      </FlexGrid>

      <IpAddressWhitelistsTable
        ipAddressWhitelists={ipAddressWhitelists}
        t={t}
        openIpAddressesModal={openIpAddressesModal}
        organizations={ipAddressesUrls?.organizations ?? []}
      />

      <Modal onClose={close} isOpen={isOpen}>
        <ModalHeader>
          {ipWhitelistSelected.id === 0
            ? t('ipAddressesManagement:modal.header_create')
            : t('ipAddressesManagement:modal.header_update')}
        </ModalHeader>
        <ModalBody>
          <AddEditModal
            ipWhitelistSelected={ipWhitelistSelected}
            setIsOpen={setIsOpen}
            setIpWhitelistSelected={setIpWhitelistSelected}
            ipAddressesUrls={ipAddressesUrls}
            refreshList={refreshList}
            organizations={ipAddressesUrls?.organizations ?? []}
          />
        </ModalBody>
      </Modal>
    </>
  )
}

export default IpAddressWhitelistManagement
