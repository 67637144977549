import { FormControl } from 'baseui/form-control'
import { HOPInput } from '@/shared/components/HOPComponents/HOPInput'
import { SIZE } from 'baseui/button'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import useAddEditModal from './useAddEditModal'
import UserComboBox from './UserComboBox'

const AddEditModal = ({ setIsOpen }) => {
  const { t, formErrors, selectedOffice, updateSelectedOffice, submitHandler, searchUsersUrl } = useAddEditModal({
    setIsOpen,
  })

  return (
    <form onSubmit={submitHandler}>
      <FormControl label={t('officesManagement:modal.name')} error={formErrors.name ? formErrors.name[0] : ''}>
        <HOPInput
          currentValue={selectedOffice.name}
          handleValue={value => {
            updateSelectedOffice({ name: value })
          }}
        />
      </FormControl>
      <UserComboBox
        formErrors={formErrors}
        searchUsersUrl={searchUsersUrl}
        onChange={value => updateSelectedOffice({ mcp_agent_id: value })}
        value={selectedOffice?.mcp_agent_id}
      />

      <SDButton
        disabled={false}
        isLoading={false}
        size={SIZE.compact}
        block={false}
        overrides={{
          BaseButton: {
            style: () => ({
              marginRight: '.5rem',
            }),
          },
        }}
        type='submit'
      >
        {selectedOffice.uuid ? t('common:edit') : t('common:create')}
      </SDButton>
      <SDButton size={SIZE.compact} block={false} type='button' onClick={() => setIsOpen(false)}>
        {t('common:cancel')}
      </SDButton>
    </form>
  )
}

export default AddEditModal
