import { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import useBusinessType from '@/shared/hooks/useBusinessType'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import { selectMonConseillerPersoFormErrors, selectSelectedMonConseillerPerso } from '../MonConseillerPersoSelectors'
import { monConseillerPersoActions } from '../MonConseillerPersoSagas'
import { useSnackbar } from 'baseui/snackbar'
import { Check } from 'baseui/icon'

const useMonConseillerPersoForm = ({ close }) => {
  const dispatch = useDispatch()
  const { enqueue } = useSnackbar()
  const { loaderRoutesEnergy, loaderRoutes, loaderRoutesInsurance } = useSelector(selectAuthData)
  const { getApplicationUrl } = useBusinessType({
    loaderRoutesEnergy,
    loaderRoutes,
    loaderRoutesInsurance,
    modulesStatuses: {},
    modulesStatusesEnergy: {},
    modulesStatusesInsurance: {},
  })
  const initUrl = getApplicationUrl('mon-conseiller-perso-management')
  const { t } = useTranslation()
  const { tinymceKey } = useSelector(selectPageConfigurationData)
  const selectedMonConseillerPerso = useSelector(selectSelectedMonConseillerPerso)
  const errors = useSelector(selectMonConseillerPersoFormErrors)
  const [formValue, setFormValue] = useState(selectedMonConseillerPerso)
  const scriptOfferEditorRef = useRef(null)
  const scriptValidationEditorRef = useRef(null)
  const list = [
    {
      label: t('monConseillerPersoConfiguration:status_inactive'),
      id: 0,
    },
    {
      label: t('monConseillerPersoConfiguration:status_active'),
      id: 1,
    },
  ]
  const convertList = list => {
    const convertedList = {}
    list.map(element => {
      convertedList[element.id] = element.label
    })
    return convertedList
  }

  const updateSuccessNotification = message => {
    close()
    enqueue({
      message,
      startEnhancer: ({ size }) => <Check size={size} />,
    })
  }

  const submitHandler = event => {
    event.preventDefault()
    let scriptOffer = ''
    let scriptValidation = ''
    if (scriptOfferEditorRef.current) {
      scriptOffer = scriptOfferEditorRef.current.getContent()
    }
    if (scriptValidationEditorRef.current) {
      scriptValidation = scriptValidationEditorRef.current.getContent()
    }
    const monConseillerPersoData = {
      name: formValue.name,
      script_offer: scriptOffer,
      script_validation: scriptValidation,
      price: formValue.price,
      zoho_id: formValue.zoho_id,
      endpoint_name: formValue.endpoint_name,
      status: formValue.status || '',
    }

    if (selectedMonConseillerPerso.id) {
      monConseillerPersoData.id = selectedMonConseillerPerso.id
      dispatch({
        type: monConseillerPersoActions.UPDATE_MON_CONSEILLER_PERSO,
        monConseillerPersoData,
        id: selectedMonConseillerPerso.id,
        initUrl,
        updateSuccessNotification,
      })
    } else {
      dispatch({
        type: monConseillerPersoActions.CREATE_MON_CONSEILLER_PERSO,
        monConseillerPersoData,
        initUrl,
        updateSuccessNotification,
      })
    }
  }

  return {
    t,
    tinymceKey,
    close,
    selectedMonConseillerPerso,
    statusList: convertList(list),
    formValue,
    setFormValue,
    submitHandler,
    scriptOfferEditorRef,
    scriptValidationEditorRef,
    errors,
  }
}

export default useMonConseillerPersoForm
