import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  isInitialized: false,
  data: {
    routes: {},
  },
  error: '',
}

const updatePageConfigurationIsLoadingHandler = (state, action) => {
  state.isLoading = action.payload
}

const updatePageConfigurationIsInitializedHandler = (state, action) => {
  state.isInitialized = action.payload
}

const updatePageConfigurationDataHandler = (state, action) => {
  state.data = action.payload
}

const updatePageConfigurationErrorHandler = (state, action) => {
  state.error = action.payload
}

export const PageConfigurationSlice = createSlice({
  name: 'pageConfiguration',
  initialState,
  reducers: {
    updatePageConfigurationIsLoading: updatePageConfigurationIsLoadingHandler,
    updatePageConfigurationData: updatePageConfigurationDataHandler,
    updatePageConfigurationError: updatePageConfigurationErrorHandler,
    updatePageConfigurationInitialization: updatePageConfigurationIsInitializedHandler,
    clearPageConfiguration: () => initialState,
  },
})

export const {
  updatePageConfigurationIsLoading,
  updatePageConfigurationData,
  updatePageConfigurationError,
  updatePageConfigurationInitialization,
  clearPageConfiguration,
} = PageConfigurationSlice.actions

export default PageConfigurationSlice.reducer
