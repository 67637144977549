import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import { StyledBodyCell } from 'baseui/table-grid'

const AddressCell = ({ firstLine, secondLine, striped }) => {
  const [css] = useStyletron()
  const columnStyle = css({
    display: 'flex',
    flexDirection: 'column',
  })
  const rowStyle = css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '.25rem',
  })
  const cellStyle = css({
    padding: '.5rem',
    backgroundColor: 'none',
  })

  return (
    <StyledBodyCell className={cellStyle} $striped={striped}>
      <div className={columnStyle}>
        <div className={rowStyle}>{firstLine}</div>
        <div className={rowStyle}>{secondLine}</div>
      </div>
    </StyledBodyCell>
  )
}

AddressCell.propTypes = {
  firstLine: PropTypes.string,
  secondLine: PropTypes.string,
  striped: PropTypes.bool,
}

export default AddressCell
