export const SDButtonGroupOverrides = (direction, error) => {
    return {
      Root: {
        style: ({ $theme }) => {
          const outlineStyle = error ? `.125rem solid ${$theme.colors.negative500}` : 'none'

          return {
            flexDirection: direction ?? 'initial',
            padding: '.1875rem',
            backgroundColor: $theme.colors.white,

            border: `solid 1px ${$theme.colors.mono300}`,
            outline: outlineStyle,
            outlineOffset: '-.125rem',

            width: '100%',
            borderTopRightRadius: $theme.borders.radius200,
            borderBottomRightRadius: $theme.borders.radius200,
            borderBottomLeftRadius: $theme.borders.radius200,
            borderTopLeftRadius: $theme.borders.radius200,
          }
        },
      },
    }
  },
  SDButtonGroupButtonOverrides = () => {
    return {
      BaseButton: {
        style: ({ $theme, $disabled, $isSelected }) => {
          const bgColour = $isSelected ? $theme.colors.bg150 : $theme.colors.white,
            disabledBgColour = $isSelected ? $theme.colors.mono400 : $theme.colors.mono100,
            textColor = $disabled ? $theme.colors.mono600 : $isSelected ? $theme.colors.primary500 : 'inherit',
            outlineStyle = $disabled ? 'none' : `.125rem solid ${$theme.colors.primary500}`

          return {
            flex: '1 1 auto',

            paddingTop: '.5rem',
            paddingRight: '.5rem',
            paddingBottom: '.5rem',
            paddingLeft: '.5rem',

            color: textColor,

            backgroundColor: bgColour,

            borderTopRightRadius: '.375rem',
            borderBottomRightRadius: '.375rem',
            borderBottomLeftRadius: '.375rem',
            borderTopLeftRadius: '.375rem',

            ':hover': {
              outline: outlineStyle,
              outlineOffset: '-.125rem',
            },

            ':focus': {
              outline: outlineStyle,
              outlineOffset: '-.125rem',
            },

            ':disabled': {
              backgroundColor: disabledBgColour,
            },

            ':disabled :hover': {
              backgroundColor: disabledBgColour,
            },

            ':disabled :focus': {
              backgroundColor: disabledBgColour,
            },

            ':not(:last-child)': {
              marginRight: '.1875rem',
            },
          }
        },
      },
    }
  }
