import { createSelector } from 'reselect'

export const selectedCarbonOffsets = state => state.carbonOffsets

export const selectSelectedCarbonOffset = createSelector(
  selectedCarbonOffsets,
  usersManagement => usersManagement.selectedCarbonOffset,
)

export const selectCarbonOffsetFormErrors = createSelector(
  selectedCarbonOffsets,
  usersManagement => usersManagement.formErrors,
)
