import { Suspense } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import BackofficePage from '@/hoc/BackofficePage'
import routesConfig from './config'
import { SnackbarProvider } from 'baseui/snackbar'
import Home from '@/containers/HomePage'
import CarbonOffsets from '@/containers/CarbonOffsetManagement'
import Dashboard from '@/containers/DashboardPage'
import TelesalesForm from '@/containers/SubscriptionFormPage'
import ErrorPage from '@/containers/ErrorPage/ErrorPage'
import UsersManagement from '@/containers/UsersManagement'
import ServicesStatus from '@/containers/ServicesStatus'
import OfficesManagement from '@/containers/OfficesManagement'
import Assistances from '@/containers/AssistancesManagement/Assistances'
import MonConseillerPersoProducts from '@/containers/MonConseillerPersoProductsManagement/MonConseillerPersoProducts'
import OrganizationRouter from '@/containers/OrganizationsManagement'
import ProviderOptionsManagement from '@/containers/ProviderOptionsManagement'
import DevTools from '@/containers/DevTools'
import Objectives from '@/containers/Objectives'
import CallInformation from '@/containers/CallInformation'
import IpAddressWhitelistManagement from '@/containers/IpAddressWhitelistManagement'
import { snackBarOverrides } from '@/containers/SubscriptionFormPage/ModulesPanel/styled-components'
import useVonageDialer from '../containers/VonageDialer/useVonageDialer'
import ContactPage from '@/containers/ContactPage/ContactPage'

const suspense = component => <Suspense fallback='loading'>{component}</Suspense>

const CustomRoutes = () => {
  useVonageDialer()

  return (
    <BrowserRouter>
      <BackofficePage>
        <SnackbarProvider overrides={snackBarOverrides()}>
          <Routes>
            <Route path={routesConfig('home')} element={suspense(<Home />)} />
            <Route path={routesConfig('services-status')} element={suspense(<ServicesStatus />)} />
            <Route path={routesConfig('assistances-management')} element={suspense(<Assistances />)} />
            <Route
              path={routesConfig('mon-conseiller-perso-management')}
              element={suspense(<MonConseillerPersoProducts />)}
            />
            <Route path={routesConfig('carbon-offset')} element={suspense(<CarbonOffsets />)} />
            <Route
              path={routesConfig('subscriptions-list-telesales')}
              element={suspense(<Dashboard type='overview' />)}
            />
            <Route
              path={routesConfig('subscriptions-list-websales')}
              element={suspense(<Dashboard type='websales' />)}
            />
            <Route path={routesConfig('telesales-form')} element={suspense(<TelesalesForm />)} />
            <Route path={routesConfig('dev-tools')} element={suspense(<DevTools />)} />
            <Route path={routesConfig('call-information')} element={suspense(<CallInformation />)} />
            <Route path={routesConfig('organizations-management')} element={suspense(<OrganizationRouter />)} />
            <Route path={routesConfig('provider-options')} element={suspense(<ProviderOptionsManagement />)} />
            <Route path={routesConfig('users-management')} element={suspense(<UsersManagement />)} />
            <Route path={routesConfig('contact-page')} element={suspense(<ContactPage />)} />
            <Route
              path={routesConfig('ip-addresses-management')}
              element={suspense(<IpAddressWhitelistManagement />)}
            />
            <Route path={routesConfig('offices-management')} element={suspense(<OfficesManagement />)} />
            <Route path={routesConfig('objectives-management')} element={suspense(<Objectives />)} />
            <Route path='' element={<Navigate to={routesConfig('home')} />} />
            <Route path='login' element={<Navigate to={routesConfig('home')} />} />
            <Route path='*' element={<ErrorPage />} />
          </Routes>
        </SnackbarProvider>
      </BackofficePage>
    </BrowserRouter>
  )
}

export default CustomRoutes
