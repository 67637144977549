import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconBSComplete = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? ''
  size = size ?? 60
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 40 40' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <circle cx='20' cy='20' r='20' fill='#A8BACD' />
      <mask
        id='mask0_1507_41459'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='8'
        y='8'
        width='24'
        height='24'
      >
        <rect x='8' y='8' width='24' height='24' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_1507_41459)'>
        <path
          d='M13.3 28.5C12.8 28.5 12.375 28.325 12.025 27.975C11.675 27.625 11.5 27.2 11.5 26.7V13.3C11.5 12.8 11.675 12.375 12.025 12.025C12.375 11.675 12.8 11.5 13.3 11.5H17.75C17.8333 10.9333 18.0833 10.4583 18.5 10.075C18.9167 9.69167 19.4167 9.5 20 9.5C20.5833 9.5 21.0833 9.69167 21.5 10.075C21.9167 10.4583 22.1667 10.9333 22.25 11.5H26.7C27.2 11.5 27.625 11.675 27.975 12.025C28.325 12.375 28.5 12.8 28.5 13.3V26.7C28.5 27.2 28.325 27.625 27.975 27.975C27.625 28.325 27.2 28.5 26.7 28.5H13.3ZM20 12.35C20.2167 12.35 20.396 12.279 20.538 12.137C20.6793 11.9957 20.75 11.8167 20.75 11.6C20.75 11.3833 20.6793 11.204 20.538 11.062C20.396 10.9207 20.2167 10.85 20 10.85C19.7833 10.85 19.6043 10.9207 19.463 11.062C19.321 11.204 19.25 11.3833 19.25 11.6C19.25 11.8167 19.321 11.9957 19.463 12.137C19.6043 12.279 19.7833 12.35 20 12.35ZM13 26.05C13.9 25.1667 14.946 24.471 16.138 23.963C17.3293 23.4543 18.6167 23.2 20 23.2C21.3833 23.2 22.671 23.4543 23.863 23.963C25.0543 24.471 26.1 25.1667 27 26.05V13.3C27 13.2333 26.9667 13.1667 26.9 13.1C26.8333 13.0333 26.7667 13 26.7 13H13.3C13.2333 13 13.1667 13.0333 13.1 13.1C13.0333 13.1667 13 13.2333 13 13.3V26.05ZM20 21.05C20.9 21.05 21.6667 20.7333 22.3 20.1C22.9333 19.4667 23.25 18.7 23.25 17.8C23.25 16.8833 22.9333 16.1123 22.3 15.487C21.6667 14.8623 20.9 14.55 20 14.55C19.1 14.55 18.3333 14.8623 17.7 15.487C17.0667 16.1123 16.75 16.8833 16.75 17.8C16.75 18.7 17.0667 19.4667 17.7 20.1C18.3333 20.7333 19.1 21.05 20 21.05ZM14.45 27H25.55V26.8C24.7667 26.0833 23.9043 25.554 22.963 25.212C22.021 24.8707 21.0333 24.7 20 24.7C18.9833 24.7 18 24.8707 17.05 25.212C16.1 25.554 15.2333 26.075 14.45 26.775V27ZM20 19.55C19.5167 19.55 19.104 19.375 18.762 19.025C18.4207 18.675 18.25 18.2667 18.25 17.8C18.25 17.3167 18.4207 16.904 18.762 16.562C19.104 16.2207 19.5167 16.05 20 16.05C20.4833 16.05 20.896 16.2207 21.238 16.562C21.5793 16.904 21.75 17.3167 21.75 17.8C21.75 18.2667 21.5793 18.675 21.238 19.025C20.896 19.375 20.4833 19.55 20 19.55Z'
          fill='white'
        />
      </g>
    </Icon>
  )
}

IconBSComplete.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconBSComplete
