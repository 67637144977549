import { useStyletron } from 'baseui'
import { Notification } from 'baseui/notification'
import PropTypes from 'prop-types'

const SubscriptionStatusStepItem = ({ name, stepConfig }) => {
  const [css] = useStyletron()
  const stepItemStyle = css({
    padding: '0.1rem',
    margin: '0.1rem',
  })
  const wrapIconStyle = css({
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    margin: '0',
    padding: '0',
  })
  const stepStatusNameStyle = css({
    color: '#41474E',
    fontSize: '14px',
    fontWeight: 'bold',
    padding: '0.1rem',
    margin: '0rem',
  })
  const iconLineStyle = css({
    float: 'left',
    height: '40px',
    marginTop: '10px',
    verticalAlign: 'middle',
  })
  const iconStatusStyle = css({
    padding: '0.1rem',
    margin: '0.1rem',
    paddingTop: '0.6rem',
  })

  return (
    <>
      <div className={stepItemStyle}>
        <div className={wrapIconStyle}>
          <div className={iconStatusStyle}>{stepConfig.icon}</div>
          {stepConfig.iconLine && <div className={iconLineStyle}>{stepConfig.iconLine}</div>}
        </div>
        <div className={stepStatusNameStyle}>{name}</div>
        <Notification
          overrides={{
            Body: {
              style: {
                width: 'auto',
                borderRadius: '5px',
                textAlign: 'center',
                paddingTop: '5px',
                paddingBottom: '5px',
                backgroundColor: stepConfig.color,
                color: '#565E66',
                fontWeight: 'normal',
                fontSize: '10px',
              },
            },
          }}
        >
          {stepConfig.text}
        </Notification>
      </div>
    </>
  )
}

SubscriptionStatusStepItem.propTypes = {
  stepConfig: PropTypes.object,
  name: PropTypes.string,
}

export default SubscriptionStatusStepItem
