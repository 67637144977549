import { createSelector } from 'reselect'

export const selectedMonConseillerPerso = state => state.monConseillerPersoProducts

export const selectSelectedMonConseillerPerso = createSelector(
  selectedMonConseillerPerso,
  monConseillerPersoProducts => monConseillerPersoProducts.selectedMonConseillerPerso,
)

export const selectMonConseillerPersoFormErrors = createSelector(
  selectedMonConseillerPerso,
  monConseillerPersoProducts => monConseillerPersoProducts.formErrors,
)
