import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'

import useResizeObserver from './useResizeObserver'
import { draggerOverrides, StyledCardDragger, StyledHeader, StyledIcon, StyledTitle } from './styled-components'
import iconLogo from './unknown--filled.svg'
import IconCarbonMove from '@/shared/icons/IconCarbonMove'

const Panel = props => {
  const [css, theme] = useStyletron()
  const panelRef = useRef()
  const logo = props.logo ?? iconLogo
  const alt = props.alt ?? 'Alternative text...'
  const title = props.title ?? 'Random child'
  const height = useResizeObserver(panelRef)
  const tool = props.type && props.type === 'tool'
  const headerContent = props.headerContent ?? null

  useEffect(() => {
    const panelId = props.layouts.xxs?.find(entry => entry.i === props.id)
    const newHeight = height === 0 ? 0 : Math.ceil(height + 16)
    const updatedItem = {}
    const newLayouts = {}

    if (panelId && panelId.h === newHeight) {
      return
    }

    if (!panelId) {
      updatedItem.i = props.id
      updatedItem.x = 0
      updatedItem.y = 7
      updatedItem.w = 1
      updatedItem.h = 0
      updatedItem.minH = 0
      updatedItem.isResizable = false

      Object.keys(props.layouts).forEach(breakpoint => {
        newLayouts[breakpoint] = [...props.layouts[breakpoint]]
        newLayouts[breakpoint].push(updatedItem)
      })
    } else {
      Object.keys(props.layouts).forEach(breakpoint => {
        newLayouts[breakpoint] = props.layouts[breakpoint].map(item => {
          if (props.id === item.i) {
            return {
              ...item,
              h: newHeight,
            }
          }
          return item
        })
      })
    }

    props.setLayouts(newLayouts)
  })

  if (props.empty === true) {
    return <div ref={panelRef} />
  }

  return (
    // Todo: In this component, we need to replace the <div> with <StyledPanel>
    // from the styled-components file, but that makes the React Grid Layout
    // fail to calculate the total height. Need to find a fix (probably
    // updating the useResizeObserver with a callback ref, need to check)
    <div
      className={css({
        padding: '1rem',
        backgroundColor: tool ? theme.colors.bg050 : 'white',
        margin: '0 .5rem',
        borderRadius: '1rem',
        boxShadow: theme.lighting.shadow400,
        overflow: 'hidden',
      })}
      ref={panelRef}
      id={props.id}
    >
      <StyledCardDragger className='card__dragger'>&nbsp;</StyledCardDragger>

      <StyledHeader>
        <StyledIcon src={logo} alt={alt} />
        <StyledTitle>{title}</StyledTitle>
        {headerContent && <StyledTitle>{headerContent}</StyledTitle>}
        <IconCarbonMove overrides={draggerOverrides} title='Move me...' />
      </StyledHeader>

      {props.children}
    </div>
  )
}

Panel.propTypes = {
  alt: PropTypes.string,
  children: PropTypes.node,
  empty: PropTypes.bool,
  id: PropTypes.string,
  layouts: PropTypes.object,
  logo: PropTypes.node,
  setLayouts: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string,
  headerContent: PropTypes.func,
}

export default Panel
