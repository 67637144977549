import PropTypes from 'prop-types'
import { Table } from 'baseui/table-semantic'
import { useTranslation } from 'react-i18next'
import { SIZE } from 'baseui/input'

const SubscribersList = ({ items }) => {
  const { t } = useTranslation()
  const overrides = {
    TableBodyRow: {
      style: ({ $theme, $rowIndex }) => ({
        backgroundColor: $rowIndex % 2 ? $theme.colors.backgroundPrimary : $theme.colors.backgroundSecondary,
        ':hover': {
          backgroundColor: $theme.colors.backgroundTertiary,
        },
      }),
    },
  }
  const COLUMNS = [
    t('devTools:id'),
    t('devTools:email'),
    t('devTools:email_token'),
    t('devTools:firstname'),
    t('devTools:lastname'),
    t('devTools:subscriber_status'),
    t('devTools:export_state'),
    t('devTools:created_at'),
    t('devTools:edit'),
    '',
    '',
  ]

  return (
    <Table
      overrides={overrides}
      size={SIZE.compact}
      columns={COLUMNS}
      data={items}
      emptyMessage={<h1> {t('devTools:no_result')} </h1>}
    />
  )
}

SubscribersList.propTypes = {
  items: PropTypes.array,
}
export default SubscribersList
