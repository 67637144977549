import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useBusinessType from '@/shared/hooks/useBusinessType'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { pageConfigurationActions } from '@/store/PageConfiguration/PageConfigurationSagas'
import {
  selectPageConfigurationData,
  selectPageConfigurationIsLoading,
} from '@/store/PageConfiguration/PageConfigurationSelectors'
import { useTranslation } from 'react-i18next'
import useModal from '@/shared/hooks/useModal'
import { updateOfficesManagementSelectedOffice } from './OfficesManagementSlice'
import { selectOfficesManagementSelectedOffice } from './OfficesManagementSelectors'

const useOfficesManagement = () => {
  const dispatch = useDispatch()
  const { loaderRoutesEnergy, loaderRoutes, loaderRoutesInsurance } = useSelector(selectAuthData)
  const { getApplicationUrl } = useBusinessType({
    loaderRoutesEnergy,
    loaderRoutesInsurance,
    loaderRoutes,
    modulesStatuses: {},
    modulesStatusesEnergy: {},
    modulesStatusesInsurance: {},
  })

  const initUrl = getApplicationUrl('offices')
  const { offices } = useSelector(selectPageConfigurationData)
  const isLoading = useSelector(selectPageConfigurationIsLoading)
  const selectedOffice = useSelector(selectOfficesManagementSelectedOffice)

  const { t } = useTranslation()
  const { isOpen, close, setIsOpen } = useModal()

  const updateOffice = office => {
    const officeSelected = {
      uuid: office?.uuid ?? '',
      name: office?.uuid ? office.name : '',
      mcp_agent_id: office?.mcp_agent?.id
        ? [
            {
              id: office.mcp_agent?.id,
              firstName: office.mcp_agent?.firstname ?? '',
              name: office.mcp_agent?.name ?? '',
              email: office.mcp_agent?.email ?? '',
            },
          ]
        : [],
    }
    dispatch(updateOfficesManagementSelectedOffice(officeSelected))

    setIsOpen(true)
  }

  useEffect(() => {
    dispatch({ type: pageConfigurationActions.INITIALIZE, initUrl })
  }, [])

  return {
    offices,
    pageConfigurationIsLoading: isLoading,
    t,
    isOpen,
    close,
    setIsOpen,
    updateOffice,
    selectedOffice,
  }
}

export default useOfficesManagement
