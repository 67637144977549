import { useState } from 'react'
import { TYPE } from 'baseui/select'
import PropTypes from 'prop-types'
import SDInput from '@/shared/components/SDComponents/SDInput/SDInput'
import { SIZE } from 'baseui/input'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { useTranslation } from 'react-i18next'
import IconCarbonSearch from '@/shared/icons/IconCarbonSearch'
import { KIND } from 'baseui/button'

const DevToolsSearch = ({ requestSearchHandler, placeholder }) => {
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')
  const handleSearchChange = term => {
    if (term !== '') {
      setSearchTerm(term)
    }
  }

  return (
    <>
      <SDInput
        id='subscribersSearch'
        type={TYPE.search}
        placeholder={placeholder}
        onChange={event => handleSearchChange(event.target.value)}
      />
      <SDButton
        kind={KIND.secondary}
        size={SIZE.compact}
        block={false}
        startEnhancer={() => <IconCarbonSearch size={16} />}
        overrides={{
          BaseButton: {
            style: () => ({
              marginLeft: '.5rem',
              paddingRight: '.5rem',
              width: '13rem',
            }),
          },
        }}
        onClick={event => {
          event.preventDefault()
          requestSearchHandler(searchTerm)
        }}
      >
        {t('devTools:search_button')}
      </SDButton>
    </>
  )
}

DevToolsSearch.propTypes = {
  requestSearchHandler: PropTypes.func,
  placeholder: PropTypes.string,
}

export default DevToolsSearch
