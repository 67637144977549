const dashboardData = {
  url: '',
  page: 1,
  token: '',
  scrollPosition: 0,
  tableInitPath: null,
  detailsPath: null,
  tableLoading: false,
  prefiltersLoaded: false,
  filtersLoading: false,
  endpoints: null,
  usersList: {},
  providersList: {},
  filters: {
    status: '',
    energy: '',
    creationFrom: '',
    creationTo: '',
    prestation: '',
    contractType: '',
    subscriptionId: '',
    zohoId: '',
    meterIdElectricity: '',
    meterIdGas: '',
    lastname: '',
    firstname: '',
    email: '',
    phone: '',
    userId: '',
    providerId: '',
    details: [],
    origin: '',
    custom: '',
    priority: [],
  },
  data: {},
  currentSubscription: null,
}

export default dashboardData
