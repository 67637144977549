import { FormControl } from 'baseui/form-control'
import SDInput from '@/shared/components/SDComponents/SDInput/SDInput'
import ComparatorOffer from '../Fields/ComparatorOffer'

const Identity = ({ t, errors, providerState, updateOffer }) => {
  return (
    <>
      <FormControl label={t('organizationManagement:offer.name')} error={errors('name')}>
        <SDInput
          error={errors('name')}
          value={providerState.selectedOffer.name ?? ''}
          onChange={event => {
            event.preventDefault()
            updateOffer('name', event.target.value)
          }}
        />
      </FormControl>
      <FormControl label={t('organizationManagement:offer.short_name')} error={errors('short_name')}>
        <SDInput
          error={errors('short_name')}
          value={providerState.selectedOffer.short_name ?? ''}
          onChange={event => {
            event.preventDefault()
            updateOffer('short_name', event.target.value)
          }}
        />
      </FormControl>
      <FormControl label={t('organizationManagement:offer.provider_code')} error={errors('provider_code')}>
        <SDInput
          error={errors('provider_code')}
          value={providerState.selectedOffer.provider_code ?? ''}
          onChange={event => {
            event.preventDefault()
            updateOffer('provider_code', event.target.value)
          }}
        />
      </FormControl>
      <FormControl label={t('organizationManagement:offer.gas_provider_code')} error={errors('gas_provider_code')}>
        <SDInput
          error={errors('gas_provider_code')}
          value={providerState.selectedOffer.gas_provider_code ?? ''}
          onChange={event => {
            event.preventDefault()
            updateOffer('gas_provider_code', event.target.value)
          }}
        />
      </FormControl>
      <FormControl label={t('organizationManagement:offer.zoho_id')} error={errors('zoho_id')}>
        <SDInput
          error={errors('zoho_id')}
          value={providerState.selectedOffer.zoho_id ?? ''}
          onChange={event => {
            event.preventDefault()
            updateOffer('zoho_id', event.target.value)
          }}
        />
      </FormControl>
      <ComparatorOffer
        currentValue={providerState.selectedOffer.comparator_id}
        updateOffer={updateOffer}
        providerState={providerState}
        t={t}
      />
    </>
  )
}

export default Identity
