const providerData = {
  initialized: false,
  save: {
    loading: false,
    saved: false,
    offerSaved: false,
    offerDeleted: false,
    offerDuplicated: false,
  },
  errors: {},
  general: {
    providersList: [],
    title: '',
  },
  tinymceKey: '',
  routes: {
    providerInformation: '',
    store: '',
    update: '',
  },
  selectedProvider: {
    uuid: '',
    apiIntegration: 0,
  },
  selectedOffer: {
    id: 0,
  },
  viewParameters: {
    parametersLoaded: false,
  },
}

export default providerData
