import FieldsSwitch from '../Fields/FieldsSwitch'

const ElectricityGasRelated = ({ t, errors, providerState, updateOffer }) => {
  const fields = providerState.viewParameters.attributes?.options ?? {}

  return (
    <FieldsSwitch
      group='electricity_gas'
      fields={fields}
      t={t}
      updateOffer={updateOffer}
      providerState={providerState}
    />
  )
}

export default ElectricityGasRelated
