import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useBusinessType from '@/shared/hooks/useBusinessType'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { useTranslation } from 'react-i18next'
import useModal from '@/shared/hooks/useModal'
import { pageConfigurationActions } from '@/store/PageConfiguration/PageConfigurationSagas'
import {
  selectPageConfigurationData,
  selectPageConfigurationIsLoading,
} from '@/store/PageConfiguration/PageConfigurationSelectors'
import { updateSelectedMonConseillerPerso } from './MonConseillerPersoSlice'
import { selectSelectedMonConseillerPerso } from './MonConseillerPersoSelectors'

const useMonConseillerPerso = () => {
  const dispatch = useDispatch()
  const { loaderRoutesEnergy, loaderRoutes, loaderRoutesInsurance } = useSelector(selectAuthData)
  const { getApplicationUrl, type } = useBusinessType({
    loaderRoutesEnergy,
    loaderRoutes,
    loaderRoutesInsurance,
    modulesStatuses: {},
    modulesStatusesEnergy: {},
    modulesStatusesInsurance: {},
  })

  const initUrl = getApplicationUrl('mon-conseiller-perso-management')
  const { t } = useTranslation()
  const { monConseillerPersoProducts, tinymceKey } = useSelector(selectPageConfigurationData)
  const isLoading = useSelector(selectPageConfigurationIsLoading)
  const selectedMonConseillerPerso = useSelector(selectSelectedMonConseillerPerso)
  const { isOpen, close, setIsOpen } = useModal()
  const [page, setPage] = useState(1)
  const [monConseillerPersoSearch, setMonConseillerPersoSearch] = useState(monConseillerPersoProducts ?? [])

  const setMonConseillerPersoHandler = monConseillerPersoSearchList => {
    if (monConseillerPersoSearchList !== null) {
      setMonConseillerPersoSearch(monConseillerPersoSearchList)
    } else {
      setMonConseillerPersoSearch(monConseillerPersoProducts)
    }
  }

  const updateMonConseillerPersoSelected = monConseillerPerso => {
    const monConseillerPersoSelected = {
      id: monConseillerPerso?.id ?? 0,
      name: monConseillerPerso?.id ? monConseillerPerso.name : '',
      price: monConseillerPerso?.id ? monConseillerPerso.price : '',
      script_offer: monConseillerPerso?.id ? monConseillerPerso.script_offer : '',
      script_validation: monConseillerPerso?.id ? monConseillerPerso.script_validation : '',
      status: monConseillerPerso?.id ? monConseillerPerso.status : '',
      zoho_id: monConseillerPerso?.id ? monConseillerPerso.zoho_id : '',
      endpoint_name: monConseillerPerso?.id ? monConseillerPerso.endpoint_name : '',
    }

    dispatch(updateSelectedMonConseillerPerso(monConseillerPersoSelected))
    setIsOpen(true)
  }

  useEffect(() => {
    dispatch({ type: pageConfigurationActions.INITIALIZE, initUrl })
  }, [])

  useEffect(() => {
    if (!monConseillerPersoProducts) return
    setMonConseillerPersoSearch(monConseillerPersoProducts)
  }, [monConseillerPersoProducts])

  return {
    t,
    monConseillerPersoProducts: monConseillerPersoSearch,
    isLoading,
    tinymceKey,
    elementsPerPage: 7,
    isOpen,
    close,
    page,
    setPage,
    setMonConseillerPersoHandler,
    updateMonConseillerPersoSelected,
    selectedMonConseillerPerso,
    type,
  }
}

export default useMonConseillerPerso
