import PropTypes from 'prop-types'
import { StyledBodyCell } from 'baseui/table-grid'
import useCallLogModal from './useCallLogModal'

const CallRows = ({ calls }) => {
  const { t, commentStyle, displayContentStyle, dateStyle } = useCallLogModal({ handler: null, id: null })

  if (calls.length === 0) {
    return (
      <div role='row' className={displayContentStyle}>
        <StyledBodyCell>
          <span className={commentStyle}>{t('subscriptionTable:empty_call_log')}</span>
        </StyledBodyCell>
      </div>
    )
  }

  const reversedCalls = [...calls].reverse()

  return reversedCalls.map((row, index) => {
    const striped = index % 2 === 0
    const date = new Date(row).toLocaleString('fr-FR')
    return (
      <div role='row' className={displayContentStyle} key={index}>
        <StyledBodyCell $striped={striped}>
          <span className={dateStyle}>{date}</span>
        </StyledBodyCell>
      </div>
    )
  })
}

CallRows.propTypes = {
  calls: PropTypes.array,
}

export default CallRows
