import { StyledLoadingSpinner } from 'baseui/button'
import { StyledTable, SortableHeadCell } from 'baseui/table-grid'
import { withStyle } from 'baseui'
import CommentsCell from './bones/CommentsCell'
import ContactCell from './bones/ContactCell'
import DatesCell from './bones/DatesCell'
import OfferCell from './bones/OfferCell'
import StatusCell from './bones/StatusCell'
import OriginCell from './bones/OriginCell'
import useDashboardTable from './useDashboardTable'
import useDashboardTableStyles from './useDashboardTableStyles'

const DashboardTable = () => {
  const { t, data, tableLoading, handleSorting, sort, retrieveSubscriptionDetails, currentSubscriptionId } =
    useDashboardTable()

  const { headCellOverrides, loadingStyle, tableHeight, tableRow, headingDisplay } = useDashboardTableStyles()

  const Table = withStyle(StyledTable, {
    alignContent: 'start',
  })

  if (tableLoading) {
    return (
      <div className={loadingStyle}>
        <StyledLoadingSpinner />
      </div>
    )
  }

  return (
    <div className={tableHeight}>
      <Table role='grid' $gridTemplateColumns='15% 5% 20% 20% 20% 20%'>
        <div role='row' className={headingDisplay}>
          <SortableHeadCell
            title={t('subscriptionTable:status')}
            direction={sort.sortingKey === 'status' ? sort.direction : ''}
            onSort={() => handleSorting('status')}
            overrides={headCellOverrides}
          />

          <SortableHeadCell
            title={t('subscriptionTable:origin')}
            direction={sort.sortingKey === 'origin' ? sort.direction : ''}
            onSort={() => handleSorting('origin')}
            overrides={headCellOverrides}
          />

          <SortableHeadCell
            title={
              sort.sortingKey?.includes('dates')
                ? t('subscriptionTable:' + sort.sortingKey)
                : t('subscriptionTable:dates')
            }
            direction={sort.sortingKey?.includes('dates') ? sort.direction : ''}
            onSort={() => handleSorting('dates')}
            overrides={headCellOverrides}
          />
          <SortableHeadCell
            title={
              sort.sortingKey?.includes('contact')
                ? t('subscriptionTable:' + sort.sortingKey)
                : t('subscriptionTable:contact')
            }
            direction={sort.sortingKey?.includes('contact') ? sort.direction : ''}
            onSort={() => handleSorting('contact')}
            overrides={headCellOverrides}
          />
          <SortableHeadCell
            title={
              sort.sortingKey?.includes('offer')
                ? t('subscriptionTable:' + sort.sortingKey)
                : t('subscriptionTable:offer')
            }
            direction={sort.sortingKey?.includes('offer') ? sort.direction : ''}
            onSort={() => handleSorting('offer')}
            overrides={headCellOverrides}
          />
          <SortableHeadCell title={t('subscriptionTable:comments')} overrides={headCellOverrides} />
        </div>
        {data.map((row, index) => {
          const striped = index % 2 === 0
          const selected = row.id === currentSubscriptionId

          return (
            <div role='row' className={tableRow} key={row.id} onClick={() => retrieveSubscriptionDetails(row.id)}>
              <StatusCell
                noStatusText={t('subscriptionTable:no_status')}
                status={row.translatedStatus}
                errors={row.statusErrors}
                striped={striped}
                selected={selected}
              />

              <OriginCell origin={row.subscriberData.details.salesChannel} striped={striped} selected={selected} />

              <DatesCell
                dates={[row.dateCreatedAt, row.dateSignature, row.dateActivation]}
                striped={striped}
                selected={selected}
              />

              <ContactCell
                name={row.firstname}
                surname={row.lastname}
                phone={row.phone}
                numberOfCalls={row.numberOfCalls}
                zohoLink={row.zohoLink}
                striped={striped}
                selected={selected}
                noContactDataText={t('subscriptionTable:no_contact_data')}
              />

              <OfferCell
                provider={row.providerName}
                offer={row.offer}
                prestation={row.situation}
                retrievedFrom='hop'
                striped={striped}
                selected={selected}
              />

              <CommentsCell
                noCommentText={t('subscriptionTable:no_comments')}
                comment={row.lastComment?.comment ?? row.lastComment}
                color={row.lastComment?.colorStyle ?? ''}
                striped={striped}
                selected={selected}
              />
            </div>
          )
        })}
      </Table>
    </div>
  )
}

export default DashboardTable
