import { useEffect, useState } from 'react'
import { Card, Title } from './styled-components'
import { useTranslation } from 'react-i18next'
import FormComponent from './Components/Form'
import Table from './Components/Table'
import useHttp from '@/shared/hooks/useHttp'
import { useSelector } from 'react-redux'
import { selectPageConfigurationData } from '../../../store/PageConfiguration/PageConfigurationSelectors'
import { selectCommonFormConfigurationData } from '../SubscriptionFormConfigurationSelectors'
import { selectCommonSubscriptionData } from '../SubscriptionFormSelectors'
import { selectAuthData } from '@/containers/Login/AuthSelectors'

const CommentsContainer = () => {
  const { t } = useTranslation()
  const {
    comments_list_url: commentsListUrl,
    update_comment_url: updateCommentUrl,
    create_comment_url: createCommentUrl,
  } = useSelector(selectPageConfigurationData)
  const { lang } = useSelector(selectAuthData)
  const { contractLoaded } = useSelector(selectCommonSubscriptionData)
  const { id, formLoaded } = useSelector(selectCommonFormConfigurationData)
  const { sendRequest, getConfig } = useHttp()
  const [comments, setComments] = useState([])
  const [newComment, setNewComment] = useState('')

  const fetchComments = () => {
    sendRequest(commentsListUrl + '?lang=' + lang, getConfig('GET'), (response, statusCode) => {
      if (statusCode === 200 && response && Object.keys(response).length > 0) {
        setComments(response)
      }
    })
  }
  const createMethod = comment => {
    const config = getConfig('POST')
    const options = new URLSearchParams()
    options.append('comment', comment)
    options.append('subscriber_id', id)
    options.append('lang', lang)
    config.body = options

    sendRequest(createCommentUrl, config, (response, statusCode) => {
      if (statusCode === 200 && response && Object.keys(response).length > 0) {
        fetchComments()
        setNewComment('')
        return
      }
      alert('Comment not created')
    })
  }
  const updateMethod = (commentId, comment) => {
    const config = getConfig('PUT')
    const options = new URLSearchParams()
    options.append('comment', comment)
    options.append('lang', lang)
    config.body = options

    sendRequest(updateCommentUrl.replace('*', commentId), config, (response, statusCode) => {
      if (statusCode === 200) {
        fetchComments()
        setNewComment('')
        return
      }
      alert('Comment not updated')
    })
  }

  const deleteMethod = commentId => {
    sendRequest(
      updateCommentUrl.replace('*', commentId) + '?lang=' + lang,
      getConfig('DELETE'),
      (response, statusCode) => {
        if (statusCode === 200) {
          setComments([])
          fetchComments()
          return
        }
        alert('Comment not deleted')
      },
    )
  }

  useEffect(() => {
    if (commentsListUrl) {
      fetchComments()
    }
  }, [commentsListUrl])

  if (formLoaded === false || contractLoaded === false) {
    return ''
  }

  return (
    <Card>
      <Title>{t('subscriptionForm:comments_module.comments')}</Title>
      <Table data={comments} updateMethod={updateMethod} deleteMethod={deleteMethod} />
      <FormComponent newComment={newComment} setNewComment={setNewComment} createMethod={createMethod} />
    </Card>
  )
}

export default CommentsContainer
