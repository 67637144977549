import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import DevToolsSearch from '../DevToolsSearch'
import { FormControl } from 'baseui/form-control'
import useVonageDialer from '../../VonageDialer/useVonageDialer'

const VonageDevTool = ({ urls }) => {
  const { handleCallStart } = useVonageDialer()
  const flexGridItemProps = {
    display: 'flex',
    alignItems: 'center',
  }

  const fakeCallStart = zohoId => {
    const fakeEvent = {
      type: 'CALL_START',
      data: {
        phoneNumber: '1234567890',
        direction: 'inbound',
        contact: {
          extraAttributes: {
            ContactID: zohoId,
            FriendlyName: 'Test Lead Source',
            ContactEmail: 'test@example.com',
            ContactFirst: 'Test',
            ContactLast: 'User',
            ContactNLBP: 'true',
          },
        },
      },
    }
    handleCallStart(fakeEvent)
  }

  return (
    <>
      <FormControl label='Please provide a Zoho contact ID (that already exists in your subscribers table), it will FAKE a call, and subsequently open the Vonage page.'>
        <FlexGrid flexGridColumnCount={2} marginBottom='.5rem' flexGridColumnGap='scale800'>
          <FlexGridItem {...flexGridItemProps}>
            <DevToolsSearch
              requestSearchHandler={value => fakeCallStart(value)}
              placeholder={"Entrez un identifiant Zoho pour créer l'appel fictif"}
            />
          </FlexGridItem>
        </FlexGrid>
      </FormControl>
    </>
  )
}

export default VonageDevTool
