import { call, put, select, takeLatest } from 'redux-saga/effects'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import getAPIConfig from '@/shared/tools/getAPIConfig'
import { initializeSaga } from '@/store/PageConfiguration/PageConfigurationSagas'
import { updateCarbonOffsetFormErrors } from './CarbonOffsetsSlice'

export const carbonOffsetsActions = {
  UPDATE_CARBON_OFFSET: 'UPDATE_CARBON_OFFSET',
  CREATE_CARBON_OFFSET: 'CREATE_CARBON_OFFSET',
}

export function* updateCarbonOffset({ carbonOffsetData, id, initUrl, updateSuccessNotification }) {
  try {
    const { authTokenSession, lang } = yield select(selectAuthData)
    const { carbon_offsets_url_update } = yield select(selectPageConfigurationData)
    const updateRoute = carbon_offsets_url_update + '?lang=' + lang + '&id=' + id

    const config = yield call(getAPIConfig, { method: 'put', authTokenSession, body: carbonOffsetData })
    const response = yield call(fetch, updateRoute, config)
    const { errors } = yield call([response, response.json])

    if (response.status === 200) {
      yield call(updateSuccessNotification, 'Carbon offset bien mise à jour')
      yield call(initializeSaga, { initUrl: initUrl + '?lang=' + lang })
    } else {
      yield put(updateCarbonOffsetFormErrors(errors))
    }
  } catch (error) {
    console.error(error)
  }
}

export function* createCarbonOffset({ carbonOffsetData, initUrl, updateSuccessNotification }) {
  try {
    const { authTokenSession, lang } = yield select(selectAuthData)
    const { carbon_offsets_url_store } = yield select(selectPageConfigurationData)

    const config = yield call(getAPIConfig, { method: 'post', authTokenSession, body: carbonOffsetData })
    const response = yield call(fetch, carbon_offsets_url_store + '?lang=' + lang, config)
    const { errors } = yield call([response, response.json])

    if (response.status === 200) {
      yield call(updateSuccessNotification, 'Carbon offset crée')
      yield call(initializeSaga, { initUrl: initUrl + '?lang=' + lang })
    } else {
      yield put(updateCarbonOffsetFormErrors(errors))
    }
  } catch (error) {
    console.error(error)
  }
}

export default function* carbonOffsetsSagas() {
  yield takeLatest(carbonOffsetsActions.UPDATE_CARBON_OFFSET, updateCarbonOffset)
  yield takeLatest(carbonOffsetsActions.CREATE_CARBON_OFFSET, createCarbonOffset)
}
