import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconCarbonNextories = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'Nextories'
  size = size ?? 16
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 32 32' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <g>
        <path
          d='M29,17.4c-0.3,0-0.5-0.1-0.7-0.3L17.7,6.5L7.1,17.1c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4L17,4.4
			c0.4-0.4,1-0.4,1.4,0l11.3,11.3c0.4,0.4,0.4,1,0,1.4C29.5,17.3,29.3,17.4,29,17.4z'
        />
      </g>
      <path d='M10.5,15.6v7.6c0,0,1.5,1,3.9,1s8.6-1.7,9.6-9.1c-4.8-4.8-6.4-6.4-6.4-6.4L10.5,15.6z' />
      <path
        d='M25.5,16.1c0,0-1.2,9.9-11.1,9.9S-0.3,15.6,4.3,7.9C2.1,10.9,2,15,2,15S1.5,27.3,14.9,27.9c10.4,0,12.1-10,12.1-10
		S26.6,17,25.5,16.1z'
      />
    </Icon>
  )
}

IconCarbonNextories.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconCarbonNextories
