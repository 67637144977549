import PropTypes from 'prop-types'
import SDInput from '@/shared/components/SDComponents/SDInput/SDInput'
import { SIZE } from 'baseui/input'

export const HOPInput = ({
  id,
  handleValue,
  currentValue,
  placeholder = '',
  disabled = false,
  autocomplete = 'on',
  endEnhancer = '',
  name = '',
  error = false,
  onBlur = null,
}) => {
  return (
    <SDInput
      id={id}
      size={SIZE.compact}
      placeholder={placeholder}
      value={currentValue}
      onChange={e => handleValue(e.target.value)}
      onBlur={e => {
        if (typeof onBlur === 'function') {
          onBlur(e.target.value)
        }
      }}
      disabled={disabled}
      autoComplete={autocomplete}
      endEnhancer={endEnhancer}
      name={name}
      error={error}
    />
  )
}

HOPInput.propTypes = {
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBlur: PropTypes.func,
  handleValue: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  autocomplete: PropTypes.string,
  endEnhancer: PropTypes.string,
  error: PropTypes.bool,
  name: PropTypes.string,
}
