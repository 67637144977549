import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconCarbonTwoPersonLift = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'Tag'
  size = size ?? 16
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 32 32' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <path d='M26.96,30l-1.9215-6.7253a1.0008,1.0008,0,0,1,.3369-1.0554L29.874,18.62,28.52,13.2014l-2.7382,3.4234A1.0026,1.0026,0,0,1,25,17H20V15h4.52l3.6993-4.6248a1,1,0,0,1,1.7509.3824l2,8a.9989.9989,0,0,1-.3447,1.0232l-4.48,3.5845,1.7389,6.0854Z' />
      <path d='M23,5.5A3.5,3.5,0,1,1,26.5,9,3.5042,3.5042,0,0,1,23,5.5Zm2,0A1.5,1.5,0,1,0,26.5,4,1.5017,1.5017,0,0,0,25,5.5Z' />
      <path d='M20.0039,19A2.0039,2.0039,0,0,1,18,16.9961V15.0039A2.0039,2.0039,0,0,1,20.0039,13H22V10H10v3h1.9961A2.0039,2.0039,0,0,1,14,15.0039v1.9922A2.0039,2.0039,0,0,1,11.9961,19H10v3H22V19Z' />
      <path d='M5.04,30l1.9215-6.7253a1.0013,1.0013,0,0,0-.3369-1.0555L2.126,18.62l1.3545-5.4185,2.7382,3.4234A1.0026,1.0026,0,0,0,7,17h5V15H7.4805L3.7812,10.3752a1,1,0,0,0-1.7509.3824l-2,8A.9989.9989,0,0,0,.375,19.7808l4.4805,3.5844-1.739,6.0855Z' />
      <path d='M5.5,9A3.5,3.5,0,1,1,9,5.5,3.5042,3.5042,0,0,1,5.5,9Zm0-5A1.5,1.5,0,1,0,7,5.5,1.5017,1.5017,0,0,0,5.5,4Z' />
    </Icon>
  )
}

IconCarbonTwoPersonLift.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconCarbonTwoPersonLift
