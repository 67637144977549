import { useRef } from 'react'
import { Card } from './styled-components'
import RetractSale from './RetractSale/RetractSale'
import InfoFields from './InfoFields/InfoFields'
import ChangeUser from './ChangeUser/ChangeUser'
import ExportLogsComponent from '@/containers/SubscriptionFormPage/SubscriptionActionsPanel/ExportLogs/ExportLogsComponent'
import HistoricAuditLogsComponent from '@/containers/SubscriptionFormPage/SubscriptionActionsPanel/HistoricAuditLogs/HistoricAuditLogsComponent'
import useFormStatus from '../useFormStatus'
import BusinessVerticalAccess from './BusinessVerticalAccess/BusinessVerticalAccess'

const SubscriptionActionsContainer = () => {
  const containerRef = useRef()
  const { formIsLoaded } = useFormStatus()

  if (!formIsLoaded) return ''

  return (
    <>
      <BusinessVerticalAccess />
      <Card ref={containerRef}>
        <ExportLogsComponent />
        <HistoricAuditLogsComponent />
        <RetractSale />
        <ChangeUser />
        <InfoFields />
      </Card>
    </>
  )
}

export default SubscriptionActionsContainer
