import { TYPE } from 'baseui/select'
import PropTypes from 'prop-types'
import SDInput from '@/shared/components/SDComponents/SDInput/SDInput'
import { useTranslation } from 'react-i18next'

const SearchInTable = ({ setElements, elements, placeholder = null }) => {
  const { t } = useTranslation()
  const handleSearchChange = e => {
    if (e.target.value !== '') {
      const filteredElements = elements.filter(element => {
        return (
          (element.name && element.name.toLowerCase().includes(e.target.value.toLowerCase())) ||
          (element.zoho_id && element.zoho_id.toString().toLowerCase().includes(e.target.value.toLowerCase()))
        )
      })
      setElements(filteredElements)
    } else {
      setElements(null)
    }
  }

  return (
    <SDInput
      id='elementSearch'
      type={TYPE.search}
      placeholder={placeholder ?? t('common:search_product')}
      onChange={event => handleSearchChange(event)}
      onInputChange={event => handleSearchChange(event)}
    />
  )
}

SearchInTable.propTypes = {
  elements: PropTypes.array,
  setElements: PropTypes.func,
  placeholder: PropTypes.string,
}

export default SearchInTable
