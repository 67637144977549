import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  formErrors: {},
  selectedCarbonOffset: {
    id: 0,
    name: '',
  },
}

const updateCarbonOffsetFormErrorsHandler = (state, action) => {
  state.formErrors = action.payload
}

const updateSelectedCarbonOffsetHandler = (state, action) => {
  state.selectedCarbonOffset = action.payload
}

export const CarbonOffsetsSlice = createSlice({
  name: 'carbonOffsets',
  initialState,
  reducers: {
    updateCarbonOffsetFormErrors: updateCarbonOffsetFormErrorsHandler,
    updateSelectedCarbonOffset: updateSelectedCarbonOffsetHandler,
    clearCarbonOffsets: () => initialState,
  },
})

export const { updateCarbonOffsetFormErrors, updateSelectedCarbonOffset, clearCarbonOffsets } =
  CarbonOffsetsSlice.actions

export default CarbonOffsetsSlice.reducer
