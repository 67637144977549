import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import { StyledBodyCell } from 'baseui/table-grid'
import { useTranslation } from 'react-i18next'
import retrieveAdditionalSalesIcons from '@/shared/icons/retrieveAdditionalSalesIcons'

const AdditionalTypeCell = ({ additionalSaleType, striped }) => {
  const { t } = useTranslation()
  const [css] = useStyletron()
  const columnStyle = css({
    display: 'flex',
    flexDirection: 'column',
  })
  const rowStyle = css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '.25rem',
  })
  const cellStyle = css({
    padding: '.5rem',
    backgroundColor: 'none',
  })
  const iconStyle = css({
    padding: '.5rem',
    backgroundColor: 'none',
  })
  const textStyle = css({
    padding: '.5rem',
    backgroundColor: 'none',
  })

  return (
    <StyledBodyCell className={cellStyle} $striped={striped}>
      <div className={columnStyle}>
        <div className={rowStyle}>
          <span className={iconStyle}>{retrieveAdditionalSalesIcons(additionalSaleType)}</span>
          <span className={textStyle}>{t('additionalSales:sales_type_name.' + additionalSaleType)}</span>
        </div>
      </div>
    </StyledBodyCell>
  )
}

AdditionalTypeCell.propTypes = {
  additionalSaleType: PropTypes.string,
  striped: PropTypes.bool,
}

export default AdditionalTypeCell
