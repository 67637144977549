export default function modulesIsActive(key, modulesStatus) {
  switch (key) {
    case 'assistances':
      return modulesStatus['assistances-options'] ?? false
    case 'monConseillerPersoProducts':
      return modulesStatus['mon-conseiller-perso-options'] ?? false
    case 'carbonOffset':
      return modulesStatus['carbon-offset'] ?? false
    case 'objectives':
      return modulesStatus?.goals ?? false
    default:
      return true
  }
}
