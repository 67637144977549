import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import useHttp from '@/shared/hooks/useHttp'
import useBusinessType from '@/shared/hooks/useBusinessType'
import { selectAuthData } from '@/containers/Login/AuthSelectors'

const useHome = () => {
  const [data, setData] = useState(null)
  const {
    modulesStatuses,
    modulesStatusesEnergy,
    loaderRoutesEnergy,
    loaderRoutesInsurance,
    modulesStatusesInsurance,
    loaderRoutes,
    lang,
    vonageDialerStatus,
    isExternal,
  } = useSelector(selectAuthData)

  const { type, getApplicationUrl, getModulesStatuses } = useBusinessType({
    loaderRoutesEnergy,
    loaderRoutes,
    modulesStatuses,
    modulesStatusesEnergy,
    loaderRoutesInsurance,
    modulesStatusesInsurance,
  })
  const { getConfig, sendRequest } = useHttp()
  const initUrl = getApplicationUrl('home') + '?lang=' + lang

  const initialize = () => {
    sendRequest(initUrl, getConfig('GET'), (json, code) => {
      setData(json)
    })
  }

  useEffect(() => initialize(), [])

  return {
    data,
    setData,
    moduleStatuses: getModulesStatuses(type),
    vonageDialerStatus,
    isExternal,
  }
}

export default useHome
