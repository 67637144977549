import { useRef, useState } from 'react'

import useHttp from '@/shared/hooks/useHttp'

const useWelcomeMessage = (data, setData) => {
  const [isEditingWelcomeText, setIsEditingWelcomeText] = useState(false)

  const { getConfig, sendRequest, isLoading } = useHttp()
  const editorRef = useRef(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [error, setError] = useState(null)
  const saveWelcomeMessage = () => {
    setErrorMessage(null)
    setError(null)
    if (!editorRef.current) {
      return
    }
    const options = new URLSearchParams()
    const config = getConfig('POST')
    const url = data.editWelcomeMessageUrl ?? ''
    options.append('text', editorRef.current.getContent())
    config.body = options
    sendRequest(url, config, result => {
      if (!result.success) {
        setError(true)
        setErrorMessage(result.message)
        return
      }
      setError(false)
      const newData = {
        ...data,
        welcomeMessage: editorRef.current.getContent(),
      }
      setData(newData)
      setIsEditingWelcomeText(false)
    })
  }

  return {
    editorRef,
    isEditingWelcomeText,
    setIsEditingWelcomeText,
    saveWelcomeMessage,
    isLoading,
    errorMessage,
    error
  }
}

export default useWelcomeMessage
