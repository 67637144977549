import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { selectedDashboard } from '../DashboardSelectors'

const useDashboardPreviewer = () => {
  const { t } = useTranslation()
  const { currentSubscription } = useSelector(selectedDashboard)

  return {
    t,
    currentSubscription,
  }
}

export default useDashboardPreviewer
