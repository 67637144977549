import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconGoogle = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'Google'
  size = size ?? 24
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 24 24' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <path
        d='M17.5425 7.58528H9.1575V11.0578H13.9875C13.5375 13.2703 11.655 14.5378 9.1575 14.5378C7.74721 14.5378 6.39469 13.9775 5.39746 12.9803C4.40024 11.9831 3.84 10.6306 3.84 9.22028C3.84 7.80999 4.40024 6.45747 5.39746 5.46024C6.39469 4.46302 7.74721 3.90278 9.1575 3.90278C10.366 3.90449 11.5368 4.32303 12.4725 5.08778L15.09 2.47028C13.9972 1.50653 12.6867 0.822506 11.2712 0.476996C9.85571 0.131487 8.37747 0.134821 6.96353 0.486712C5.54959 0.838603 4.24223 1.52853 3.15378 2.49721C2.06534 3.46588 1.22835 4.68434 0.714749 6.04789C0.201145 7.41144 0.0262819 8.8793 0.205184 10.3253C0.384086 11.7714 0.911405 13.1524 1.74183 14.3496C2.57225 15.5469 3.68095 16.5247 4.97265 17.1989C6.26435 17.8731 7.70044 18.2236 9.1575 18.2203C13.6575 18.2203 17.745 14.9503 17.745 9.22028C17.7405 8.66931 17.6726 8.12069 17.5425 7.58528Z'
        fill='#1460AA'
      />
    </Icon>
  )
}

IconGoogle.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconGoogle
