import { call, select } from 'redux-saga/effects'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import getAPIConfig from '@/shared/tools/getAPIConfig'

export const billingModuleActions = {
  BILLING_MODULE_FETCH_PROMO_CODE: 'BILLING_MODULE_FETCH_PROMO_CODE',
}

export function* fetchPromoCode({ params, callback }) {
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { get_promo_code: url } = yield select(selectPageConfigurationData)
  const fullUrl = `${url}?lang=${lang}&${params}`
  try {
    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })
    const response = yield call(fetch, fullUrl, config)
    const result = yield call([response, response.json])
    yield call(callback, result)
  } catch (error) {
    console.warn('fetchPromoCodeError', error)
  }
}
