import { createSelector } from 'reselect'

export const selectSubscriptionForm = state => state.subscriptionForm

// example to fetch a given data: useSelector(selectAdditionalSaleDataByName('voltalis'))
export const selectAdditionalSaleDataByName = name => state => state.subscriptionForm.additionalSales[name] ?? null

export const selectSubscriptionFormData = createSelector(selectSubscriptionForm, data => data)

export const selectAllAdditionalSalesData = createSelector(selectSubscriptionForm, data => data.additionalSales)

export const selectCommonSubscriptionData = createSelector(selectSubscriptionForm, data => data.common)

export const selectCustomerSubscriptionData = createSelector(selectSubscriptionForm, data => data.common.customer)

export const selectBillingSubscriptionData = createSelector(selectSubscriptionForm, data => data.common.billing)

export const selectMainAddressSubscriptionData = createSelector(selectSubscriptionForm, data => data.common.mainAddress)

export const selectInvoiceAddressSubscriptionData = createSelector(
  selectSubscriptionForm,
  data => data.common.invoiceAddress,
)

export const selectEnergySubscriptionData = createSelector(selectSubscriptionForm, data => data.energy)

export const selectElectricityMeterSubscriptionData = createSelector(
  selectSubscriptionForm,
  data => data.energy.elecMeter,
)

export const selectGasMeterSubscriptionData = createSelector(selectSubscriptionForm, data => data.energy.gasMeter)

export const selectEnergyContractSubscriptionData = createSelector(selectSubscriptionForm, data => data.energy.contract)

export const selectEnergyCharacteristicsSubscriptionData = createSelector(
  selectSubscriptionForm,
  data => data.energy.characteristics,
)

export const selectTelecomSubscriptionData = createSelector(selectSubscriptionForm, data => data.telecom)

export const selectPropaneSubscriptionData = createSelector(selectSubscriptionForm, data => data.energy.propane)

export const selectEnergyConsumptionData = createSelector(selectSubscriptionForm, data => data.energy.consumption)

export const selectEnergyEstimationData = createSelector(selectSubscriptionForm, data => data.energy.estimation)

export const selectTelecomContractSubscriptionData = createSelector(
  selectSubscriptionForm,
  data => data.telecom.contract,
)

export const selectTelecomCharacteristicsSubscriptionData = createSelector(
  selectSubscriptionForm,
  data => data.telecom.characteristics,
)

export const selectInsuranceContractSubscriptionData = createSelector(
  selectSubscriptionForm,
  data => data.insurance.contract,
)

export const selectInsuranceCharacteristicsSubscriptionData = createSelector(
  selectSubscriptionForm,
  data => data.insurance.characteristics,
)
