import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { KIND, SIZE } from 'baseui/button'
import Title from '@/shared/components/PageTitle'
import PropTypes from 'prop-types'
import useCallInformation from '@/containers/CallInformation/useCallInformation'

const CreateSaleButtons = ({createSaleIsLoading}) => {

  const {
    createSale,
    t,
    authorizedBusinessTypes,
    businessDataMatch
  } = useCallInformation()


  if (authorizedBusinessTypes.length === 0) {
    return
  }

  return (
    <>
      <Title title={t('callInformation:create_sale_button')} />
      {authorizedBusinessTypes.map(type => {
        const { Icon, titleKey, sale, path } = businessDataMatch[type]
        return (
          <SDButton
            key={type}
            isLoading={createSaleIsLoading}
            onClick={() => createSale(sale, path)}
            size={SIZE.large}
            kind={KIND.tertiary}
          >
            <Icon size={24} title={t(titleKey)} />
          </SDButton>
        )
      })}
    </>
  )
}

CreateSaleButtons.propTypes = {
  createSaleIsLoading: PropTypes.bool,
}

export default CreateSaleButtons
