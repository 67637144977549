import { createSelector } from 'reselect'

export const selectPageConfiguration = state => state.pageConfiguration

export const selectPageConfigurationData = createSelector(
  selectPageConfiguration,
  pageConfiguration => pageConfiguration.data,
)

export const selectPageConfigurationError = createSelector(
  selectPageConfiguration,
  pageConfiguration => pageConfiguration.error,
)

export const selectPageConfigurationIsLoading = createSelector(
  selectPageConfiguration,
  pageConfiguration => pageConfiguration.isLoading,
)

export const selectPageConfigurationIsInitialized = createSelector(
  selectPageConfiguration,
  pageConfiguration => pageConfiguration.isInitialized,
)
