import { useState, useRef, useEffect } from 'react'

export default function useResizeObserver(elementRef) {
  const [height, setHeight] = useState(0)
  const resizeObserver = useRef(
    new ResizeObserver(entry => {
      const element = entry[0]
      const computedHeight = Array.isArray(element.borderBoxSize)
        ? element.borderBoxSize[0].blockSize
        : element.borderBoxSize.blockSize

      setHeight(computedHeight)
    }),
  )

  useEffect(() => {
    if (elementRef.current) {
      resizeObserver.current.observe(elementRef.current)
    }
  }, [elementRef, resizeObserver])

  return height
}
