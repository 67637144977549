import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import { StyledBodyCell } from 'baseui/table-grid'

const CommentsCell = ({ comment, striped, selected, noCommentText, color }) => {
  const [css] = useStyletron()
  const cellStyle = css({
    padding: '.5rem',
    backgroundColor: selected ? '#c1d1e2' : 'none',
  })
  const dateStyle = css({
    fontSize: '12px',
    fontWeight: 'bold',
    textAlign: 'right',
  })
  const lastComment = comment ?? noCommentText
  const lastCommentSliced = lastComment.length > 60 ? `${lastComment.slice(0, 60)}...` : lastComment
  const commentStyle = css({
    fontSize: '12px',
    fontStyle: 'italic',
    color,
  })
  const date = ''
  /* lastCommentArray?.comment ?
      new Date(lastCommentArray.createdAt)
        .toLocaleString('fr-FR') :
      */

  return (
    <StyledBodyCell
      className={cellStyle}
      $style={() => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      })}
      $striped={striped && !selected}
    >
      <p className={commentStyle}>{lastCommentSliced}</p>
      <span className={dateStyle}>{date}</span>
    </StyledBodyCell>
  )
}

CommentsCell.propTypes = {
  comment: PropTypes.string,
  striped: PropTypes.bool,
  selected: PropTypes.bool,
  noCommentText: PropTypes.string,
  color: PropTypes.string,
}

export default CommentsCell
