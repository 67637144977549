import { useState } from 'react'
import PropTypes from 'prop-types'
import { FormControl } from 'baseui/form-control'
import { SIZE } from 'baseui/button-group'
import SDInput from '@/shared/components/SDComponents/SDInput/SDInput'

const GenericInput = ({ onBlur, label }) => {
  const [value, setValue] = useState('')
  return (
    <FormControl label={label}>
      <SDInput size={SIZE.compact} value={value} onBlur={onBlur} onChange={event => setValue(event.target.value)} />
    </FormControl>
  )
}

GenericInput.propTypes = {
  onBlur: PropTypes.func,
  label: PropTypes.string,
}

export default GenericInput
