import {
  StyledHeader,
  StyledServiceCard,
  StyledTitle,
  checkboxFCOverrides,
  checkboxOverrides,
} from '@/containers/ServicesStatus/styled-components'
import IconCarbonPhone from '@/shared/icons/IconCarbonPhone'
import { FormControl } from 'baseui/form-control'
import { Checkbox, LABEL_PLACEMENT, STYLE_TYPE } from 'baseui/checkbox'
import { FlexGrid } from 'baseui/flex-grid'
import PropTypes from 'prop-types'
import useVonageSwitchModule from './useVonageSwitchModule'

const VonageSwitchModule = ({ vonageStatusDb, vonageUpdateUrl }) => {
  const { vonageStatus, vonageStatusLoading, submitVonageStatus, vonageTitle } = useVonageSwitchModule(
    vonageStatusDb,
    vonageUpdateUrl,
  )

  return (
    <>
      <FlexGrid flexGridColumnCount={1} flexGridColumnGap='scale800' flexGridRowGap='scale800'>
        <StyledServiceCard>
          <StyledHeader>
            <IconCarbonPhone />
            <StyledTitle>{vonageTitle}</StyledTitle>
          </StyledHeader>
          <FormControl overrides={checkboxFCOverrides}>
            <Checkbox
              disabled={vonageStatusLoading}
              overrides={checkboxOverrides}
              checked={vonageStatus}
              checkmarkType={STYLE_TYPE.toggle_round}
              onChange={submitVonageStatus}
              labelPlacement={LABEL_PLACEMENT.left}
            ></Checkbox>
          </FormControl>
        </StyledServiceCard>
      </FlexGrid>
    </>
  )
}

VonageSwitchModule.propTypes = {
  vonageStatusDb: PropTypes.bool.isRequired,
  vonageUpdateUrl: PropTypes.string.isRequired,
}

export default VonageSwitchModule
