import SDSelect from '@/shared/components/SDComponents/SDSelect/SDSelect'
import PropTypes from 'prop-types'
import { TYPE } from 'baseui/select'

const HOPGroupedSelect = ({
  hasError = false,
  optionsList,
  selectedValue,
  onChangeHandler,
  placeholder = '',
  clearable = false,
  type = TYPE.select,
  disabled = false,
}) => {
  return (
    <SDSelect
      disabled={disabled}
      error={hasError}
      options={optionsList}
      clearable={clearable}
      value={selectedValue}
      onChange={onChangeHandler}
      placeholder={placeholder}
      type={type}
    />
  )
}

HOPGroupedSelect.propTypes = {
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  optionsList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChangeHandler: PropTypes.func,
  hasError: PropTypes.bool,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool,
}

export default HOPGroupedSelect
