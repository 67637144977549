import { useState } from 'react'
import { Select, TYPE } from 'baseui/select'
import { FormControl } from 'baseui/form-control'
import debounce from '@/shared/tools/debounce'
import { useTranslation } from 'react-i18next'

const UserComboBox = props => {
  const [localValue, setValue] = useState([])
  const [options, setOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  const getOptionLabel = ({ option }) => {
    return `${option.name} ${option.firstName} - ${option.email}`
  }

  /**
   * Fetch options asyncronously
   * @type {debounced}
   */
  const handleInputChange = debounce(async value => {
    if (!value) {
      return
    }

    setIsLoading(true)

    try {
      // to switch to get route path from BO route() fn
      const result = await fetch(`/admin/users-mngt/search?role=user&search=${value}`)
      const data = await result.json()

      setOptions(data.users)
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, 400)

  return (
    <>
      <FormControl label={t('objectives:users')}>
        <Select
          options={options}
          valueKey='id'
          getOptionLabel={getOptionLabel}
          getValueLabel={getOptionLabel}
          placeholder={t('objectives:users')}
          maxDropdownHeight='300px'
          type={TYPE.search}
          multi
          onChange={({ value }) => {
            setValue(value)
            props?.onChange(value)
          }}
          closeOnSelect={false}
          isLoading={isLoading}
          onInputChange={event => {
            const target = event.target
            handleInputChange(target.value)
          }}
          value={props.value}
          filterOptions={option => option}
        />
      </FormControl>
    </>
  )
}

export default UserComboBox
