const subscriptionStatus = async (route, callback, settings) => {
  try {
    const response = await fetch(route, settings)
    const data = await response.json()
    if (typeof callback === 'function') {
      callback(data)
    }
  } catch (error) {
    if (typeof callback === 'function') {
      callback(error)
    }
  }
}

export default subscriptionStatus
