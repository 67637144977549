import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconSigned = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? ''
  size = size ?? 60
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 40 40' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <circle cx='20' cy='20' r='20' fill='#A8BACD' />
      <mask
        id='mask0_1507_42061'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='8'
        y='8'
        width='24'
        height='24'
      >
        <rect x='8' y='8' width='24' height='24' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_1507_42061)'>
        <path
          d='M22.975 18.7L21.3 17.025L15.9 22.425C15.8 22.525 15.7293 22.6293 15.688 22.738C15.646 22.846 15.625 22.9583 15.625 23.075V23.925C15.625 24.0417 15.671 24.146 15.763 24.238C15.8543 24.3293 15.9583 24.375 16.075 24.375H16.925C17.0417 24.375 17.154 24.354 17.262 24.312C17.3707 24.2707 17.475 24.2 17.575 24.1L22.975 18.7ZM23.575 18.1L24.2 17.5C24.3667 17.3333 24.45 17.1373 24.45 16.912C24.45 16.6873 24.3667 16.4917 24.2 16.325L23.675 15.8C23.5083 15.6333 23.3167 15.55 23.1 15.55C22.8833 15.55 22.6833 15.6333 22.5 15.8L21.875 16.45L23.575 18.1ZM20 29.5C18.6833 29.5 17.446 29.25 16.288 28.75C15.1293 28.25 14.125 27.575 13.275 26.725C12.425 25.875 11.75 24.8707 11.25 23.712C10.75 22.554 10.5 21.3167 10.5 20C10.5 18.6833 10.75 17.4457 11.25 16.287C11.75 15.129 12.425 14.125 13.275 13.275C14.125 12.425 15.1293 11.75 16.288 11.25C17.446 10.75 18.6833 10.5 20 10.5C21.3167 10.5 22.5543 10.75 23.713 11.25C24.871 11.75 25.875 12.425 26.725 13.275C27.575 14.125 28.25 15.129 28.75 16.287C29.25 17.4457 29.5 18.6833 29.5 20C29.5 21.3167 29.25 22.554 28.75 23.712C28.25 24.8707 27.575 25.875 26.725 26.725C25.875 27.575 24.871 28.25 23.713 28.75C22.5543 29.25 21.3167 29.5 20 29.5ZM20 28C22.2333 28 24.125 27.225 25.675 25.675C27.225 24.125 28 22.2333 28 20C28 17.7667 27.225 15.875 25.675 14.325C24.125 12.775 22.2333 12 20 12C17.7667 12 15.875 12.775 14.325 14.325C12.775 15.875 12 17.7667 12 20C12 22.2333 12.775 24.125 14.325 25.675C15.875 27.225 17.7667 28 20 28Z'
          fill='white'
        />
      </g>
    </Icon>
  )
}

IconSigned.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconSigned
