import PropTypes from 'prop-types'
import { Editor } from '@tinymce/tinymce-react'
import { FormControl } from 'baseui/form-control'
import { HOPInput } from '@/shared/components/HOPComponents/HOPInput'
import HOPSelect from '@/shared/components/HOPComponents/HOPSelect'
import { SIZE } from 'baseui/button'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import useMonConseillerPersoForm from './useMonConseillerPersoForm'

const MonConseillerPersoForm = ({ onCancel }) => {
  const {
    t,
    selectedMonConseillerPerso,
    formValue,
    tinymceKey,
    setFormValue,
    submitHandler,
    statusList,
    scriptOfferEditorRef,
    scriptValidationEditorRef,
    errors,
  } = useMonConseillerPersoForm({ close: onCancel })

  return (
    <form onSubmit={submitHandler}>
      <FormControl label={t('monConseillerPersoConfiguration:name')} error={errors?.name ? errors?.name[0] : ''}>
        <HOPInput
          currentValue={formValue.name}
          handleValue={value => {
            setFormValue({ ...formValue, name: value })
          }}
        />
      </FormControl>
      <FormControl
        label={t('monConseillerPersoConfiguration:zoho_id')}
        error={errors?.zoho_id ? errors?.zoho_id[0] : ''}
      >
        <HOPInput
          currentValue={formValue.zoho_id}
          handleValue={value => {
            setFormValue({ ...formValue, zoho_id: value })
          }}
        />
      </FormControl>
      <FormControl
        label={t('monConseillerPersoConfiguration:endpoint_name')}
        error={errors?.endpoint_name ? errors?.endpoint_name[0] : ''}
      >
        <HOPInput
          currentValue={formValue.endpoint_name}
          handleValue={value => {
            setFormValue({ ...formValue, endpoint_name: value })
          }}
        />
      </FormControl>
      <FormControl label={t('monConseillerPersoConfiguration:price')} error={errors?.price ? errors?.price[0] : ''}>
        <HOPInput
          currentValue={formValue.price}
          handleValue={value => {
            setFormValue({ ...formValue, price: value })
          }}
        />
      </FormControl>
      <FormControl label={t('monConseillerPersoConfiguration:status')} error={errors?.status ? errors?.status[0] : ''}>
        <HOPSelect
          optionsList={statusList}
          placeholder={
            formValue.status === 0 || formValue.status === 1
              ? parseInt(formValue.status) === 0
                ? t('monConseillerPersoConfiguration:status_inactive')
                : t('monConseillerPersoConfiguration:status_active')
              : t('monConseillerPersoConfiguration:place_holder')
          }
          selectedValue={formValue.status}
          onChangeHandler={params => {
            setFormValue({ ...formValue, status: params.value[0].id })
          }}
        />
      </FormControl>
      <FormControl
        label={t('monConseillerPersoConfiguration:description')}
        error={errors?.script_offer ? errors?.script_offer[0] : ''}
      >
        <Editor
          apiKey={tinymceKey}
          init={{
            plugins: [
              'advlist',
              'autolink',
              'lists',
              'link',
              'image',
              'charmap',
              'preview',
              'anchor',
              'searchreplace',
              'visualblocks',
              'code',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'code',
              'wordcount',
            ],
            toolbar:
              'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
          }}
          initialValue={formValue.script_offer}
          onInit={(evt, editor) => {
            scriptOfferEditorRef.current = editor
          }}
          onChange={e => {
            setFormValue({ ...formValue, script_offer: e.target.value })
          }}
        />
      </FormControl>

      <FormControl
        label={t('monConseillerPersoConfiguration:script_validation')}
        error={errors?.script_validation ? errors?.script_validation[0] : ''}
      >
        <Editor
          apiKey={tinymceKey}
          init={{
            plugins: [
              'advlist',
              'autolink',
              'lists',
              'link',
              'image',
              'charmap',
              'preview',
              'anchor',
              'searchreplace',
              'visualblocks',
              'code',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'code',
              'wordcount',
            ],
            toolbar:
              'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
          }}
          initialValue={formValue.script_validation}
          onInit={(evt, editor) => {
            scriptValidationEditorRef.current = editor
          }}
          onChange={e => {
            setFormValue({ ...formValue, script_validation: e.target.value })
          }}
        />
      </FormControl>

      <SDButton
        size={SIZE.compact}
        block={false}
        overrides={{
          BaseButton: {
            style: () => ({
              marginRight: '.5rem',
            }),
          },
        }}
        type='submit'
      >
        {selectedMonConseillerPerso.id ? t('common:edit') : t('monConseillerPersoConfiguration:create')}
      </SDButton>
      <SDButton size={SIZE.compact} block={false} type='button' onClick={onCancel}>
        {t('common:cancel')}
      </SDButton>
    </form>
  )
}

MonConseillerPersoForm.propTypes = {
  onCancel: PropTypes.func,
  tinymceKey: PropTypes.string,
}

export default MonConseillerPersoForm
