import { FormControl } from 'baseui/form-control'
import SDInput from '@/shared/components/SDComponents/SDInput/SDInput'
import { SIZE } from 'baseui/input'

const GenericNumber = ({ attributeId, attributeTechName, currentValue, updateOffer, t }) => {
  return (
    <FormControl key={'attr_' + attributeId} label={t('organizationManagement:offer.attr_' + attributeTechName)}>
      <SDInput
        size={SIZE.compact}
        type='number'
        onChange={e => updateOffer('attr_' + attributeId, e.target.value)}
        value={currentValue}
      />
    </FormControl>
  )
}

export default GenericNumber
