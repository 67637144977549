import { StyledTable, StyledHeadCell, StyledBodyCell } from 'baseui/table-grid'
import { useStyletron, withStyle } from 'baseui'
import { useTranslation } from 'react-i18next'
import RowsRequestTable from './RowsRequestTable'
import { Notification } from 'baseui/notification'

const ModalExportLogsTable = ({ data }) => {
  withStyle(StyledBodyCell, {
    display: 'flex',
    alignItems: 'center',
  })
  const [css] = useStyletron()
  const { t } = useTranslation()

  if (data.length === 0) {
    return (
      <Notification
        overrides={{
          Body: {
            style: {
              width: 'auto',
              color: 'rgb(20, 96, 170)',
              backgroundColor: 'rgb(233, 242, 251)',
            },
          },
        }}
      >
        {() => t('subscriptionForm:subscription_actions_module.export_logs.no_export_logs_result')}
      </Notification>
    )
  }

  return (
    <div
      className={css({
        height: 'auto',
      })}
    >
      <StyledTable role='grid' $gridTemplateColumns='auto auto auto auto auto'>
        <div role='row' className={css({ display: 'contents' })}>
          <StyledHeadCell>
            {t('subscriptionForm:subscription_actions_module.export_logs.head_direction_text')}
          </StyledHeadCell>
          <StyledHeadCell>
            {t('subscriptionForm:subscription_actions_module.export_logs.head_workflow_used_text')}
          </StyledHeadCell>
          <StyledHeadCell>
            {t('subscriptionForm:subscription_actions_module.export_logs.head_message_type_text')}
          </StyledHeadCell>
          <StyledHeadCell>
            {t('subscriptionForm:subscription_actions_module.export_logs.head_date_text')}
          </StyledHeadCell>
          <StyledHeadCell>
            {t('subscriptionForm:subscription_actions_module.export_logs.head_content_text')}
          </StyledHeadCell>
        </div>

        {Object.entries(data).map(([index, item]) => {
          const striped = index % 2 === 0
          return (
            // eslint-disable-next-line react/jsx-key
            <>
              <RowsRequestTable
                requestType='SENT'
                striped={striped}
                workflowName={item.workflow_name}
                description={item.description}
                requestDate={item.request_sent.date}
                requestData={item.request_sent.params}
                requestUrl={item.request_sent.url}
                index={index}
                id={item.id}
              />
              <RowsRequestTable
                requestType='RESPONSE'
                striped={striped}
                workflowName={item.workflow_name}
                description={item.description}
                requestDate={item.request_response.date}
                requestData={item.request_response.response}
                index={index}
                id={item.id}
              />
            </>
          )
        })}
      </StyledTable>
    </div>
  )
}

export default ModalExportLogsTable
