import { Notification } from 'baseui/notification'
import PropTypes from 'prop-types'

const ContactPageNotification = ({message}) => {
  return (
    <Notification
      overrides={{
        Body: {
          style: {
            margin: '0 0 0 0',
            width: '100%',
            color: 'rgb(20, 96, 170)',
            backgroundColor: 'rgb(233, 242, 251)'
          }
        }
      }}
    >
      {message}
    </Notification>
  )
}

ContactPageNotification.propTypes = {
  message: PropTypes.string
}

export default ContactPageNotification
