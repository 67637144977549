import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconTransferred = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? ''
  size = size ?? 60
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 40 40' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <circle cx='20' cy='20' r='20' fill='#A8BACD' />
      <mask
        id='mask0_1507_42049'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='8'
        y='8'
        width='24'
        height='24'
      >
        <rect x='8' y='8' width='24' height='24' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_1507_42049)'>
        <path
          d='M18 26.75C17.7833 26.75 17.6043 26.6793 17.463 26.538C17.321 26.396 17.25 26.2167 17.25 26C17.25 25.7833 17.321 25.604 17.463 25.462C17.6043 25.3207 17.7833 25.25 18 25.25H19.25C19.1 23 18.246 21.075 16.688 19.475C15.1293 17.875 13.2333 16.9833 11 16.8C10.7833 16.7667 10.6043 16.679 10.463 16.537C10.321 16.3957 10.25 16.2167 10.25 16C10.25 15.8 10.325 15.625 10.475 15.475C10.625 15.325 10.8 15.2667 11 15.3C13.0833 15.4333 14.925 16.1123 16.525 17.337C18.125 18.5623 19.2833 20.1167 20 22C20.6333 20.4333 21.5377 19.0917 22.713 17.975C23.8877 16.8583 25.1167 15.95 26.4 15.25H23C22.7833 15.25 22.6043 15.179 22.463 15.037C22.321 14.8957 22.25 14.7167 22.25 14.5C22.25 14.3 22.321 14.125 22.463 13.975C22.6043 13.825 22.7833 13.75 23 13.75H27.85C28.1 13.75 28.3127 13.8373 28.488 14.012C28.6627 14.1873 28.75 14.4083 28.75 14.675V19.375C28.75 19.575 28.6793 19.75 28.538 19.9C28.396 20.05 28.2167 20.125 28 20.125C27.7833 20.125 27.604 20.05 27.462 19.9C27.3207 19.75 27.25 19.575 27.25 19.375V16.5C25.7167 17.3167 24.2877 18.45 22.963 19.9C21.6377 21.35 20.9 23.1333 20.75 25.25H22C22.2167 25.25 22.396 25.3207 22.538 25.462C22.6793 25.604 22.75 25.7833 22.75 26C22.75 26.2167 22.6793 26.396 22.538 26.538C22.396 26.6793 22.2167 26.75 22 26.75H18Z'
          fill='white'
        />
      </g>
    </Icon>
  )
}

IconTransferred.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconTransferred
