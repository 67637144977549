import { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import useBusinessType from '@/shared/hooks/useBusinessType'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import { selectAssistanceFormErrors, selectSelectedAssistance } from '../AssistancesSelectors'
import { assistancesActions } from '../AssistancesSagas'
import { useSnackbar } from 'baseui/snackbar'
import { Check } from 'baseui/icon'

const useAssistanceForm = ({ close }) => {
  const dispatch = useDispatch()
  const { enqueue } = useSnackbar()
  const { loaderRoutesEnergy, loaderRoutes, loaderRoutesInsurance } = useSelector(selectAuthData)
  const { getApplicationUrl } = useBusinessType({
    loaderRoutesEnergy,
    loaderRoutes,
    loaderRoutesInsurance,
    modulesStatuses: {},
    modulesStatusesEnergy: {},
    modulesStatusesInsurance: {},
  })
  const initUrl = getApplicationUrl('assistances-management')
  const { t } = useTranslation()
  const { tinymceKey } = useSelector(selectPageConfigurationData)
  const selectedAssistance = useSelector(selectSelectedAssistance)
  const errors = useSelector(selectAssistanceFormErrors)
  const [formValue, setFormValue] = useState(selectedAssistance)
  const descriptionEditorRef = useRef(null)
  const knowMoreEditorRef = useRef(null)
  const list = [
    {
      label: t('assistancesConfiguration:status_inactive'),
      id: 0,
    },
    {
      label: t('assistancesConfiguration:status_active'),
      id: 1,
    },
  ]
  const convertList = list => {
    const convertedList = {}
    list.map(element => {
      convertedList[element.id] = element.label
    })
    return convertedList
  }

  const updateSuccessNotification = message => {
    close()
    enqueue({
      message,
      startEnhancer: ({ size }) => <Check size={size} />,
    })
  }

  const submitHandler = event => {
    event.preventDefault()
    let description = ''
    let knowMore = ''
    if (descriptionEditorRef.current) {
      description = descriptionEditorRef.current.getContent()
    }
    if (knowMoreEditorRef.current) {
      knowMore = knowMoreEditorRef.current.getContent()
    }
    const assistanceData = {
      name: formValue.name,
      description,
      know_more: knowMore,
      price: formValue.price,
      zoho_id: formValue.zoho_id,
      potential_name: formValue.zoho_prefix,
      status: formValue.status || '',
    }

    if (selectedAssistance.id) {
      assistanceData.id = selectedAssistance.id
      dispatch({
        type: assistancesActions.UPDATE_ASSISTANCE,
        assistanceData,
        id: selectedAssistance.id,
        initUrl,
        updateSuccessNotification,
      })
    } else {
      dispatch({ type: assistancesActions.CREATE_ASSISTANCE, assistanceData, initUrl, updateSuccessNotification })
    }
  }

  return {
    t,
    tinymceKey,
    close,
    selectedAssistance,
    statusList: convertList(list),
    formValue,
    setFormValue,
    submitHandler,
    descriptionEditorRef,
    knowMoreEditorRef,
    errors,
  }
}

export default useAssistanceForm
