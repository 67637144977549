import { useState } from 'react'
import { useStyletron } from 'baseui'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import PropTypes from 'prop-types'
import { TYPE } from 'baseui/select'
import { SIZE } from 'baseui/input'
import { useTranslation } from 'react-i18next'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import useHttp from '@/shared/hooks/useHttp'
import SDInput from '@/shared/components/SDComponents/SDInput/SDInput'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { KIND } from 'baseui/button'
import IconCarbonSearch from '@/shared/icons/IconCarbonSearch'
import { useSelector } from 'react-redux'

const CacheSearch = ({ urls }) => {
  const { t } = useTranslation()
  const [css] = useStyletron()
  const cardStyle = css({
    minHeight: '400px',
    with: 'auto',
    maxWidth: '100%',
    padding: '1rem',
    marginBottom: '2rem',
    backgroundColor: 'white',
    borderRadius: '1rem',
    boxShadow: '0 2px 6px rgba(20,96,170,.3)',
  })
  const { sendRequest, getConfig } = useHttp()
  const flexGridItemProps = {
    display: 'flex',
    alignItems: 'center',
  }
  const [searchTerm, setSearchTerm] = useState('')
  const { lang } = useSelector(selectAuthData)
  const handleSearchChange = term => {
    if (term !== '') {
      setSearchTerm(term)
    }
  }
  const [cacheData, setCacheData] = useState('')
  const requestSubscriptionsHandler = response => {
    setCacheData(JSON.stringify(response, null, '\t'))
  }
  const requestSearchHandler = searchTerm => {
    const countryUrl = urls.cache_search + '/' + searchTerm + '?lang=' + lang
    sendRequest(countryUrl, getConfig('GET'), requestSubscriptionsHandler)
  }

  return (
    <>
      <FlexGrid flexGridColumnCount={2} marginBottom='.5rem' flexGridColumnGap='scale800'>
        <FlexGridItem {...flexGridItemProps}>
          <SDInput
            id='cacheSearch'
            type={TYPE.search}
            placeholder={t('devTools:search_cache_key')}
            onChange={event => handleSearchChange(event.target.value)}
          />
          <SDButton
            kind={KIND.secondary}
            size={SIZE.compact}
            startEnhancer={() => <IconCarbonSearch size={16} />}
            overrides={{
              BaseButton: {
                style: () => ({
                  marginLeft: '.5rem',
                  paddingRight: '.5rem',
                  width: '13rem',
                }),
              },
            }}
            onClick={event => {
              event.preventDefault()
              requestSearchHandler(searchTerm, false)
            }}
          >
            {t('devTools:search_button')}
          </SDButton>
        </FlexGridItem>
      </FlexGrid>
      <div className={cardStyle}>
        <pre>{cacheData}</pre>
      </div>
    </>
  )
}

CacheSearch.propTypes = {
  urls: PropTypes.object,
}

export default CacheSearch
