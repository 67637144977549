import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useBusinessType from '@/shared/hooks/useBusinessType'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { useTranslation } from 'react-i18next'
import useModal from '@/shared/hooks/useModal'
import { pageConfigurationActions } from '@/store/PageConfiguration/PageConfigurationSagas'
import {
  selectPageConfigurationData,
  selectPageConfigurationIsLoading,
} from '@/store/PageConfiguration/PageConfigurationSelectors'
import { updateSelectedAssistance } from './AssistancesSlice'
import { selectSelectedAssistance } from './AssistancesSelectors'

const useAssistances = () => {
  const dispatch = useDispatch()
  const { loaderRoutesEnergy, loaderRoutes, loaderRoutesInsurance } = useSelector(selectAuthData)
  const { getApplicationUrl } = useBusinessType({
    loaderRoutesEnergy,
    loaderRoutes,
    loaderRoutesInsurance,
    modulesStatuses: {},
    modulesStatusesEnergy: {},
    modulesStatusesInsurance: {},
  })
  const initUrl = getApplicationUrl('assistances-management')
  const { t } = useTranslation()
  const { assistances, tinymceKey } = useSelector(selectPageConfigurationData)
  const isLoading = useSelector(selectPageConfigurationIsLoading)
  const selectedAssistance = useSelector(selectSelectedAssistance)
  const { isOpen, close, setIsOpen } = useModal()
  const [page, setPage] = useState(1)
  const [assistancesSearch, setAssistancesSearch] = useState(assistances ?? [])

  const setAssistanceHandler = assistanceSearchList => {
    if (assistanceSearchList !== null) {
      setAssistancesSearch(assistanceSearchList)
    } else {
      setAssistancesSearch(assistances)
    }
  }

  const updateAssistanceSelected = assistance => {
    const assistanceSelected = {
      id: assistance?.id ?? 0,
      name: assistance?.id ? assistance.name : '',
      price: assistance?.id ? assistance.price : '',
      description: assistance?.id ? assistance.description : '',
      know_more: assistance?.id ? assistance.know_more : '',
      status: assistance?.id ? assistance.status : '',
      zoho_id: assistance?.id ? assistance.zoho_id : '',
      zoho_prefix: assistance?.id ? assistance.potential_name : '',
    }

    dispatch(updateSelectedAssistance(assistanceSelected))
    setIsOpen(true)
  }

  useEffect(() => {
    dispatch({ type: pageConfigurationActions.INITIALIZE, initUrl })
  }, [])

  useEffect(() => {
    if (!assistances) return
    setAssistancesSearch(assistances)
  }, [assistances])

  return {
    t,
    assistances: assistancesSearch,
    isLoading,
    tinymceKey,
    elementsPerPage: 7,
    isOpen,
    close,
    page,
    setPage,
    setAssistanceHandler,
    updateAssistanceSelected,
    selectedAssistance,
  }
}

export default useAssistances
