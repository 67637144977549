import PropTypes from 'prop-types'
import { Grid, Cell } from 'baseui/layout-grid'
import CommonFallback from '@/shared/components/CommonFallback'
import DashboardFilters from './DashboardFilters/DashboardFilters'
import DashboardTable from './DashboardTable/DashboardTable'
import DashboardPreviewer from './DashboardPreviewer/DashboardPreviewer'
import useDashboard from './useDashboard'
import useDashboardStyles from './useDashboardStyles'
import DashboardPaginator from './DashboardTable/DashboardPaginator'

const Dashboard = ({ type }) => {
  const { isLoading, getSubscriptions, filters, usersList, providersList, applicationType } = useDashboard({ type })

  const { cardStyle } = useDashboardStyles()

  if (isLoading) {
    return <CommonFallback />
  }

  return (
    <Grid>
      <Cell span={12}>
        <DashboardFilters
          applicationType={applicationType}
          type={type}
          filters={filters}
          usersList={usersList}
          providersList={providersList}
        />
      </Cell>
      <Cell span={9}>
        <div className={cardStyle}>
          <DashboardTable />
          <DashboardPaginator />
        </div>
      </Cell>
      <Cell span={3}>
        <DashboardPreviewer updateTable={getSubscriptions} />
      </Cell>
    </Grid>
  )
}

Dashboard.propTypes = {
  type: PropTypes.string,
}

export default Dashboard
