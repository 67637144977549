import { useEffect, useState } from 'react'
import { TYPE } from 'baseui/select'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FormControl } from 'baseui/form-control'
import { Accordion, Panel as AccordionPanel } from 'baseui/accordion'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import SDInput from '@/shared/components/SDComponents/SDInput/SDInput'
import ValidationStatusBadge from '@/shared/components/FormHeader/bones/ValidationStatusBadge'
import useHttp from '@/shared/hooks/useHttp'
import { useSelector } from 'react-redux'

const CommandsTools = ({ urls }) => {
  const { t } = useTranslation()
  const [status, setStatus] = useState({})
  const [commandsList, setCommandsList] = useState([])
  const { sendRequest, getConfig } = useHttp()
  const requestCommandListHandler = response => {
    setCommandsList(response)
  }
  const { lang } = useSelector(selectAuthData)
  const requestSubmitHandler = response => {
    const commandResponse =
      response.status === false
        ? { text: t('devTools:commands_result_ko') + response.response, colour: 'negative' }
        : { text: t('devTools:commands_result_ok'), colour: 'positive' }
    setStatus(commandResponse)
  }
  const submitHandler = command => {
    sendRequest(urls.commands_execute + '?lang=' + lang + '&command=' + command, getConfig('GET'), requestSubmitHandler)
  }
  const getCommandList = () => {
    sendRequest(urls.commands_list + '?lang=' + lang, getConfig('GET'), requestCommandListHandler)
  }
  const commandStatus = () => {
    if (status && Object.keys(status).length !== 0) {
      return <ValidationStatusBadge text={status.text} status={status.colour} />
    }
  }
  const commandRow = (key, value) => {
    return (
      <AccordionPanel
        key={'command' + key}
        title={value.name}
        overrides={{
          Header: {
            style: () => ({
              fontSize: '0.9em',
              margin: '0 0 0 0',
              padding: '0.2rem 0 0.2rem 1rem',
              color: 'grey',
            }),
          },
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: value.description }} />
        <p>
          <b> Signature : {value.signature} </b>
        </p>
      </AccordionPanel>
    )
  }

  useEffect(() => {
    getCommandList()
  }, [])

  return (
    <>
      <FormControl label={t('devTools:commands_label')}>
        <SDInput
          id='commandInput'
          type={TYPE.search}
          placeholder={t('devTools:commands_place_holder')}
          onKeyPress={event => {
            event.key === 'Enter' && submitHandler(event.target.value)
          }}
        />
      </FormControl>
      {commandStatus()}

      <FormControl label={t('devTools:commands_list')}>
        <Accordion>
          {Object.entries(commandsList).map(([key, value]) => {
            return commandRow(key, value)
          })}
        </Accordion>
      </FormControl>
    </>
  )
}

CommandsTools.propTypes = {
  urls: PropTypes.object,
}

export default CommandsTools
