import { useEffect, useState } from 'react'
import { Card } from '@/containers/SubscriptionFormPage/AdditionalSalesPanel/styled-components'
import ObjectiveForm from './Objective/ObjectiveForm'
import { useNavigate } from 'react-router-dom'
import { createObjective } from './objectiveRequest'
import { createGoal } from './goalRequest'
import { useTranslation } from 'react-i18next'
import { useStyletron } from 'baseui'
import { useSelector } from 'react-redux'
import { selectAuthData } from '@/containers/Login/AuthSelectors'

const ObjectiveCreate = () => {
  const navigate = useNavigate()
  const { authTokenSession } = useSelector(selectAuthData)
  const { t } = useTranslation()
  const [css, theme] = useStyletron()
  const headingStyle = css({
    margin: '1rem 10px 30px 30px',
    color: 'white',
    ...theme.typography.HeadingMedium,
  })
  const [showSaveNotification, setShowSaveNotification] = useState()
  const onSubmitHandler = async data => {
    const { goals } = data

    try {
      const objective = await createObjective(data.objective, authTokenSession)
      const objectiveId = objective.id
      const promise = new Promise(async (resolve, reject) => {
        try {
          await createGoal(objectiveId, goals, authTokenSession)
          setShowSaveNotification(true)
          setTimeout(() => {
            navigate(`/telecom/objectives/${objectiveId}`)
          }, 3000)
        } catch (e) {
          reject(e)
          setShowSaveNotification(false)
        }
      })
      await promise
      setShowSaveNotification(true)
      setTimeout(() => {
        navigate(`/telecom/objectives/${objectiveId}`)
      }, 3000)
    } catch (e) {
      console.error(e)
      setShowSaveNotification(false)
    }
  }

  useEffect(() => {
    setShowSaveNotification()
  }, [onSubmitHandler])

  return (
    <>
      <h1 className={headingStyle}>{t('objectives:create_button')}</h1>
      <Card>
        <ObjectiveForm onSubmit={onSubmitHandler} showSaveNotification={showSaveNotification} />
      </Card>
    </>
  )
}

export default ObjectiveCreate
