import { fieldsConfig } from './fieldsConfig'
import GenericNumber from './GenericNumber'
import GenericInput from './GenericInput'
import GenericEditor from './GenericEditor'
import MultipleButtonGroup from './MultipleButtonGroup'
import MultipleCheckbox from './MultipleCheckbox'
import PrelevementDaysOptions from './PrelevementDaysOptions'

const FieldsSwitch = ({ fields, group, t, updateOffer, providerState }) => {
  const translateOptions = options => {
    if (Object.entries(options).length <= 0) {
      return options
    }
    const translatedOptions = {}
    Object.entries(options).map(([key, label]) => {
      translatedOptions[key] = t('organizationManagement:offer.option_' + label)
    })
    return translatedOptions
  }

  if (Object.entries(fields).length <= 0) {
    return ''
  }

  const ordereredFields = []
  Object.entries(fields).map(([, element]) => {
    const config = fieldsConfig[element.tech_name] ?? {}
    if (config.type && config.group === group && config.order) {
      ordereredFields[config.order] = element
    }
  })

  return (
    <>
      {Object.entries(ordereredFields).map(([, element]) => {
        const currentValue = providerState.selectedOffer['attr_' + element.attribute_id] ?? ''
        const config = fieldsConfig[element.tech_name] ?? {}
        if (config.type && config.group === group) {
          switch (config.type) {
            case 'checkbox':
              return (
                <MultipleCheckbox
                  label={t('organizationManagement:offer.attr_' + element.tech_name)}
                  options={translateOptions(config.optionsList ?? {})}
                  selectedValue={currentValue}
                  onChange={value => updateOffer('attr_' + element.attribute_id, value)}
                />
              )
            case 'radio':
              return (
                <MultipleButtonGroup
                  parentKey={'attr_' + element.attribute_id}
                  label={t('organizationManagement:offer.attr_' + element.tech_name)}
                  options={translateOptions(config.optionsList ?? {})}
                  selectedValue={currentValue}
                  onClick={value => updateOffer('attr_' + element.attribute_id, value)}
                />
              )
            case 'input':
              return (
                <GenericInput
                  attributeId={element.attribute_id}
                  updateOffer={updateOffer}
                  attributeTechName={element.tech_name}
                  currentValue={currentValue}
                  t={t}
                />
              )
            case 'number':
              return (
                <GenericNumber
                  attributeId={element.attribute_id}
                  updateOffer={updateOffer}
                  attributeTechName={element.tech_name}
                  currentValue={currentValue}
                  t={t}
                />
              )
            case 'editor':
              return (
                <GenericEditor
                  attributeId={element.attribute_id}
                  updateOffer={updateOffer}
                  attributeTechName={element.tech_name}
                  currentValue={currentValue}
                  t={t}
                  tinymceKey={providerState.tinymceKey}
                />
              )
            case 'prelevmentDaysOptions':
              return (
                <PrelevementDaysOptions
                  attributeId={element.attribute_id}
                  updateOffer={updateOffer}
                  attributeTechName={element.tech_name}
                  providerState={providerState}
                  currentValue={currentValue}
                  t={t}
                />
              )
            default:
              return 'UNDEFINED TYPE: ' + config.type
          }
        }
      })}
    </>
  )
}

export default FieldsSwitch
