export const TelecomOfferParametersGeneration = (offerData, selectedProviderId) => {
  const options = new URLSearchParams()
  options.append('id', offerData.id ?? '')
  options.append('organisation_id', selectedProviderId ?? '')
  options.append('product_type', offerData.product_type ?? '')
  options.append('name', offerData.name ?? '')
  options.append('zoho_id', offerData.zoho_id ?? '')
  options.append('active', offerData.active ?? '')
  options.append('description', offerData.description ?? '')

  return options
}
