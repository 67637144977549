import PropTypes from 'prop-types'

import SDPaginator from '@/shared/components/SDComponents/SDPaginator/SDPaginator'

const Paginator = ({ elements, setPage, page, elementsPerPage }) => {
  const lastPage = Math.ceil(elements.length / elementsPerPage)

  if (!elements) {
    return ''
  }

  return (
    <SDPaginator
      numPages={lastPage}
      currentPage={page}
      onPageChange={({ nextPage }) => {
        setPage(Math.min(Math.max(nextPage, 1), lastPage))
      }}
    />
  )
}

Paginator.propTypes = {
  elements: PropTypes.array,
  setPage: PropTypes.func,
  page: PropTypes.number,
  elementsPerPage: PropTypes.number,
}

export default Paginator
