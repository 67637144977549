import { styled } from 'baseui'

export const StyledFormServicesPanel = styled('div', () => ({
  display: 'flex',
  flexDirection: 'column',

  '@media screen and (min-width: 768px)': {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: '-1rem',
  },
}))

export const StyledServiceCard = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem',
  margin: '0 0 1rem 0',
  backgroundColor: $theme.colors.white,
  borderRadius: $theme.borders.radius400,
  boxShadow: $theme.lighting.shadow400,

  '@media screen and (min-width: 768px)': {
    flex: '0 1 calc(50% - 1rem)',
    margin: '0 1rem 1rem 0',
  },

  '@media screen and (min-width: 1100px)': {
    flex: '0 1 calc(33% - 1rem)',
    margin: '0 1rem 1rem 0',
  },
}))

export const StyledHeader = styled('div', () => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '.5rem',
  justifyContent: 'space-between',
}))

export const StyledTitle = styled('h2', ({ $theme }) => ({
  margin: '0',
  color: $theme.colors.primary,
  ...$theme.typography.HeadingSmall,
}))

export const StyledText = styled('p', () => ({
  margin: '0 0 1rem 0',
}))

export const titleIconOverrides = {
  Svg: {
    style: () => ({
      marginRight: '.5rem',
    }),
  },
}

export const checkboxOverrides = {
  Label: {
    style: () => ({
      marginRight: 'auto',
    }),
  },
}

export const buttonOverrides = {
  ControlContainer: {
    style: () => ({
      marginTop: '2rem',
    }),
  },
}

export const checkboxFCOverrides = {
  ControlContainer: {
    style: () => ({
      marginTop: 'auto',
      marginBottom: '0',
      width: 'auto',
    }),
  },
}
