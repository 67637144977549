import { useCallback } from 'react'

const useImportScript = () => {
  const loadScript = useCallback((resourceUrl, async = true, loggedIn, additionalAttributes = {}) => {
    if (loggedIn) {
      const script = document.createElement('script')
      script.src = resourceUrl
      if (async) {
        script.async = async
      }
      for (const [key, value] of Object.entries(additionalAttributes)) {
        script.setAttribute(key, value)
      }
      document.body.appendChild(script)
      return () => {
        document.body.removeChild(script)
      }
    }
  }, [])

  return {
    loadScript,
  }
}

export default useImportScript
