import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'
import { FormControl } from 'baseui/form-control'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import SDInput from '@/shared/components/SDComponents/SDInput/SDInput'
import { useTranslation } from 'react-i18next'
import HeadingAddEdit from '../HeadingAddEdit'

const InsuranceForm = ({ providerDispatch, providerState, saveProvider, title }) => {
  const { t } = useTranslation()
  const [css, theme] = useStyletron()
  const cardStyle = css({
    padding: '1rem',
    marginBottom: '2rem',
    color: 'white',
  })
  const blockStyle = css({
    padding: '1rem',
    marginTop: '2rem',
    marginBottom: '2rem',
    backgroundColor: 'white',
    borderRadius: '1rem',
    boxShadow: '0 2px 6px rgba(20,96,170,.3)',
    width: '100%',
    color: theme.colors.contentSecondary,
  })
  const sectionStyle = css({
    margin: '0.5rem 5px 5px 5px',
    color: theme.colors.primary,
  })
  const updateProvider = (attributeName, value) => {
    providerDispatch({ type: 'updateProvider', payload: { [attributeName]: value } })
  }
  return (
    <div className={cardStyle}>
      <HeadingAddEdit title={title} saveHandler={saveProvider} />
      <FlexGrid flexGridColumnCount={3} flexGridColumnGap='scale800' flexGridRowGap='scale800'>
        <FlexGridItem>
          <div className={blockStyle}>
            <h2 className={sectionStyle}>{t('organizationManagement:general_info.title')}</h2>
            <FormControl label={t('organizationManagement:name')}>
              <SDInput
                value={providerState.selectedProvider.name ?? ''}
                onChange={event => {
                  event.preventDefault()
                  updateProvider('name', event.target.value)
                }}
              />
            </FormControl>
            <FormControl label={t('organizationManagement:general_info.zoho_prefix')}>
              <SDInput
                value={providerState.selectedProvider.zoho_prefix ?? ''}
                onChange={event => {
                  event.preventDefault()
                  updateProvider('zoho_prefix', event.target.value)
                }}
              />
            </FormControl>
          </div>
        </FlexGridItem>
      </FlexGrid>
    </div>
  )
}

InsuranceForm.propTypes = {
  providerState: PropTypes.object,
  providerDispatch: PropTypes.func,
  saveProvider: PropTypes.func,
  title: PropTypes.string,
}

export default InsuranceForm
