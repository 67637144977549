import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconCarbonVoltalis = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'Voltalis'
  size = size ?? 16
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 32 32' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <path
        d='M24.6,17.4c-0.7,4.3-4.6,7.4-9,7.2c-4.4-0.2-7.9-3.8-8.2-8.2c-0.2-4.4,2.9-8.3,7.2-9c0.8-0.1,1.4-0.8,1.5-1.7
		c0-0.5-0.2-1-0.6-1.3c-0.4-0.3-0.9-0.5-1.4-0.4C8,5,3.6,10.5,4,16.7c0.4,6,5.3,10.9,11.3,11.3C21.5,28.4,27,24,28,17.9
		c0.1-0.5-0.1-1-0.4-1.4c-0.3-0.4-0.8-0.6-1.3-0.6C25.5,15.9,24.7,16.6,24.6,17.4z'
      />
      <path
        className='st1'
        d='M17.5,5.6c-0.3,0.9,0.2,1.9,1.1,2.1c0.1,0,0.3,0.1,0.4,0.1c0.8,0,1.5-0.4,1.7-1.2c0.1-0.4,0.1-0.9-0.1-1.3
		c-0.2-0.4-0.6-0.7-1-0.8c-0.4-0.1-0.9-0.1-1.3,0.1C17.9,4.8,17.6,5.2,17.5,5.6z M22.1,9.9c-0.7-0.7-0.7-1.7,0-2.4
		C22.5,7.2,22.9,7,23.3,7c0.5,0,0.9,0.2,1.2,0.5C24.9,7.8,25,8.2,25,8.7c0,0.5-0.2,0.9-0.5,1.2c-0.3,0.3-0.8,0.5-1.3,0.5
		C22.8,10.4,22.4,10.2,22.1,9.9z M24.3,13.4c-0.3-0.9,0.2-1.8,1.1-2.1c0.9-0.3,1.8,0.2,2.1,1.1c0.3,0.9-0.2,1.8-1.1,2.1
		c-0.2,0.1-0.4,0.1-0.6,0.1C25.1,14.6,24.5,14.1,24.3,13.4z'
      />
    </Icon>
  )
}

IconCarbonVoltalis.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconCarbonVoltalis
