import { FormControl } from 'baseui/form-control'
import { HOPInput } from '@/shared/components/HOPComponents/HOPInput'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { SIZE } from 'baseui/button'

import useAddEditModal from './useAddEditModal'
import SDSelect from '@/shared/components/SDComponents/SDSelect/SDSelect'
import { TYPE } from 'baseui/select'
import { useState } from 'react'

const AddEditModal = ({ setIsOpen, ipWhitelistSelected, setIpWhitelistSelected, ipAddressesUrls, organizations, refreshList }) => {
  const { t, close, formErrors, submitHandler } = useAddEditModal({
    setIsOpen,
    ipWhitelistSelected,
    ipAddressesUrls,
    refreshList
  })

  const defaultOrganization = organizations.find((organization) => organization.id === ipWhitelistSelected.organization_id) ?? ''
  const [organization, setOrganization] = useState([defaultOrganization])

  return (
    <form onSubmit={(event) => {
      event.preventDefault()
      submitHandler(organization[0]?.id ?? '')
    }}
    >
      <FormControl label={t('ipAddressesManagement:modal.name')} error={formErrors.name ? formErrors.name[0] : ''}>
        <HOPInput
          currentValue={ipWhitelistSelected.name}
          handleValue={value => {
            setIpWhitelistSelected({
              ...ipWhitelistSelected,
              name: value
            })
          }}
        />
      </FormControl>
      <FormControl
        label={t('ipAddressesManagement:modal.ip_addresses')}
        error={formErrors.ip_addresses ? formErrors.ip_addresses[0] : ''}
      >
        <HOPInput
          currentValue={ipWhitelistSelected.ip_addresses}
          handleValue={value => {
            setIpWhitelistSelected({
              ...ipWhitelistSelected,
              ip_addresses: value
            })
          }}
        />
      </FormControl>

      <FormControl
        label={t('ipAddressesManagement:organization')}
      >
        <SDSelect
          id='organization_id'
          type={TYPE.search}
          options={organizations}
          value={organization}
          onChange={params => setOrganization(params.value)}
        />
      </FormControl>

      <SDButton
        disabled={false}
        isLoading={false}
        size={SIZE.compact}
        block={false}
        overrides={{
          BaseButton: {
            style: () => ({
              marginRight: '.5rem'
            })
          }
        }}
        type='submit'
      >
        {ipWhitelistSelected.id ? t('common:edit') : t('common:create')}
      </SDButton>
      <SDButton size={SIZE.compact} block={false} type='button' onClick={close}>
        {t('common:cancel')}
      </SDButton>
    </form>
  )
}

export default AddEditModal
