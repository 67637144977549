import { useSelector } from 'react-redux'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import useBusinessType from '@/shared/hooks/useBusinessType'
import { useTranslation } from 'react-i18next'

const useBusinessVerticalAccess = () => {
  const { authorizedSites } = useSelector(selectAuthData)
  const { t } = useTranslation()
  const { getApplicationTypeFolder, translateBusinessTypeToUrl } = useBusinessType({
    loaderRoutesEnergy: {},
    loaderRoutes: {},
    loaderRoutesInsurance: {},
    modulesStatuses: {},
    modulesStatusesEnergy: {},
    modulesStatusesInsurance: {},
  })
  const otherVerticals =
    authorizedSites && Array.isArray(authorizedSites) && authorizedSites.length > 0
      ? authorizedSites.filter(site => {
          const appFolder = getApplicationTypeFolder()
          return site.business_type !== appFolder.toLowerCase()
        })
      : []
  const switchToVertical = businessType => {
    const path = window.location.pathname.split('/')
    const translatedPath = translateBusinessTypeToUrl(businessType)
    window.location = `/${translatedPath}/subscription/edit/${path[4]}`
  }

  return {
    otherVerticals,
    t,
    switchToVertical,
  }
}

export default useBusinessVerticalAccess
