import formatUTCDate from '@/shared/tools/formatUTCDate'

const fetchObjective = async objectiveId => {
  const url = `/admin/api/goals/objectives/${objectiveId}`
  const response = await fetch(url)
  const objective = await response.json()

  return objective
}

const createObjective = async (objective, token) => {
  const { start, end, description } = objective
  const formData = new FormData()
  formData.append('start', formatUTCDate(start))
  formData.append('end', formatUTCDate(end))
  formData.append('description', description)
  const response = await fetch('/admin/api/goals/objectives', {
    method: 'POST',
    body: formData,
    headers: {
      'X-CSRF-TOKEN': token,
    },
  })
  return await response.json()
}

const updateObjective = async (objective, token) => {
  const { id, start, end, description } = objective

  const formData = new FormData()
  formData.append('start', formatUTCDate(start))
  formData.append('end', formatUTCDate(end))
  formData.append('description', description)
  formData.append('_method', 'PUT')

  return await fetch(`/admin/api/goals/objectives/${id}`, {
    method: 'POST',
    body: formData,
    headers: {
      'X-CSRF-TOKEN': token,
    },
  })
}

const deleteObjective = async (id, token) => {
  return fetch(`/admin/api/goals/objectives/${id}`, {
    method: 'DELETE',
    headers: {
      'X-CSRF-TOKEN': token,
    },
  })
}

export { fetchObjective, updateObjective, deleteObjective, createObjective }
