import { useCallback, useEffect, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import { SIZE } from 'baseui/input'
import useHttp from '@/shared/hooks/useHttp'
import { useSelector } from 'react-redux'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import SDSelect from '@/shared/components/SDComponents/SDSelect/SDSelect'
import { TYPE } from 'baseui/select'
import { FormControl } from 'baseui/form-control'
import { Textarea } from 'baseui/textarea'
import providerActions from '@/containers/OrganizationsManagement/providerActions'
import providerData from '@/containers/OrganizationsManagement/providerData'
import useBusinessType from '@/shared/hooks/useBusinessType'
import { useTranslation } from 'react-i18next'
import { useStyletron } from 'baseui'
import { Button } from 'baseui/button'
import { Notification } from 'baseui/notification'
import { Card, StyledBody } from 'baseui/card'

const ImportPodTool = ({ batchPdlNetUpdaterUrl }) => {
  const { t } = useTranslation()
  const { loaderRoutesEnergy, loaderRoutes, loaderRoutesInsurance, lang } = useSelector(selectAuthData)
  const { getApplicationUrl } = useBusinessType({
    loaderRoutesEnergy,
    loaderRoutes,
    loaderRoutesInsurance,
    modulesStatuses: {},
    modulesStatusesEnergy: {},
    modulesStatusesInsurance: {},
  })
  const initUrl = getApplicationUrl('organizations-management')
  const { sendRequest, getConfig } = useHttp()
  const [providerId, setProviderId] = useState('')
  const [pdlList, setPdlList] = useState([])
  const [providerState, providerDispatch] = useReducer(providerActions, providerData)
  const [error, setError] = useState(false)
  const [responseMessage, setResponseMessage] = useState(null)
  const [css, theme] = useStyletron()
  const handleRequest = useCallback(async url => {
    const headers = getConfig('POST')
    const options = new URLSearchParams()
    options.append('provider', providerId[0].uuid)
    const pdlArray = pdlList.split('\n').map(pdl => pdl.trim()).filter(pdl => pdl.length > 0)
    options.append('pdlList', JSON.stringify(pdlArray))
    headers.body = options

    sendRequest(`${url}?lang=${lang}`, headers, (response, statusCode) => {
      if (statusCode === 200) {
        setError(false)
        setResponseMessage(response.message || '')
      } else {
        setError(true)
        setResponseMessage(response.error || t('devTools:batch_pdl_to_net_updater.unknown_error'))
      }
    })
  }, [getConfig, lang, pdlList, providerId, sendRequest, t])

  const initialize = useCallback(async () => {
    if (!initUrl) return

    await sendRequest(`${initUrl}?lang=${lang}`, getConfig('GET'), response =>
      providerDispatch({ type: 'initialize', payload: response }),
    )
  }, [getConfig, initUrl, lang, sendRequest])

  useEffect(() => {
    initialize()
  }, [])

  return (
    <div className={css({
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      width: '100%',
    })}>
      <Card
        overrides={{
          Root: {
            style: {
              width: '100%',
              maxWidth: '800px',
            },
          },
        }}
      >
        <StyledBody>
          <FormControl label={t('devTools:batch_pdl_to_net_updater.select_provider_label')}>
            <SDSelect
              id='provider'
              type={TYPE.search}
              options={providerState.general.providersList}
              value={providerId}
              onChange={params => setProviderId(params.value)}
            />
          </FormControl>

          <FormControl label={t('devTools:batch_pdl_to_net_updater.pdl_list_label')}>
            <Textarea
              value={pdlList}
              onChange={e => setPdlList(e.target.value)}
              placeholder="PDL list"
              clearOnEscape
            />
          </FormControl>

          <Button
            size={SIZE.compact}
            onClick={event => {
              event.preventDefault()
              handleRequest(batchPdlNetUpdaterUrl)
            }}
            overrides={{
              BaseButton: {
                style: {
                  width: '100%',
                  backgroundColor: theme.colors.primary,
                  color: theme.colors.white,
                },
              },
            }}
          >
            {t('devTools:batch_pdl_to_net_updater.update_button')}
          </Button>

          {responseMessage && (
            <Notification
              kind={error ? 'negative' : 'positive'}
              overrides={{
                Body: {
                  style: {
                    marginTop: '20px',
                    width: '100%',
                    maxWidth: '800px',
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                  },
                },
              }}
            >
              {responseMessage}
            </Notification>
          )}
        </StyledBody>
      </Card>
    </div>
  )
}

ImportPodTool.propTypes = {
  batchPdlNetUpdaterUrl: PropTypes.string.isRequired,
}

export default ImportPodTool
