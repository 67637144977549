import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'

const PanelHeading = ({ title, subtitle }) => {
  const [css, theme] = useStyletron()
  const componentStyle = css({
    margin: 0,
    padding: '10px',
  })
  const headingStyle = css({
    margin: 0,
    color: theme.colors.primary,
    ...theme.typography.HeadingSmall,
  })
  const paragraphStyle = css({
    margin: 0,
  })

  return (
    <div className={componentStyle}>
      <h2 className={headingStyle} color={theme.colors.primary}>
        {title}
      </h2>
      <p className={paragraphStyle}>{subtitle}</p>
    </div>
  )
}

PanelHeading.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default PanelHeading
