import { useTranslation } from 'react-i18next'
import useModal from '@/shared/hooks/useModal'
import { useState } from 'react'
import { useStyletron } from 'styletron-react'
import useBusinessType from '@/shared/hooks/useBusinessType'

const useToolsUtilities = updateTable => {
  const { t } = useTranslation()
  const { isOpen, setIsOpen } = useModal()
  const [modalType, setModalType] = useState('')
  const { type } = useBusinessType({
    loaderRoutesEnergy: [],
    loaderRoutes: [],
    modulesStatuses: {},
    modulesStatusesEnergy: {},
  })
  const [css] = useStyletron()
  const buttonGroupStyle = css({
    width: '100%',
  })
  const buttonStyle = css({
    flex: '1 1 0',
  })
  const setIsOpenHandler = type => {
    updateTable()
    setIsOpen(false)
  }

  return {
    t,
    setModalType,
    modalType,
    isOpen,
    setIsOpen,
    buttonGroupStyle,
    buttonStyle,
    setIsOpenHandler,
    applicationType: type,
  }
}

export default useToolsUtilities
