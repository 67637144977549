import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconCarbonEngie = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'Engie'
  size = size ?? 16
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 32 32' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <path className='st0' d='M36.9,3.4' />
      <path
        d='M29.8,22.8c0,0,0-0.7-0.4-0.7c-0.1,0-0.1,0-0.2-0.1c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.1-0.3-0.1-0.4l-0.3,0
	c0.1-0.4,0-1.3-0.2-0.8c-0.3,0.5-1,1.7-1.1,1.2c-0.1-0.4,0.2-1.3,0.3-2.4l0.7-4.6c0.4-2.7-2.7-2.3-4.6-1.3c-0.1,0-0.1,0.2-0.1,0.2
	c-0.1,0.1-0.3,0.2-0.3,0.3c-0.1,0.1-0.1,0.4-0.1,0.4c0,0.2-0.2,0.3-0.2,0.4c0,0.3,0,0.6,0.2,0.8c0,0.1,0.4-0.1,0.4,0
	c0,0.1,0,0.1,0.3,0c0.4-0.1,0.8-0.2,0.7,0.2l-1,4.6c-0.3,1.1-0.5,2.4-0.5,2.9c0,3,4.1,2.7,5.5,1.6c0.5-0.4,1.1-1,1.2-1.6
	C30,23.2,29.7,23.3,29.8,22.8L29.8,22.8z M8.5,23.2c0-0.3-0.2-0.6-0.4-0.7c-0.2-0.2-0.7,0.2-1,0.2c-0.3,0-0.4-0.1-0.5-0.3
	c-0.1-0.4,0-1.3,0.2-2.7l0.7-4.8c0.1-1.1-0.4-1.8-1.1-2c-0.6-0.2-1.2-0.2-2.1,0.1c-0.8,0.3-1.9,1-1.7,1.2c-0.1,0.1-0.4,0.3-0.4,0.5
	c-0.1,0.2-0.2,0.1-0.3,0.4c-0.1,0.4,0.1,0.5,0.1,1c0.1,0.1,0.3,0.1,0.5,0c0,0,0.6-0.2,0.6,0c0,0,0,0,0.1,0c0.2,0.1,0.8-0.4,0.7,0
	C3.9,16.6,3.2,19,2.8,21c-0.2,1.2-0.3,2.3-0.3,2.8C2.6,25,3.4,25.9,5,25.9c2,0,3.2-0.8,3.2-1.2c0-0.1,0.3-0.4,0.4-0.5
	c0-0.1,0-0.3,0-0.3c0-0.1,0.2-0.1,0.2-0.3C8.9,23.4,8.6,23.5,8.5,23.2z M22,22.1c-0.1,0-0.2,0-0.2-0.1c-0.5,0.5-1.1,1-1.9,1
	c-0.8,0-1.6-0.3-2.2-0.7c-0.5-0.3-1.1-0.6-1.6-0.9c-0.1-0.1-0.3-0.1-0.4-0.2c0,0-0.1,0-0.1,0l4.6-4.3c1.1-1,1.5-1.8,1.5-2.6
	c0-1-0.6-1.8-2.1-1.6l0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.4,0-0.5,0.1c-0.1,0-0.3,0.1-0.5,0.1c-0.4,0.1-2.9,0-3.8-0.2l-2.5-0.4
	l-0.1,0c-0.3,0-0.6-0.1-0.9,0c-0.1,0-0.3,0-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1C10,12.7,9.8,12.9,9.8,13c0,0.1,0.1,0.2,0,0.2
	c-0.1,0-0.2,0.1-0.4,0.3c-0.2,0.2-0.2,0.3-0.1,0.4C9.5,14,9.6,14,9.8,14.1c0.3,0.2,0.1,0.4,0,0.4c0,0.1,0,0.3,0.3,0.6
	c0.2,0.2,0.2,0.5,0.3,0.5c0.1,0,0.3,0.2,0.4,0.2c0,0.1,0.1,0.1,0.1,0.1c0,0,0.2,0,0.4,0c0.3,0,0.6,0.1,0.9,0.1c0.1,0,0,0.1,0.4,0.1
	c0.9,0.1,1.9,0,2.5-0.1L16,16c0.1,0,0.4,0,0.5,0c0.1,0,0.4,0,0.4-0.1c-0.8,1.2-2,2-3,2.9L11.5,21c-1.6,1.4-1.8,1.9-1.8,2.8
	c0,1.3,0.9,1.8,2,1.7c0.2,0.1,0.9-0.2,1.9-0.8c0.4-0.2,0.6-0.1,1,0c0.3,0.1,0.6,0.2,0.9,0.3c0.7,0.3,1.6,0.5,2.3,0.7
	c1,0.2,1.7,0.2,2.4,0c0.8-0.2,1.4-0.8,1.5-1c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.1-0.3-0.1-0.5c0.1,0,0.2-0.1,0.3-0.2s0.1-0.1,0.1-0.2
	c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.2,0-0.3,0-0.5c0.1-0.2,0.4-0.3,0.4-0.4C22.2,22.1,22.1,22.1,22,22.1z M9.5,6.1
	C8.6,6.2,7.8,6.5,7.7,6.5c-0.1,0-0.4-0.3-0.5-0.3c-0.2,0-0.5,0-0.8,0.1C6,6.4,5.8,6.5,5.4,6.9C5,7.3,4.7,8,4.6,8.6l0.1,0.4
	c0,0,0,0.6,0.1,0.8c0.5,0.8,0.5,0.5,0.9,1.1C6.4,11.1,6.6,11,6.8,11c0.7,0,0.9-0.2,1.1-0.6L8,10.3c0.3-0.1,0.5-0.3,0.7-1
	C8.9,9,8.9,8.7,9,8.3c0,0,0-0.5-0.2-0.5c0-0.3,0-0.9,0.4-1c0.1-0.2,0.2-0.3,0.6-0.4C10,6.3,9.9,6,9.5,6.1z M25.6,8.8
	c-0.2,0.6,0.3,1.8,0.8,1.8c0,0,0.3,0.1,0.5,0.1c0.2,0,0.4,0.2,0.6,0.2c0.6,0,0.9-0.1,1.2-0.3c0.2-0.1,0.4-0.5,0.6-0.5
	c0.4-0.3,0.6-1,0.6-1.8c0-0.1-0.1-0.6-0.1-0.6c-0.3-0.4-0.1-0.9-0.4-1.1c0,0-0.5,0.2-0.4-0.4C29,5.9,28.7,6,28.3,6.3
	c-0.2,0.2-0.4-0.1-0.5-0.1c-1.7,0.4-1.8,1.5-2.3,2C25.5,8.4,25.5,8.6,25.6,8.8L25.6,8.8z'
      />
    </Icon>
  )
}

IconCarbonEngie.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconCarbonEngie
