import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconCustomMuterLoger = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'ContentView'
  size = size ?? 16
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='0 0 32 32' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <path d='M18 14.5h2v-1c0-.6.4-1 1-1h6c.6 0 1 .4 1 1v6h2v-6c0-1.7-1.3-3-3-3h-6c-1.7 0-3 1.3-3 3v1zM24 9.5c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zM2 19.5h2v-6c0-.6.4-1 1-1h6c.6 0 1 .4 1 1v1h2v-1c0-1.7-1.3-3-3-3H5c-1.7 0-3 1.3-3 3v6zM8 9.5c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zM16 16C8.3 16 2 22.3 2 30h2c0-.9.1-1.7.3-2.6.6-2.5 1.9-4.8 3.8-6.4l1.3 2h3.3l-1.5 4h-4l-1 3h2.1l.3-1H11v1h2v-1.8l2.9-7.8-1.9-.7-.4 1.3h-3l-.8-1.2c2.8-1.7 6.1-2.2 9.3-1.4V22c0 1.1.9 2 2 2h2.6c.5 0 1-.2 1.4-.6l.1-.1.3-.7c.8 1 1.4 2.2 1.9 3.4h-4.7c-1 0-1.8.7-2 1.7l-.5 2.3h2l.3-2h5.2c.1.7.2 1.3.2 2h2c.1-7.7-6.2-14-13.9-14zm7.5 6H21v-2.9c1.1.5 2 1.1 2.9 1.9l-.4 1z' />
    </Icon>
  )
}

IconCustomMuterLoger.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconCustomMuterLoger
