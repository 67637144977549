import useContactPage from '@/containers/ContactPage/useContactPage'
import { useStyletron } from 'baseui'
import PropTypes from 'prop-types'
import { StyledHeadCell, StyledRow, StyledTable } from 'baseui/table'
import SubscriptionsRowsTable from '@/containers/ContactPage/SubscriptionsRowsTable'
import ContactPageNotification from '@/containers/ContactPage/ContactPageNotification'

const SubscriptionsResultTable = ({subscriptions, urlCreateCache}) => {
  const {
    t,
  } = useContactPage()
  const [css] = useStyletron()
  const subscriptionsArray = Object.keys(subscriptions).map(key => subscriptions[key]);

  if (subscriptions.length === 0) {
    return (
      <ContactPageNotification message={t('contactPage:subscriptions_table_results.noContent')} />
    )
  }

  return (
    <div
      className={css({
        height: 'auto',
        width: '100%',
      })}
    >
    <StyledTable role='grid'>
      <StyledRow role='row'>
        <StyledHeadCell $style={{ width: '10%' }}>
          {t('contactPage:subscriptions_table_results.headers.subscriptionId')}
        </StyledHeadCell>
        <StyledHeadCell $style={{ width: '20%' }}>
          {t('contactPage:subscriptions_table_results.headers.email')}
        </StyledHeadCell>
        <StyledHeadCell $style={{ width: '15%' }}>
          {t('contactPage:subscriptions_table_results.headers.firstName')}
        </StyledHeadCell>
        <StyledHeadCell $style={{ width: '15%' }}>
          {t('contactPage:subscriptions_table_results.headers.lastName')}
        </StyledHeadCell>
        <StyledHeadCell $style={{ width: '10%' }}>
          {t('contactPage:subscriptions_table_results.headers.phone')}
        </StyledHeadCell>
        <StyledHeadCell $style={{ width: '10%' }}>
          {t('contactPage:subscriptions_table_results.headers.createdAt')}
        </StyledHeadCell>
        <StyledHeadCell $style={{ width: '10%' }}>
          {t('contactPage:subscriptions_table_results.headers.dataCompleted')}
        </StyledHeadCell>
        <StyledHeadCell $style={{ width: '10%' }}>
          {t('contactPage:subscriptions_table_results.headers.actions')}
        </StyledHeadCell>
      </StyledRow>
        {subscriptionsArray.map((item, index) => (
            <SubscriptionsRowsTable
              key={index}
              subscriptionId={item.id}
              striped={index % 2 === 0}
              email={item.email}
              firstName={item.firstname}
              lastName={item.lastname}
              phone={item.phone1}
              createdAt={item.created_at}
              productEnergyCount={item.product_energy_count}
              productInsuranceCount={item.product_insurance_count}
              productTelecomCount={item.product_telecom_count}
              addressCount={item.adresses_count}
              bankingCount={item.banking_count}
              urlCreateCache={urlCreateCache}
            />
        ))}
    </StyledTable>
    </div>
  )
}

SubscriptionsResultTable.propTypes = {
  subscriptions: PropTypes.array,
  urlCreateCache: PropTypes.string,
}

export default SubscriptionsResultTable
