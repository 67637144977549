import { FormControl } from 'baseui/form-control'
import SDInput from '@/shared/components/SDComponents/SDInput/SDInput'
import useContactPage from '@/containers/ContactPage/useContactPage'
import { SIZE } from 'baseui/input'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { useEffect } from 'react'
import SubscriptionsResultTable from '@/containers/ContactPage/SubscriptionsResultTable'
import ContactPageNotification from '@/containers/ContactPage/ContactPageNotification'
import Title from '@/shared/components/PageTitle'

const ContactPage = () => {
  const {
    t,
    zoho,
    setZoho,
    requestSearchContactHandler,
    initialize,
    subscriptionsResults,
    data,
    urlCreateCache,
    createCacheError,
    loading,
    searchError
  } = useContactPage()

  useEffect(() => {
    initialize()
  }, [])

  return (
  <>
    <Title title={t('contactPage:module_title')} id='contact-page' />
      <FormControl label={t('contactPage:zoho_label')}>
        <SDInput
          id='zoho-contact-search-input'
          size={SIZE.default}
          placeholder={t('contactPage:search_input_place_holder')}
          onChange={event => setZoho(event.target.value)}
          value={zoho}
          autoComplete={'off'}
        />
      </FormControl>

      <FormControl>
        <SDButton
          onClick={event => {
            event.preventDefault()
            requestSearchContactHandler()
          }}
          isLoading={loading}
        >{t('contactPage:search_contact_button')}
        </SDButton>
      </FormControl>

    {
      createCacheError &&
      <ContactPageNotification message={createCacheError} />
    }

    {
      searchError &&
      <ContactPageNotification message={searchError} />
    }

    {
      subscriptionsResults && !searchError && data.urls &&
      <SubscriptionsResultTable
        subscriptions={subscriptionsResults}
        urlCreateCache={urlCreateCache()}
      />
    }
  </>
  )
}

export default ContactPage
